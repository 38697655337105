import { IonPage } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import "./NoAccess.css";

const NoAccess = () => {
  const { t } = useTranslation();
  return (
    <IonPage>
      <h1 className="mainText">{t("noAccess")}</h1>
    </IonPage>
  );
};

export default NoAccess;
