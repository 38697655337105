import { useTranslation } from "react-i18next";
import React, { Dispatch, FC, SetStateAction } from "react";
import { IonButton, IonText } from "@ionic/react";
import Alert from "../../../../../Shared/Alert/Alert";
import "./ResultsButtons.css";

type IProps = {
  onUpdateData: () => void;
  goToNextHeat: (unsavedResults: boolean | undefined) => void;
  openAlert: boolean;
  setOpenAlert: Dispatch<SetStateAction<boolean>>;
  isLastHeat: boolean;
  unsavedResults: boolean | undefined;
};

const ResultsButtons: FC<IProps> = (props: IProps): JSX.Element => {
  const {
    onUpdateData,
    goToNextHeat,
    openAlert,
    setOpenAlert,
    isLastHeat,
    unsavedResults,
  } = props;
  const { t } = useTranslation();

  const onCancal = () => setOpenAlert(false);

  const onSave = async () => {
    if (unsavedResults) {
      onUpdateData();
      goToNextHeat(false);
    }
  };

  return (
    <div className="results-buttons-wrapper">
      <IonButton
        className="update-results-button"
        disabled={!unsavedResults}
        onClick={onUpdateData}
      >
        <IonText>{t("saveResults")}</IonText>
      </IonButton>
      <IonButton
        className="next-heat-button"
        onClick={() => goToNextHeat(unsavedResults)}
      >
        <IonText>{t("passToNextHeat")}</IonText>
      </IonButton>
      <Alert
        isOpen={openAlert && (isLastHeat || unsavedResults!)}
        setIsOpen={setOpenAlert}
        header={
          unsavedResults
            ? `${t("noSaveResults")}`
            : isLastHeat
            ? `${t("reachedLastHeat")}`
            : ""
        }
        actionOnSave={onSave}
        actionOnCancel={onCancal}
        actionText={unsavedResults ? "save" : "OK"}
      />
    </div>
  );
};

export default ResultsButtons;
