import { IonPage } from "@ionic/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { fetchActivePlanData } from "../../../apis/PlansAPI";
import useInitGraphsData from "../../../components/CustomHooks/useInitGraphsData";
import ModalExcel from "../../../components/Desktop/ToExcel/exportModal";
import ILabel from "../../../Interfaces/ILabel";
import IThreshold from "../../../Interfaces/IThreshold";
// css
import "./ReportGenerator.css";

const ReportGenerator: React.FC = (): JSX.Element => {
  const { t, i18n } = useTranslation();

  const { data } = useQuery("graphsData", fetchActivePlanData);
  const [labelsDetailsActivePlan, setLabelsDetailsActivePlan] = useState<
    ILabel[]
  >([] as ILabel[]);
  const [thresholdsDetailsActivePlan, setThresholdsDetailsActivePlan] =
    useState<IThreshold[]>([] as IThreshold[]);
  const { labelsCompetency, thresholdsCompetency, setActivePlanID } =
    useInitGraphsData(
      data,
      t,
      undefined,
      setLabelsDetailsActivePlan,
      setThresholdsDetailsActivePlan
    );
  return (
    <IonPage>
      <ModalExcel
        indicatorsLabels={labelsCompetency}
        indicatorsThreshold={thresholdsCompetency}
        trainingTypesLabels={labelsDetailsActivePlan}
        trainingTypesThreshold={thresholdsDetailsActivePlan}
        setPlanId={setActivePlanID}
      />
    </IonPage>
  );
};
export default ReportGenerator;
