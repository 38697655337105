import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import PMIcon from "../../../themeComponents/PMIcon";
import PMLabel from "../../../themeComponents/PMLabel";
import TraineesList from "../TraineesList/TraineesList";
import "./TraineesModal.css";
import { ITrainee } from "../../../../Interfaces/results/ITrainee.interface";
import { useTranslation } from "react-i18next";
import DragModal from "../../DragModal/DragModal";
import { ITrainingTypeResult } from "../../../../Interfaces/dataCalculator";

interface IModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  sortedElement: string;
  trainingType?: ITrainingTypeResult;
  trainees: ITrainee[];
}

const TraineesModal = (props: IModalProps) => {
  const { t } = useTranslation();

  return (
    <DragModal
      cssClass="traineesModal"
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
    >
      <IonGrid className="traineesModalGrid">
        <IonRow className="TMHeaderRow">
          <IonCol className="TMtitleCol" size="6">
            <PMLabel
              cssClass="dashboardModalHeader"
              fontColor="xLight"
              fontFamily="Light"
            >
              {props.trainingType && t(props.trainingType.name)}
            </PMLabel>
          </IonCol>
          <IonCol className="backdropCol" size="6">
            <PMIcon
              iconSrc={EIconsSrc.CLOSE}
              cssClass="backdropIcon"
              onClick={props.onDismiss}
            ></PMIcon>
          </IonCol>
        </IonRow>
        {/* <IonRow className="elementsCheckboxRow">כלל המרכיבים</IonRow> */}
        <IonRow className="traineesListRow">
          <TraineesList
            trainees={props.trainees}
            elements={props.trainingType && props.trainingType.elements}
            sortedElement={props.sortedElement}
            trainingTypeId={props.trainingType && props.trainingType.id}
          ></TraineesList>
        </IonRow>
      </IonGrid>
    </DragModal>
  );
};

export default TraineesModal;
