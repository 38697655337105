import Axios from "../Axios";
import { baseUrlPMBackend } from "../Configurations/consts";

export const getRelevantPlans = (forcesIds: number[]) => {
  return Axios.get(`${baseUrlPMBackend}plans/getRelevantPlans`, {
    params: {
      forcesIds: forcesIds,
    },
  });
};
