import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { IonApp, IonContent, isPlatform } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ToastContainer } from "react-toastify";
import { environment, gaTrackingCode } from "./Configurations/consts";
import ReactGA from "react-ga";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Pages */
import Header from "./components/Desktop/Header/Header";
import SideMenu from "./components/Desktop/SideMenu/SideMenu";
import Footer from "./components/Desktop/Footer/Footer";
import Tabs from "./components/Mobile/Tabs/Tabs";
import { UserContext } from "./context/UserContext/userContext";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { Switch } from "react-router";
import NotFound from "./pages/Desktop/NotFound/NotFound";
import { BrowserRouter } from "react-router-dom";
import useLogin from "./components/CustomHooks/useLogin";
import AuthRoute from "./components/Desktop/AuthRoute/AuthRoute";
import { routeRoles, routes } from "./services/routeRoles";
import NoAccess from "./pages/Desktop/NoAccess/NoAccess";
import Login from "./pages/Desktop/Login/Login";
import ForcesContextProvider from "./context/ForcesContext/forcesContextProvider";
import Loading from "./pages/Desktop/Loading/Loading";
import { AppContextProvider } from "./context/AppContextProvider";
import EEnvironment from "./Enums/EEnvironment";
import { setupIonicReact } from "@ionic/react"; // must be import in ionic v6
import { addUserForTracking } from "./apis/TrackingLoginsAPI";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { fetchPlans } from "./redux/actions/plansActions";

setupIonicReact({});

const App: FC = () => {
  const { i18n, t } = useTranslation();
  document.documentElement.lang = i18n.language;
  const { isLoggedIn, toggleDarkModeHandler, darkMode, user } =
    useContext(UserContext);
  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(true);
  const queryClient = new QueryClient();

  const dispatch = useDispatch<Dispatch<any>>();
  const { isPersonalZone } = useLogin(setIsLoginLoading);

  // Google Analytics (this is  using in Universal Analytics that will stop processing data in 1.7.2023, we need to change it to Google Analytics 4, the new version of GA).
  ReactGA.initialize(`${gaTrackingCode}`);

  const firstUpdate = useRef(true);

  const setLanguage = () => {
    let currentLang = localStorage.getItem("language");
    if (currentLang === null) {
      localStorage.setItem("language", i18n.language);
    } else {
      i18n.changeLanguage(currentLang);
    }
  };

  // Checks theme preference according to local storage
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    let theme = localStorage.getItem("theme");
    if (theme !== "dark") toggleDarkModeHandler();
    setLanguage();
  }, []);

  const addUserToLoginTracking = async () => {
    try {
      await addUserForTracking(user.id);
    } catch (error) {
      console.error("Can't insert user to login tracking table");
    }
  };

  useEffect(() => {
    if (
      firstUpdate.current &&
      isLoggedIn &&
      environment === EEnvironment.production
    ) {
      firstUpdate.current = false;
      addUserToLoginTracking();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchPlans(t));
    }
  }, [isLoggedIn, dispatch, t]);

  return isLoginLoading ? (
    <Loading></Loading>
  ) : !isLoggedIn && environment === EEnvironment.localSite ? (
    <>
      <Login></Login>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        className="toast-container"
      />
    </>
  ) : (
    <BrowserRouter>
      <Switch>
        <AuthRoute
          path={routes.noAccess}
          Component={NoAccess}
          requiredRoles={[...routeRoles.Unauthorized]}
        ></AuthRoute>
        <AuthRoute
          path="/"
          Component={() => (
            <IonApp className="noselect">
              <IonContent dir={i18n.language === "he" ? "rtl" : "ltr"}>
                {/* Root page for desktop */}
                {isPlatform("desktop") && (
                  <div className="desktopweb">
                    <IonReactRouter>
                      <Header
                        setDarkMode={toggleDarkModeHandler}
                        darkMode={darkMode}
                      />
                      <SideMenu />
                      <ToastContainer
                        position="bottom-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl
                        pauseOnFocusLoss={false}
                        draggable
                        pauseOnHover
                        className="toast-container"
                      />
                    </IonReactRouter>
                    <Footer />
                  </div>
                )}

                {/* Root page for mobile website */}
                {isPlatform("mobileweb") && (
                  <div className="mobileweb">
                    <IonReactRouter>
                      <QueryClientProvider client={queryClient}>
                        <Tabs />
                      </QueryClientProvider>
                    </IonReactRouter>
                    <ToastContainer
                      position="bottom-right"
                      autoClose={3000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl
                      pauseOnFocusLoss={false}
                      draggable
                      pauseOnHover
                      className="toast-container"
                    />
                  </div>
                )}
              </IonContent>
            </IonApp>
          )}
          requiredRoles={[
            ...routeRoles.Admins,
            ...routeRoles.Instructors,
            ...routeRoles.Viewers,
          ]}
        ></AuthRoute>
        <AuthRoute
          path="/*"
          Component={NotFound}
          requiredRoles={[
            ...routeRoles.Unauthorized,
            ...routeRoles.Admins,
            ...routeRoles.Instructors,
            ...routeRoles.Viewers,
          ]}
        ></AuthRoute>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
