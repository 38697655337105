import { IonRow, IonItem, IonLabel, IonInput, IonText } from "@ionic/react";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./HeatsList.css";
import IHeat from "../../../../Interfaces/IHeat";
import { heatList } from "./heatList";
import Spinner from "../../../Shared/Spinner/Spinner";

interface IProps {
  heat: IHeat | undefined;
  setHeat: (state: IHeat | undefined) => void;
  okHandler?: () => void;
  isDesktop?: boolean;
}

const HeatsList: FC<IProps> = (props: IProps): JSX.Element => {
  const { setHeat, isDesktop, heat, okHandler } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const { t } = useTranslation();

  // Heat is clicked
  const clicked = (heat: IHeat) => {
    setHeat(heat);
  };

  // Resets heat on load
  useEffect(() => {
    setHeat(undefined);
  }, []);

  // After heat has been updated, move to next screen
  useEffect(() => {
    if (heat !== undefined && okHandler) okHandler();
  }, [heat]);

  return (
    <div className="heatsListComponent">
      {loading ? (
        <Spinner className="spinner-trainees-list" />
      ) : (
        <div
          className={isDesktop ? "heatListWrapDesktop" : "heatListWrapMobile"}
        >
          <IonRow>
            <IonItem
              mode="md"
              lines={"none"}
              className={
                "itemHL container labelInputColor" +
                `${isDesktop ? "" : " mobileLabelInput"}`
              }
            >
              <IonLabel className="searchTextLabel" position="floating">
                {t("militaryId")}
              </IonLabel>
              <IonInput
                className={"numberColor " + (isDesktop ? "border" : "")}
                value={searchText}
                onIonChange={(event) => {
                  setSearchText(
                    event.detail.value && event.detail.value !== null
                      ? event.detail.value
                      : ""
                  );
                }}
              ></IonInput>
            </IonItem>
          </IonRow>
          <IonRow className="heatsListRow">
            <div className={isDesktop ? "heatsList" : "mobileHeatsList"}>
              {heatList.find(
                (heat) =>
                  String(heat.id).includes(searchText) ||
                  heat.name.includes(searchText)
              ) ? (
                heatList
                  .filter(
                    (heat) =>
                      String(heat.id).includes(searchText) ||
                      heat.name.includes(searchText)
                  )
                  .map((heat) => (
                    <IonItem
                      key={heat.id}
                      mode="md"
                      button
                      className={
                        "heatFound itemHL " +
                        (isDesktop ? "backgroundSearch " : "heatFoundMobile ")
                      }
                      lines={"full"}
                      onClick={() => {
                        clicked(heat);
                      }}
                    >
                      <IonText className="heatNameHL">{`${heat.name} (${heat.bullets})`}</IonText>
                    </IonItem>
                  ))
              ) : (
                <IonItem
                  mode="md"
                  className={
                    "heatNotFound itemHL" + (isDesktop ? "" : "heatFoundMobile")
                  }
                  lines={"none"}
                >
                  <IonText className={"heatNotFound"}>
                    {t("heatNotFound")}
                  </IonText>
                </IonItem>
              )}
            </div>
          </IonRow>
        </div>
      )}
    </div>
  );
};

export default HeatsList;
