import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PMLabel from "../../../components/themeComponents/PMLabel";
import "./DataHistoryMobile.css";

type Props = {
  data: any;
};

const keysToFilter = [
  "drillId",
  "DrillStartTime",
  "DrillEndTime",
  "rowIndex",
  "date",
  "hour",
];

const AccordionMobileData = (props: Props) => {
  const { t } = useTranslation();

  // filter the header that already exist and the props that shouldn't exist
  const filteredData = Object.keys(props.data)
    .filter((key) => !keysToFilter.includes(key))
    .reduce((cur: any, key: string) => {
      return Object.assign(cur, { [key]: props.data[key] });
    }, {});

  const filteredDataEntries = Object.entries(filteredData);

  return (
    <IonGrid className="in-accordion-mobile-data">
      <IonRow>
        {filteredData &&
          filteredDataEntries.map(([key, value]) => {
            return (
              <IonCol key={key} size="6">
                <IonGrid>
                  <IonRow>
                    <PMLabel
                      fontColor="light"
                      fontFamily="Light"
                      fontSize="medium"
                      textAlign="start"
                    >
                      {t(`${key}`)}
                    </PMLabel>
                  </IonRow>
                  <IonRow>
                    <PMLabel
                      fontColor="xLight"
                      fontFamily="Light"
                      fontSize="medium"
                      textAlign="start"
                    >
                      {value}
                    </PMLabel>
                  </IonRow>
                </IonGrid>
              </IonCol>
            );
          })}
      </IonRow>
    </IonGrid>
  );
};

export default AccordionMobileData;
