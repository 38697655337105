const clientId = process.env.REACT_APP_MSAL_CLIENT_ID;
const tenantId = process.env.REACT_APP_MSAL_TENANT_ID;
const scope = process.env.REACT_APP_SCOPE;
const apiUri = process.env.REACT_APP_API_URI;

const baseUrlPMBackendBasic = process.env.REACT_APP_BASE_URL_PMBACKEND;
const baseUrlPMBackend = `${baseUrlPMBackendBasic}api/`;
const MAX_SELECTED = 5;
const environment = process.env.REACT_APP_ENVIRONMENT;
const gaTrackingCode = process.env.REACT_APP_GA_TRACKING_CODE;
const ga4TrackingCode = process.env.REACT_APP_GA4_TRACKING_CODE;
const mode = process.env.REACT_APP_MODE || "site";
const trainingTypeMode = process.env.REACT_APP_TRAINING_TYPE_MODE;

// const authority = process.env.REACT_APP_MSAL_AUTHORITY;
// const redirectUri = process.env.REACT_APP_MSAL_REDIRECT_URI;
// const cacheLocation = process.env.REACT_APP_MSAL_CACHE_LOCATION;

export {
  baseUrlPMBackend,
  baseUrlPMBackendBasic,
  MAX_SELECTED,
  environment,
  gaTrackingCode,
  ga4TrackingCode,
  tenantId,
  apiUri,
  clientId,
  scope,
  mode,
  trainingTypeMode,
  // authority,
  // redirectUri,
  // cacheLocation,
};
