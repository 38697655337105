import React, { FC, useEffect, useState, memo } from "react";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import IPlan from "../../../../../Interfaces/IPlan";
import IForceFromOrbat from "../../../../../Interfaces/IForceFromOrbat";
import IForceTreeNode from "../../../../../Interfaces/IForceTreeNode";
import { useTranslation } from "react-i18next";
import DashboardDoughnut from "../../../../../components/Desktop/DashboardDoughnut/DashboardDoughnut";
import PMLabel from "../../../../../components/themeComponents/PMLabel";
import useGetCommanderDashboardGrades, {
  getElementColor,
} from "../../../../../components/CustomHooks/useGetCommanderDashboardGrades";
import ILabel from "../../../../../Interfaces/ILabel";
import { getForceFullName } from "../../../../../services/helpers";
import "react-modern-drawer/dist/index.css";
import customToast from "../../../../../components/Shared/Toast/CustomToast";
import Legend from "../../../../../components/Desktop/VerticalProgressBar/Legend";
import Colors from "../../../../../Interfaces/Colors";
import TraineesModal from "../../../../../components/Desktop/DashboardTrainees/TraineesModal/TraineesModal";
import { IForce } from "../../../../../Interfaces/results/force.interface";
import { ITrainee } from "../../../../../Interfaces/results/ITrainee.interface";
import { IModalResult } from "../../../../../Interfaces/results/modal-result.interface";
import { getRelevantPlans } from "../../../../../services/plansSharedFunctions";
import { isTwoLevelsAtleast } from "../../../../../services/treeSharedFunctions";
import DashboardTrainingTypeComp from "../../../../../components/Desktop/DashboardComps/DashboardTrainingTypeComp";
import {
  IElementResult,
  IIndicatorResult,
  ITrainingTypeResult,
} from "../../../../../Interfaces/dataCalculator";

import "./CommanderDashboard.css";
import PerformanceTemplate from "../../performanceTemplate";
import usePlans from "../../../../../components/CustomHooks/usePlans";
import {
  getIndicatorsDataForTrainingPlan,
  getTrainingTypesDataForTrainingPlan,
} from "../../../../../apis/PlansAPI";

interface IProps {
  exportToCsv: (imageUrl: string, exportType: number) => void;
  isAdmin?: boolean | true;
  isDrawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CommanderDashboard: FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const [indicatorsLabels, setIndicatorsLabels] = useState<ILabel[]>(
    [] as ILabel[]
  );
  const [trainingTypesLabels, setTrainingTypesLabels] = useState<ILabel[]>(
    [] as ILabel[]
  );

  const { isDrawerOpen, setDrawerOpen } = props;
  const [relevantPlansIds, setRelevantPlansIds] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedIndicator, setSelectedIndicator] = useState<number>();
  const [commanderForces, setCommanderForces] = useState<IForceFromOrbat[]>([]);
  const [orbatForces, setOrbatForces] = useState<IForceTreeNode[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [sortedElement, setSortedElement] = useState<string>("");
  const [modalTrainees, setModalTrainees] = useState<ITrainee[]>([]);
  const [trainingType, setTrainingType] = useState<ITrainingTypeResult>();
  const towDigitsElements = ["ממוצע מקבץ", "מקבץ מוביל"];
  const getForceChildren = (orbatTree: IForceTreeNode) => {
    return orbatTree
      ? orbatTree?.nodes
        ? [
            {
              forceType: orbatTree.force_type,
              id: orbatTree.id,
              isSoldier: orbatTree.nodes === null,
              name: orbatTree.name,
            },
            ...orbatTree.nodes
              .filter((f) => f.nodes !== null)
              ?.map((node) => ({
                forceType: node.force_type,
                id: node.id,
                isSoldier: node.nodes === null,
                name: getForceFullName(node.name, node.force_type, t),
              })),
          ]
        : [
            {
              forceType: orbatTree.force_type,
              id: orbatTree.id,
              isSoldier: orbatTree.nodes === null,
              name: orbatTree.name,
            },
          ]
      : [];
  };
  const { onPlanSelectedHandler, plansOptions, selectedPlan } =
    usePlans(relevantPlansIds);
  const {
    trainingTypeData,
    setTrainingTypeData,
    indicatorData,
    setIndicatorData,
    displayedForceData,
    amountOfSoldiers,
    onlySoldiers,
    selectedForcesID,
    setSelectedForcesID,
    setAmountOfSoldiers,
    setDisplayedForceData,
    setForceData,
    forceData,
  } = useGetCommanderDashboardGrades(
    selectedPlan,
    commanderForces,
    refresh,
    t,
    setLoading
  );

  /** reset the commander dashboard states in order to present empty charts */
  const resetCommanderStates = () => {
    setAmountOfSoldiers(undefined);
    setIndicatorData(undefined);
    setTrainingTypeData(undefined);
    setSelectedForcesID([]);
  };

  useEffect(() => {
    let mounted = true;
    getRelevantPlans(commanderForces.map((force) => force.id)).then(
      (response: any) => {
        response.data &&
          mounted &&
          setRelevantPlansIds(
            response.data.map((selectedPlan: IPlan) => Number(selectedPlan.id))
          );
      }
    );
    return () => {
      mounted = false;
    };
  }, [commanderForces]);

  useEffect(() => {
    if (orbatForces.length) {
      setLoading(true);
      setCommanderForces(getForceChildren(orbatForces[0]));
    } else {
      setDisplayedForceData(undefined);
    }
    resetCommanderStates();
  }, [orbatForces]);

  useEffect(() => {
    if (refresh) {
      if (orbatForces.length) {
        setLoading(true);
      }
      setForceData(undefined);
      setDisplayedForceData(undefined);
    }
  }, [refresh]);

  useEffect(() => {
    if (refresh && forceData !== undefined) {
      resetCommanderStates();
      setRefresh(false);
    }
  }, [forceData]);

  useEffect(() => {
    /** when selected plan changes load the plans training types and indicators data */
    if (selectedPlan) {
      resetCommanderStates();
      setForceData(undefined);
      setDisplayedForceData(undefined);
      setRefresh(true);
      getTrainingTypesDataForTrainingPlan(selectedPlan?.id).then((res) => {
        !orbatForces.length && setLoading(false);
        if (res.data.trainingTypesData)
          setTrainingTypesLabels(
            res.data.trainingTypesData.map(
              (indicator: { trainingTypeData: ILabel }) =>
                indicator.trainingTypeData
            )
          );
        else customToast.error(t("errorWhileLoadingPlanData"));
      });
      getIndicatorsDataForTrainingPlan(selectedPlan?.id).then((res) => {
        !orbatForces.length && setLoading(false);
        if (res.data.indicatorsData)
          setIndicatorsLabels(
            res.data.indicatorsData.map(
              (indicator: { indicatorData: ILabel }) => indicator.indicatorData
            )
          );
        else customToast.error(t("errorWhileLoadingPlanData"));
      });
    }
  }, [selectedPlan]);

  const onCheckForce = (forces: IForceTreeNode[]) => {
    if (!isTwoLevelsAtleast(forces[0])) {
      setDisplayedForceData(undefined);
      customToast.error(t("platoonAndUP"));
      setOrbatForces([]);
    } else {
      setDisplayedForceData(undefined);
      setOrbatForces((prev) => {
        let result = forces.map((force: IForceTreeNode) => {
          let currName: string = force.name;
          currName = currName.includes(force.force_type)
            ? currName
            : getForceFullName(force.name, force.force_type, t);

          return {
            ...force,
            name: currName,
          };
        });
        if (JSON.stringify(prev) === JSON.stringify(result)) return prev;
        return result;
      });
    }
  };

  const onCardClick = (
    element: string,
    trainingType: ITrainingTypeResult,
    indicatorId: number
  ) => {
    setIsModalOpen(true);
    setSortedElement(element);
    setTrainingType(trainingType);
    setModalTrainees(
      onlySoldiers!.map((soldier: IForce): ITrainee => {
        const parentName = soldier.hierarchicalName.split(" -> ");
        const trainee: ITrainee = {
          forceId: soldier.id,
          forceName: soldier.name,
          forceHierarchy: parentName[parentName.length - 2],
          results: soldier.results.indicatorResults
            .find(
              (indicatorResult: IIndicatorResult) =>
                Number(indicatorResult.id) === Number(indicatorId)
            )
            ?.trainingTypeResults.find(
              (trainingTypeResult: ITrainingTypeResult) =>
                Number(trainingTypeResult.id) === Number(trainingType.id)
            )
            ?.elementResults.map((er: IElementResult) => {
              er = {
                ...er,
                value:
                  !er.value && er.value !== 0 //make sure the value is null
                    ? er.value
                    : towDigitsElements.includes(er.name)
                    ? Number(er.value).toFixed(2)
                    : Math.round(er.value),
              };
              const result: IModalResult = {
                elementId: er.id,
                elementName: er.name,
                value: er.value,
                color: getElementColor(er),
              };
              return result;
            }),
        };
        if (trainee.results === undefined) trainee.results = [];
        return trainee;
      })
    );
  };

  const chart = (
    <div className="commanderDashboardChart">
      <TraineesModal
        isOpen={isModalOpen}
        sortedElement={sortedElement}
        onDismiss={() => setIsModalOpen(false)}
        trainees={modalTrainees}
        trainingType={trainingType}
      ></TraineesModal>
      <div className="CDHeaderCol">
        <div className="CDHeaderRow">
          <PMLabel
            cssClass="CDPlanName"
            fontColor="xLight"
            fontFamily="Regular"
          >
            {orbatForces[0]?.name}
          </PMLabel>
          {amountOfSoldiers && (
            <PMLabel
              cssClass="totalAmountValue"
              fontColor="xLight"
              fontFamily="Regular"
              fontSize="small"
            >
              {amountOfSoldiers} {t("soldiers")}
            </PMLabel>
          )}
        </div>
        <div className="legendRow">
          {displayedForceData?.children.map((child: IForce, index: number) =>
            !child.isSoldier ? (
              <Legend
                selectLowLevelForce={onCheckForce}
                key={child.id}
                forceName={child.name}
                color={Colors[index]}
                isSelected={
                  !selectedForcesID.length
                    ? undefined
                    : selectedForcesID.includes(Number(child.id))
                }
                setSelectedForcesID={setSelectedForcesID}
                forceId={Number(child.id)}
                force={orbatForces[0]?.nodes?.find(
                  (f) => Number(f.id) === Number(child.id)
                )}
              ></Legend>
            ) : null
          )}
        </div>
      </div>
      <div className="wrapDoughutIndicators scrollL">
        <div className="CDDoughunt ">
          <IonGrid className="CDdGrid">
            <IonRow className="dRow">
              {indicatorsLabels.map((indicator) => (
                <IonCol
                  key={indicator.id}
                  className="CDColl"
                  size={
                    12 / indicatorsLabels.length <= 1.6
                      ? String(12 / indicatorsLabels.length)
                      : "1.6"
                  }
                >
                  <DashboardDoughnut
                    key={indicator.id}
                    setSelectedIndicator={setSelectedIndicator}
                    selectedIndicator={selectedIndicator}
                    indicatorId={Number(indicator.id)}
                    indicatorName={
                      indicator.label !== "" ? t(indicator.label) : ""
                    }
                    isDisableAll={!indicatorData}
                    chartData={
                      indicatorData
                        ? indicatorData[String(indicator.id)]
                        : indicatorData
                    }
                  ></DashboardDoughnut>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </div>
        <div className="CDText" hidden={selectedIndicator !== undefined}>
          <PMLabel fontColor="light" fontFamily="Light" fontSize="xxLarge">
            {t("clickForDetails")}
          </PMLabel>
        </div>
        <div
          className="CDdetails scrollL"
          hidden={selectedIndicator === undefined}
        >
          <DashboardTrainingTypeComp
            indicatorData={indicatorData}
            selectedIndicator={selectedIndicator}
            onCardClick={onCardClick}
            trainingTypeData={trainingTypeData}
            trainingTypesLabels={trainingTypesLabels}
          ></DashboardTrainingTypeComp>
        </div>
      </div>
    </div>
  );
  return (
    <PerformanceTemplate
      key={"dashboard"}
      loadingText={t("loadingData")}
      limit={1}
      chart={chart}
      isDrawerOpen={isDrawerOpen}
      loading={loading}
      orbatForces={orbatForces}
      secondButtonText={t("refresh")}
      onClickSecondButton={() => {
        setRefresh(true);
      }}
      plansOptions={plansOptions}
      selectedPlan={selectedPlan}
      onPlanSelectedHandler={onPlanSelectedHandler}
      setChecked={onCheckForce}
      setDrawerOpen={setDrawerOpen}
      title={t("commanderDashboard")}
      displayPlatoonAndAbove
    ></PerformanceTemplate>
  );
};

export default memo(CommanderDashboard);
