import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ForcesListContext from "../../context/ForcesContext/forcesListContext";
import { UserContext } from "../../context/UserContext/userContext";
import { loadAllSoldiersDataFromDB } from "../../services/helpers";
import { userRoles } from "../../services/routeRoles";
import {
  initializeRows,
  IbasicRow,
  refreshRows,
  resetRowsData,
} from "../Desktop/DataPosting/dataPostingHelpers";
import { useSoldiersInLanes } from "./HooksWithRQ/useSoldiersInLane";
import { useWeapons } from "./HooksWithRQ/useWheapons";

const UseDataPosting = <T extends IbasicRow>(
  XDefaultRow: T,
  rowsNumber: number,
  trainingTypeId?: number
) => {
  const { t } = useTranslation();

  //useState
  const [rows, setRows] = useState<T[]>([]);
  const [isSuccess, setIsSuccess] = useState<number>(0);
  const [isHeatsModalOpen, setIsHeatsModalOpen] = useState<boolean>(false);

  //custom Hooks
  const weaponsQuery = useWeapons();
  const weapons = weaponsQuery?.data ?? [];
  const soldiersInLanesQuery = useSoldiersInLanes(trainingTypeId);
  const soldiersInLanes = soldiersInLanesQuery?.data ?? [];

  //useContext
  const { setSoldiersNamesAndIds, soldiersNamesAndIds } =
    useContext(ForcesListContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    let mounted: boolean = true;

    loadAllSoldiersData(mounted);
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (weapons && weapons.length > 0) {
      let options = {} as T;
      setRows(
        initializeRows(rowsNumber, XDefaultRow, {
          ...options,
          soldiersInLanes: soldiersInLanes,
          allSoldiers: soldiersNamesAndIds,
          weapons: weapons,
        })
      );
    }
  }, [weapons]);

  useEffect(() => {
    if (soldiersInLanes.length > 0 && trainingTypeId !== undefined) {
      let options = {} as T;
      setRows(
        initializeRows(rowsNumber, XDefaultRow, {
          ...options,
          soldiersInLanes: soldiersInLanes,
          allSoldiers: soldiersNamesAndIds,
          weapons: weapons,
        })
      );
    }
  }, [soldiersInLanes]);

  const refreshRowsWrap = (XDefaultRow: T) => {
    refreshRows(setRows, rowsNumber, XDefaultRow, weapons);
  };

  const resetRowsDataWrap = (XDefaultRow: T, isMrkOrIEST?: boolean) => {
    resetRowsData(setRows, rowsNumber, XDefaultRow, isMrkOrIEST);
  };

  const loadAllSoldiersData = (mounted?: boolean) => {
    if (!soldiersNamesAndIds || !soldiersNamesAndIds.length)
      (user.role === userRoles.Admin || user.forceToDisplayInOrbat) &&
        loadAllSoldiersDataFromDB(Number(user.forceToDisplayInOrbat?.id))
          .then((res) => {
            mounted && setSoldiersNamesAndIds(res.data ? res.data : []);
          })
          .catch((error) => {
            mounted && toast.error(t("errorToLoadData"));
          });
  };

  return {
    rows,
    setRows,
    isSuccess,
    setIsSuccess,
    weapons,
    isHeatsModalOpen,
    soldiersInLanes,
    // setSoldierInlanes,
    setIsHeatsModalOpen,
    refreshRowsWrap,
    resetRowsDataWrap,
  };
};
export default UseDataPosting;
