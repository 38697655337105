import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import readExcelFile from "read-excel-file";
import { useTranslation } from "react-i18next";
import "./ImportFile.css";
import axios from "../../../../../Axios";
import { baseUrlPMBackend } from "../../../../../Configurations/consts";
import IForceTreeNode from "./../../../../../Interfaces/IForceTreeNode";
import customToast from "../../../Toast/CustomToast";

// ionic imports
import PMIcon from "../../../../themeComponents/PMIcon";
import EIconsSrc from "../../../../../Interfaces/EIconsSrc";
import PMLoading from "../../../Alert/PMLoading";
import { IPMIcon } from "../../../../../Interfaces/IPMTheme";

interface IProps {
  isSuccess: () => void;
  checkedForce: IForceTreeNode | undefined;
  disabled?: boolean;
  onClick: () => void;
  iconColor: IPMIcon["color"];
}

const ImportFile: React.FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();
  const [loading, setLodaing] = useState<boolean>(false);
  const [file, setFile] = useState<(string | null)[][]>();

  useEffect(() => {
    if (file) {
      if (validateFileStructure(file)) {
        try {
          setLodaing(true);
          axios
            .post(`${baseUrlPMBackend}forces/buildTree`, {
              params: {
                data: file,
                topLevelForce: props.checkedForce?.id,
              },
            })
            .then((res) => {
              if (res.data.data.status === "OK") {
                customToast.success(t("importTreeSucceededMsg"));
              } else if (res.data.data.status === "error") {
                customToast.error(t("importTreeErrorMsg"));
                const element = document.createElement("a");
                const file = new Blob(
                  [
                    JSON.stringify(
                      res.data.data.forces,
                      (k, v) => {
                        if (v !== null) {
                          return v;
                        }
                      },
                      "\t"
                    ),
                  ],
                  {
                    type: "text/plain;charset=utf-8",
                  }
                );
                element.href = URL.createObjectURL(file);
                element.download = `invalid-forces.txt`;
                document.body.appendChild(element);
                element.click();
              }
              props.isSuccess();
              setLodaing(false);
            });
        } catch (e) {
          console.error(e);
        }
      }
    }
  }, [file]);

  const validateFileStructure = (rows: (string | null)[][]): boolean => {
    //TODO - check file structure
    return true;
  };

  const onDrop = useCallback((acceptedFiles) => {
    readExcelFile(acceptedFiles[0]).then((rows: (string | null)[][]) => {
      setFile(rows);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".xlsx",
  });

  return (
    <div className="conteiner" {...getRootProps()}>
      <PMLoading isOpen={loading} message={t("fileProcessing")} />

      {!props.disabled && <input {...getInputProps()} />}
      <PMIcon
        iconSrc={EIconsSrc.IMPORT_ICON}
        isButton={false}
        color={props.iconColor}
        size="large"
        disabled={props.disabled}
        onClick={props.onClick}
      />
    </div>
  );
};

export default ImportFile;
