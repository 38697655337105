import React, { FC, useState } from "react";

import { IonContent, IonGrid, IonPage } from "@ionic/react";
import "./SearchSoldier.css";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import MobileHeader from "../../../Mobile/MobileHeader/MobileHeader";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import MobileTitleRow from "../../../Mobile/MobileHeader/MobileTitleRow";
import SearchComponents from "./SearchComponents";
import ISimpleTrainee from "../../../../Interfaces/ISimpleTrainee";

const SearchSoldier: FC = () => {
  const history = useHistory();
  const station = useParams<{
    trainingTypeId: string;
    stationId: string;
    stationName: string;
    heatId: string;
    planId: string;
  }>();
  const stationName = station.stationName;
  const trainingTypeId = station.trainingTypeId;
  const stationId = station.stationId;
  const heatId = station.heatId;
  const planId = station.planId;
  const { t, i18n } = useTranslation();

  const [soldierDetails, setSoldierDetails] = useState<
    ISimpleTrainee | undefined
  >();

  const ok = (soldier: ISimpleTrainee | undefined) => {
    history.push(
      heatId
        ? `/${stationName}/${planId}/${stationId}/${trainingTypeId}/${soldier?.id}/${soldier?.name}/${heatId}`
        : `/${stationName}/${planId}/${stationId}/${trainingTypeId}/${soldier?.id}/${soldier?.name}`
    );
  };
  const backHandler = () => {
    history.goBack();
  };

  return (
    <IonPage className="pageMS">
      <IonContent className="contentContainerMS">
        <MobileHeader
          iconEndSrc={EIconsSrc.MENU_ICON}
          iconStartSrc={
            i18n.language === "he"
              ? EIconsSrc.BACK_ICON_HE
              : EIconsSrc.BACK_ICON_EN
          }
          title={t("addSoldierToStation")}
          iconStartHandler={backHandler}
        />
        <IonGrid className="itemsContainerMS">
          <div className="medicalSearchWrap">
            <MobileTitleRow title={t("addSoldierToStation")} />
            <SearchComponents
              soldierDetails={soldierDetails}
              okHandler={ok}
              setSoldierDetails={setSoldierDetails}
            ></SearchComponents>
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SearchSoldier;
