enum EEventType {
  CHECK_IN = 1,
  CHECK_OUT = 2,
  PM_CHECKOUT = 9,
  SITE_CHECKOUT = 4,
  TRADE_MILL_MESSAGE_TYPE = 7,
  MEDICAL_MESSAGE_TYPE = 8,
  URBAN_INSTRUCTOR_FEEDBACK = 12,
  MAGNET_MESSAGE_TYPE = 6,
  SHOOTING_RANGE_MESSAGE_TYPE = 11,
  SPIKE_OEM_MESSAGE_TYPE = 13,
}
export default EEventType;
