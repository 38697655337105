import { IonPage } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import "./Unauthorized.css";
import logo from "../../../Assets/unauthorized.png";

const Unauthorized: React.FC = () => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <img src={logo} className="img"></img>
    </IonPage>
  );
};

export default Unauthorized;
