import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { IonItem } from "@ionic/react";
import ReactDataGrid, { Row } from "react-data-grid";
import PMLoading from "../../Shared/Alert/PMLoading";
import customToast from "../../Shared/Toast/CustomToast";
import { useTranslation } from "react-i18next";
import PopoverMenu from "../../Shared/Popover/PopoverMenu";
import IFilters from "../../../Interfaces/IFilters";
import Spinner from "../../Shared/Spinner/Spinner";
import PMLabel from "../../themeComponents/PMLabel";
import "./DrillsDataTable.css";
import { findChangedElement } from "../../../services/helpers";
import { ProvidePlugin } from "webpack";

interface inputProps {
  columns: any[];
  rows: any[];
  setRows?: Dispatch<SetStateAction<any[]>>;
  setRowsToSave?: Dispatch<SetStateAction<any[]>>;
  isSuccess?: number;
  resetRows?: () => void;
  setLoadMore?: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: boolean;
  selectedRows?: number[];
  setSelectedRows?: Dispatch<SetStateAction<number[]>>;
  isWithFilter?: boolean;
  filters?: IFilters;
  isDataPosting?: boolean;
  onDoubleClick?: undefined | ((row: any) => void);
  isToDisplayDetails?: boolean;
  setEditRow?: React.Dispatch<React.SetStateAction<boolean>>;
  isEditRow?: boolean;
}

const DrillsDataTable: FC<inputProps> = (props: inputProps) => {
  const {
    columns,
    filters,
    isDataPosting,
    isLoading,
    isSuccess,
    isWithFilter,
    resetRows,
    selectedRows,
    setLoadMore,
    setRows,
    setSelectedRows,
    setRowsToSave,
  } = props;

  const { t } = useTranslation();
  const rowHeight = 35;
  const doubleClickMS = 300;
  const headerRowHeight = isWithFilter ? 80 : 50;
  const [duplicateColumn, setDuplicateColumn] = useState<string>("");
  const [crtlClicked, setCrtlClicked] = useState<boolean>(false);
  const [rowsToPresent, serRowsToPresent] = useState<any[]>([]);
  const [rowClicked, setRowClicked] = useState<{
    rowId: number;
    time: number;
  }>({
    rowId: 0,
    time: 0,
  });
  const [popoverState, setShowPopover] = useState<{
    showPopover: boolean;
    event: MouseEvent | undefined;
    duplicateValue: string;
  }>({
    showPopover: false,
    event: undefined,
    duplicateValue: "",
  });

  // Manages the toasts
  useEffect(() => {
    let mounted: boolean = true;
    if (isSuccess) {
      if (isSuccess !== 0 && isSuccess !== -1)
        if (isSuccess === 200) {
          customToast.success(t("dataSendSuccess"));
          if (resetRows) resetRows();
        } else customToast.error(t("dataSendError"));
    }
    return () => {
      mounted = false;
    };
  }, [isSuccess]);

  const handleScroll = (e: any) => {
    if (
      e.target.scrollTop > 0 && //make sure scroll happened
      setLoadMore &&
      Math.floor(e.target.scrollHeight - e.target.scrollTop) -
        Math.floor(e.target.clientHeight) <
        2
    ) {
      setLoadMore((prev) => (prev === false ? true : prev));
    }
  };

  document.oncontextmenu = function (e: MouseEvent) {
    if (!isDataPosting) return;
    let element = e.target as HTMLInputElement;

    if (
      element.style.cssText.includes("grid-column-start:") ||
      element.className.includes("forrmaterKey")
    ) {
      if (element.style.cssText.includes("grid-column-start:")) {
        setDuplicateColumn(
          columns[Number(element.getAttribute("aria-colindex")) - 1].key
        );
      } else {
        // in case there is forrmater
        let key = element.className.slice(
          element.className.indexOf("(") + 1,
          element.className.lastIndexOf(")")
        );
        setDuplicateColumn(key);
      }
      e.preventDefault();
      setShowPopover({
        showPopover: true,
        event: e,
        duplicateValue: element.innerText,
      });
    }
  };

  const duplicateTags = () => {
    setRows
      ? setRows(
          props.rows.map((row) => {
            return { ...row, [duplicateColumn]: popoverState.duplicateValue };
          })
        )
      : console.log();

    setShowPopover({
      showPopover: false,
      event: undefined,
      duplicateValue: "",
    });
  };
  //when new data arrive reset the rows to present
  useEffect(() => {
    !props.rows.length && serRowsToPresent([]);
  }, [props.rows]);
  /**
   * check double click on row and set the row to present
   * on one click select the row
   * @param rowNumber
   * @param row
   * @param event
   */
  const onRowClicked = (rowNumber: any, row: any, event: any) => {
    //can select different row only if edit process is off
    if (!props.isEditRow) {
      if (
        Date.now() - rowClicked.time < doubleClickMS &&
        props.isToDisplayDetails
      ) {
        serRowsToPresent((prev) =>
          //if row already exist in "rowsToPresent" remove it otherwise push it to the array.
          prev.find((rowToPresent) => rowToPresent.rowIndex === row.rowIndex)
            ? prev.filter(
                (rowToPresent) => rowToPresent.rowIndex !== row.rowIndex
              )
            : [...prev, row]
        );
      } else setRowClicked({ rowId: row.rowIndex, time: Date.now() });
      if (props.setSelectedRows)
        props.setSelectedRows((prev: number[]) => {
          if (crtlClicked) {
            if (prev?.includes(row.drillId))
              return prev.filter((rowId) => rowId !== row.drillId);
            return [...prev, row.drillId];
          } else {
            if (prev?.includes(row.drillId) && prev.length === 1) return [];
            return [row.drillId];
          }
        });
    }
  };

  /**
   * render row details if double click row
   * @param rowProps
   * @returns
   */
  const rowRenderer = (rowProps: any) => {
    //Number of rows that have details before the current row
    let numberOfRowsWithDetailsBeforeCurrent = rowsToPresent?.filter(
      (rowToPresent) => rowToPresent.rowIndex < rowProps.row.rowIndex
    ).length;
    return (
      <>
        {rowsToPresent?.find(
          // if row exist "rowsToPresent" show the row and then its details
          (rowToPresent) => rowToPresent.rowIndex === rowProps.row.rowIndex
        ) ? (
          <>
            <Row
              {...rowProps}
              top={
                rowProps.top + numberOfRowsWithDetailsBeforeCurrent * rowHeight
              }
            ></Row>
            <div
              onDoubleClick={() =>
                //remove the details that was clicked
                serRowsToPresent((prev) =>
                  prev.filter(
                    (rowToPresent) =>
                      rowToPresent.rowIndex !== rowProps.row.rowIndex
                  )
                )
              }
              className="expendDetails"
              style={{
                top: `${
                  rowProps.top +
                  (numberOfRowsWithDetailsBeforeCurrent + 1) * rowHeight
                }px`,
              }}
            >
              {Object.keys(rowProps.row)
                .filter(
                  // filter the header that already exist and the props that shouldn't exist
                  (key) =>
                    ![
                      "drillId",
                      "DrillStartTime",
                      "DrillEndTime",
                      "rowIndex",
                    ].includes(key) &&
                    !props.columns.find((header) => header.key === key)
                )
                .map((key: string) => {
                  return String(rowProps.row[key]).length ? (
                    <div className="expendDetail" key={key}>
                      <PMLabel
                        fontColor="light"
                        fontFamily="Bold"
                        cssClass="dataTypeKey"
                      >
                        {`${t(key)}`}
                      </PMLabel>
                      <PMLabel fontColor="light" fontFamily="Regular">
                        {isNaN(rowProps.row[key])
                          ? `${t(rowProps.row[key])}`
                          : `${rowProps.row[key]}`}
                      </PMLabel>
                    </div>
                  ) : null;
                })}
            </div>
          </>
        ) : (
          //if row not exist in rowsToPrPresent show only the row
          <Row
            {...rowProps}
            top={
              rowProps.top + numberOfRowsWithDetailsBeforeCurrent * rowHeight
            }
          ></Row>
        )}
      </>
    );
  };

  const onRowsChanged = (changedRows: any[]) => {
    const changedItem = findChangedElement(props.rows, changedRows)?.element;
    if (changedItem) {
      setRowsToSave!((prev: any[]) => changeUnsavedItems(prev, changedItem));
      setRows!(changedRows);
    }
  };

  const changeUnsavedItems = (
    modifiedArray: any[],
    currentItem: any
  ): any[] => {
    const { soldierId: id, result } = currentItem;
    let newArray: any[] = [...modifiedArray];
    if (result === "") {
      newArray = removeItemById(id, modifiedArray);
    } else if (!isAlreadyChanged(id, modifiedArray)) {
      newArray.push({ ...currentItem });
    } else if (isResultChanged(id, result, modifiedArray)) {
      newArray = modifiedArray.map((item: any) =>
        item.soldierId === id ? { ...item, result: result } : item
      );
    }
    return newArray;
  };

  const removeItemById = (id: number, list: any[]): any[] =>
    list.filter((item: any) => item.soldierId !== id);

  const isAlreadyChanged = (id: number, list: any[]): boolean =>
    !!list.find((item: any) => item.soldierId === id);

  const isResultChanged = (
    id: number,
    currentResult: string,
    list: any[]
  ): boolean =>
    !(
      list.find((item: any) => item.soldierId === id)!.result === currentResult
    );

  const isINFilters = (
    line: { [key: string]: string },
    filters: IFilters
  ): boolean => {
    let isInclude = true;
    Object.keys(filters).map((key) => {
      if (
        filters[key] !== undefined &&
        !String(line[key]).includes(filters[key])
      ) {
        isInclude = false;
      }
    });
    return isInclude;
  };

  document.addEventListener("keydown", function (event) {
    setCrtlClicked(event.ctrlKey);
  });
  document.addEventListener("keyup", function (event) {
    setCrtlClicked(event.ctrlKey);
  });

  //**if there is not data to present load more  */
  useEffect(() => {
    if (!isWithFilter || !filters) return;
    let rows = props.rows.filter((line: any) => isINFilters(line, filters!));
    if (props.rows.length && !rows.length && setLoadMore) setLoadMore(true);
  }, [props.rows, filters]);

  return (
    <div className="DrillsGridDiv">
      <PMLoading
        isOpen={isSuccess === -1}
        message={t("sendingData")}
        spinner={"bubbles"}
      />
      <div className="gridDiv">
        <PopoverMenu
          popoverState={popoverState}
          onDismiss={() =>
            setShowPopover({
              showPopover: false,
              event: undefined,
              duplicateValue: "",
            })
          }
        >
          <IonItem button onClick={duplicateTags} className={"duplicateItem"}>
            <p className="menuText menuTextGeneral">{t("duplicate")}</p>
          </IonItem>
        </PopoverMenu>
        <div className="gridWrap">
          <ReactDataGrid
            onRowClick={onRowClicked}
            rowClass={(row: any) => {
              if (selectedRows && selectedRows?.includes(row.drillId))
                return "rowSelected";
            }}
            onScroll={(e: any) => {
              handleScroll(e);
            }}
            className={`${
              "inputGrid " +
              (!props.isDataPosting ? " fixedHeight" : "") +
              (setSelectedRows ? " withRowSelection" : "")
            }`}
            style={{
              height:
                (filters && isWithFilter
                  ? props.rows.filter((line: any) =>
                      isINFilters(line, filters!)
                    )
                  : props.rows
                ).length *
                  rowHeight +
                headerRowHeight +
                2,
            }}
            columns={columns}
            rows={
              filters && isWithFilter
                ? props.rows.filter((line: any) => isINFilters(line, filters!))
                : props.rows
            }
            onRowsChange={(changedRows) =>
              setRows && (!isWithFilter || props.isEditRow)
                ? setRowsToSave
                  ? onRowsChanged(changedRows)
                  : setRows(changedRows)
                : () => {}
            }
            cellNavigationMode={"CHANGE_ROW"}
            rowHeight={rowHeight}
            headerRowHeight={headerRowHeight}
            rowRenderer={props.isToDisplayDetails ? rowRenderer : undefined}
          ></ReactDataGrid>
        </div>
        {isLoading ? <Spinner className={"spinner-container"} /> : null}
      </div>
    </div>
  );
};
export default DrillsDataTable;
