import { IonCol, IonRow } from "@ionic/react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Input from "./TrainingPlanInput";
import "./Indicator.css";
import "react-dropdown/style.css";
import IIndicator from "../../../Interfaces/IIndicator";
import { Option } from "react-dropdown";
import Dropdown from "../../Shared/Dropdown/Dropdown";
import Stations from "./Stations";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/store/plansStore";
import { IIndicatorReducer } from "../../../redux/reducers/indicator";
import { IPlansReducer } from "../../../redux/reducers/plans";
import {
  DELETE_INDICATOR,
  EDIT_INDICATOR,
} from "../../../redux/actions/indicatorsActions";
import IIndicatorType from "../../../Interfaces/IIndicatorType";
import { useTranslation } from "react-i18next";
import PMIcon from "../../themeComponents/PMIcon";
import { trash } from "ionicons/icons";
import { units, validateInput } from "../DataPosting/inputValidations";
import IUnsavedPlan from "../../../Interfaces/IUnsavedPlan";
import IElement from "../../../Interfaces/IElement";

interface IIndicatorComp {
  indicator: IIndicator;
  typeOptions: IIndicatorType[];
  updateIndicator: (indicator: IIndicator | undefined) => void;
  setIsUnsavedPlan: Dispatch<SetStateAction<IUnsavedPlan | undefined>>;
  currentElements: IElement[];
}

const Indicator: React.FC<IIndicatorComp> = (props: IIndicatorComp) => {
  const [indicator, setIndicator] = useState<IIndicator>();
  const [options, setOptions] = useState<string[]>([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const indicatorsTypes = useSelector<AppState, IIndicatorReducer>(
    (state) => state.indicators
  ).indicatorsTypes;

  const editMode = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).editMode;

  const savePlan = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).savePlan;

  useEffect(() => {
    if (!editMode && savePlan <= 0) {
      setIndicator(props.indicator);
    }
  }, [editMode]);

  useEffect(() => {
    setIndicator(props.indicator);
  }, [props.indicator]);

  // Dropdown options list useEffect
  useEffect(() => {
    setOptions([]);
    props.typeOptions.map((type) =>
      setOptions((options) => [...options!, t(type.name.trim())])
    );
  }, [props.typeOptions]);

  // Handles the dropdown (indicator type) changes
  const nameChangeHandler = (arg: Option) => {
    let name: string = arg.label?.toString() ? arg.label?.toString() : "";
    let id: number = indicatorsTypes.find(
      (indicatorType) => t(indicatorType.name) === arg.label
    )?.id!;

    // Resets the local indicator state
    setIndicator({
      ...indicator!,
      name: name,
      id: id,
      weight: 0,
      threshold: 0,
    });

    // Updates the redux indicator state
    dispatch({
      type: EDIT_INDICATOR,
      indicator: {
        ...indicator,
        id: id,
        name: name,
        weight: "0",
        threshold: "0",
      },
    });
  };

  const onChangeIndicatorDetailsHandler: Function = (
    field: string,
    value: string,
    units: string,
    inputIndex: number
  ): void => {
    let errs: string[] | undefined;
    errs = indicator?.errors?.map((err: string, index: number) =>
      index === inputIndex ? t(validateInput(value, units)) : err
    );
    setIndicator({
      ...indicator!,
      [field]: value !== "" ? value : null,
      errors: errs,
    });
  };

  const onDeleteIndicatorHandler = () => {
    dispatch({ type: DELETE_INDICATOR, id: indicator?.id });
  };

  return (
    <IonCol size="12" className="indicatorGrid">
      <IonRow
        className="indicatorAttributesRow "
        onBlur={() => props.updateIndicator(indicator)}
      >
        <IonCol
          size="2.93"
          className={`attributesCol  ${editMode ? "" : "viewMode"}`}
        >
          <Dropdown
            options={options}
            onChange={nameChangeHandler}
            value={t(indicator?.name.trim()!)}
            placeholder={t("measurement")}
            controlClassName="Dropdown tpDropdown"
            arrowClassName="DropdownArrow tpDropdownArrow"
            placeholderClassName="indicatorDropdownPlaceholder"
            menuClassName="DropdownMenu"
            disabled={!editMode}
          ></Dropdown>
        </IonCol>
        <IonCol size="2.58">
          <Input
            inputType="number"
            placeholder={t("measurementWeight")}
            inputName="weight"
            inputValue={indicator?.weight}
            onChangeHandler={(field: string, value: string) =>
              onChangeIndicatorDetailsHandler(field, value, units.Weight, 0)
            }
            length="short"
            isPercentageInput
            isDisabled={!editMode}
            cssClass={`tpInput ${
              indicator?.errors && indicator.errors[0] !== ""
                ? "tpInputError"
                : null
            }`}
          ></Input>
        </IonCol>
        <IonCol size="2.58">
          <Input
            inputType="number"
            placeholder={t("thresholdGrade")}
            inputName="threshold"
            inputValue={indicator?.threshold}
            onChangeHandler={(field: string, value: string) =>
              onChangeIndicatorDetailsHandler(field, value, units.Grade, 1)
            }
            length="short"
            isDisabled={!editMode}
            cssClass={`tpInput ${
              indicator?.errors && indicator.errors[1] !== ""
                ? "tpInputError"
                : null
            }`}
          ></Input>
        </IonCol>
        <IonCol size="3.91" className="indicatorTrashCol">
          {editMode ? (
            <PMIcon
              size="large"
              color="dark"
              iconSrc={trash}
              onClick={onDeleteIndicatorHandler}
              cssClass="clickable tpTrashIcon"
            ></PMIcon>
          ) : null}
        </IonCol>
      </IonRow>
      <IonRow>
        <Stations
          indicatorId={indicator?.id!}
          setIsUnsavedPlan={props.setIsUnsavedPlan}
          currentElements={props.currentElements}
        ></Stations>
      </IonRow>
    </IonCol>
  );
};

export default Indicator;
