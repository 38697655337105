import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { IonHeader, IonToolbar, IonImg, IonToggle } from "@ionic/react";
import BagiraLogo from "../../../Assets/bagiraLogo.png";
import "./Header.css";
import { UserContext } from "../../../context/UserContext/userContext";
import { useTranslation } from "react-i18next";
import {
  baseUrlPMBackend,
  environment,
  mode,
} from "../../../Configurations/consts";
import { useHistory } from "react-router";
import Axios from "../../../Axios";
import { useWebsocketTagging } from "../../../services/siteManagementSocket";
import PMTitle from "../../themeComponents/PMTitle";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import HeaderMenu from "../HeaderMenu/HeaderMenu";
import Cookies from "universal-cookie";
import customToast from "./../../Shared/Toast/CustomToast";
import AdminPasswordModal from "../AdminPassword/AdminPasswordModal";
import { checkPassword } from "../../../services/passwordsHandler";
import PlansDifferencesDetails from "../../Shared/PlansDifferencesDetails/PlansDifferencesDetails";
import { comparePlans } from "../../../apis/SyncAPI";
import EEnvironment from "../../../Enums/EEnvironment";
import EMode from "../../../Enums/EMode";

interface IProps {
  setDarkMode: Dispatch<SetStateAction<boolean>>;
  darkMode: boolean;
}

const Header: FC<IProps> = (props: IProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { user, isTaggingActive, setTaggingMode } = useContext(UserContext);
  const [iconColor, setIconColor] = useState<"light" | "xLight">("light");
  const [planId, setPlanId] = useState<number>();
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const history = useHistory();
  const taggingMessage = useWebsocketTagging();
  const cookie = new Cookies();
  const [invalid, setInvalid] = useState<boolean>(false);
  const [isResetMemoOpen, setIsResetMemoOpen] = useState<boolean>(false);
  const [plansDifferences, setPlansDifferences] = useState<any>();
  const [isPlansModalOpen, setIsPlansModalOpen] = useState<boolean>(
    plansDifferences !== undefined
  );
  useEffect(() => {
    let mounted: boolean = true; // in order to ensure that the data wont be presented if "mounted" is false

    const getActivePlanId = async () => {
      try {
        await Axios.get(`${baseUrlPMBackend}plans/active`)
          .then((res) => mounted && setPlanId(res.data?.id))
          .catch((err) => console.error(err));
      } catch (error) {
        console.error(error);
      }
    };

    getActivePlanId();
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (isTaggingActive) {
      if (history.location.pathname === "/") {
        history?.push({
          pathname: "/performance",
          state: {
            data: planId,
            taggingMessage: taggingMessage,
          },
        });
      } else {
        history?.push({
          pathname: "/performance/details",
          state: {
            data: planId,
            taggingMessage: taggingMessage,
          },
        });
      }
    }
  }, [isTaggingActive, taggingMessage]);

  const resetMemorization = async () => {
    Axios.post(`${baseUrlPMBackend}/memorization/resetActivePlanGrades`)
      .then(() => {
        customToast.success(t("successfullyResetPlansGrades"));
      })
      .catch(() => {
        customToast.error(t("errorWhileResetPlansGrades"));
      });
  };

  const onSendPasswordHandler = async (password: string) => {
    const isPasswordValid = await checkPassword(password, setInvalid);
    if (isPasswordValid) {
      setInvalid(false);
      setIsResetMemoOpen(false);
      resetMemorization();
    }
  };
  const onComparePlansClicked = async () => {
    setShowPopover({
      showPopover: false,
      event: undefined,
    });
    setPlansDifferences(undefined);
    try {
      await comparePlans().then((result) => {
        setPlansDifferences(result);
        setIsPlansModalOpen(true);
      });
    } catch (error) {
      console.error(error);
      customToast.error(t("comparePlansError"));
    }
  };
  return (
    <div className="header">
      <IonHeader className="header-height">
        <IonToolbar className="header-height">
          <div className="header-content">
            <IonImg src={BagiraLogo} />
            <PMTitle fontSize="medium" fontColor="light" fontFamily="Regular">
              {t("siteHeader")}
            </PMTitle>
            <div className="toggleButton">
              {environment?.toString() !== EEnvironment.production &&
                mode === EMode.site && (
                  <>
                    <PMTitle
                      fontSize="medium"
                      fontColor="light"
                      fontFamily="Regular"
                    >
                      {isTaggingActive
                        ? t("taggingMode") + " " + t("on")
                        : t("taggingMode") + " " + t("off")}
                    </PMTitle>
                    <IonToggle
                      color="tertiary"
                      checked={isTaggingActive}
                      title={t("taggingMode")}
                      name="toggle"
                      mode="md"
                      onIonChange={() => setTaggingMode()}
                    />
                  </>
                )}
              <PMTitle fontSize="medium" fontColor="light" fontFamily="Regular">
                {t("hello") + ", " + user.displayName}
              </PMTitle>
            </div>
            <HeaderMenu
              popoverState={popoverState}
              setDarkMode={props.setDarkMode}
              darkMode={props.darkMode}
              onDismiss={() =>
                setShowPopover({
                  showPopover: false,
                  event: undefined,
                })
              }
              onComparePlansClicked={onComparePlansClicked}
            />
            <PlansDifferencesDetails
              plansDifferences={plansDifferences?.data}
              isOpen={isPlansModalOpen}
              setIsOpen={setIsPlansModalOpen}
            />
            <PMIcon
              isButton={false}
              iconSrc={EIconsSrc.MENU_DOTS}
              size="large"
              color={iconColor}
              onMouseEnter={() => setIconColor("xLight")}
              onMouseLeave={() => setIconColor("light")}
              onClick={(e: any) => {
                e.persist();
                setShowPopover({ showPopover: true, event: e });
              }}
            />
          </div>
        </IonToolbar>
      </IonHeader>
      <AdminPasswordModal
        isOpen={isResetMemoOpen}
        onSendPasswordHandler={(password: string) =>
          onSendPasswordHandler(password)
        }
        onCancelHandler={() => {
          setInvalid(false);
          setIsResetMemoOpen(false);
        }}
        invalid={invalid}
        text={t("resetMemorizationDeleteQ")}
      ></AdminPasswordModal>
    </div>
  );
};

export default Header;
