import { baseUrlPMBackendBasic } from "./consts";

const address = {
  pmBackend: `${baseUrlPMBackendBasic}api`,
  stations: "/stations",
  shootingRange: "/shooting-range",
  shootingDays: "/shooting-days",
  heats: "/heats",
  results: "/results",
  forces: "/forces",
  soldiers: "/soldiers",
  children: "/children",
  plans: "/plans",
  active: "/active",
  users: "/users",
  login: "/login",
  trackingLogins: "/tracking-logins",
  add: "/add",
  history: "/history",
  syncData: "/sync-data",
  lastUpdateTime: "/last-update-time",
  passedParams: {
    shootingDayId: "/:shootingDayId",
    heatId: "/:heatId",
  },
  comparePlans: "/comparePlans",
};

const paths = {
  stations: {
    getPath: () => `${address.stations}`,
    shootingRange: {
      getPath: () => `${paths.stations.getPath() + address.shootingRange}`,
      shootingDays: {
        getPath: (id?: number) =>
          `${paths.stations.shootingRange.getPath() + address.shootingDays}${
            id ? "/" + id : ""
          }`,
        heats: {
          getPath: (shootingDayId?: number, id?: number) =>
            `${
              paths.stations.shootingRange.shootingDays.getPath(shootingDayId) +
              address.heats
            }${id ? "/" + id : ""}`,
          results: {
            getPath: (shootingDayId: number, heatId: number) =>
              `${
                paths.stations.shootingRange.shootingDays.heats.getPath(
                  shootingDayId,
                  heatId
                ) + address.results
              }`,
          },
        },
      },
      plans: {
        getPath: () =>
          `${paths.stations.shootingRange.getPath() + address.plans}`,
      },
    },
  },
  forces: {
    getPath: () => `${address.forces}`,
    soldiers: {
      getPath: () => `${paths.forces.getPath() + address.soldiers}`,
    },
    children: {
      getPath: () => `${paths.forces.getPath() + address.children}`,
      soldiers: {
        getPath: (parentForceId: number) =>
          `${paths.forces.children.getPath() + address.soldiers}${
            "/" + parentForceId
          }`,
      },
    },
  },
  plans: {
    getPath: () => `${address.plans}`,
    active: {
      getPath: () => `${paths.plans.getPath() + address.active}`,
    },
  },
  users: {
    getPath: () => `${address.users}`,
  },
  trackingLogins: {
    getPath: () => `${address.trackingLogins}`,
    add: {
      getPath: () => `${paths.trackingLogins.getPath() + address.add}`,
    },
    history: {
      getPath: () => `${paths.trackingLogins.getPath() + address.history}`,
    },
  },
  syncService: {
    getPath: () => `${address.syncData}`,
    lastUpdateTime: {
      getPath: () => `${paths.syncService.getPath() + address.lastUpdateTime}`,
    },
    comparePlans: {
      getPath: () => `${paths.syncService.getPath() + address.comparePlans}`,
    },
  },
};

export const URLs = {
  stations: {
    shootingRange: {
      getURL: () => `${paths.stations.shootingRange.getPath()}`,
      shootingDays: {
        getURL: (id?: number) =>
          `${paths.stations.shootingRange.shootingDays.getPath(id)}`,
        heats: {
          getURL: (shootingDayId?: number, id?: number) =>
            `${
              shootingDayId
                ? paths.stations.shootingRange.shootingDays.heats.getPath(
                    shootingDayId,
                    id
                  )
                : paths.stations.shootingRange.shootingDays.getPath(
                    shootingDayId
                  ) +
                  address.passedParams.shootingDayId +
                  address.heats
            }`,
          results: {
            getURL: (shootingDayId?: number, heatId?: number) =>
              `${
                URLs.stations.shootingRange.shootingDays.heats.getURL(
                  shootingDayId,
                  heatId
                ) +
                (shootingDayId && heatId ? "" : address.passedParams.heatId) +
                address.results
              }`,
          },
        },
      },
      plans: {
        getURL: () => `${paths.stations.shootingRange.plans.getPath()}`,
      },
    },
    getURL: () => `${paths.stations.getPath()}`,
  },
};

export const APIs = {
  stations: {
    shootingRange: {
      shootingDays: {
        getAll: () =>
          `${
            address.pmBackend +
            paths.stations.shootingRange.shootingDays.getPath()
          }`,
        getShootingDayById: (shootingDayId: number) =>
          `${
            address.pmBackend +
            paths.stations.shootingRange.shootingDays.getPath(shootingDayId)
          }`,
        heats: {
          getByShootingDayId: (shootingDayId: number) =>
            `${
              address.pmBackend +
              paths.stations.shootingRange.shootingDays.getPath(shootingDayId) +
              address.heats
            }`,
          getHeatById: (shootingDayId: number, heatId: number) =>
            `${
              address.pmBackend +
              paths.stations.shootingRange.shootingDays.heats.getPath(
                shootingDayId,
                heatId
              )
            }`,
          getAll: () =>
            `${
              address.pmBackend +
              paths.stations.shootingRange.shootingDays.heats.getPath()
            }`,
        },
      },
    },
  },
  forces: {
    children: {
      getChildrenSoldiersById: (forceId: number) =>
        `${
          address.pmBackend + paths.forces.children.soldiers.getPath(forceId)
        }`,
    },
  },
  plans: {
    getAll: () => `${address.pmBackend + paths.plans.getPath()}`,
    active: {
      getActivePlan: () => `${address.pmBackend + paths.plans.getPath()}`,
    },
  },
  users: {},
  trackingLogins: {
    addUserToTracking: () =>
      `${address.pmBackend + paths.trackingLogins.add.getPath()}`,
    getAll: () =>
      `${address.pmBackend + paths.trackingLogins.history.getPath()}`,
  },
  syncService: {
    getLastUpdateTime: () =>
      `${address.pmBackend + paths.syncService.lastUpdateTime.getPath()}`,
    syncData: () => `${address.pmBackend + paths.syncService.getPath()}`,
    comparePlans: () =>
      `${address.pmBackend + paths.syncService.comparePlans.getPath()}`,
  },
};
