import Axios from "../Axios";
import { baseUrlPMBackend } from "../Configurations/consts";
import bcrypt from "bcryptjs";
const saltRounds = 10;

const checkSuperUserPassword = async (
  password: string,
  setInvalid: (state: boolean) => void
) => {
  try {
    setInvalid(false);
    let hashedPassword = await hashPassword(password);

    const isPasswordValid = await Axios.post(
      `${baseUrlPMBackend}users/authSuperUserPassword`,
      {
        params: { password: hashedPassword },
      }
    );
    if (!isPasswordValid.data) setInvalid(true);
    else setInvalid(false);

    return isPasswordValid.data;
  } catch (error) {
    setInvalid(true);
    return null;
  }
};

const checkSuperSoferPassword = async (
  password: string,
  setInvalid: (state: boolean) => void
) => {
  try {
    setInvalid(false);
    let hashedPassword = await hashPassword(password);

    const isPasswordValid = await Axios.post(
      `${baseUrlPMBackend}users/authSuperSoferPassword`,
      {
        params: { password: hashedPassword },
      }
    );
    if (!isPasswordValid.data) setInvalid(true);
    else setInvalid(false);

    return isPasswordValid.data;
  } catch (error) {
    setInvalid(true);
    return null;
  }
};
const checkPassword = async (
  password: string,
  setInvalid: (state: boolean) => void
) => {
  try {
    setInvalid(false);
    let hashedPassword = await hashPassword(password);

    const isPasswordValid = await Axios.post(
      `${baseUrlPMBackend}users/authAdminPassword`,
      {
        params: { password: hashedPassword },
      }
    );
    if (!isPasswordValid.data) setInvalid(true);
    else setInvalid(false);

    return isPasswordValid.data;
  } catch (error) {
    setInvalid(true);
    return null;
  }
};
const hashPassword = async (plaintextPassword: string) => {
  try {
    return await bcrypt.hash(plaintextPassword, saltRounds);
  } catch {
    return 500;
  }
};

export { checkSuperUserPassword, checkPassword, checkSuperSoferPassword };
