import React, { useEffect, useState } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./FinalGradeDisplay.css";
import IFinalGradeDisplay from "../../../Interfaces/IFinalGradeDisplay";

const FinalGradeDisplay: React.FC<IFinalGradeDisplay> = (
  props: IFinalGradeDisplay
) => {
  // the color need to be change in run time that why its here
  const [colorClassName, setColor] = useState("classColorGreen");

  const setGradeColor = () => {
    if (Math.round(props.soldierFinalGrade.grade) < props.threshold)
      setColor("classColorYellow");
    else setColor("classColorGreen");
  };
  useEffect(() => {
    setGradeColor();
  }, []);
  useEffect(() => {
    setGradeColor();
  }, [props]);
  return (
    <div
      className={
        "finalGradeDisplayContainer " +
        `${props.className ? props.className : ""}`
      }
    >
      <CircularProgressbarWithChildren
        className={`finalGradeDisplay ${colorClassName}`}
        value={props.soldierFinalGrade.grade}
        strokeWidth={4}
        styles={{
          root: { filter: " drop-shadow(0 0 0.2rem black)" },
        }}
      >
        <span
          className={`gradeText  
              ${
                props.soldierFinalGrade.isAttended === false
                  ? "gradeTextRed"
                  : ""
              } ${props.isMobile ? "mobileGradeText" : ""}`}
        >{`${Math.round(props.soldierFinalGrade.grade)}`}</span>
      </CircularProgressbarWithChildren>
      {!props.isModal && props.isLegend !== false ? (
        <div className="legendContainer">
          <canvas
            className="legendStyle"
            style={{ backgroundColor: props.soldierFinalGrade.legendColor }}
          />
          <p className="legendTextStyle">{props.soldierFinalGrade.name}</p>
        </div>
      ) : null}
    </div>
  );
};

export default FinalGradeDisplay;
