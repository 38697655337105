import React from "react";
import { Route } from "react-router-dom";
import { Redirect, useLocation } from "react-router-dom";

// css
import "./DataPosting.css";

// ionic imports
import {
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";

// interfaces
import TabItem from "../../../Interfaces/ITabItem";
import Mrk from "../../../components/Desktop/DataPosting/Mrk";
import LiveShooting from "../../../components/Desktop/DataPosting/ShootingRange/LiveShooting";

import { useTranslation } from "react-i18next";
// Tabs buttons options
import MedicalDesktop from "../../../components/Desktop/DataPosting/MedicalDesktop";
import TreadmillDesktop from "../../../components/Desktop/DataPosting/TreadmillDesktop";
import UrbanInstructorFeedback from "../../../components/Desktop/DataPosting/UrbanInstructorFeedback";
import PMTitle from "../../../components/themeComponents/PMTitle";
import Magnet from "../../../components/Desktop/DataPosting/Magnet";
import BISTUrban from "../../../components/Desktop/DataPosting/BISTUrban";
import { environment, mode } from "../../../Configurations/consts";
import ResponsiveShooting from "../../../components/Desktop/DataPosting/ResponsiveShooting";
import EEnvironment from "../../../Enums/EEnvironment";
import usePlans from "../../../components/CustomHooks/usePlans";
import SPIKE from "../../../components/Desktop/DataPosting/SPIKE";
import { trainingTypeMode } from "../../../Configurations/consts";
import EMode from "../../../Enums/EMode";
const DataPosting: React.FC = (): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation();

  const TabsOptions: TabItem[] = []; //for every environment

  const addTabsAccordingToEnvironment = () => {
    let tabs: TabItem[] = TabsOptions;

    if (environment !== EEnvironment.production)
      tabs = [
        ...tabs,
        {
          url: "/dataPosting/responsiveShooting",
          title: t("responsiveShooting"),
        },
        {
          url: "/dataPosting/mrk",
          title: t("mrk"),
        },
        {
          url: "/dataPosting/BISTUrban",
          title: t("urban"),
        },
        {
          url: "/dataPosting/medical",
          title: t("medical"),
        },
        {
          url: "/dataPosting/treadmill",
          title: t("treadmill"),
        },
        {
          url: "/dataPosting/urbanInstructorFeedback",
          title: t("urbanInstructorFeedback"),
        },
      ];

    if (environment !== EEnvironment.localSite) {
      tabs = [
        {
          url: "/dataPosting/liveShooting",
          title: t("liveShooting"),
        },
        {
          url: "/dataPosting/magnet",
          title: t("magnet"),
        },
        ...tabs,
      ];
    }
    if (
      (environment === EEnvironment.development || mode === EMode.station) &&
      trainingTypeMode
    ) {
      tabs = [
        ...tabs,
        {
          url: `/dataPosting/${trainingTypeMode}`,
          title: t(trainingTypeMode),
          trainingType: trainingTypeMode,
        },
      ];
    }
    return tabs.filter((trainingType) => {
      return (
        mode === EMode.site ||
        (mode === EMode.station &&
          trainingTypeMode &&
          trainingTypeMode === trainingType.trainingType)
      );
    });
  };
  const { onPlanSelectedHandler, plansOptions, selectedPlan } = usePlans();

  return (
    <IonTabs className="pm-tabs">
      <IonRouterOutlet id="main">
        <Route
          path="/dataPosting"
          render={() => (
            <Redirect to={addTabsAccordingToEnvironment()[0].url} />
          )}
          exact
        />
        <Route
          path="/dataPosting/mrk"
          render={() => (
            <Mrk
              onPlanSelectedHandler={onPlanSelectedHandler}
              plansOptions={plansOptions}
              selectedPlan={selectedPlan}
            ></Mrk>
          )}
          exact
        />
        <Route
          path="/dataPosting/BISTUrban"
          render={() => (
            <BISTUrban
              onPlanSelectedHandler={onPlanSelectedHandler}
              plansOptions={plansOptions}
              selectedPlan={selectedPlan}
            ></BISTUrban>
          )}
          exact
        />
        <Route
          path="/dataPosting/liveShooting"
          render={() => (
            <LiveShooting
              onPlanSelectedHandler={onPlanSelectedHandler}
              plansOptions={plansOptions}
              selectedPlan={selectedPlan}
            />
          )}
          exact
        />
        <Route
          path="/dataPosting/responsiveShooting"
          render={() => (
            <ResponsiveShooting
              onPlanSelectedHandler={onPlanSelectedHandler}
              plansOptions={plansOptions}
              selectedPlan={selectedPlan}
            ></ResponsiveShooting>
          )}
          exact
        />
        <Route
          path="/dataPosting/medical"
          render={() => (
            <MedicalDesktop
              onPlanSelectedHandler={onPlanSelectedHandler}
              plansOptions={plansOptions}
              selectedPlan={selectedPlan}
            ></MedicalDesktop>
          )}
          exact={true}
        />
        <Route
          path="/dataPosting/treadmill"
          render={() => (
            <TreadmillDesktop
              onPlanSelectedHandler={onPlanSelectedHandler}
              plansOptions={plansOptions}
              selectedPlan={selectedPlan}
            ></TreadmillDesktop>
          )}
          exact={true}
        />
        <Route
          path="/dataPosting/urbanInstructorFeedback"
          render={() => (
            <UrbanInstructorFeedback
              onPlanSelectedHandler={onPlanSelectedHandler}
              plansOptions={plansOptions}
              selectedPlan={selectedPlan}
            ></UrbanInstructorFeedback>
          )}
          exact={true}
        />
        <Route
          path="/dataPosting/magnet"
          render={() => (
            <Magnet
              onPlanSelectedHandler={onPlanSelectedHandler}
              plansOptions={plansOptions}
              selectedPlan={selectedPlan}
            ></Magnet>
          )}
          exact={true}
        />
        <Route
          path="/dataPosting/SPIKE"
          render={() => (
            <SPIKE
              onPlanSelectedHandler={onPlanSelectedHandler}
              plansOptions={plansOptions}
              selectedPlan={selectedPlan}
            ></SPIKE>
          )}
          exact={true}
        />
      </IonRouterOutlet>
      <IonTabBar
        slot="top"
        className="ion-justify-content-start dataPostingTabs"
      >
        {addTabsAccordingToEnvironment().map((item: TabItem, index: number) => {
          return (
            <IonTabButton
              className={location.pathname === item.url ? "tab-selected" : ""}
              tab={item.title}
              href={item.url}
              key={index}
            >
              <PMTitle fontColor="light" fontFamily="Light" fontSize="medium">
                {item.title}
              </PMTitle>
            </IonTabButton>
          );
        })}
      </IonTabBar>
    </IonTabs>
  );
};
export default DataPosting;
