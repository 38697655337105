import React, { useState } from "react";
import ForcesContext from "./forcesContext";
import IForceTreeNode from "../../Interfaces/IForceTreeNode";

const ForcesContextProvider: React.FC = (props) => {
  const [forces, setForces] = useState<IForceTreeNode[]>([]);
  const [isForcesTreeOpen, setIsForcesTreeOpen] = useState<boolean>(false);

  return (
    <ForcesContext.Provider
      value={{ forces, setForces, isForcesTreeOpen, setIsForcesTreeOpen }}
    >
      {props.children}
    </ForcesContext.Provider>
  );
};

export default ForcesContextProvider;
