import React, { FC } from "react";
import "./PerformanceDisplay.css";
import { IonPage } from "@ionic/react";
import { MAX_SELECTED } from "../../../Configurations/consts";
import IForceTreeNode from "../../../Interfaces/IForceTreeNode";
import { useTranslation } from "react-i18next";
import IFinalGradeDisplay from "../../../Interfaces/IFinalGradeDisplay";
import FinalGradeDisplay from "../../../components/Shared/FinalGradeDisplay/FinalGradeDisplay";
import IPlan from "../../../Interfaces/IPlan";

import PMLoading from "../../../components/Shared/Alert/PMLoading";
import PMButton from "../../../components/themeComponents/PMButton";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import PMTitle from "../../../components/themeComponents/PMTitle";
import { Option } from "react-dropdown";

// Import Swiper styles

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import OrbatTreeDrawer from "../../../components/Shared/Orbat/OrbatTree/OrbatTreeDrawer";
import PMLabel from "../../../components/themeComponents/PMLabel";
import Dropdown from "../../../components/Shared/Dropdown/Dropdown";
interface IProps {
  title: string;
  trendsDropdown?: React.ReactNode;
  chart: React.ReactNode;
  finalGrades?: IFinalGradeDisplay[];
  loading: boolean;
  orbatForces: IForceTreeNode[];
  setChecked: (forces: IForceTreeNode[]) => void;
  isDrawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClickFirstButton?: () => void;
  firstButtonText?: string;
  onClickSecondButton?: () => void;
  secondButtonText?: string;
  loadingText: string;
  limit?: number;
  selectedPlan: IPlan | undefined;
  onPlanSelectedHandler?: (planOption: Option) => void;
  plansOptions?: Option[];
  isPlanLoading?: boolean;
  displayPlatoonAndAbove?: boolean | undefined;
}

const PerformanceTemplate: FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const {
    trendsDropdown,
    orbatForces,
    setChecked,
    finalGrades,
    isDrawerOpen,
    setDrawerOpen,
    loading,
    onClickFirstButton,
    firstButtonText,
    onClickSecondButton,
    secondButtonText,
    loadingText,
    limit,
    onPlanSelectedHandler,
    selectedPlan,
    plansOptions,
    displayPlatoonAndAbove,
  } = props;

  return (
    <IonPage>
      <PMLoading isOpen={loading} message={loadingText} spinner={"bubbles"} />
      <div className="shadowBorder">
        <div
          className={`treeAndChart ${
            finalGrades ? "" : "fullWidthTreeAndChart"
          }`}
        >
          <OrbatTreeDrawer
            onCheckForce={setChecked}
            orbatForces={orbatForces}
            limit={limit ? limit : MAX_SELECTED}
            isDrawerOpen={isDrawerOpen}
            setDrawerOpen={setDrawerOpen}
            displayPlatoonAndAbove={displayPlatoonAndAbove}
          ></OrbatTreeDrawer>

          <div className="chartsAndControls">
            <div className="header-scope">
              <PMLabel
                fontColor="light"
                fontFamily="Regular"
                fontSize="xxLarge"
                textAlign="start"
              >
                {props.title}
              </PMLabel>
              <div className="desktopDropdown">
                {trendsDropdown ? (
                  trendsDropdown
                ) : (
                  <Dropdown
                    options={plansOptions ? plansOptions : []}
                    onChange={
                      onPlanSelectedHandler ? onPlanSelectedHandler : () => {}
                    }
                    value={`${
                      selectedPlan && selectedPlan?.id !== undefined
                        ? `${selectedPlan.name} ${
                            selectedPlan.isActive ? `(${t("active")})` : ""
                          }`
                        : t("selectPlan")
                    }`}
                    placeholder={t("measurement")}
                    controlClassName="Dropdown DWidth"
                    arrowClassName="DropdownArrow"
                    placeholderClassName="DropdownPlaceholderCompetency"
                    menuClassName="DropdownMenuCompetency DropdownMenu"
                    disabled={false}
                    isLoading={props.isPlanLoading}
                  ></Dropdown>
                )}
              </div>
            </div>
            <div className="graph-col">{props.chart}</div>
            <div className="buttonsCompetency">
              <div className="TrendsToggle">
                {firstButtonText && onClickFirstButton ? (
                  <PMButton
                    size="medium"
                    color="tertiary"
                    onClickHandler={onClickFirstButton}
                    label={{
                      fontSize: "medium",
                      fontColor: "xLight",
                      fontFamily: "Regular",
                    }}
                  >
                    {firstButtonText}
                  </PMButton>
                ) : null}
              </div>
              {secondButtonText && onClickSecondButton ? (
                <PMButton
                  size="medium"
                  color="tertiary"
                  fill="outline"
                  icon={{
                    iconSrc: EIconsSrc.REFRESH_ICON,
                    color: "xLight",
                    size: "large",
                  }}
                  onClickHandler={onClickSecondButton}
                  label={{
                    fontSize: "medium",
                    fontColor: "xLight",
                    fontFamily: "Regular",
                  }}
                >
                  {secondButtonText}
                </PMButton>
              ) : null}
            </div>
          </div>
        </div>

        {finalGrades ? (
          <div className="badges-col">
            {selectedPlan && (
              <div className="title-container">
                <PMTitle fontColor="light" fontFamily="Light" fontSize="large">
                  {t("lethality")}
                </PMTitle>
              </div>
            )}
            <div className="scrollContainerD">
              <div className="gradeContainerD">
                {finalGrades[0] &&
                  finalGrades.map(
                    (grade: IFinalGradeDisplay, index: number) => (
                      <div className="badge-gradeQ" key={index}>
                        <FinalGradeDisplay
                          key={index}
                          soldierFinalGrade={grade.soldierFinalGrade}
                          threshold={grade.threshold}
                        />
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </IonPage>
  );
};

export default PerformanceTemplate;
