import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./InstructorFeedbackComponent.css";
import UFIComponentHook from "../../../CustomHooks/UFIComponentHook";
import { UIFElementsData } from "../../../../Interfaces/IUrbanInstructorFeedback";
import UIFMobileElement from "./UIFMobileElement";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

// Import Swiper styles

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

interface IProps {
  traineeId: number;
  afterPostHandler?: () => void;
  isDesktop?: boolean;
  planId: string;
}
const InstructorFeedbackComponent: React.FC<IProps> = (
  props: IProps
): JSX.Element => {
  const { isDesktop, traineeId, afterPostHandler } = props;
  const [isComplete] = useState<boolean>(true);

  const { t } = useTranslation();
  const {
    data,
    isTraineeChanged,
    setUIFGrades,
    postData,
    setIsTraineeChanged,
  } = UFIComponentHook(t, traineeId, props.planId, isDesktop, afterPostHandler);

  return (
    <>
      <Swiper
        modules={[Pagination]}
        pagination={{
          clickable: true,
        }}
        loop
        allowTouchMove
        className="mobileUFISlides"
      >
        {data.map((elementData: UIFElementsData, index) => (
          <SwiperSlide className="mobileUFISlide" key={elementData.title}>
            <UIFMobileElement
              key={elementData.dataType}
              dataType={elementData.dataType}
              elements={elementData.elements}
              title={elementData.title}
              setGrade={setUIFGrades}
              resetAll={isTraineeChanged}
              setResetAll={setIsTraineeChanged}
              index={index}
              isCompleted={isComplete}
              postData={postData}
            ></UIFMobileElement>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default InstructorFeedbackComponent;
