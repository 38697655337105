import { IonCol, IonRow } from "@ionic/react";
import React, { useEffect, useState } from "react";
import PMLabel from "../../../themeComponents/PMLabel";
import Trainee from "../Trainee/Trainee";
import "./TraineesList.css";
import IElement from "../../../../Interfaces/IElement";
import { useTranslation } from "react-i18next";
import PMIcon from "../../../themeComponents/PMIcon";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import { ITrainee } from "../../../../Interfaces/results/ITrainee.interface";
import InfiniteScroll from "react-infinite-scroll-component";

interface ITraineesListProps {
  trainees: ITrainee[];
  trainingTypeId?: number;
  sortedElement: string;
  elements: any[];
}

const TRAINEES_RENDER_CHUNK_SIZE = 50;

const TraineesList = (props: ITraineesListProps) => {
  const [trainees, setTrainees] = useState<ITrainee[]>(props.trainees);
  const [sortedElement, setSortedElement] = useState<string>(
    props.sortedElement
  );
  const [hasMoreTrainees, setHasMoreTrainees] = useState<boolean>(true);
  const [count, setCount] = useState({
    prev: 0,
    next: TRAINEES_RENDER_CHUNK_SIZE,
  });
  const [current, setCurrent] = useState<any[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setTrainees(sortTraineesBySelectedElement());
  }, [sortedElement]);

  // Renders more trainees
  const getMoreData = () => {
    // If we've reached the end of the list , set hasMore to be false
    if (current.length === trainees.length) {
      setHasMoreTrainees(false);
      return;
    }
    setTimeout(() => {
      setCurrent(
        current.concat(
          trainees.slice(
            count.prev + TRAINEES_RENDER_CHUNK_SIZE,
            count.next + TRAINEES_RENDER_CHUNK_SIZE
          )
        )
      );
    }, 500);
    setCount((prevState) => ({
      prev: prevState.prev + TRAINEES_RENDER_CHUNK_SIZE,
      next: prevState.next + TRAINEES_RENDER_CHUNK_SIZE,
    }));
  };
  // Assigns clicked element as sorted element
  const headerClickHandler = (name: string) => {
    if (name === sortedElement) {
      setSortedElement("");
    } else {
      setSortedElement(name);
    }
  };

  // Sorts trainees by clicked header
  const sortTraineesBySelectedElement = () => {
    // Finds selected ("sorted") element
    const isNegative: boolean = Object.values(props.elements).find(
      (element: IElement) => element.name === sortedElement
    )?.negative;

    // Sorted list according to element negative value (true/false)
    let sortedTrainees: ITrainee[] = trainees.sort((traineeA, traineeB) => {
      const [traineeAValue, traineeBValue] = [
        traineeA.results.find(
          (result: any) => result.elementName === sortedElement
        )?.value,
        traineeB.results.find(
          (result: any) => result.elementName === sortedElement
        )?.value,
      ];
      //require in order to sort the null/ undefined values in the bottom
      if (traineeAValue === undefined || traineeAValue === null)
        return traineeBValue === undefined || traineeBValue === null ? 0 : 1;
      if (traineeBValue === undefined || traineeBValue === null) return -1;
      return isNegative
        ? traineeAValue - traineeBValue
        : traineeBValue - traineeAValue;
    });

    setCurrent(sortedTrainees.slice(0, count.next));
    return sortedTrainees;
  };

  return (
    <IonCol size="12" className="traineesListCol">
      <IonRow>
        <IonCol size="11.7" className="headersCol">
          <div className="nameBadgeDiv nameBadgeHeaderDiv">
            <IonCol size="1" className="upDownArrowsCol">
              <PMIcon
                iconSrc={EIconsSrc.ARROWS}
                cssClass="upDownArrows"
              ></PMIcon>
            </IonCol>
            <IonCol size="3" className="rankCol">
              <PMLabel cssClass="rankLabel">#</PMLabel>
            </IonCol>
            <IonCol size="8.5" className="nameCol">
              <PMLabel cssClass="nameLabel">{t("soldierName")}</PMLabel>
            </IonCol>
          </div>
          <IonCol size="1.5" className="header">
            <PMLabel cssClass="nameLabel">{t("class")}</PMLabel>
          </IonCol>
          {props.elements &&
            Object.values(props.elements).map((element: IElement) => (
              <IonCol size="1" className="forceCol" key={element.id}>
                <PMLabel
                  cssClass={`header ${
                    sortedElement === element.name ? "selected" : ""
                  }`}
                  onClick={() => headerClickHandler(element.name)}
                  id={element.id.toString()}
                  key={element.id}
                >
                  {t(element.name)}
                </PMLabel>
              </IonCol>
            ))}
        </IonCol>
      </IonRow>
      <InfiniteScroll
        dataLength={current.length}
        next={getMoreData}
        hasMore={hasMoreTrainees}
        loader={<PMLabel>{t("loading")}</PMLabel>}
        className="infiniteScroll"
        height={300}
      >
        {current.map((trainee: any, index: number) => (
          <Trainee
            key={index}
            trainee={trainee}
            elements={props.elements}
            trainingTypeId={props.trainingTypeId && props.trainingTypeId}
            ranking={index + 1}
            sortedElement={sortedElement}
          ></Trainee>
        ))}
      </InfiniteScroll>
    </IonCol>
  );
};

export default TraineesList;
