import IElement from "../../Interfaces/IElement";
import {
  ADD_ELEMENT,
  DELETE_ELEMENT,
  EDIT_ELEMENT,
  ElementActionTypes,
  FETCH_ELEMENTS_FAILURE,
  FETCH_ELEMENTS_REQUEST,
  FETCH_ELEMENTS_SUCCESS,
  DECREMENT_ELEMENTS_ERROR_COUNTER,
  INCREMENT_ELEMENTS_ERROR_COUNTER,
  RESET_ELEMENTS_ERROR_COUNTER,
  SET_ELEMENTS,
  UPDATE_ELEMENT,
} from "../actions/elementsActions";

export interface IElementsReducer {
  elements: IElement[];
  loading: boolean;
}

const elementsReducerDefaultState: IElementsReducer = {
  elements: [],
  loading: false,
};

const elementsReducer = (
  state = elementsReducerDefaultState,
  action: ElementActionTypes
) => {
  switch (action.type) {
    case SET_ELEMENTS:
      return { ...state, elements: action.elements };

    case ADD_ELEMENT:
      return [...state.elements, action.element];

    case EDIT_ELEMENT:
      return {
        ...state,
        elements: [
          ...state.elements.map((element) => {
            if (element.id === action.element.id) {
              return {
                ...element,
                weight: action.element.weight,
                upperThreshold: action.element.upperThreshold,
                lowerThreshold: action.element.lowerThreshold,
                isForDashboard: action.element.isForDashboard,
                requiredThreshold: action.element.requiredThreshold,
                errors: action.element.errors,
              };
            } else {
              return element;
            }
          }),
        ],
      };

    case DELETE_ELEMENT:
      return state.elements.filter(({ id }) => id !== action.id);

    case FETCH_ELEMENTS_REQUEST:
      return { ...state, loading: true };

    case FETCH_ELEMENTS_SUCCESS:
      return { ...state, loading: false, elements: action.elements, error: "" };

    case FETCH_ELEMENTS_FAILURE:
      return { ...state, loading: false, elements: [], error: action.error };

    case UPDATE_ELEMENT: {
      return {
        ...state,
        elements: [
          ...state.elements.map((element) => {
            if (element.id === action.element.id) {
              return { ...element, ...action.element };
            } else {
              return element;
            }
          }),
        ],
      };
    }
    default:
      return state;
  }
};

export default elementsReducer;
