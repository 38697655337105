import { IonGrid, IonRow, IonCol } from "@ionic/react";
import React, { useEffect, useState } from "react";
import ISimpleTrainee from "../../../Interfaces/ISimpleTrainee";
import SearchComponents from "../../Shared/MenualStation/SearchSoldierComp/SearchComponents";
import TreadmillComponent from "../../Shared/MenualStation/TreadmillStation/TreadmillComponent";
import TradeMillStation from "../../Shared/MenualStation/TreadmillStation/TreadmillStation";
import DesktopManualInputTemplate from "./DesktopManualInputTemplate";
import IDataPosting from "../../../Interfaces/IDataPosting";

import "./TreadmillDesktop.css";
const TreadmillDesktop: React.FC<IDataPosting> = (
  props: IDataPosting
): JSX.Element => {
  const [soldierDetails, setSoldierDetails] = useState<
    ISimpleTrainee | undefined
  >();
  const trainingTypeTreadmillId = 4;
  const [isSendDataClicked, setSendDataClicked] = useState<boolean>(false);

  const [isSendEnabled, setIsComplete] = useState<boolean>(false);

  useEffect(() => {
    setSendDataClicked(false);
  }, [isSendDataClicked]);
  return (
    <DesktopManualInputTemplate
      selectedPlan={props.selectedPlan}
      plansOptions={props.plansOptions}
      onPlanSelectedHandler={props.onPlanSelectedHandler}
      pageName={"treadmill"}
      onClickThirdButton={() => setSendDataClicked(true)}
      isSendDisabled={!isSendEnabled}
    >
      <IonGrid className="searchGrid">
        <IonRow className="searchRow">
          <IonCol size="2" className="searchCol">
            <SearchComponents
              soldierDetails={soldierDetails}
              setSoldierDetails={setSoldierDetails}
              isDesktop={true}
            />
          </IonCol>
          <IonCol size="1">
            <div></div>
          </IonCol>
          <IonCol size="9" className="col2Treadmill">
            <TreadmillComponent
              traineeId={Number(soldierDetails?.id)}
              trainingTypeId={trainingTypeTreadmillId}
              isDesktop={true}
              isSendClicked={isSendDataClicked}
              isComplete={isSendEnabled}
              setIsComplete={setIsComplete}
              planId={props.selectedPlan?.id}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </DesktopManualInputTemplate>
  );
};

export default TreadmillDesktop;
