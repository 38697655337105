import React, { FC, useContext, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { getAllShootingDays } from "../../../../../apis/ShootingRangeAPI";
import ForcesContext from "../../../../../context/ForcesContext/forcesContext";
import EIconsSrc from "../../../../../Interfaces/EIconsSrc";
import { URLs } from "../../../../../Configurations/urls";
import IShootingDay from "../../../../../Interfaces/IShootingDay";
import useNavigation from "../../../../CustomHooks/useNavigation";
import NotExistLabel from "../../../../Shared/NotExistLabel/NotExistLabel";
import Spinner from "../../../../Shared/Spinner/Spinner";
import List from "../../../List/List";
import MobileHeader from "../../../MobileHeader/MobileHeader";
import MobileTitleRow from "../../../MobileHeader/MobileTitleRow";
import OrbatTreeModal from "../../../OrbatTreeModal/OrbatTreeModal";
import "./ShootingDaySelectionScreen.css";
import { useLocation } from "react-router";

type IProps = {};

const ShootingDaySelectionScreen: FC<IProps> = (props: IProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { navigate } = useNavigation();
  const [loading, setLoading] = useState<boolean>(true);
  const [shootingDays, setShootingDays] = useState<IShootingDay[]>();
  const { forces, isForcesTreeOpen, setIsForcesTreeOpen } =
    useContext(ForcesContext);
  const { state } = useLocation<{
    planId: number;
  }>();

  useEffect(() => {
    getShootingDays();
  }, []);

  useEffect(() => {
    if (shootingDays) {
      setLoading(false);
    }
  }, [shootingDays]);

  const getShootingDays = async () => {
    getAllShootingDays(true).then(
      (response: AxiosResponse<IShootingDay[] | undefined>) =>
        setShootingDays(response.data)
    );
  };

  const navigateToHeats = (item: IShootingDay) => {
    const currentShootingDay: IShootingDay | undefined = shootingDays?.find(
      (currentShootingDay) => currentShootingDay.id === item.id
    );
    navigate(
      `${URLs.stations.shootingRange.shootingDays.heats.getURL(item.id)}`,
      {
        planId: state.planId,
        shootingDayName: currentShootingDay?.name,
      }
    );
  };

  const returnToPreviousPage = () => {
    navigate(`${URLs.stations.shootingRange.plans.getURL()}`);
  };

  return (
    <div className="shooting-day-selection">
      <MobileHeader
        isUserDisplay={false}
        title={t("liveShooting")}
        iconStartSrc={
          i18n.language === "he"
            ? EIconsSrc.BACK_ICON_HE
            : EIconsSrc.BACK_ICON_EN
        }
        iconStartHandler={returnToPreviousPage}
      />
      <div className="shooting-days-list">
        {forces.length > 0 ? (
          <>
            <MobileTitleRow title={t("selectShootingDay")} />
            {loading ? (
              <Spinner />
            ) : (
              <List
                items={
                  shootingDays?.sort(
                    (currentDay: IShootingDay, nextDay: IShootingDay) =>
                      currentDay.id - nextDay.id
                  )!
                }
                handleClick={(listItem: IShootingDay | undefined) =>
                  navigateToHeats(listItem!)
                }
              />
            )}
          </>
        ) : (
          <NotExistLabel
            text="noSelectedForces"
            className="no-selected-forces"
          />
        )}
      </div>
      <OrbatTreeModal
        limit={1}
        isOpen={isForcesTreeOpen}
        close={() => setIsForcesTreeOpen(false)}
        mode="secondary"
      />
    </div>
  );
};

export default ShootingDaySelectionScreen;
