import { useQuery } from "react-query";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";

const asyncFetchWeapons = async () => {
  const res = await Axios.get(`${baseUrlPMBackend}weaponType/getAll`);
  res.data = [{ name: " " }, ...res.data];
  return res.data
    .filter((weap: { name: string }) => weap.name !== "None")
    .map((weapon: { name: string }) => weapon.name);
};

export const useWeapons = () => {
  let query = useQuery<string[]>({
    queryKey: ["weapons"],
    queryFn: asyncFetchWeapons,
  });
  return query;
};
