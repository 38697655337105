import { IonItem, IonRow, IonText } from "@ionic/react";
import React from "react";
import "./PMToggle.css";

interface ILeadersToggle {
  firstOptionText: string;
  secondOptionText: string;
  state: boolean;
  setState: (state: boolean) => void;
}
const LeadersToggle: React.FC<ILeadersToggle> = (props: ILeadersToggle) => {
  return (
    <IonRow className="toggleRow">
      <IonItem
        mode="md"
        lines="none"
        button
        onClick={() => props.setState(true)}
        className={props.state ? "toggleItem selectedToggle" : "toggleItem"}
      >
        <IonText className="toggleText">{props.firstOptionText}</IonText>
      </IonItem>
      <IonItem
        mode="md"
        lines="none"
        button
        onClick={() => props.setState(false)}
        className={!props.state ? "toggleItem selectedToggle" : "toggleItem"}
      >
        <IonText className="toggleText">{props.secondOptionText}</IonText>
      </IonItem>
    </IonRow>
  );
};
export default LeadersToggle;
