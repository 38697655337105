import IForceTreeNode from "../../../Interfaces/IForceTreeNode";
import {
  ILeadersDisplayData,
  ILeadersDetails,
  ILeadersResponse,
  ILeadersSearchResponse,
  ILeadersResult,
} from "../../../Interfaces/ILeaders";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import { getForceById, getForceFullName } from "../../../services/helpers";
import IPlan from "../../../Interfaces/IPlan";
import { TFunction } from "i18next";
import { Dispatch, SetStateAction } from "react";
import customToast from "../Toast/CustomToast";

export const getForcesIds = (orbatForces: IForceTreeNode[]) => {
  let forcesIds: number[] = [];

  orbatForces?.map((details) => {
    if (details.id === undefined) return undefined;
    forcesIds.push(details.id);
  });
  return forcesIds;
};

const loadTrainingGrades: Function = async (
  forcesIds: ILeadersDisplayData[],
  isLeadersForTrainee: boolean,
  plan: IPlan
) => {
  let leaders: ILeadersDisplayData[] = [];
  try {
    let ledgersObject = await Axios.get(
      `${baseUrlPMBackend}performanceGrades/getLeadersResults`,
      {
        params: {
          forcesIds: forcesIds,
          isLeadersForTrainee: isLeadersForTrainee,
          planId: plan.id,
        },
      }
    );
    leaders = ledgersObject.data;
    if (ledgersObject.data == "") return [];
  } catch (error) {
    leaders = [];
  }

  return leaders;
};

const loadLeadersDataFirstTime: Function = async (
  forcesIds: number[],
  setUnits: Dispatch<SetStateAction<ILeadersDetails[]>>,
  setIndividuals: Dispatch<SetStateAction<ILeadersDetails[]>>,
  setLoading: (state: boolean) => void,
  isLeadersForTrainee: boolean,
  plan: IPlan,
  t: TFunction
) => {
  if (forcesIds === undefined || forcesIds.length === 0) {
    setUnits([]);
    setIndividuals([]);
    setLoading(false);
    return;
  }
  loadTrainingGrades(forcesIds, isLeadersForTrainee, plan)
    .then((responseLeader: ILeadersResponse[]) => {
      setIndividuals([]);
      setUnits([]);
      Promise.all(
        responseLeader.map((resp: ILeadersResponse) => {
          updateUI(resp, setIndividuals, setUnits, setLoading);
        })
      );
      // updateUI(responseLeader, setIndividuals, setUnits, setLoading);
      setLoading(false);
    })
    .catch(() => {
      customToast.error(t("errorWhileLoadingData"));
      setLoading(false);
    });
};

const getNewCheckedIn = (
  newForceNodes: IForceTreeNode[],
  units: ILeadersDetails[],
  individuals: ILeadersDetails[]
) => {
  let newChecked: IForceTreeNode[] = [];
  for (let index = 0; index < newForceNodes?.length; index++) {
    const node = newForceNodes[index];
    if (node.nodes !== null) {
      if (
        units.findIndex((unit) => Number(node.id) === Number(unit.id)) === -1
      ) {
        newChecked.push(node);
      }
    } else {
      if (
        individuals.findIndex(
          (individual) => Number(node.id) === Number(individual.id)
        ) === -1
      ) {
        newChecked.push(node);
      }
    }
  }
  return newChecked;
};

const removeUnChecked = (
  setUnits: (prev: any) => void,
  setIndividuals: (prev: any) => void,
  orbatForces: IForceTreeNode[],
  t: TFunction
) => {
  setUnits((prev: ILeadersDetails[]) => {
    return prev.filter(
      (unit) =>
        searchForceDetailsById(
          {
            forceId: unit.id,
            grade: unit.grade,
            affiliation: unit.affiliation,
            name: unit.name,
            isSoldier: false,
          },
          orbatForces,
          t
        ) !== undefined
    );
  });
  setIndividuals((prev: ILeadersDetails[]) => {
    return prev.filter(
      (individual) =>
        searchForceDetailsById(
          {
            forceId: individual.id,
            grade: individual.grade,
            affiliation: individual.affiliation,
            name: individual.name,
            isSoldier: true,
          },
          orbatForces,
          t
        ) !== undefined
    );
  });
};

const searchForceDetailsById = (
  leaderResponse: ILeadersResult,
  orbatForces: IForceTreeNode[],
  t: TFunction
) => {
  let forceD: IForceTreeNode | undefined;
  for (let index = 0; index < orbatForces.length; index++) {
    const force = orbatForces[index];
    if (forceD === undefined && force != undefined) {
      forceD = getForceById(force, leaderResponse.forceId);
    }
    if (forceD != undefined) {
      let results: ILeadersSearchResponse = {
        grade: leaderResponse.grade,
        isSoldier: forceD.nodes === null,
        id: forceD.id,
        name: forceD.name.includes(forceD.force_type)
          ? forceD.name
          : getForceFullName(forceD.name, forceD.force_type, t),
        affiliation: leaderResponse.affiliation,
      };
      return results;
    }
  }
  return undefined;
};
const updateUI = (
  response: ILeadersResponse,
  setIndividuals: Dispatch<SetStateAction<ILeadersDetails[]>>,
  setUnits: Dispatch<SetStateAction<ILeadersDetails[]>>,
  setLoading: (state: boolean) => void
) => {
  let maxUnitsToDisplay = 20;
  let maxIndividualsToDisplay = 100;

  let individualsTemp: ILeadersDetails[] = [];
  let unitsTemp: ILeadersDetails[] = [];
  getResults(response, individualsTemp, unitsTemp);
  setIndividuals((prev: ILeadersDetails[]) => {
    prev = prev.concat(individualsTemp);
    prev = prev.reduce(
      (accumalator: ILeadersDetails[], current: ILeadersDetails) => {
        if (!accumalator.some((item) => item.id === current.id)) {
          accumalator.push(current);
        }
        return accumalator;
      },
      []
    );
    return prev
      .sort((x, y) => {
        return y.grade - x.grade;
      })
      .slice(0, maxIndividualsToDisplay);
  });
  setUnits((prev: ILeadersDetails[]) => {
    prev = prev.concat(unitsTemp);
    prev = prev.reduce(
      (accumalator: ILeadersDetails[], current: ILeadersDetails) => {
        if (!accumalator.some((item) => item.id === current.id)) {
          accumalator.push(current);
        }
        return accumalator;
      },
      []
    );
    return prev
      .sort((x, y) => {
        return y.grade - x.grade;
      })
      .slice(0, maxUnitsToDisplay);
  });
  setLoading(false);
};
const getResults = (
  responseLeaders: ILeadersResponse,
  individualsTemp: ILeadersDetails[],
  unitsTemp: ILeadersDetails[]
) => {
  const UNIT_PARTICIPANTS_PERCENTS = 70;
  if (!responseLeaders.children.length) {
    //stop condition!!

    responseLeaders.results.isAttendAllTrainingPlan &&
      individualsTemp.push({
        grade: responseLeaders.results.grade,
        id: responseLeaders.id,
        name: responseLeaders.name,
        affiliation: responseLeaders.hierarchicalName,
      });
  } else {
    //search for training type with less than 70% participants
    const isOneStationHasLessThan70PercentsParticipants =
      responseLeaders.results.indicatorResults.find((indicatorResult) =>
        indicatorResult.trainingTypeResults.find(
          (trainingTypeResult) =>
            trainingTypeResult.weight && //make sure the training plan includes the training type
            (trainingTypeResult.participants /
              (trainingTypeResult.soldierMissed +
                trainingTypeResult.participants)) *
              100 <
              UNIT_PARTICIPANTS_PERCENTS
        )
      );
    !isOneStationHasLessThan70PercentsParticipants &&
      responseLeaders.results.participantsAtLeastOnes &&
      unitsTemp.push({
        grade: responseLeaders.results.grade,
        id: responseLeaders.id,
        name: responseLeaders.name,
        affiliation: responseLeaders.hierarchicalName,
      });
    responseLeaders.children.map((child) => {
      getResults(child, individualsTemp, unitsTemp);
    });
  }
};
const checkedOrUnCheckedPerformed = async (
  units: ILeadersDetails[],
  individuals: ILeadersDetails[],
  setLoading: (state: boolean) => void,
  setUnits: (state: any) => void,
  setIndividuals: (state: any) => void,
  orbatForces: IForceTreeNode[],
  isLeadersForTrainee: boolean,
  plan: IPlan | undefined,
  t: TFunction
) => {
  removeUnChecked(setUnits, setIndividuals, orbatForces, t);
  let newChecked: IForceTreeNode[] = await getNewCheckedIn(
    orbatForces,
    units,
    individuals
  );
  let forcesIds = await getForcesIds(newChecked);
  if (forcesIds === undefined || forcesIds.length === 0) return;
  setLoading(true);

  loadTrainingGrades(forcesIds, isLeadersForTrainee, plan)
    .then((response: ILeadersResponse[]) => {
      Promise.all(
        response.map((resp: ILeadersResponse) => {
          updateUI(resp, setIndividuals, setUnits, setLoading);
        })
      );
    })
    .catch(() => {
      customToast.error(t("errorWhileLoadingData"));

      setLoading(false);
    });
};

export { loadLeadersDataFirstTime, checkedOrUnCheckedPerformed };
