import { Dispatch } from "react";
import IStation from "../../Interfaces/IStation";
import { AppActions } from "./rootActions";
import axios from "../../Axios";
import IStationType from "../../Interfaces/IStationType";
import { baseUrlPMBackend } from "../../Configurations/consts";
import { useSelector } from "react-redux";
import { IStationReducer } from "../reducers/stations";
import { AppState } from "../store/plansStore";

// Station actions
export const SET_STATIONS = "SET_STATIONS";
export const ADD_STATION = "ADD_STATION";
export const EDIT_STATION = "EDIT_STATION";
export const DELETE_STATION = "DELETE_STATION";
export const FETCH_STATIONS_REQUEST = "FETCH_STATIONS_REQUEST";
export const FETCH_STATIONS_SUCCESS = "FETCH_STATIONS_SUCCESS";
export const FETCH_STATIONS_FAILURE = "FETCH_STATIONS_FAILURE";
export const FETCH_STATIONS_TYPES_REQUEST = "FETCH_STATIONS_TYPES_REQUEST";
export const FETCH_STATIONS_TYPES_SUCCESS = "FETCH_STATIONS_TYPES_SUCCESS";
export const FETCH_STATIONS_TYPES_FAILURE = "FETCH_STATIONS_TYPES_FAILURE";
export const UPDATE_STATION = "UPDATE_STATION";
export const SAVE_STATIONS_REQUEST = "SAVE_STATIONS_REQUEST";
export const SAVE_STATIONS_SUCCESS = "SAVE_STATIONS_SUCCESS";
export const SAVE_STATIONS_FAILURE = "SAVE_STATIONS_FAILURE";
export const INCREMENT_STATIONS_UPDATE_COUNTER =
  "INCREMENT_STATIONS_UPDATE_COUNTER";
export const RESET_STATIONS_UPDATE_COUNTER = "RESET_STATIONS_UPDATE_COUNTER";
export const INCREMENT_STATIONS_RENDER_COUNTER =
  "INCREMENT_STATIONS_RENDER_COUNTER";
export const DECREMENT_STATIONS_RENDER_COUNTER =
  "DECREMENT_STATIONS_RENDER_COUNTER";
export const RESET_STATIONS_RENDER_COUNTER = "RESET_STATIONS_RENDER_COUNTER";

//            Station
export interface SetStationsAction {
  type: typeof SET_STATIONS;
  stations: IStation[];
}

export interface AddStationAction {
  type: typeof ADD_STATION;
  station: IStation;
}

export interface EditStationAction {
  type: typeof EDIT_STATION;
  station: IStation;
}

export interface DeleteStationAction {
  type: typeof DELETE_STATION;
  id: number;
}

export interface fetchStationsRequest {
  type: typeof FETCH_STATIONS_REQUEST;
}

export interface fetchStationsSuccess {
  type: typeof FETCH_STATIONS_SUCCESS;
  stations: IStation[];
}

export interface fetchStationsFailure {
  type: typeof FETCH_STATIONS_FAILURE;
  error: Error | "";
}

export interface fetchStationsTypesRequest {
  type: typeof FETCH_STATIONS_TYPES_REQUEST;
}

export interface fetchStationsTypesSuccess {
  type: typeof FETCH_STATIONS_TYPES_SUCCESS;
  stationsTypes: IStationType[];
}

export interface fetchStationsTypesFailure {
  type: typeof FETCH_STATIONS_TYPES_FAILURE;
  error: Error | "";
}

export interface UpdateStation {
  type: typeof UPDATE_STATION;
  station: IStation;
}
export interface SaveStationsRequest {
  type: typeof SAVE_STATIONS_REQUEST;
}

export interface SaveStationsSuccess {
  type: typeof SAVE_STATIONS_SUCCESS;
}

export interface SaveStationsFailure {
  type: typeof SAVE_STATIONS_FAILURE;
  error: Error | "";
}

export interface ResetUpdateCounter {
  type: typeof RESET_STATIONS_UPDATE_COUNTER;
}

export interface IncrementUpdateCounter {
  type: typeof INCREMENT_STATIONS_UPDATE_COUNTER;
}

export interface DecrementRenderCounter {
  type: typeof DECREMENT_STATIONS_RENDER_COUNTER;
}

export interface IncrementRenderCounter {
  type: typeof INCREMENT_STATIONS_RENDER_COUNTER;
}

export interface ResetRenderCounter {
  type: typeof RESET_STATIONS_RENDER_COUNTER;
}

export const fetchStationsByPlanId = (planId: number) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_STATIONS_REQUEST,
    });

    axios
      .get(`${baseUrlPMBackend}stations/getStationsByPlanId`, {
        params: { planId: planId },
      })
      .then((res) => {
        let stations: IStation[] = res.data.rows.map((station: IStation) => {
          return { ...station, errors: ["", ""] };
        });
        dispatch({ type: FETCH_STATIONS_SUCCESS, stations: stations });
      })
      .catch((error) => {
        dispatch({ type: FETCH_STATIONS_FAILURE, error: error });
      });
  };
};

export const fetchStationsTypes = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_STATIONS_TYPES_REQUEST,
    });

    axios
      .get(`${baseUrlPMBackend}stations/getStationsTypes`)
      .then((res) => {
        let stationsTypes: IStationType[] = res.data.rows;
        dispatch({
          type: FETCH_STATIONS_TYPES_SUCCESS,
          stationsTypes: stationsTypes,
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_STATIONS_TYPES_FAILURE, error: error });
      });
  };
};

export const saveStations = (stations: IStation[], planId: number, t: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: SAVE_STATIONS_REQUEST,
    });

    dispatch({ type: RESET_STATIONS_UPDATE_COUNTER });

    axios
      .post(`${baseUrlPMBackend}stations/saveStations`, {
        params: {
          stations: stations,
          planId: planId,
        },
      })
      .then((res) => {
        // dispatch({type:RESET_STATIONS_RENDER_COUNTER})
        dispatch({ type: SAVE_STATIONS_SUCCESS });
        let insertedStations: IStation[] = res.data;
        insertedStations.map((station) =>
          dispatch({ type: UPDATE_STATION, station: station })
        );
      })
      .catch((error) => {
        dispatch({ type: SAVE_STATIONS_FAILURE, error: error });
      });
  };
};

export const incrementCounter = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INCREMENT_STATIONS_UPDATE_COUNTER });
  };
};

export const incrementRenderCounter = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INCREMENT_STATIONS_RENDER_COUNTER });
  };
};

export const decrementRenderCounter = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: DECREMENT_STATIONS_RENDER_COUNTER });
  };
};

// Station types
export type StationActionTypes =
  | SetStationsAction
  | AddStationAction
  | EditStationAction
  | DeleteStationAction
  | fetchStationsRequest
  | fetchStationsSuccess
  | fetchStationsFailure
  | fetchStationsTypesRequest
  | fetchStationsTypesFailure
  | fetchStationsTypesSuccess
  | UpdateStation
  | SaveStationsRequest
  | SaveStationsSuccess
  | SaveStationsFailure
  | IncrementUpdateCounter
  | ResetUpdateCounter
  | IncrementRenderCounter
  | DecrementRenderCounter
  | ResetRenderCounter;
