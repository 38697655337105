import { AxiosResponse } from "axios";
import axios from "../Axios";
import { baseUrlPMBackend } from "../Configurations/consts";
import { APIs } from "../Configurations/urls";
import IPlan from "../Interfaces/IPlan";

export const getTrainingTypesDataForTrainingPlan = async (
  planId: number | undefined
) =>
  axios.get(
    `${baseUrlPMBackend}performanceGrades/getTrainingTypesDefinedDataPerTrainingPlan`,
    {
      params: { planId: planId },
    }
  );

export const getIndicatorsDataForTrainingPlan = async (
  planId: number | undefined
) =>
  axios.get(
    `${baseUrlPMBackend}performanceGrades/getIndicatorsDataForTrainingPlan`,
    {
      params: { planId: planId },
    }
  );

export const fetchGraphData = (planId: number) =>
  axios.get(`${baseUrlPMBackend}performanceGrades/GetTrainingPlanDefinedData`, {
    params: { planId: planId },
  });

export const fetchActivePlanData = async () => {
  try {
    return await axios.get(
      `${baseUrlPMBackend}performanceGrades/GetTrainingPlanDefinedData`
    );
  } catch (err) {
    console.error(err);
  }
};

export const getActivePlan = async (): Promise<
  AxiosResponse<IPlan | undefined>
> => {
  try {
    return await axios.get(APIs.plans.active.getActivePlan());
  } catch (error) {
    throw error;
  }
};

export const getPlans = async (): Promise<AxiosResponse<IPlan[]>> => {
  try {
    return await axios.get(APIs.plans.getAll());
  } catch (error) {
    throw error;
  }
};
