import React from "react";
import { useTranslation } from "react-i18next";
import PMButton from "../../themeComponents/PMButton";

interface ISaveCancelButtons {
  onCancelClickHandler: () => void;
  onSaveClickHandler: () => void;
  disabled?: boolean;
}

const SaveCancelButtons = (props: ISaveCancelButtons) => {
  const { onCancelClickHandler, onSaveClickHandler, disabled } = props;
  const { t } = useTranslation();
  return (
    <>
      <PMButton
        fill="clear"
        color="light"
        size="small"
        onClickHandler={onCancelClickHandler}
        label={{
          fontSize: "medium",
          fontFamily: "Light",
          fontColor: "light",
        }}
        isDisabled={disabled}
      >
        {t("cancel")}
      </PMButton>
      <PMButton
        color="tertiary"
        size="small"
        onClickHandler={onSaveClickHandler}
        label={{
          fontSize: "medium",
          fontFamily: "Light",
          fontColor: "light",
        }}
        isDisabled={disabled}
      >
        {t("save")}
      </PMButton>
    </>
  );
};

export default SaveCancelButtons;
