import { IonCol, IonRow } from "@ionic/react";
import React from "react";
import IElement from "../../../../Interfaces/IElement";
import { ITrainee } from "../../../../Interfaces/results/ITrainee.interface";
import { IModalResult } from "../../../../Interfaces/results/modal-result.interface";
import PMLabel from "../../../themeComponents/PMLabel";
import "./Trainee.css";

interface ITraineeProps {
  trainee: ITrainee;
  elements: IElement[];
  trainingTypeId?: number;
  ranking: number;
  sortedElement: string;
}

const Trainee = (props: ITraineeProps) => {
  return (
    <IonRow className="traineeRow">
      <div className="nameBadgeDiv">
        <IonCol
          size="0.5"
          className={`badgeCol ${
            props.trainee.results.find(
              (result: IModalResult) =>
                result.elementName === props.sortedElement
            )?.color
          }Badge`}
        ></IonCol>
        <IonCol size="3" className="rankCol">
          <PMLabel cssClass="rankLabel">{props.ranking}</PMLabel>
        </IonCol>
        <IonCol size="7.5" className="nameCol">
          <PMLabel cssClass="nameLabel">{props.trainee.forceName}</PMLabel>
        </IonCol>
      </div>
      <IonCol size="1.5" className="forceCol">
        {props.trainee.forceHierarchy}
      </IonCol>
      {props.elements !== undefined
        ? Object.values(props.elements)
            .map((element: IElement) =>
              props.trainee.results.find(
                (result: IModalResult) => result.elementName === element.name
              )
            )
            .map((result: IModalResult, index: number) => (
              <IonCol size="1" className="forceCol" key={index}>
                <PMLabel
                  cssClass={`nameCol TM${
                    props?.sortedElement === result?.elementName
                      ? result?.color
                      : ""
                  }`}
                >
                  {result && result.value != null ? result.value : "-"}
                </PMLabel>
              </IonCol>
            ))
        : null}
    </IonRow>
  );
};

export default Trainee;
