import React, { useContext, useEffect } from "react";
import { IonContent, IonItem, IonList, IonMenu } from "@ionic/react";
import { useLocation } from "react-router-dom";
import "./Menu.css";
import { UserContext } from "../../../../context/UserContext/userContext";
import { routeRoles, routes } from "../../../../services/routeRoles";
import { useTranslation } from "react-i18next";
import { environment } from "../../../../Configurations/consts";
import PMIcon from "../../../themeComponents/PMIcon";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import EEnvironment from "../../../../Enums/EEnvironment";
import EMode from "../../../../Enums/EMode";
import { mode } from "../../../../Configurations/consts";

interface MenuItem {
  title: string;
  url: string;
  icon: string;
  requiredRoles: string[];
  allowedModes: string[];
}

const Menu: React.FC = (): JSX.Element => {
  const location = useLocation();
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const MenuItems: MenuItem[] = [
    {
      title: t("PlansPage"),
      url: routes.trainingPlan,
      icon: "./assets/icon/folder.svg",
      requiredRoles: [
        ...routeRoles.Admins,
        ...routeRoles.Instructors,
        ...routeRoles.Viewers,
      ],
      allowedModes: [EMode.site, EMode.station],
    },
    {
      title: t("siteManagement"),
      url: routes.siteManagement,
      icon: "./assets/icon/site-management.svg",
      requiredRoles: [
        ...routeRoles.Admins,
        ...routeRoles.Instructors,
        ...routeRoles.Viewers,
      ],
      allowedModes: [EMode.site],
    },
    {
      title: t("competencyPage"),
      url: routes.performance,
      icon: "./assets/icon/kvector.svg",
      requiredRoles: [
        ...routeRoles.Admins,
        ...routeRoles.Instructors,
        ...routeRoles.Viewers,
      ],
      allowedModes: [EMode.site],
    },
    {
      title: t("orbatTreePage"),
      url: routes.orbatSettings,
      icon: EIconsSrc.TREE_ICON,
      requiredRoles: [...routeRoles.Admins, ...routeRoles.Instructors],
      allowedModes: [EMode.site, EMode.station],
    },
    {
      title: t("DataPostingPage"),
      url: routes.dataPosting,
      icon: EIconsSrc.DATA_POSTING,
      requiredRoles: [...routeRoles.Admins, ...routeRoles.Instructors],
      allowedModes: [EMode.site, EMode.station],
    },
    {
      title: t("dataHistory"),
      url: routes.dataHistory,
      icon: EIconsSrc.HISTORY,
      requiredRoles: [
        ...routeRoles.Admins,
        ...routeRoles.Instructors,
        ...routeRoles.Viewers,
      ],
      allowedModes: [EMode.site, EMode.station],
    },
    {
      title: t("reportGenerator"),
      url: routes.reportGenerator,
      icon: EIconsSrc.REPORT_GENERATOR,
      requiredRoles: [
        ...routeRoles.Admins,
        ...routeRoles.Instructors,
        ...routeRoles.Viewers,
      ],
      allowedModes: [EMode.site],
    },
    {
      title: t("personalZone"),
      url: routes.personal,
      icon: EIconsSrc.PERSONAL_ZONE,
      requiredRoles: user.relatedForce
        ? [
            ...routeRoles.Admins,
            ...routeRoles.Instructors,
            ...routeRoles.Viewers,
          ]
        : [],
      allowedModes: [EMode.site],
    },
    {
      title: t("stationView"),
      url: routes.stationView,
      icon: "./assets/icon/kvector.svg",
      requiredRoles: [
        ...routeRoles.Admins,
        ...routeRoles.Instructors,
        ...routeRoles.Viewers,
      ],
      allowedModes:
        environment === EEnvironment.development
          ? [EMode.station, EMode.site]
          : [EMode.station],
    },
  ];

  const menuItemsFilteredByEnv = () => {
    if (environment?.toString() === EEnvironment.production) {
      return MenuItems.filter(
        (item: MenuItem) =>
          ![
            routes.siteManagement.toString(),
            routes.stationView.toString(),
          ].includes(item.url)
      );
    } else {
      return MenuItems;
    }
  };

  return (
    <IonMenu contentId="menuId" className="side-menu">
      <IonContent scrollY={false} className="contentMenuList">
        <IonList className="menuList">
          {menuItemsFilteredByEnv().map((item: MenuItem, index: number) => {
            return (
              <IonItem
                hidden={
                  !item.requiredRoles.includes(user.role) ||
                  !item.allowedModes.includes(mode)
                }
                class="ion-align-items-center"
                className={
                  location.pathname.includes(item.url)
                    ? "selected side-menu-item"
                    : "side-menu-item "
                }
                routerLink={item.url}
                routerDirection="none"
                lines="none"
                detail={false}
                key={index}
              >
                <div className="item-content">
                  <PMIcon
                    iconSrc={item.icon}
                    size="xLarge"
                    color={
                      location.pathname.includes(item.url) ? "xLight" : "dark"
                    }
                  />
                  <p>{item.title}</p>
                </div>
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
