const comparison = (
  currentValue: number,
  comparValue: number,
  big: boolean
) => {
  if (
    isNaN(currentValue) ||
    Number(currentValue) < 0 ||
    (!isNaN(Number(comparValue)) &&
      ((big && Number(comparValue) < Number(currentValue)) ||
        (!big && Number(comparValue) > Number(currentValue))))
  ) {
    //mark police cells as red
    return "invalidInput";
  }
  return "";
};

const positiveNumber = (currentValue: number) => {
  if (isNaN(currentValue) || Number(currentValue) < 0) {
    //mark police cells as red
    return "invalidInput";
  }
  return "";
};
const positiveNumberNtoM = (currentValue: string, m: number, n = 0) => {
  if (
    currentValue !== "" &&
    (isNaN(Number(currentValue)) ||
      Number(currentValue) > m ||
      Number(currentValue) < n)
  ) {
    //mark police cells as red
    return "invalidInput";
  }
  return "";
};

export const units = {
  Rounds: "rounds",
  Score: "score",
  Percentage: "percentage",
  Targets: "targets",
  Amount: "amount",
  Level: "level",
  Weight: "weight",
  Seconds: "seconds",
  Grade: "grade",
};

const validateInput = (input: string, unit: string) => {
  let error: string = "";
  let nInput: number = Number(input);

  switch (unit) {
    case units.Percentage:
    case units.Grade:
    case units.Weight:
      if (nInput < 0 || nInput > 100) error = "zeroHundredError";
      else error = "";
      break;

    case units.Score:
    case units.Amount:
    case units.Level:
    case units.Rounds:
    case units.Targets:
    case units.Seconds:
      if (nInput < 0) error = "positiveError";
      else error = "";
      break;
  }

  return error;
};

export { comparison, positiveNumber, positiveNumberNtoM, validateInput };
