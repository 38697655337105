import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store/plansStore";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./Configurations/msalConfig";
import "./services/i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { AppContextProvider } from "./context/AppContextProvider";

const msalInstance = new PublicClientApplication(msalConfig);
const queryClient = new QueryClient();

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </AppContextProvider>
    </QueryClientProvider>
  </MsalProvider>,
  document.getElementById("root")
);
