import React, { useContext } from "react";
import ForcesListContext from "../../../context/ForcesContext/forcesListContext";
import ISimpleTrainee from "../../../Interfaces/ISimpleTrainee";
import "./SoldiersListEditor.css";

const SoldiersListEditor: React.FC<{
  row: any;
  onRowChange: any;
  column: any;
}> = (props: { row: any; onRowChange: any; column: any }) => {
  const { soldiersNamesAndIds, setSoldiersNamesAndIds } =
    useContext(ForcesListContext);

  return (
    <div className="dataListDiv">
      <input
        className="dataListInput"
        list={"editorDataList"}
        onChange={(event) => {
          let soldier;
          if (soldiersNamesAndIds) {
            soldier = soldiersNamesAndIds.find(
              (soldier: ISimpleTrainee) =>
                String(soldier.id) === event.target.value
            );
          }
          let newRow = {
            ...props.row,
            [props.column.key]: soldier ? soldier.name : event.target.value,
          };

          if (soldier) {
            if (soldier.name !== "") {
              newRow["soldierId"] = soldier.id;
            } else {
              newRow["soldierId"] = "";
            }
            if (soldier.id + "" !== "") {
              newRow["soldierName"] = soldier.name;
            } else {
              newRow["soldierName"] = "";
            }
          } else {
            newRow["soldierId"] = "";
          }
          props.onRowChange(newRow);
        }}
      ></input>
      <datalist
        id="editorDataList"
        className="dataListDropdown"
        aria-modal={false}
      >
        {soldiersNamesAndIds.map((option: any, index: number) => (
          <option
            key={option.uniqId}
            value={option.id}
            className="dataListOption"
          >
            {option.name}
          </option>
        ))}
      </datalist>
    </div>
  );
};

export default SoldiersListEditor;
