import { useQuery } from "react-query";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import ISoldier from "../../../Interfaces/ISoldier";

const fetchSoldiersInLanes = (trainingTypeId?: number) => {
  return Axios.get(`${baseUrlPMBackend}lanes/getSoldiersInLanes`, {
    params: { trainingTypeId: trainingTypeId },
  }).then((response) => {
    let result = response.data;
    let soldiersInLanes: ISoldier[] = Object.keys(result).map((key) => {
      return { ...result[key], laneId: key };
    });
    return soldiersInLanes;
  });
};

export const useSoldiersInLanes = (trainingTypeId?: number) => {
  let query = useQuery<ISoldier[]>({
    queryKey: ["soldiersInLanes", trainingTypeId],
    queryFn: () => fetchSoldiersInLanes(trainingTypeId),
    enabled: !!trainingTypeId,
  });
  return query;
};
