import React, { FC, useState } from "react";
import { IonPage, IonContent, IonGrid } from "@ionic/react";
import "./TreadmillStation.css";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MobileHeader from "../../../Mobile/MobileHeader/MobileHeader";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import MobileTitleRow from "../../../Mobile/MobileHeader/MobileTitleRow";

import TreadmillComponent from "./TreadmillComponent";

const TradeMillStation: FC = () => {
  const { i18n, t } = useTranslation();
  const params = useParams<{
    stationName: string;
    trainingTypeId: string;
    stationId: string;
    laneId: string;
    traineeName: string;
    traineeId: string;
    planId: string;
  }>();
  const traineeId = parseInt(params.traineeId);
  const traineeName = params.traineeName;
  const stationName = params.stationName;
  const trainingTypeId = parseInt(params.trainingTypeId);
  const history = useHistory();

  const [isComplete, setIsComplete] = useState<boolean>(false);

  const backHandler = () => {
    history.goBack();
  };

  return (
    <IonPage>
      <IonContent className="contentContainerM">
        <MobileHeader
          iconEndSrc={EIconsSrc.MENU_ICON}
          iconStartSrc={
            i18n.language === "he"
              ? EIconsSrc.BACK_ICON_HE
              : EIconsSrc.BACK_ICON_EN
          }
          title={t(stationName)}
          iconStartHandler={backHandler}
        />
        <IonGrid className="itemsContainerTM">
          <MobileTitleRow title={traineeName} />

          <TreadmillComponent
            traineeId={traineeId}
            trainingTypeId={trainingTypeId}
            afterPostHandler={backHandler}
            isComplete={isComplete}
            setIsComplete={setIsComplete}
            planId={undefined}
          ></TreadmillComponent>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default TradeMillStation;
