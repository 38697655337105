import React from "react";
import "./OrbatTreeDrawer.css";

import Drawer from "react-modern-drawer";
import i18n from "../../../../services/i18next";

import OrbatTree from "./OrbatTree";
import IForceTreeNode from "../../../../Interfaces/IForceTreeNode";
import openTree from "../../../../Assets/openTree.png";
import closeTree from "../../../../Assets/closeTree.png";
interface IProps {
  orbatForces: IForceTreeNode[];
  onCheckForce: (forces: IForceTreeNode[]) => void;
  limit?: number;
  isDrawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  displayPlatoonAndAbove?: boolean | undefined;
}

const OrbatTreeDrawer: React.FC<IProps> = (props: IProps) => {
  const { setDrawerOpen, isDrawerOpen } = props;
  const toggleDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };

  return (
    <div className="orbarTreeDrawer">
      <Drawer
        // open={isTaggingActive ? false : isDrawerOpen ? true : false}
        open={isDrawerOpen}
        size={100}
        onClose={() => {
          setDrawerOpen(false);
        }}
        overlayOpacity={0.2}
        style={{
          top: "unset",
          right: "unset",
          left: "unset",
          width: "18%",
          minWidth: "320px",
          height: "calc(100% - 48px)",
          backgroundColor: "transparent",
        }}
        direction={i18n.language === "he" ? "right" : "left"}
      >
        <div className="teecollapseRow">
          <OrbatTree
            checked={props.onCheckForce}
            limit={props.limit}
            readonly
            checkedForces={props.orbatForces}
            displayPlattonAndAbove={props.displayPlatoonAndAbove}
          />
          <div className="closeButtonWrap">
            <div className="closeButton" onClick={toggleDrawer}>
              <img src={i18n.language === "he" ? closeTree : openTree} />
            </div>
          </div>
        </div>
      </Drawer>
      <div className="openButtonWrap">
        <div className="openButton" onClick={toggleDrawer}>
          <img src={i18n.language === "he" ? openTree : closeTree} />
        </div>
      </div>
    </div>
  );
};

export default OrbatTreeDrawer;
