import React from "react";
import "./SquaresList.css";
import { IonLabel, IonItemDivider, IonItemGroup, IonList } from "@ionic/react";
import IStationData from "../../../Interfaces/IStationData";
import ListItem from "./ListItem";
import { useTranslation } from "react-i18next";

const SquaresList: React.FC<IStationData> = (props: IStationData) => {
  const { t } = useTranslation();

  return (
    <IonItemGroup>
      <IonItemDivider sticky className="headerContainerSqL">
        <IonLabel className="stationTitle">
          {t(props.stationName.trim())}
        </IonLabel>
      </IonItemDivider>

      <IonList className="listContainerSqL">
        {props.stationTraineesDetails.map((trainee, index) => (
          <ListItem
            key={index}
            traineeDetails={trainee}
            setSingleTraineeToRemove={props.setSingleTraineeToRemove}
            singleTraineeToRemove={props.singleTraineeToRemove}
            isAlertOpen={props.isAlertOpen}
            setNewColumnForTrainee={props.setNewColumnForTrainee}
            onTraineeClicked={props.onTraineeClicked}
          ></ListItem>
        ))}
      </IonList>
    </IonItemGroup>
  );
};
export default SquaresList;
