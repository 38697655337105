import axios from "../../Axios";
import { Dispatch } from "redux";
import IPlan from "../../Interfaces/IPlan";
import { AppActions } from "./rootActions";
import { baseUrlPMBackend } from "../../Configurations/consts";
import customToast from "../../components/Shared/Toast/CustomToast";
import { getPlans } from "../../apis/PlansAPI";
import { AxiosResponse } from "axios";

// Plan actions
export const SET_PLANS = "SET_PLANS";
export const ADD_PLAN = "ADD_PLAN";
export const EDIT_PLAN = "EDIT_PLAN";
export const DELETE_PLAN = "DELETE_PLAN";
export const SELECTED_PLAN = "SELECTED_PLAN";
export const FETCH_PLANS_REQUEST = "FETCH_PLANS_REQUEST";
export const FETCH_PLANS_SUCCESS = "FETCH_PLANS_SUCCESS";
export const FETCH_PLANS_FAILURE = "FETCH_PLANS_FAILURE";
export const SAVE_PLAN_BUTTON_CHANGE = "SAVE_PLAN_BUTTON_CHANGE";
export const UPDATE_PLAN = "UPDATE_PLAN";
export const SAVE_PLAN_ATTRIBUTES_REQUEST = "SAVE_PLAN_ATTRIBUTES_REQUEST";
export const SAVE_PLAN_ATTRIBUTES_SUCCESS = "SAVE_PLAN_ATTRIBUTES_SUCCESS";
export const SAVE_PLAN_ATTRIBUTES_FAILURE = "SAVE_PLAN_ATTRIBUTES_FAILURE";
export const DELETE_PLAN_REQUEST = " DELETE_PLAN_REQUEST";
export const DELETE_PLAN_SUCCESS = " DELETE_PLAN_SUCCESS";
export const DELETE_PLAN_FAILURE = " DELETE_PLAN_FAILURE";
export const UPDATE_PLAN_ID = "UPDATE_PLAN_ID";
export const SET_EDIT_MODE = "SET_EDIT_MODE";
export const CANCEL_CHECKBOX_EDIT = "CANCEL_CHECKBOX_EDIT";
export const SET_ACTIVE_REQUEST = "SET_ACTIVE_REQUEST";
export const SET_ACTIVE_SUCCESS = "SET_ACTIVE_SUCCESS";
export const SET_ACTIVE_FAILURE = "SET_ACTIVE_FAILURE";
export const REMOVE_ACTIVE_PLAN = "REMOVE_ACTIVE_PLAN";
export const DUPLICATE_PLAN_REQUEST = "DUPLICATE_PLAN_REQUEST";
export const DUPLICATE_PLAN_SUCCESS = "DUPLICATE_PLAN_SUCCESS";
export const DUPLICATE_PLAN_FAILURE = "DUPLICATE_PLAN_FAILURE";
export const INCREMENT_UPDATES_COUNTER = "INCREMENT_UPDATES_COUNTER";
//            Plan
export interface SetPlansAction {
  type: typeof SET_PLANS;
  plans: IPlan[];
}

export interface AddPlanAction {
  type: typeof ADD_PLAN;
  plan: IPlan;
}

export interface EditPlanAction {
  type: typeof EDIT_PLAN;
  plan: IPlan;
}

export interface DeletePlanAction {
  type: typeof DELETE_PLAN;
  planId: number;
}

export interface SelectedPlanAction {
  type: typeof SELECTED_PLAN;
  selectedPlan: IPlan | undefined;
  editMode?: boolean;
}
export interface FetchPlansRequest {
  type: typeof FETCH_PLANS_REQUEST;
}

export interface FetchPlansSuccess {
  type: typeof FETCH_PLANS_SUCCESS;
  plans: IPlan[];
}

export interface FetchPlansFailure {
  type: typeof FETCH_PLANS_FAILURE;
  error: Error | "";
}

export interface SavePlanButtonChange {
  type: typeof SAVE_PLAN_BUTTON_CHANGE;
}

export interface UpdatePlan {
  type: typeof UPDATE_PLAN;
  plan: IPlan;
}

export interface SavePlanAttributesRequest {
  type: typeof SAVE_PLAN_ATTRIBUTES_REQUEST;
}

export interface SavePlanAttributesSuccess {
  type: typeof SAVE_PLAN_ATTRIBUTES_SUCCESS;
  plan: IPlan;
}

export interface SavePlanAttributesFailure {
  type: typeof SAVE_PLAN_ATTRIBUTES_FAILURE;
  initialPlan: IPlan | undefined;
  error: Error | "";
}

export interface DeletePlanRequest {
  type: typeof DELETE_PLAN_REQUEST;
}

export interface DeletePlanSuccess {
  type: typeof DELETE_PLAN_SUCCESS;
}

export interface DeletePlanFailure {
  type: typeof DELETE_PLAN_FAILURE;
  error: Error | "";
}

export interface setActiveRequest {
  type: typeof SET_ACTIVE_REQUEST;
}

export interface setActiveSuccess {
  type: typeof SET_ACTIVE_SUCCESS;
  planId: number;
}

export interface setActiveFailure {
  type: typeof SET_ACTIVE_FAILURE;
  error: Error | "";
}

export interface removeActivePlan {
  type: typeof REMOVE_ACTIVE_PLAN;
}

export interface UpdatePlanId {
  type: typeof UPDATE_PLAN_ID;
  id: number;
}

export interface SetEditMode {
  type: typeof SET_EDIT_MODE;
  editMode?: boolean;
}

export interface CancelCheckboxEdit {
  type: typeof CANCEL_CHECKBOX_EDIT;
  plan: IPlan;
}

export interface duplicatePlanRequest {
  type: typeof DUPLICATE_PLAN_REQUEST;
}

export interface duplicatePlanSuccess {
  type: typeof DUPLICATE_PLAN_SUCCESS;
  plan: IPlan;
}

export interface duplicatePlanFailure {
  type: typeof DUPLICATE_PLAN_FAILURE;
  error: Error | "";
}
export interface incrementUpdatesCounter {
  type: typeof INCREMENT_UPDATES_COUNTER;
}

export const fetchPlans = (t: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_PLANS_REQUEST,
    });
    await getPlans()
      .then((response: AxiosResponse<IPlan[]>) => {
        let plans: IPlan[] = response.data.map((plan: IPlan) => ({
          ...plan,
          name: t(plan.name),
          error: "",
        }));
        dispatch({ type: FETCH_PLANS_SUCCESS, plans: plans });
        dispatch({
          type: SET_ACTIVE_SUCCESS,
          planId: response.data?.find((plan: IPlan) => plan.isActive)?.id ?? -1,
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_PLANS_FAILURE, error: error });
        customToast.error(t("loadPlansError"));
      });
  };
};

export const savePlanAttributes = (
  plan: IPlan,
  initialPlan: IPlan | undefined,
  type: "newPlan" | "savePlan",
  t: any
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: SAVE_PLAN_ATTRIBUTES_REQUEST,
    });
    let startDate = new Date(plan.startDate!);
    let endDate = new Date(plan.endDate!);
    let newStartDate = Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      new Date().getTimezoneOffset() / 60 > 0
        ? startDate.getHours()! - new Date().getTimezoneOffset() / 60
        : startDate.getHours()! + new Date().getTimezoneOffset() / 60,
      startDate.getMinutes(),
      0,
      0
    );
    let newEndDate = Date.UTC(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      new Date().getTimezoneOffset() / 60 > 0
        ? endDate.getHours()! - new Date().getTimezoneOffset() / 60
        : endDate.getHours()! + new Date().getTimezoneOffset() / 60,
      endDate.getMinutes(),
      0,
      0
    );

    let newPlan = { ...plan, startDate: newStartDate, endDate: newEndDate };

    axios
      .post(`${baseUrlPMBackend}plans/savePlan`, {
        params: {
          plan: newPlan,
        },
      })
      .then((res) => {
        if (res.data.rows.length > 0)
          dispatch({ type: UPDATE_PLAN_ID, id: res.data.rows[0].id });
        if (type === "savePlan") {
          dispatch({
            type: SAVE_PLAN_ATTRIBUTES_SUCCESS,
            plan: {
              ...newPlan,
              startDate: plan.startDate,
              endDate: plan.endDate,
            },
          });
          customToast.success(t("planSavedSuccessfull"));
        } else {
          customToast.success(t("planAddedSuccessfull"));
        }

        dispatch({
          type: SELECTED_PLAN,
          selectedPlan: {
            ...newPlan,
            startDate: plan.startDate,
            endDate: plan.endDate,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: SAVE_PLAN_ATTRIBUTES_FAILURE,
          initialPlan: initialPlan,
          error: error,
        });
        customToast.error(t("errorWhileSavePlan"));
      });
  };
};

export const deletePlan = (planId: number, t: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: DELETE_PLAN_REQUEST,
    });

    axios
      .get(`${baseUrlPMBackend}plans/deletePlan`, {
        params: {
          planId: planId,
        },
      })
      .then((res) => {
        dispatch({ type: DELETE_PLAN, planId: planId });
        dispatch({ type: SELECTED_PLAN, selectedPlan: undefined });

        dispatch({ type: DELETE_PLAN_SUCCESS });
        customToast.success(t("planDeletedSuccessfull"));
      })
      .catch((error) => {
        dispatch({ type: DELETE_PLAN_FAILURE, error: error });
        customToast.error(t("deletePlanError"));
      });
  };
};

export const setActive = (planId: number, t: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: SET_ACTIVE_REQUEST,
    });

    axios
      .post(`${baseUrlPMBackend}plans/setActive`, {
        params: {
          planId: planId,
        },
      })
      .then((res) => {
        dispatch({ type: REMOVE_ACTIVE_PLAN });
        dispatch({ type: SET_ACTIVE_SUCCESS, planId: planId });
        customToast.success(t("activetedPlanSuccessfull"));
      })
      .catch((error) => {
        dispatch({ type: SET_ACTIVE_FAILURE, error: error });
        customToast.error(t("errorToActivetePlan"));
      });
  };
};
export const duplicatePlan = (planId: number, t: any) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: DUPLICATE_PLAN_REQUEST,
    });

    axios
      .post(`${baseUrlPMBackend}plans/duplicatePlan`, {
        params: {
          planId: planId,
          duplicateString: t("duplicate"),
        },
      })
      .then(async (res) => {
        customToast.success(t("planDuplicatedSuccefuly"));
        await dispatch({ type: DUPLICATE_PLAN_SUCCESS, plan: res.data });
        dispatch(fetchPlans(t));
        setTimeout(() => {}, 1000);
      })
      .catch((error) => {
        dispatch({ type: DUPLICATE_PLAN_FAILURE, error: error });
        customToast.error(t("errorDuplicatePlan"));
      });
  };
};

export type PlanActionTypes =
  | SetPlansAction
  | AddPlanAction
  | EditPlanAction
  | DeletePlanAction
  | SelectedPlanAction
  | FetchPlansSuccess
  | FetchPlansFailure
  | FetchPlansRequest
  | SavePlanButtonChange
  | UpdatePlan
  | SavePlanAttributesRequest
  | SavePlanAttributesSuccess
  | SavePlanAttributesFailure
  | DeletePlanRequest
  | DeletePlanSuccess
  | DeletePlanFailure
  | UpdatePlanId
  | SetEditMode
  | CancelCheckboxEdit
  | setActiveRequest
  | setActiveSuccess
  | setActiveFailure
  | removeActivePlan
  | duplicatePlanRequest
  | duplicatePlanSuccess
  | duplicatePlanFailure
  | incrementUpdatesCounter;
