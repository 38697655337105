import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { Redirect, useLocation } from "react-router-dom";
import { Option } from "react-dropdown";

// css
import "./DataHistoryTabs.css";

// ionic imports
import {
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";

// interfaces
import DataHistory from "./DataHistory";

import { useTranslation } from "react-i18next";
import Axios from "../../../Axios";
import {
  baseUrlPMBackend,
  mode,
  trainingTypeMode,
} from "../../../Configurations/consts";
import PMLoading from "../../../components/Shared/Alert/PMLoading";
import { IHistoryTabs, ITabOptions } from "../../../Interfaces/IDataHistory";
import { IDateRange } from "../../../Interfaces/IDatePicker";
import IForceTreeNode from "../../../Interfaces/IForceTreeNode";
import useForcesChecked from "../../../components/CustomHooks/useForcesChecked";
import PMLabel from "../../../components/themeComponents/PMLabel";
import { routes } from "../../../services/routeRoles";
import usePlans from "../../../components/CustomHooks/usePlans";
import EMode from "../../../Enums/EMode";

const DataHistoryTabs: React.FC = (): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation();

  //useState
  const [TabsOptions, setTabsOptions] = useState<ITabOptions[]>(
    [] as ITabOptions[]
  );
  const [selectedSoldier, setSelectedSoldier] = useState<IForceTreeNode[]>(
    [] as IForceTreeNode[]
  );
  const [inputType, setInputType] = useState<"date" | "plan">("date");

  //default current day.
  const [rangeDate, setRangeDate] = useState<IDateRange | undefined>({
    startDate: new Date(new Date().setHours(0, 0, 0, 0)),
    endDate: new Date(new Date().setHours(23, 59, 59)),
  });

  let isMustHaveSelectedPlan = false;

  //custom Hooks
  const { onPlanSelectedHandler, plansOptions, selectedPlan } = usePlans(
    undefined,
    isMustHaveSelectedPlan
  );
  const { forcesToRemove, newForces } = useForcesChecked(selectedSoldier);

  const onPlanSelected = (option: Option) => {
    onPlanSelectedHandler(option);
    setRangeDate(undefined);
  };
  const onDateChanged = (rangeDate: IDateRange) => {
    setRangeDate(rangeDate);
    onPlanSelectedHandler({ label: "", value: "" });
  };

  useEffect(() => {
    let mounted: boolean = true;
    Axios.get(`${baseUrlPMBackend}dataHistory/getTrainingTypesHeaders`)
      .then((res) => {
        let tabsData: ITabOptions[] = [];
        res.data.data.map((row: IHistoryTabs) => {
          tabsData.push({
            headersArray: row.headers,
            tabId: row.trainingTypeId,
            title: row.trainingTypeName,
            url: `${routes.dataHistory}/${row.trainingTypeName}`,
          });
        });
        mounted &&
          setTabsOptions(
            tabsData.filter(
              (trainingType) =>
                mode === EMode.site ||
                (mode === EMode.station &&
                  trainingTypeMode &&
                  trainingTypeMode === trainingType.title)
            )
          );
      })
      .catch((error) => {});
    return () => {
      mounted = false;
    };
  }, []);

  return TabsOptions.length ? (
    <IonTabs className="pm-tabs">
      <IonRouterOutlet id="main">
        <Route
          path={routes.dataHistory}
          render={() => (
            <Redirect
              to={`${
                TabsOptions.length ? TabsOptions[0].url : routes.dataHistory
              }`}
            />
          )}
          exact={true}
        />

        {TabsOptions.map((tab) => (
          <Route
            path={tab.url}
            key={tab.tabId}
            render={() => (
              <DataHistory
                forcesToRemove={forcesToRemove}
                newForces={newForces!}
                headers={tab.headersArray}
                trainingTypeId={tab.tabId}
                rangeDate={rangeDate}
                setRangeDate={onDateChanged}
                selectedSoldier={selectedSoldier}
                setSelectedSoldier={setSelectedSoldier}
                selectedPlan={selectedPlan}
                plansOptions={plansOptions}
                onPlanSelectedHandler={onPlanSelected}
                inputType={inputType}
                setInputType={setInputType}
              ></DataHistory>
            )}
            exact={true}
          />
        ))}
      </IonRouterOutlet>
      <IonTabBar
        slot="top"
        hidden={!TabsOptions.length}
        className="ion-justify-content-start dataHistoryTabs"
      >
        {TabsOptions.map((item: ITabOptions, index: number) => {
          return (
            <IonTabButton
              className={location.pathname === item.url ? "tab-selected" : ""}
              tab={item.title}
              href={item.url}
              key={index}
            >
              <PMLabel
                fontColor="light"
                fontFamily="Light"
                fontSize="medium"
                textAlign="center"
              >
                {t(item.title.trim())}
              </PMLabel>
            </IonTabButton>
          );
        })}
      </IonTabBar>
    </IonTabs>
  ) : (
    <PMLoading
      isOpen={!TabsOptions.length}
      message={t("loadingData")}
      spinner="bubbles"
    ></PMLoading>
  );
};
export default DataHistoryTabs;
