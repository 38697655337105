import { IonSegment, IonSegmentButton } from "@ionic/react";
import React from "react";
import { useTranslation } from "react-i18next";
import PMLabel from "../../../components/themeComponents/PMLabel";
import { ITabOptions } from "../../../Interfaces/IDataHistory";

type Props = {
  segmentsOptions: ITabOptions[];
  selectedSegment: ITabOptions | undefined;
  setSelectedSegment: React.Dispatch<
    React.SetStateAction<ITabOptions | undefined>
  >;
};
const SegmentsMenu = (props: Props) => {
  const { t } = useTranslation();

  const segmentChange = (event: any) => {
    const result = props.segmentsOptions.filter(
      (seg) => seg.title === event.detail.value
    );
    props.setSelectedSegment(result[0]);
  };

  return (
    <>
      {props.segmentsOptions.length && (
        <IonSegment
          onIonChange={(event) => segmentChange(event)}
          value={props.selectedSegment?.title}
          scrollable={true}
          className="all-segments"
        >
          {props.segmentsOptions.map((option) => {
            return (
              <IonSegmentButton
                key={option.tabId}
                className="one-segment"
                value={option.title}
              >
                <PMLabel
                  fontSize="large"
                  fontFamily="Regular"
                  cssClass="titleCard"
                >
                  {t(option.title)}
                </PMLabel>
              </IonSegmentButton>
            );
          })}
        </IonSegment>
      )}
    </>
  );
};

export default SegmentsMenu;
