import { IonModal } from "@ionic/react";
import { useRef, useState, useEffect, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import IStationsChart from "../../../../../Interfaces/IStationsChart";
import PMButton from "../../../../themeComponents/PMButton";

import "./TooltipData.css";
import TooltipList from "./TooltipList";

interface IClickHandler {
  showStation?: boolean;
  index?: number;
  header?: string;
  isGoBack?: boolean;
}

type Props = {
  tooltipData: IStationsChart[];
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const TooltipData = (props: Props) => {
  const { tooltipData, isOpen, setIsOpen } = props;

  const [dataShow, setDataShow] = useState<
    "forces" | "trainingTypes" | "elements"
  >("trainingTypes"); // select which kind of data to display
  const [soliderPosition, setSoliderPosition] = useState<number>(0);
  const [stationPosition, setStationPosition] = useState<number>(0);
  const [header, setHeader] = useState<string>("");

  const modal = useRef<HTMLIonModalElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    //if there is only one force to show we don't need to to give to user an option to choose from
    if (tooltipData.length === 1) setDataShow("trainingTypes");
  }, [tooltipData]);

  //checking the state inside of the modal and update the data that need to be shown
  const onClickHandler = ({
    showStation,
    index,
    header,
    isGoBack,
  }: IClickHandler) => {
    if (isGoBack) {
      setDataShow((prev) => {
        return prev === "elements" ? "trainingTypes" : "forces";
      });
    } else {
      setDataShow((prev) => {
        return prev === "forces" ? "trainingTypes" : "elements";
      });
      if (showStation === false) {
        setSoliderPosition(index!);
      } else if (showStation === true) {
        setStationPosition(index!);
        setHeader(header!);
      }
    }
  };
  return (
    <>
      {tooltipData && (
        <IonModal
          ref={modal}
          className="modal-sheet"
          isOpen={isOpen}
          initialBreakpoint={0.4}
          breakpoints={[0, 0.4, 0.6, 0.75]}
          backdropBreakpoint={1} // don't show the backdrop at all. set to 0 and reload if you want to see the changes
          onDidDismiss={() => {
            setIsOpen(false);
            // rest the state inside of the modal according to how many forces there are
            if (tooltipData.length === 1) setDataShow("trainingTypes");
            else setDataShow("forces");
          }}
        >
          <div className="ion-padding tooltip-list">
            {dataShow === "forces" ? (
              <TooltipList
                header={t("chooseForce")}
                showSection={"forceName"}
                listData={tooltipData}
                onClickHandler={onClickHandler}
                expand={false}
              />
            ) : dataShow === "trainingTypes" ? (
              <TooltipList
                header={t("chooseForceStation")}
                showSection={"forceTrainingType"}
                listData={tooltipData[soliderPosition]?.gradesArray?.filter(
                  (trainingType) => trainingType.isAttended
                )}
                onClickHandler={onClickHandler}
                expand={false}
              />
            ) : (
              <TooltipList
                header={header}
                showSection={"stationNameWithValue"}
                listData={
                  tooltipData[soliderPosition].gradesArray !== undefined
                    ? tooltipData[soliderPosition]?.gradesArray?.filter(
                        (trainingType) => trainingType.isAttended
                      )![stationPosition].tooltipResults.tooltipElementsResults
                    : undefined
                }
                onClickHandler={onClickHandler}
                expand={true}
              />
            )}
          </div>
        </IonModal>
      )}
    </>
  );
};

export default TooltipData;
