import { IonRow, IonItem, IonGrid } from "@ionic/react";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import EEventType from "../../../Interfaces/EEventType";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import ICheckInOutData from "../../../Interfaces/ICheckInData";
import ITraineeDetails from "../../../Interfaces/ITraineeDetails";
import IMobileListItem from "../../../components/Mobile/SiteManagement/ManualStations/MobileListItem";
import { useWebsocketCheckIn } from "../../../services/siteManagementSocket";
import "./TraineesListComponent.css";
import PMTitle from "../../themeComponents/PMTitle";
import PMIcon from "../../themeComponents/PMIcon";
import Spinner from "../Spinner/Spinner";
interface IProps {
  isManual?: string;
  stationId: number;
  stationName: string;
  trainingTypeId: number;
  planId?: number;
  isStateCleaning?: boolean;
  cleaningHandler?: () => void;
  setCheckedTraineesList?: Dispatch<SetStateAction<ITraineeDetails[]>>;
  isLoading: boolean;
  setIsLoading: (state: boolean) => void;
  stationTraineesList: ITraineeDetails[];
  setTraineesList: Dispatch<SetStateAction<ITraineeDetails[]>>;
  onClickTraineeHandler?: Dispatch<SetStateAction<ITraineeDetails | undefined>>;
  isDesktop?: boolean;
  selectedTrainee?: ITraineeDetails;
}

const TraineesListComponent: React.FC<IProps> = (
  props: IProps
): JSX.Element => {
  const {
    isManual,
    stationId,
    stationName,
    trainingTypeId,
    planId,
    isStateCleaning,
    cleaningHandler,
    setCheckedTraineesList,
    setIsLoading,
    isLoading,
    stationTraineesList,
    setTraineesList,
    onClickTraineeHandler,
    isDesktop,
    selectedTrainee,
  } = props;
  const { t } = useTranslation();
  const [receivedMessage] = useWebsocketCheckIn();

  const removeTraineeFromOtherStation: Function = (
    ICheckInData: ICheckInOutData
  ) => {
    let newStationTraineesList: ITraineeDetails[] = [...stationTraineesList];
    const isSameTrainee = (trainee: ITraineeDetails) =>
      Number(trainee.uniqId) === Number(ICheckInData.traineeDetails.uniqId);

    const elementIndex: number =
      newStationTraineesList.findIndex(isSameTrainee);
    if (elementIndex !== -1) {
      newStationTraineesList.splice(elementIndex, 1);
    }
    return newStationTraineesList;
  };
  const removeTraineeFromStation = (checkInData: ICheckInOutData) => {
    let traineeToRemove: ITraineeDetails = checkInData.traineeDetails;
    let newStationTraineesList: ITraineeDetails[];
    if (traineeToRemove !== undefined && traineeToRemove !== null) {
      newStationTraineesList = removeTraineeFromOtherStation(checkInData);
      setTraineesList(newStationTraineesList);
    }
  };

  const addTraineeToStation: Function = (checkInData: ICheckInOutData) => {
    if (checkInData === undefined) return;
    if (checkInData.stationId !== stationId) {
      let newStationTraineesList: ITraineeDetails[] =
        removeTraineeFromOtherStation(checkInData);
      setTraineesList(newStationTraineesList);
      return;
    }

    let newStationTraineesList: ITraineeDetails[] =
      removeTraineeFromOtherStation(checkInData);

    const isEmpty = (element: ITraineeDetails) => element.traineeName === "";
    const emptyPlaceIndex: number = newStationTraineesList.findIndex(isEmpty); // get the index of next empty place in the list

    if (emptyPlaceIndex !== -1) {
      //empty place exists
      newStationTraineesList[emptyPlaceIndex] = checkInData.traineeDetails;
    } else {
      newStationTraineesList.push(checkInData.traineeDetails);
    }
    setTraineesList(newStationTraineesList);
  };

  const loadTraineesInStation: Function = async () => {
    setIsLoading(true);

    let traineesInStation: ITraineeDetails[] = [];
    try {
      const URI = baseUrlPMBackend + "siteManagement/getTraineesInStation";
      let traineesInStationResponse = await Axios.get(URI, {
        params: {
          stationId: stationId,
        },
      });
      traineesInStation =
        traineesInStationResponse.data === null
          ? []
          : traineesInStationResponse.data;
      setIsLoading(false);
    } catch {
      traineesInStation = [];
      fetchTrainees();
    }
    return traineesInStation;
  };
  const fetchTrainees = async () => {
    await loadTraineesInStation().then((data: ITraineeDetails[]) => {
      setTraineesList(data);
    });
  };

  const setCheckedTrainee = (trainee: ITraineeDetails) => {
    if (setCheckedTraineesList)
      setCheckedTraineesList((prev: ITraineeDetails[]) => {
        prev.push(trainee);
        return prev;
      });
  };

  const setUnCheckedTrainee = (unCheckedTrainee: ITraineeDetails) => {
    if (setCheckedTraineesList)
      setCheckedTraineesList((prev: ITraineeDetails[]) => {
        const isSameTrainee = (trainee: ITraineeDetails) =>
          Number(trainee.uniqId) === Number(unCheckedTrainee.uniqId);
        const elementIndex: number = prev.findIndex(isSameTrainee);
        if (elementIndex !== -1) {
          prev.splice(elementIndex, 1);
        }
        return prev;
      });
  };

  /**
   * when we received a massage update the UI
   */
  useEffect(() => {
    switch (receivedMessage?.eventType) {
      case EEventType.CHECK_IN:
        addTraineeToStation(receivedMessage);
        break;
      case EEventType.CHECK_OUT:
        removeTraineeFromStation(receivedMessage);
        break;
    }
  }, [receivedMessage]);
  return (
    <IonGrid className="itemsContainerTL">
      <IonRow>
        <IonItem
          mode="md"
          lines="none"
          className="traineeItemTL titleTL"
          button={false}
        >
          <PMTitle fontColor="light" fontFamily="Regular" fontSize="medium">
            {t("traineesList")}
          </PMTitle>
          <PMIcon
            color="xLight"
            size="medium"
            hidden={isDesktop}
            isButton={false}
            iconSrc={EIconsSrc.TRASH_ICON}
            onClick={cleaningHandler}
          />
        </IonItem>
      </IonRow>
      {isLoading ? (
        <Spinner className="spinner-trainees-list" />
      ) : (
        <div className="scrollContainerTL">
          {stationTraineesList?.map((traineeDetails) => (
            <IMobileListItem
              key={traineeDetails.uniqId}
              isDesktop={isDesktop ? isDesktop : false}
              setCheckedTrainee={setCheckedTrainee}
              setUnCheckedTrainee={setUnCheckedTrainee}
              isButton={isManual === "manual"}
              isDisabled={false}
              trainee={traineeDetails}
              isEdit={isStateCleaning ? isStateCleaning : false}
              trainingTypeId={trainingTypeId}
              stationId={stationId}
              stationName={stationName}
              planId={planId}
              setSelectedTraineeForDesktop={onClickTraineeHandler}
              selectedTrainee={selectedTrainee}
            />
          ))}
        </div>
      )}
    </IonGrid>
  );
};

export default TraineesListComponent;
