import React, { useEffect } from "react";
import "./DashboardDetailsCard.css";
import PMLabel from "../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import VerticalStackedBar from "../VerticalProgressBar/VerticalStackedBar";
import Dictionary from "../../../pages/IDictionary";
import PMTitle from "../../themeComponents/PMTitle";
import DashboardDetailsCard from "./DashboardDetailsCard";
import ILabel from "../../../Interfaces/ILabel";
import { ITrainingTypeResult } from "../../../Interfaces/dataCalculator";
interface IProps {
  indicatorData: Dictionary<any> | undefined;
  selectedIndicator: number | undefined;
  trainingTypeData: Dictionary<any> | undefined;
  trainingTypesLabels: ILabel[];
  onCardClick: (
    element: string,
    trainingType: ITrainingTypeResult,
    indicatorId: number
  ) => void;
}

const DashboardTrainingTypeComp: React.FC<IProps> = (
  props: IProps
): JSX.Element => {
  const {
    indicatorData,
    selectedIndicator,
    trainingTypeData,
    trainingTypesLabels,
    onCardClick,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      {trainingTypeData &&
        trainingTypeData[Number(selectedIndicator)] &&
        Object.values(trainingTypeData[Number(selectedIndicator)]).map(
          (trainingType: any) => (
            <div className="trainingTypeDetails " key={Number(trainingType.id)}>
              <div className="trainingTypeTitleRow borderRowTitle">
                <div className="trainingTypeTitleCol">
                  <PMTitle fontSize="large" fontColor="xLight">
                    {
                      trainingTypesLabels.find(
                        (t) => +t.id === +trainingType.id
                      )?.label
                    }
                  </PMTitle>
                  <div className="trainingTypeTitleRow">
                    <div className="textRowCD">
                      <PMLabel
                        fontColor="xLight"
                        fontSize="medium"
                        fontFamily="Bold"
                        textAlign="center"
                      >
                        {trainingType.participants}
                      </PMLabel>
                      <PMLabel
                        fontColor="xLight"
                        fontSize="small"
                        textAlign="center"
                        cssClass="textCD"
                      >
                        {t("attended")}
                      </PMLabel>
                    </div>
                    <PMLabel
                      fontColor="light"
                      fontSize="small"
                      textAlign="center"
                      cssClass="seperator"
                    >
                      |
                    </PMLabel>
                    <div className="textRowCD">
                      <PMLabel
                        fontColor="xLight"
                        fontSize="medium"
                        fontFamily="Bold"
                        textAlign="center"
                      >
                        {trainingType.soldierMissed}
                      </PMLabel>
                      <PMLabel
                        fontColor="xLight"
                        fontSize="small"
                        textAlign="center"
                        cssClass="textCD"
                      >
                        {t("notAttended")}
                      </PMLabel>
                    </div>
                  </div>
                </div>
              </div>
              <div className="CDdetailsRow scrollM">
                {indicatorData &&
                  trainingTypeData[Number(selectedIndicator)] &&
                  trainingTypeData[Number(selectedIndicator)][
                    Number(trainingType.id)
                  ] &&
                  Object.values(
                    trainingTypeData[Number(selectedIndicator)][
                      Number(trainingType.id)
                    ].elements
                  ).map((element: any) => (
                    <DashboardDetailsCard
                      key={String(element.id)}
                      element={element}
                      totalAmountValue={trainingType.participants}
                      indicatorId={Number(selectedIndicator)}
                      trainingType={trainingType}
                      onCardClick={onCardClick}
                    ></DashboardDetailsCard>
                  ))}
              </div>
            </div>
          )
        )}
    </>
  );
};

export default DashboardTrainingTypeComp;
