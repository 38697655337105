import React from "react";
import "./SecurityContact.css";

import { useIonPopover } from "@ionic/react";

import { useTranslation } from "react-i18next";
import PMLabel from "../../themeComponents/PMLabel";

const SecurityContact: React.FC = () => {
  const { t } = useTranslation();
  const [present, dismiss] = useIonPopover(PopoverList, {
    onHide: () => dismiss(),
  });

  return (
    <PMLabel
      onClick={(e) =>
        present({
          event: e.nativeEvent,
        })
      }
      textAlign="center"
      fontColor="light"
      fontFamily="Light"
      fontSize="medium"
    >
      {t("security")}
    </PMLabel>
  );
};

const PopoverList: React.FC = () => (
  <div className="contact-popover">
    <>
      <h3>לפניות בנושא גילוי ליקויי אבטחה ודלף:</h3>
    </>
    <>
      <h5>מייל אזרחי: Certhagana@gmail.com</h5>
      <h5>
        מייל צבאי: אגף התקשוב וההגנה בסב"ר/חמ"ל ההגנה בסייבר (חמ"ל ההגנה CTRL+K)
      </h5>
    </>
  </div>
);

export default SecurityContact;
