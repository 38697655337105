import { IonItem, IonLabel } from "@ionic/react";
import React, { useEffect, useState, memo } from "react";
import "./PerformanceLeaders.css";
import LeadersList from "../../../../../components/Shared/Leaders/LeadersList";
import { useTranslation } from "react-i18next";
import {
  ILeadersDetails,
  ILeadersSlideProps,
  LeadersProps,
} from "../../../../../Interfaces/ILeaders";
import {
  loadLeadersDataFirstTime,
  checkedOrUnCheckedPerformed,
} from "../../../../../components/Shared/Leaders/functionsLeaders";
import Dropdown from "../../../../../components/Shared/Dropdown/Dropdown";
import PerformanceTemplate from "../../performanceTemplate";
const PerformanceLeaders: React.FC<LeadersProps> = (
  props: LeadersProps
): JSX.Element => {
  const [individuals, setIndividuals] = useState<ILeadersDetails[]>([]);
  const [units, setUnits] = useState<ILeadersDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const {
    checkedForces,
    setChecked,
    selectedPlan,
    allPlansOptions,
    onPlanSelectedHandler,
    isPersonalZone,
    isDrawerOpen,
    setDrawerOpen,
  } = props;

  const leadersSlidesProps: ILeadersSlideProps[] = [
    {
      setstate: setUnits,
      state: units,
      title: t("unitsTitle"),
    },
    {
      setstate: setIndividuals,
      state: individuals,
      title: t("individualsTitle"),
    },
  ];

  useEffect(() => {
    setLoading(true);
    loadLeadersDataFirstTime(
      checkedForces,
      setUnits,
      setIndividuals,
      setLoading,
      isPersonalZone,
      selectedPlan,
      t
    );
  }, []);
  useEffect(() => {
    setLoading(true);
    setIndividuals([]);
    setUnits([]);
    loadLeadersDataFirstTime(
      checkedForces,
      setUnits,
      setIndividuals,
      setLoading,
      isPersonalZone,
      selectedPlan,
      t
    );
  }, [selectedPlan]);
  useEffect(() => {
    checkedOrUnCheckedPerformed(
      units,
      individuals,
      setLoading,
      setUnits,
      setIndividuals,
      checkedForces,
      isPersonalZone,
      selectedPlan,
      t
    );
  }, [checkedForces]);
  const chart = (
    <div className="rowLeadersData scrolL">
      {leadersSlidesProps?.map((slide, index) => (
        <div key={index} className="leadersColLD">
          <IonItem
            key={index}
            lines="none"
            mode="md"
            className="leadersTitleLD"
          >
            <IonLabel className="titleLabel">{slide.title}</IonLabel>
          </IonItem>
          <LeadersList leaders={slide.state} />
        </div>
      ))}
    </div>
  );
  return (
    <PerformanceTemplate
      key={"leaders"}
      loadingText={t("loadingData")}
      chart={chart}
      isDrawerOpen={isDrawerOpen}
      loading={loading}
      orbatForces={checkedForces}
      selectedPlan={selectedPlan}
      plansOptions={allPlansOptions}
      onPlanSelectedHandler={onPlanSelectedHandler}
      setChecked={setChecked}
      setDrawerOpen={setDrawerOpen}
      title={t("leaders")}
    ></PerformanceTemplate>
  );
};

export default memo(PerformanceLeaders);
