import React from "react";
import ISimpleTrainee from "../../Interfaces/ISimpleTrainee";

interface Context {
  soldiersNamesAndIds: ISimpleTrainee[];
  setSoldiersNamesAndIds: React.Dispatch<
    React.SetStateAction<ISimpleTrainee[]>
  >;
}

const ForcesListContext = React.createContext<Context>({} as Context);

export default ForcesListContext;
