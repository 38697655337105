import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import "./PerformanceDetails.css";
import BarChart from "../../../../../components/Shared/Charts/Bar/BarChart";
import IPlan from "../../../../../Interfaces/IPlan";
import { MAX_SELECTED } from "../../../../../Configurations/consts";
import IForceTreeNode from "../../../../../Interfaces/IForceTreeNode";
import IFinalGradeDisplay from "../../../../../Interfaces/IFinalGradeDisplay";
import IForceFromOrbat from "../../../../../Interfaces/IForceFromOrbat";
import IStationsChart from "../../../../../Interfaces/IStationsChart";
import { useTranslation } from "react-i18next";
import ILabel from "../../../../../Interfaces/ILabel";
import IThreshold from "../../../../../Interfaces/IThreshold";
import domtoimage from "dom-to-image-improved";
import { Option } from "react-dropdown";
import { useHistory } from "react-router-dom";
import EExportType from "../../../../../Interfaces/EExportType";
import HashtagsModal from "../../../../../components/Desktop/Hashtags/HashtagsModal";
import { useWebsocketTagging } from "../../../../../services/siteManagementSocket";
import { UserContext } from "../../../../../context/UserContext/userContext";
import PerformanceTemplate from "../../performanceTemplate";
interface IProps {
  orbatForces: IForceTreeNode[];
  checkedForces: IForceFromOrbat[];
  setChecked: (forces: IForceTreeNode[]) => void;
  exportToCsv: (imageUrl: string, exportType: number) => void;
  fromTab: boolean;
  isAdmin?: boolean | true;
  exportHandler: () => void;
  downloadLoading: boolean;
  thresholds: IThreshold[];
  labels: ILabel[];
  plan: IPlan;
  selectedPlan: IPlan;
  trainingTypesGrades: IStationsChart[];
  finalGrades: IFinalGradeDisplay[];
  newForces: IForceFromOrbat[];
  forcesToRemove: IForceFromOrbat[];
  setDetailsPlanId: Dispatch<SetStateAction<number | undefined>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsToRefresh: (state: boolean) => void;
  setForceTaggingRemoved: (forceId: number | undefined) => void;
  allPlansOptions: Option[];
  onPlanSelectedHandler: (option: Option) => void;
  isDrawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PerformanceDetails: FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const history: any = useHistory();
  const {
    orbatForces,
    setChecked,
    fromTab,
    downloadLoading,
    thresholds,
    labels,
    plan,
    selectedPlan,
    trainingTypesGrades,
    finalGrades,
    newForces,
    forcesToRemove,
    checkedForces,
    setDetailsPlanId,
    setLoading,
    setIsToRefresh,
    loading,
    setForceTaggingRemoved,
    allPlansOptions,
    onPlanSelectedHandler,
    isDrawerOpen,
    setDrawerOpen,
  } = props;

  const [myNewForces, setMyNewForces] = useState<IForceFromOrbat[]>();

  useEffect(() => {
    setMyNewForces((prev) => {
      if (!prev) return checkedForces;
      return newForces;
    });
  }, [newForces]);

  const [hashtagsModalData, setHashtagsModalData] = useState<{
    isOpen: boolean;
    plan: IPlan | undefined;
    forceId: number;
    trainingTypeId: number;
    forceName: string;
    trainingTypeName: string;
  }>({
    isOpen: false,
    plan: undefined,
    forceId: -1,
    trainingTypeId: -1,
    forceName: "",
    trainingTypeName: "",
  });

  const [taggedSoldiers, setTaggedSoldiers] = useState<IForceTreeNode[]>();
  const [tagging, setTagging] = useState<IForceTreeNode | undefined>();

  const [isRemove, setIsRemove] = useState<boolean>(false);
  const taggingMessage: IForceTreeNode | undefined = useWebsocketTagging();
  const { isTaggingActive } = useContext(UserContext);

  useEffect(() => {
    if (tagging && isTaggingActive) {
      setTaggedSoldiers((prev: IForceTreeNode[] | undefined) => {
        if (
          !prev?.find((soldier: IForceTreeNode) => soldier.id == tagging.id)
        ) {
          if (prev) {
            if (prev.length >= MAX_SELECTED) {
              prev.shift();
            }
            return [...prev, { ...tagging, id: +tagging.id }];
          } else {
            return [
              {
                ...tagging,
                id: +tagging.id,
              },
            ];
          }
        } else {
          return prev;
        }
      });
    }
  }, [tagging]);

  useEffect(() => {
    if (taggedSoldiers && !isRemove) {
      let temp: IForceTreeNode[] = taggedSoldiers?.filter(
        (force: IForceTreeNode) =>
          !orbatForces?.find((OForce) => OForce.id == force.id)
      );
      let forcesToCheck = orbatForces ? [...orbatForces, ...temp] : temp;
      setChecked(limitToMaxPremittedSelectedForces(forcesToCheck));
    } else if (taggedSoldiers && isRemove) {
      setIsRemove(false);
    }
  }, [taggedSoldiers, isRemove]);

  useEffect(() => {
    if (taggingMessage) {
      setTagging(taggingMessage);
    }
  }, [taggingMessage]);

  useEffect(() => {
    if (plan && isTaggingActive) {
      if (
        forcesToRemove &&
        forcesToRemove[0] &&
        taggedSoldiers?.find(
          (soldier: IForceTreeNode) => soldier.id == forcesToRemove![0].id
        )
      ) {
        setIsRemove(true);
        setTaggedSoldiers((prev: IForceTreeNode[] | undefined) =>
          prev?.filter(
            (prev: IForceTreeNode) => prev.id != forcesToRemove[0].id
          )
        );
      }
    }
  }, [forcesToRemove]);

  useEffect(() => {
    if (history.location.state) {
      let planId = history.location.state?.data;
      if (history.location.state.taggingMessage) {
        setTagging(history.location.state.taggingMessage);
      }
      if (history.location.state.taggingMessage === undefined) {
        if (
          Number(selectedPlan?.id) !== Number(planId) &&
          Number(plan?.id) !== Number(planId)
        ) {
          setDetailsPlanId((prev) => {
            return planId;
          });

          setMyNewForces(checkedForces);
        }
      }
    }
  }, [history.location.state]);

  useEffect(() => {
    if (fromTab && !history.location.state) {
      setDetailsPlanId(undefined);
      setMyNewForces(checkedForces);
    }
  }, [fromTab]);

  const limitToMaxPremittedSelectedForces = (
    selectedForces: IForceTreeNode[]
  ) => {
    if (selectedForces.length > MAX_SELECTED) {
      setForceTaggingRemoved(selectedForces.shift()?.id);
    }
    return selectedForces;
  };

  const getImageAndSendToCsv = async () => {
    let imageUrl = await domtoimage.toJpeg(
      document.getElementById("csvBarImage")
    );
    props.exportToCsv(imageUrl, EExportType.details);
  };

  const onBarClick = (
    plan: IPlan | undefined,
    forceId: number,
    trainingTypeId: number,
    forceName: string,
    trainingTypeName: string
  ) => {
    setHashtagsModalData({
      ...hashtagsModalData,
      isOpen: true,
      plan: plan ? plan : selectedPlan,
      forceId: forceId,
      trainingTypeId: trainingTypeId,
      forceName: forceName,
      trainingTypeName: trainingTypeName,
    });
  };

  return (
    <PerformanceTemplate
      loadingText={
        loading ? t("loadingData") : downloadLoading ? t("downloadFile") : ""
      }
      chart={
        <div className="barD">
          <div className="grapsWrap">
            <HashtagsModal
              isOpen={hashtagsModalData.isOpen}
              plan={hashtagsModalData.plan}
              forceId={hashtagsModalData.forceId}
              trainingTypeId={hashtagsModalData.trainingTypeId}
              forceName={hashtagsModalData.forceName}
              trainingTypeName={hashtagsModalData.trainingTypeName}
              onModalClose={() =>
                setHashtagsModalData({
                  ...hashtagsModalData,
                  isOpen: false,
                  plan: undefined,
                })
              }
            ></HashtagsModal>
            <BarChart
              id="csvBarImage"
              barChartData={trainingTypesGrades}
              thresholds={thresholds}
              labels={labels}
              selectedForces={checkedForces}
              newForces={myNewForces}
              forcesToRemove={forcesToRemove}
              barsType={"trainingTypes"}
              plan={plan}
              isDesktop
              onBarClick={onBarClick}
            />
          </div>
        </div>
      }
      finalGrades={finalGrades}
      secondButtonText={t("refresh")}
      firstButtonText={t("export")}
      onClickSecondButton={() => setIsToRefresh(true)}
      onClickFirstButton={() => getImageAndSendToCsv()}
      isDrawerOpen={isDrawerOpen}
      loading={loading || downloadLoading}
      orbatForces={orbatForces}
      selectedPlan={plan}
      plansOptions={allPlansOptions}
      onPlanSelectedHandler={onPlanSelectedHandler}
      setChecked={setChecked}
      setDrawerOpen={setDrawerOpen}
      title={t("details")}
    ></PerformanceTemplate>
  );
};

export default PerformanceDetails;
