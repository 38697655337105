import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DropdownEditor from "../DropdownEditor";
import DrillsDataTable from "../DrillsDataTable";
import {
  getWeapons,
  handleSave,
  sendDrillToBBackend,
  XDefaultColumn,
} from "../dataPostingHelpers";
import {
  ComparisonFormatter,
  PositiveNumberFormatter,
  PositiveNumberNtoMFormatter,
} from "../InputFormatters";
import SoldiersListEditor from "../SoldiersListEditor";
import { AxiosResponse } from "axios";
import IShootingDay from "../../../../Interfaces/IShootingDay";
import {
  getAllHeats,
  getAllShootingDays,
} from "../../../../apis/ShootingRangeAPI";
import IDropdownOption from "../../../../Interfaces/IDropdownOption";
import IShootingHeat from "../../../../Interfaces/IShootingHeat";
import { Option } from "react-dropdown";
import IForceTreeNode from "../../../../Interfaces/IForceTreeNode";
import { IonGrid, IonPage, IonRow } from "@ionic/react";
import { getChildrenSoldiers } from "../../../../services/helpers";
import IShootingRangeForce from "../../../../Interfaces/IShootingRangeForce";
import {
  MAX_RESULT,
  MIN_RESULT,
} from "../../../../Configurations/shootingRangeConsts";
import IShootingHeatType from "../../../../Interfaces/IShootingHeatType";
import { getFinalResultsByTypes } from "../../../../services/shootingRangeFunctions";
import EEventType from "../../../../Interfaces/EEventType";
import { getActivePlan } from "../../../../apis/PlansAPI";
import IPlan from "../../../../Interfaces/IPlan";
import { errorClassName } from "../../../Shared/InputWithError/InputWithError";
import ShootingRangeInputs from "./ShootingRangeInputs";
import IShootingRangeRow from "../../../../Interfaces/IShootingRangeRow";
import IShootingRangeColumn from "../../../../Interfaces/IShootingRangeColumn";
import NotExistLabel from "../../../Shared/NotExistLabel/NotExistLabel";
import EPicturesSrc from "../../../../Interfaces/EPicturesSrc";
import IDataPosting from "../../../../Interfaces/IDataPosting";
import DesktopManualInputTemplate from "../DesktopManualInputTemplate";
import Alert from "../../../Shared/Alert/Alert";

import "./LiveShooting.css";
import { useWeapons } from "../../../CustomHooks/HooksWithRQ/useWheapons";

const TRAINING_TYPE_ID: number = 11;

interface IShootingRangeMessage {
  MessageType: number;
  TimeStamp: Date;
  SoldierID: number | string;
  TrainingTypeID: number;
  WeaponType: string | null;
  NumOfShots: string;
  RedHits: number | undefined;
  Grouping: number | undefined;
  Score: number | undefined;
  PlanId: number | undefined;
  DrillStartTime: Date;
  Hashtags: string[];
}

interface IDefaultRow {
  soldierName: string;
  soldierId: string;
  weaponType: string;
  numberOfShots: string;
  result: string;
}

const LiveShooting: React.FC<IDataPosting> = (
  props: IDataPosting
): JSX.Element => {
  const { t } = useTranslation();

  const defaultRows: IDefaultRow = {
    soldierName: "",
    soldierId: "",
    weaponType: "",
    numberOfShots: "",
    result: "",
  };

  const initialColumns: IShootingRangeColumn[] = [
    {
      ...XDefaultColumn,
      key: "soldierName",
      editable: false,
      name: t("soldierName"),
      editor: SoldiersListEditor,
      cellClass: "shooting-range-cell",
    },
    {
      ...XDefaultColumn,
      key: "soldierId",
      editable: false,
      name: t("militaryId"),
      formatter: PositiveNumberFormatter,
      cellClass: "shooting-range-cell",
    },
    {
      ...XDefaultColumn,
      key: "weaponType",
      editable: false,
      name: t("weaponType"),
      editor: DropdownEditor,
      cellClass: "shooting-range-cell",
    },
    {
      ...XDefaultColumn,
      key: "numberOfShots",
      editable: false,
      name: t("numberOfShots"),
      formatter: PositiveNumberFormatter,
      cellClass: "shooting-range-cell",
    },
    {
      ...XDefaultColumn,
      key: "result",
      editable: true,
      name: null,
      formatter: null,
      cellClass: "shooting-range-cell",
    },
  ];

  const [isTreeOpened, setIsTreeOpened] = useState<boolean>(false);
  const [forcePath, setForcePath] = useState<string>();
  const [shootingDays, setShootingDays] = useState<IShootingDay[]>();
  const [selectedShootingDay, setSelectedShootingDay] =
    useState<IShootingDay>();
  const [shootingDayOptions, setShootingDayOptions] = useState<
    IDropdownOption[]
  >([]);
  const [heats, setHeats] = useState<IShootingHeat[]>();
  const [selectedHeat, setSelectedHeat] = useState<IShootingHeat>();
  const [heatOptions, setHeatOptions] = useState<IDropdownOption[]>([]);
  const [selectedForces, setSelectedForces] = useState<IForceTreeNode[]>([]);
  const [trainees, setTrainees] = useState<IShootingRangeForce[]>();
  const [rows, setRows] = useState<IShootingRangeRow[]>([]);
  const [rowsToSave, setRowsToSave] = useState<IShootingRangeRow[]>([]);
  const [columns, setColumns] =
    useState<IShootingRangeColumn[]>(initialColumns);
  const [isSuccess, setIsSuccess] = useState<number>(0);
  const [activePlanId, setActivePlanId] = useState<number | undefined>();
  const [hashtags, setHashtags] = useState<string>("");
  const [unsavedResults, setUnsavedResults] = useState<boolean>();
  const [isValid, setIsValid] = useState<boolean>(true);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [isLastHeat, setIsLastHeat] = useState<boolean>(false);
  const filterRef = useRef<HTMLDivElement>(null);

  //custom Hooks
  const weaponsQuery = useWeapons();
  const weapons = weaponsQuery?.data ?? [];

  useEffect(() => {
    setUnsavedResults(rowsToSave.length !== 0 && isValid);
  }, [rowsToSave, isValid]);

  useEffect(() => {
    let mounted: boolean = true;

    const getCurrentActivePlan = async () => {
      getActivePlan().then(
        (response: AxiosResponse<IPlan | undefined>) =>
          mounted && setActivePlanId(response.data?.id)
      );
    };

    const getShootingDays = async () => {
      await getAllShootingDays().then(
        (response: AxiosResponse<IShootingDay[] | undefined>) =>
          mounted && setShootingDays(response.data)
      );
    };

    const getHeats = async () => {
      await getAllHeats().then(
        (response: AxiosResponse<IShootingHeat[] | undefined>) =>
          mounted && setHeats(response.data)
      );
    };
    const initializeData = () => {
      getCurrentActivePlan();
      getShootingDays();
      getHeats();
      // getWeapons(true, setWeapons);
    };

    initializeData();
    document.addEventListener("mousedown", handleViewClick);
    return () => {
      document.removeEventListener("mousedown", handleViewClick);
      mounted = false;
    };
  }, []);

  useEffect(() => {
    setIsTreeOpened(false);
    if (selectedForces[0]) {
      setForcePath(selectedForces[0].name);
      setTrainees(getChildrenSoldiers(selectedForces[0], []));
    } else {
      setForcePath(undefined);
    }
  }, [selectedForces]);

  useEffect(() => {
    shootingDays &&
      setShootingDayOptions(() => [
        ...shootingDays
          .sort((day1: IShootingDay, day2: IShootingDay) => day1.id - day2.id)
          .map((shootingDay: IShootingDay) => ({
            label: t(shootingDay.name),
            value: String(shootingDay.id),
          })),
      ]);
  }, [shootingDays]);

  useEffect(() => {
    heats &&
      selectedShootingDay &&
      setHeatOptions(() => [...getHeatsBySelectedShootingDay(heats)]);
  }, [heats, selectedShootingDay]);

  useEffect(() => {
    if (heats && selectedHeat) {
      getHashtags();
      setColumns((prev: IShootingRangeColumn[]) =>
        getResultColumnFormat(prev, selectedHeat.type)
      );
      setIsLastHeat(lastIndex(heats, selectedHeat.id) === heats.length);
    }
  }, [selectedHeat]);

  useEffect(() => {
    if (isLastHeat) {
      setOpenAlert(true);
    }
  }, [isLastHeat]);

  useEffect(() => {
    if (trainees && selectedShootingDay && selectedHeat) {
      initializeRowsWithTraineesData();
    }
  }, [trainees, selectedShootingDay, selectedHeat]);

  useEffect(() => {
    if (rows.length) {
      checkValidation();
    }
  }, [rows]);

  const onUpdateData = async (row: IShootingRangeRow) => {
    const time: Date = new Date();
    let types: IShootingHeatType = getFinalResultsByTypes(
      row.result,
      selectedHeat!.type
    );

    const shootingRangeDrill: IShootingRangeMessage = {
      MessageType: EEventType.SHOOTING_RANGE_MESSAGE_TYPE,
      TimeStamp: time,
      SoldierID: row.soldierId,
      TrainingTypeID: TRAINING_TYPE_ID,
      WeaponType: row.weaponType,
      NumOfShots: row.numberOfShots,
      RedHits: types.numberOfHits,
      Grouping: types.grouping,
      Score: types.score,
      PlanId: activePlanId,
      DrillStartTime: time,
      Hashtags: hashtags.split(" "),
    };

    sendDrillToBBackend(
      shootingRangeDrill,
      () => {
        setIsSuccess(200);
      },
      () => {
        setIsSuccess(500);
      }
    );
    setRowsToSave([]);
    setIsSuccess(0);
  };

  const checkValidation = () =>
    setIsValid(() => !document.getElementsByClassName(errorClassName).length);

  const initializeRows = () => {
    let tempRows: IShootingRangeRow[] = [];
    for (let index = 0; index < trainees!.length; index++) {
      tempRows[index] = {
        ...defaultRows,
        weapons: weapons,
        soldierId: trainees![index].id,
        soldierName: trainees![index].name,
        weaponType: trainees![index].weaponType,
        numberOfShots: selectedHeat!.bullets,
      };
    }
    return tempRows;
  };

  const initializeRowsWithTraineesData = () => {
    setRows(initializeRows());
  };

  const getHashtags = () => {
    setHashtags(
      `${selectedHeat?.name.replaceAll(
        " ",
        "_"
      )} ${selectedShootingDay?.name.replaceAll(" ", "_")}`
    );
  };

  const getHeatsBySelectedShootingDay = (
    heats: IShootingHeat[]
  ): IDropdownOption[] => {
    return heats
      .sort((heat1: IShootingHeat, heat2: IShootingHeat) => heat1.id - heat2.id)
      .filter(
        (heat: IShootingHeat) =>
          +heat.shootingDayId === +selectedShootingDay!.id
      )
      .map((heat: IShootingHeat) => ({
        label: t(heat.name),
        value: String(heat.id),
      }));
  };

  const onChangeShootingDay = (currentItem: Option) => {
    setSelectedShootingDay(() => ({
      id: +currentItem.value,
      name: String(currentItem.label),
    }));
    setSelectedHeat(undefined);
  };

  const onChangeHeat = (currentValue: number) => {
    let currentHeat: IShootingHeat | undefined = heats?.find(
      (heat: IShootingHeat) => heat.id === currentValue
    );
    setSelectedHeat(currentHeat);
  };

  const handleViewClick = (event: MouseEvent) => {
    if (!filterRef.current?.contains(event.target as HTMLElement)) {
      setIsTreeOpened(false);
    }
  };

  const getResultColumnFormat = (
    currentColumns: IShootingRangeColumn[],
    heatType: string
  ): IShootingRangeColumn[] =>
    currentColumns.map((column: IShootingRangeColumn) => {
      if (column.key === "result") {
        return {
          ...column,
          name: `${t("achievementRequired")} - ${t(heatType)}`,
          formatter:
            heatType === "numberOfHits"
              ? (event: any) => (
                  <ComparisonFormatter
                    comparisonKey="numberOfShots"
                    event={event}
                  />
                )
              : heatType === "grouping"
              ? PositiveNumberFormatter
              : (event: any) => (
                  <PositiveNumberNtoMFormatter
                    minToMax={{ max: MAX_RESULT, min: MIN_RESULT }}
                    event={event}
                  />
                ),
        };
      } else {
        return column;
      }
    });

  const goToNextHeat = () => {
    if (isLastHeat) {
      setOpenAlert(true);
    } else {
      const heatsInCurrentDay = heats?.filter(
        (heat: IShootingHeat) =>
          +heat.shootingDayId === +selectedShootingDay!.id
      );

      const nextHeatIndex: number = lastIndex(
        heatsInCurrentDay!,
        selectedHeat!.id
      );

      if (nextHeatIndex !== heatsInCurrentDay?.length) {
        setSelectedHeat(heatsInCurrentDay![nextHeatIndex]);
        setRowsToSave([]);
      } else {
        setIsLastHeat(true);
      }
    }
  };

  const lastIndex = (heatsList: IShootingHeat[], currentId: number): number =>
    heatsList.findIndex((heat: IShootingHeat) => heat.id === currentId) + 1;

  const onSaveResults = () => {
    handleSave(rowsToSave, onUpdateData, t);
  };
  const handleSaveAndGoToNextHeat = () => {
    onSaveResults();
    goToNextHeat();
  };
  return (
    <DesktopManualInputTemplate
      selectedPlan={props.selectedPlan}
      plansOptions={props.plansOptions}
      onPlanSelectedHandler={props.onPlanSelectedHandler}
      pageName={"liveShooting"}
      headerChildren={
        <ShootingRangeInputs
          filterRef={filterRef}
          forcePath={forcePath}
          heatOptions={heatOptions}
          shootingDayOptions={shootingDayOptions}
          isTreeOpened={isTreeOpened}
          onChangeHeat={onChangeHeat}
          onChangeShootingDay={onChangeShootingDay}
          selectedForces={selectedForces}
          selectedHeat={selectedHeat}
          selectedShootingDay={selectedShootingDay}
          setIsTreeOpened={setIsTreeOpened}
          setSelectedForces={setSelectedForces}
        />
      }
      isToHideButtons={
        !(selectedForces[0] && selectedShootingDay && selectedHeat)
      }
      onClickThirdButton={handleSaveAndGoToNextHeat}
      onClickSecondButton={goToNextHeat}
      onClickFirstButton={onSaveResults}
      thirdButtonText={t("saveResultsAndPassToNextHeat")}
      secondButtonText={t("passToNextHeat")}
      firstButtonText={t("saveResults")}
      isDisabledFirstButton={!unsavedResults}
      isDisabledThirdButton={!unsavedResults}
    >
      {selectedForces[0] && selectedShootingDay && selectedHeat ? (
        <DrillsDataTable
          columns={columns}
          isSuccess={isSuccess}
          rows={rows}
          setRows={setRows}
          isDataPosting
          setRowsToSave={setRowsToSave}
        />
      ) : (
        <div className="not-selected-data">
          <NotExistLabel text="noSelectedData" className="not-selected" />
          <img
            className="shooting-range-image"
            src={EPicturesSrc.SHOOTING_RANGE}
          />
        </div>
      )}
      <Alert
        isOpen={openAlert}
        setIsOpen={setOpenAlert}
        header={isLastHeat ? `${t("reachedLastHeat")}` : ""}
        actionOnSave={() => setOpenAlert(false)}
        actionText={"OK"}
      />
    </DesktopManualInputTemplate>
  );
};

export default LiveShooting;
