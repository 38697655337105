import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./UIFComponent.css";
import UIFElement from "./UIFElement";
import { IonButton, IonCol, IonLabel, IonRow, IonTitle } from "@ionic/react";
import PMLabel from "../../../themeComponents/PMLabel";

import UFIComponentHook from "../../../CustomHooks/UFIComponentHook";
import { UIFElementsData } from "../../../../Interfaces/IUrbanInstructorFeedback";
interface IProps {
  traineeId: number | undefined;
  isDesktop?: boolean;
  isSendClicked?: boolean;
  setIsComplete: (state: boolean) => void;
  planId: number | undefined;
}

const UIFComponent: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { traineeId, isDesktop, isSendClicked, setIsComplete } = props;
  const { t } = useTranslation();
  const {
    data,
    isTraineeChanged,
    setUIFGrades,
    postData,
    setIsTraineeChanged,
    isComplete,
  } = UFIComponentHook(t, traineeId, props.planId, isDesktop);
  useEffect(() => {
    if (isSendClicked) postData();
  }, [isSendClicked]);
  useEffect(() => {
    setIsComplete(isComplete);
  }, [isComplete]);
  return (
    <React.Fragment>
      <IonRow className="titleRow">
        <IonCol size="2">
          <IonLabel></IonLabel>
        </IonCol>
        <IonCol size="7">
          <IonLabel></IonLabel>
        </IonCol>
        <IonCol size="1.5">
          <IonLabel></IonLabel>
        </IonCol>
        <IonCol className="colL">
          <PMLabel
            textAlign="center"
            fontColor="xLight"
            fontFamily="Regular"
            fontSize="medium"
          >
            {t("avgGrade")}
          </PMLabel>
        </IonCol>
      </IonRow>
      <div className="UFIWrap">
        <div className="rowUIFC">
          {data.map((elementData: UIFElementsData, index) => (
            <UIFElement
              key={elementData.dataType}
              dataType={elementData.dataType}
              elements={elementData.elements}
              title={elementData.title}
              setGrade={setUIFGrades}
              resetAll={isTraineeChanged}
              setResetAll={setIsTraineeChanged}
              index={index}
            ></UIFElement>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default UIFComponent;
