import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IonCol, IonRow } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import IIndicator from "../../../Interfaces/IIndicator";
import IIndicatorType from "../../../Interfaces/IIndicatorType";
import { fetchElementsByPlanId } from "../../../redux/actions/elementsActions";
import { useTranslation } from "react-i18next";
import {
  EDIT_INDICATOR,
  fetchIndicatorsByPlanId,
  SET_INDICATORS,
} from "../../../redux/actions/indicatorsActions";
import { fetchStationsByPlanId } from "../../../redux/actions/stationsActions";
import { IIndicatorReducer } from "../../../redux/reducers/indicator";
import { IPlansReducer } from "../../../redux/reducers/plans";
import { AppState } from "../../../redux/store/plansStore";
import Indicator from "./Indicator";
import "./Indicators.css";
import IUnsavedPlan from "../../../Interfaces/IUnsavedPlan";
import { compareToInitialArrayOfObjects } from "../../../services/helpers";
import IElement from "../../../Interfaces/IElement";

type IProps = {
  setIsUnsavedPlan: Dispatch<SetStateAction<IUnsavedPlan | undefined>>;
  currentElements: IElement[];
  setCurrentElements: Dispatch<SetStateAction<IElement[]>>;
};

const Indicators: React.FC<IProps> = (props: IProps) => {
  const { setIsUnsavedPlan, currentElements, setCurrentElements } = props;
  const { t } = useTranslation();
  const [typeOptions, setTypeOptions] = useState<IIndicatorType[]>([]);
  const [initialIndicators, setInitialIndicators] = useState<IIndicator[]>([]);
  const [planId, setPlanId] = useState<number>(-1);
  const [localIndicators, setLocalIndicators] = useState<IIndicator[]>([]);
  const [mounted, setMounted] = useState<boolean>(true);
  const dispatch = useDispatch<Dispatch<any>>();

  // Selects reducers
  const indicatorsState = useSelector<AppState, IIndicatorReducer>(
    (state) => state.indicators
  );
  const stationsLoading = useSelector<AppState, IIndicatorReducer>(
    (state) => state.stations
  ).loading;
  const elementsLoading = useSelector<AppState, IIndicatorReducer>(
    (state) => state.elements
  ).loading;

  const selectedPlan = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).selectedPlan;
  const editMode = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).editMode;
  const savePlan = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).savePlan;

  // To prevent setState when component is unmounted
  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    if (editMode) {
      setIsUnsavedPlan((prev: IUnsavedPlan | undefined) => ({
        ...prev!,
        indicators: {
          ...prev?.indicators!,
          isIndicatorsUnsaved: compareToInitialArrayOfObjects(
            initialIndicators,
            localIndicators
          ),
        },
      }));
    }
  }, [localIndicators]);

  // Fetches all indicators/stations/elements
  useEffect(() => {
    if (selectedPlan && selectedPlan?.id !== planId) {
      setPlanId(selectedPlan!.id);

      dispatch(fetchIndicatorsByPlanId(selectedPlan?.id!, t));

      dispatch(fetchStationsByPlanId(selectedPlan?.id!));

      dispatch(fetchElementsByPlanId(selectedPlan?.id!, setCurrentElements));
      setInitialIndicators([]);
    }
  }, [selectedPlan]);

  // Sets local Indicators on indicators redux state change
  useEffect(() => {
    if (mounted) setLocalIndicators(indicatorsState.indicators);
  }, [indicatorsState.indicators]);

  useEffect(() => {
    let options: IIndicatorType[] = indicatorsState.indicatorsTypes.filter(
      (type) =>
        !indicatorsState.indicators.some(
          (indicator) => +type.id === +indicator.id
        )
    );
    setTypeOptions(options);
  }, [indicatorsState.indicators, indicatorsState.indicatorsTypes]);

  useEffect(() => {
    if (editMode && !indicatorsState.loading) {
      let initialIndiTemp: IIndicator[] = indicatorsState.indicators.filter(
        (indi) => typeof indi.weight !== "number"
      );

      if (initialIndiTemp.length > initialIndicators.length) {
        setInitialIndicators(initialIndiTemp);
      }
    }
  }, [indicatorsState.indicators, editMode]);

  useEffect(() => {
    if (
      !editMode &&
      savePlan <= 0 &&
      !indicatorsState.loading &&
      initialIndicators.length !== 0
    ) {
      dispatch({
        type: SET_INDICATORS,
        indicators: initialIndicators,
      });
      setLocalIndicators(initialIndicators);
    }
  }, [editMode]);

  // Updates indicators onBlur of child indicator
  const updateIndicator = (indicator: IIndicator | undefined) => {
    setLocalIndicators((prev: IIndicator[]) =>
      prev.map((prevIndicator: IIndicator) =>
        prevIndicator.id === indicator?.id ? indicator : prevIndicator
      )
    );
    dispatch({ type: EDIT_INDICATOR, indicator: indicator });
  };

  return indicatorsState.loading || stationsLoading || elementsLoading ? (
    <h1 className="loading">{t("loadingData")}</h1>
  ) : (
    <IonCol size="12" className="indicatorsGrid">
      {localIndicators &&
        localIndicators.map((indicator) => (
          <IonRow key={indicator.id} className="indicatorRow">
            <Indicator
              key={indicator.id}
              indicator={indicator}
              typeOptions={typeOptions}
              updateIndicator={updateIndicator}
              setIsUnsavedPlan={setIsUnsavedPlan}
              currentElements={currentElements}
            ></Indicator>
          </IonRow>
        ))}
    </IonCol>
  );
};

export default Indicators;
