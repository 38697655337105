import { IonPage, IonGrid, IonRow } from "@ionic/react";
import React, { useEffect } from "react";
import "./DesktopManualInputTemplate.css";
import PMButton from "../../themeComponents/PMButton";
import { useTranslation } from "react-i18next";
import { Option } from "react-dropdown";
import Dropdown from "../../Shared/Dropdown/Dropdown";
import PMLabel from "../../themeComponents/PMLabel";
import IPlan from "../../../Interfaces/IPlan";

interface IProps {
  onClickFirstButton?: () => void;
  onClickSecondButton?: () => void;
  onClickThirdButton: () => void;
  isSendDisabled?: boolean;
  children: React.ReactNode;
  headerChildren?: React.ReactNode;
  pageName: string;
  plansOptions: Option[];
  selectedPlan: IPlan | undefined;
  onPlanSelectedHandler: (planOption: Option) => void;
  firstButtonText?: string;
  secondButtonText?: string;
  thirdButtonText?: string;
  isDisabledFirstButton?: boolean;
  isDisabledSecondButton?: boolean;
  isDisabledThirdButton?: boolean;
  isToHideButtons?: boolean;
}
const DesktopManualInputTemplate: React.FC<IProps> = (
  props: IProps
): JSX.Element => {
  const {
    onClickFirstButton,
    onClickSecondButton,
    onClickThirdButton,
    children,
    isSendDisabled,
    plansOptions,
    onPlanSelectedHandler,
    pageName,
    selectedPlan,
    headerChildren,
  } = props;
  const { t } = useTranslation();

  return (
    <IonPage className="">
      <IonGrid className="compMD gridMD dataPostingPage">
        <IonRow className="dataPostingHeader">
          <PMLabel fontColor="light" fontFamily="Regular" fontSize="large">
            {t(pageName)}
          </PMLabel>
          <div className="rowDMTHeader">
            {headerChildren ? headerChildren : null}
            <Dropdown
              options={plansOptions}
              onChange={onPlanSelectedHandler}
              value={`${
                // If plan is not undefined
                selectedPlan && selectedPlan?.id !== undefined
                  ? // If plan is active - display active
                    `${selectedPlan.name} ${
                      selectedPlan.isActive ? `(${t("active")})` : ""
                    }`
                  : // If plan is undefined, just the placeholder
                    t("selectPlan")
              }`}
              placeholder={t("measurement")}
              controlClassName="Dropdown DWidth"
              arrowClassName="DropdownArrow"
              placeholderClassName="DropdownPlaceholderCompetency"
              menuClassName="DropdownMenuCompetency DropdownMenu"
              disabled={false}
            ></Dropdown>
          </div>
        </IonRow>
        <IonRow className="compMD mainViewMD scrollL">{children}</IonRow>
        {props.isToHideButtons ? null : (
          <IonRow className="compMD buttonsRowMD">
            <PMButton
              label={{
                fontColor: "light",
                fontSize: "medium",
                fontFamily: "Light",
              }}
              color="text-primary"
              fill="clear"
              size="fit"
              isHidden={onClickFirstButton === undefined}
              onClickHandler={onClickFirstButton}
              isDisabled={props.isDisabledFirstButton}
            >
              {props.firstButtonText ? props.firstButtonText : t("reset")}
            </PMButton>
            <PMButton
              label={{
                fontColor: "light",
                fontSize: "medium",
                fontFamily: "Light",
              }}
              color="text-primary"
              fill="clear"
              size="fit"
              isHidden={onClickSecondButton === undefined}
              onClickHandler={onClickSecondButton}
              isDisabled={props.isDisabledSecondButton}
            >
              {props.secondButtonText ? props.secondButtonText : t("refresh")}
            </PMButton>
            <PMButton
              label={{
                fontColor: "light",
                fontSize: "medium",
                fontFamily: "Light",
              }}
              color="tertiary"
              fill="solid"
              size="fit"
              onClickHandler={onClickThirdButton}
              isDisabled={isSendDisabled || props.isDisabledThirdButton}
            >
              {props.thirdButtonText ? props.thirdButtonText : t("send")}
            </PMButton>
          </IonRow>
        )}
      </IonGrid>
    </IonPage>
  );
};

export default DesktopManualInputTemplate;
