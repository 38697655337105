import { IonButton, IonInput, IonLabel, IonText } from "@ionic/react";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Collapse } from "react-collapse";
import { useTranslation } from "react-i18next";
import ISelectionOption from "../../../Interfaces/ISelectionOption";
import Checkbox from "../../Shared/Checkbox/Checkbox";
import "./MultiSelectDropdown.css";

interface IProps {
  setChecked?: Dispatch<SetStateAction<ISelectionOption[]>>;
  setCheckedHandler?: (
    checkedData?: { id: string; labelName: string },
    checked?: boolean,
    isSelect?: boolean
  ) => void;
  // selectOrClearAll?: (isSelect: boolean) => void;
  selectionPotions: ISelectionOption[];
  title: string;
  placeHolder?: string;
  isForModal?: boolean;
}
const MultiSelectDropdown: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    setChecked,
    setCheckedHandler,
    // selectOrClearAll,
    selectionPotions,
    title,
    placeHolder,
    isForModal,
  } = props;
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);
  const filterRef = useRef<HTMLDivElement>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [selectAll, setSelectAll] = useState<boolean>(false);
  useEffect(() => {
    if (setCheckedHandler) setCheckedHandler(undefined, undefined, selectAll);
  }, [selectAll]);
  const toggleExpanded = () => {
    if (!expanded) {
      setExpanded(true);
    }
  };

  /**
   * checkbox changes
   * @param event
   * @returns
   */
  const handleChange = (event: any, option: ISelectionOption) => {
    if (setChecked)
      return setChecked((prev: ISelectionOption[]) => {
        let newArray: ISelectionOption[] = [];
        prev.map((option) => {
          if (Number(option.option.id) === Number(event.detail.value))
            newArray.push({
              option: option.option,
              isSelected: event.detail.checked,
            });
          else newArray.push(option);
        });
        return newArray;
      });
    else if (setCheckedHandler) {
      setCheckedHandler(
        {
          id: event.detail.value,
          labelName: option.option.label,
        },
        event.detail.checked
      );
    }
  };

  /**
   * define all view listeners in order to close the drop down
   */
  useEffect(() => {
    document.addEventListener("mousedown", handleViewClick);

    return () => {
      document.removeEventListener("mousedown", handleViewClick);
    };
  }, []);
  const handleViewClick = (event: MouseEvent) => {
    if (!filterRef.current?.contains(event.target as HTMLElement)) {
      setExpanded(false);
    }
  };

  return (
    <div className="DDW" ref={filterRef}>
      <div
        className="DDW"
        onClick={() => {
          setExpanded((prev) => !prev);
        }}
      >
        <div className={"dropDownWrap " + (isForModal ? "withoutInput" : "")}>
          <div className={"searchWrap "}>
            <IonText className="searchTitle">
              {title}
              {isForModal
                ? ` (${
                    selectionPotions.filter((option) => option.isSelected)
                      .length
                  })`
                : ""}
            </IonText>
            {isForModal ? null : (
              <IonInput
                className="inputSearch"
                type="text"
                value={searchText}
                placeholder={placeHolder}
                onIonChange={(e) =>
                  setSearchText(
                    e.detail.value && e.detail.value !== null
                      ? e.detail.value
                      : ""
                  )
                }
              ></IonInput>
            )}
          </div>
          <div
            className={`dropDown ${expanded ? "up-arrow" : "down-arrow"}`}
          ></div>
        </div>
      </div>
      <Collapse isOpened={expanded}>
        <div className="selections" hidden={!expanded}>
          {isForModal ? (
            <div className="selectionRow">
              <IonButton
                className="scaBtn"
                onClick={() => {
                  if (setCheckedHandler)
                    setCheckedHandler(undefined, undefined, true);
                }}
              >
                {t("selectAll")}
              </IonButton>
              <IonButton
                className="scaBtn"
                onClick={() => {
                  if (setCheckedHandler)
                    setCheckedHandler(undefined, undefined, false);
                }}
              >
                {t("clearAll")}
              </IonButton>
            </div>
          ) : null}
          {[
            ...selectionPotions.filter(
              (option) => option.isSelected && !isForModal
            ),
            ...selectionPotions.filter((option) =>
              (!option.isSelected || isForModal) && option.option.label2
                ? `${option.option.label} ${option.option.label2}`.includes(
                    searchText
                  )
                : (!option.isSelected || isForModal) &&
                  option.option.label.includes(searchText)
            ),
          ].map((option) => (
            <div className="selectionRow" key={option.option.id}>
              <Checkbox
                name={`${option.option.label}`}
                value={String(option.option.id)}
                isChecked={option.isSelected}
                onChange={(event) => {
                  handleChange(event, option);
                }}
              />
              <div className="searchLabels">
                <IonLabel className="selectorLabel">
                  {`${t(option.option.label)}`}
                </IonLabel>
                {option.option.label2 ? (
                  <IonLabel className="searchLabels2">
                    {`${t(option.option.label2)}`}
                  </IonLabel>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default MultiSelectDropdown;
