import { IonLabel } from "@ionic/react";
import Axios from "../../../Axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import IHashtag from "../../../Interfaces/IHashtag";
import IPlan from "../../../Interfaces/IPlan";
import PMLoading from "../../Shared/Alert/PMLoading";
import PolarArea from "../../Shared/Charts/PolarArea/PolarArea";
import HashtagsLabels from "./HashtagsLabels";
import "./HashtagsModal.css";
import DragModal from "../DragModal/DragModal";

interface hashtagsProps {
  isOpen: boolean;
  plan: IPlan | undefined;
  forceId: number;
  trainingTypeId: number;
  forceName: string;
  trainingTypeName: string;
  onModalClose: () => void;
}
const HashtagsModal = (props: hashtagsProps) => {
  const [hashtags, setHashtags] = useState<IHashtag[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { t } = useTranslation();

  const onModalClose = () => {
    setIsLoading(true);
    setHashtags([]);
    props.onModalClose();
  };

  const updateHashtag = (hashtag: IHashtag) => {
    setHashtags((prev) => [
      ...prev.map((currHashtag: IHashtag) => {
        if (currHashtag.name?.trim() === hashtag.name?.trim()) {
          return { ...currHashtag, isActive: !currHashtag.isActive };
        } else {
          return currHashtag;
        }
      }),
    ]);
  };

  const selectAllHashtags = () => {
    setHashtags((prev) =>
      prev.map((hashtag) => {
        return { ...hashtag, isActive: true };
      })
    );
  };

  const clearAllHashtags = () => {
    setHashtags((prev) =>
      prev.map((hashtag) => {
        return { ...hashtag, isActive: false };
      })
    );
  };

  useEffect(() => {
    if (props.plan) {
      let mounted: boolean = true;
      Axios.get(`${baseUrlPMBackend}performanceGrades/getHashtagsResults`, {
        params: {
          planId: props.plan.id,
          forceId: props.forceId,
          trainingTypeId: props.trainingTypeId,
        },
      }).then((res) => {
        if (mounted) {
          let hashtags: IHashtag[] = res.data;

          setHashtags(
            hashtags === null
              ? []
              : hashtags.map((hashtag) => {
                  return { ...hashtag, isActive: false };
                })
          );
          setIsLoading(false);
        }
      });
      return () => {
        mounted = false;
      };
    }
  }, [props.plan]);

  return (
    <DragModal
      cssClass="hashtagsModal"
      isOpen={props.isOpen}
      onDismiss={onModalClose}
    >
      <PMLoading
        isOpen={isLoading}
        message={t("loadingData")}
        spinner={"bubbles"}
      />
      <div className="hashtagsModalDiv">
        <div className="title">
          <IonLabel className="trainingTypeName">
            {props.trainingTypeName}
          </IonLabel>
          <IonLabel className="forceName">{props.forceName}</IonLabel>
        </div>
        <div className="polarAreaDiv">
          <PolarArea
            chartData={{
              datasets: hashtags.map((hashtag) => {
                return {
                  name: hashtag.name,
                  isActive: hashtag.isActive,
                  rounds: hashtag.rounds,
                  grade: Math.round(hashtag.grade),
                };
              }),
              labels: hashtags.map((hashtag) => {
                return { hashtag: hashtag.name, isActive: hashtag.isActive };
              }),
            }}
          ></PolarArea>
        </div>
        <div className="hashtagsLabelsDiv">
          <HashtagsLabels
            hashtags={hashtags}
            updateHashtag={(hashtag: IHashtag) => {
              updateHashtag(hashtag);
            }}
            isLoading={isLoading}
            selectAllHashtags={selectAllHashtags}
            clearHashtagsSelection={clearAllHashtags}
          ></HashtagsLabels>
        </div>
      </div>
    </DragModal>
  );
};

export default HashtagsModal;
