import { IonRow, IonItem, IonText, IonButton } from "@ionic/react";
import React, { useEffect, useState } from "react";
import MobileButton from "../../../Mobile/MobileHeader/MobileButton";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import EEventType from "../../../../Interfaces/EEventType";
import customToast from "../../Toast/CustomToast";

import "./TreadmillComponent.css";
import PMLabel from "../../../themeComponents/PMLabel";
import PMButton from "../../../themeComponents/PMButton";
import { sendDrillToBBackend } from "../../../Desktop/DataPosting/dataPostingHelpers";
interface IProps {
  traineeId: number | undefined;
  trainingTypeId: number;
  afterPostHandler?: () => void;
  isDesktop?: boolean;
  isSendClicked?: boolean;
  isComplete: boolean;
  setIsComplete: (state: boolean) => void;
  planId: number | undefined;
}
const TreadmillComponent: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    traineeId,
    trainingTypeId,
    afterPostHandler,
    isDesktop,
    isSendClicked,
    setIsComplete,
    isComplete,
  } = props;
  const { t } = useTranslation();
  const [overalltime, setOverallTime] = useState<string>("");
  const [isInProgress, setIsInProgress] = useState<boolean>(false);
  // const [isComplete, setIsComplete] = useState<boolean>(false);
  const startDateTime: Date = new Date();

  /**
   * check if the user insert the all required input
   * @param overalltime
   */
  const isCompleteInput = () => {
    let durationParams: string[] = getDurationParams(overalltime);
    let seconds = durationParams[1];
    let minutes = durationParams[0];

    let isFullSeconds: boolean = !(
      isNaN(parseInt(seconds?.split("")[1])) ||
      isNaN(parseInt(seconds?.split("")[0]))
    );
    let isFullMinutes: boolean = !(
      isNaN(parseInt(minutes?.split("")[1])) ||
      isNaN(parseInt(minutes?.split("")[0]))
    );

    let isLogic: boolean =
      parseInt(minutes) < 60 && parseInt(seconds) < 60 ? true : false;

    let duration = parseInt(seconds) * 60 * 60 + parseInt(minutes) * 60;
    setIsComplete(
      !isNaN(duration) && isFullSeconds && isFullMinutes && isLogic
    );
  };
  /**
   * return an array with the hour, minutes and secons as a string
   * @param overalltime
   */
  const getDurationParams = (overalltime: string) => {
    const overallTime: string[] = overalltime.split(":");
    const minutes = overallTime[0];
    const seconds = overallTime[1];
    return [minutes, seconds];
  };

  /**
   * returns the duration in seconds
   * @param overalltime
   */
  const getDurationInSeconds = (overalltime: string) => {
    let durationParams = getDurationParams(overalltime);
    return parseInt(durationParams[0]) * 60 + parseInt(durationParams[1]);
  };
  /**
   * post the data to b-backend
   */
  const postData = () => {
    if (traineeId === undefined) {
      customToast.error(t("noSelectedSoldier"));
      return;
    }
    setIsInProgress(true);
    const timeStamp: Date = new Date();
    const duration = getDurationInSeconds(overalltime);
    const tradeMillDrill = {
      MessageType: EEventType.TRADE_MILL_MESSAGE_TYPE,
      TimeStamp: timeStamp,
      TrainingTypeID: trainingTypeId,
      SoldierID: traineeId,
      DrillStartTime: startDateTime,
      DrillEndTime: timeStamp,
      Duration: duration,
      PlanId: props.planId,
    };
    const displayToast = true;

    sendDrillToBBackend(
      tradeMillDrill,
      afterPostHandler,
      () => {
        setIsInProgress(false);
      },
      t,
      displayToast
    );
  };

  useEffect(() => {
    isCompleteInput();
  }, [overalltime]);
  useEffect(() => {
    setOverallTime("");
  }, [traineeId]);
  useEffect(() => {
    if (isSendClicked) postData();
  }, [isSendClicked]);
  return (
    <React.Fragment>
      <div className="treadmillWrap">
        <IonRow className="rowMedical">
          <IonItem
            mode="md"
            class="manualInputContainerM itemM"
            lines="full"
            key={traineeId}
          >
            <PMLabel fontSize="medium" fontFamily="Light" fontColor="light">
              {t("overallTime")}
            </PMLabel>
            <div className="inputWrap">
              <InputMask
                className="overallInputM"
                type="text"
                size={11}
                alwaysShowMask
                mask="99:99"
                // maskPlaceholder="mm:ss"
                onChange={(e) => {
                  setOverallTime(e.target.value);
                }}
              />
            </div>
          </IonItem>
        </IonRow>
      </div>
      <IonRow
        className={(!isDesktop ? "mobileButton" : "") + " buttonContainerMT"}
      >
        {isDesktop ? null : (
          <div>
            <PMButton
              onClickHandler={postData}
              isDisabled={!isComplete || isInProgress}
              color="tertiary"
              size="small"
              label={{
                fontColor: "light",
                fontSize: "medium",
                fontFamily: "Regular",
              }}
            >
              {t("send")}
            </PMButton>
          </div>
        )}
      </IonRow>
    </React.Fragment>
  );
};

export default TreadmillComponent;
