import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import IPlan from "../../Interfaces/IPlan";
import { Option } from "react-dropdown";
import { AppState } from "../../redux/store/plansStore";
import { IPlansReducer } from "../../redux/reducers/plans";
import { useSelector } from "react-redux";

const usePlans = (
  relevantPlanIds?: number[],
  isMustHaveSelectedPlan: boolean = true
) => {
  const { t } = useTranslation();

  const plansState = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  );
  const plans = plansState.plans;
  useEffect(() => {
    let activePlan = plans.find((plan: IPlan) => plan.isActive);
    setActivePlan(activePlan ? activePlan : null);
  }, [plans]);
  const [selectedPlan, setSelectedPlan] = useState<IPlan | undefined>();
  const [plansOptions, setPlansOptions] = useState<Option[]>([]);
  const [globalPlans, setGlobalPlans] = useState<IPlan[]>([]);
  const [activePlan, setActivePlan] = useState<IPlan | null>(null);
  const onPlanSelectedHandler = (planOption: Option) => {
    setSelectedPlan(() => {
      let selectedPlan = plans.find(
        (plan: { id: number; name: string }) => +plan.id == +planOption.value
      );
      //if selected plan doesn't exist get the first option
      if (!selectedPlan && isMustHaveSelectedPlan)
        selectedPlan = plans.find(
          (plan: IPlan) => +plan.id === +plansOptions[0].value
        );

      return selectedPlan;
    });
  };

  useEffect(() => {
    /* when new plans arrived from db*/
    // set the plans options
    setPlansOptions(
      plans.map((plan: IPlan) => ({
        key: plan.id,
        label: `${plan.name} ${plan.isActive ? `(${t("active")})` : ""}`,
        value: String(plan.id),
      }))
    );
    //set the selected plan as the first option is it's must to have selected plan
    isMustHaveSelectedPlan &&
      setSelectedPlan(plans.find((plan: IPlan) => plan.isActive));
    //set the global plans
    setGlobalPlans(plans.filter((plan: IPlan) => plan.isGlobal));
  }, [plans]);

  useEffect(() => {
    /* when selected plan changed */
    //filter the plans options to be without the selected plan
    setPlansOptions(
      plans
        .filter(
          (plan: IPlan) =>
            plan.id !== selectedPlan?.id &&
            (!relevantPlanIds ||
              !relevantPlanIds.length ||
              relevantPlanIds?.includes(Number(plan.id)))
        )
        .map((plan: IPlan) => ({
          key: plan.id,
          label: `${plan.name} ${plan.isActive ? `(${t("active")})` : ""}`,
          value: String(plan.id),
        }))
    );
  }, [selectedPlan]);

  useEffect(() => {
    /**
     * when force relevant plans ids exist filter plan options by "relevantPlanIds"
     */
    let relevantPlans = plans;
    if (relevantPlanIds) {
      relevantPlans = plans.filter(
        (plan: IPlan) =>
          !relevantPlanIds.length || relevantPlanIds.includes(Number(plan.id))
      );
      relevantPlanIds.length &&
        isMustHaveSelectedPlan &&
        setSelectedPlan((prev) => {
          return !prev ? relevantPlans[0] : prev;
        });
    }

    setPlansOptions(
      relevantPlans
        .filter((plan) => Number(plan.id) !== Number(selectedPlan?.id))
        .map((plan: IPlan) => ({
          key: plan.id,
          label: `${plan.name} ${plan.isActive ? `(${t("active")})` : ""}`,
          value: String(plan.id),
        }))
    );
  }, [relevantPlanIds]);

  return {
    activePlan,
    plansOptions,
    selectedPlan,
    onPlanSelectedHandler,
    globalPlans,
    plans,
  };
};

export default usePlans;
