import { FC, useContext, useEffect, useState } from "react";
import {
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { useLocation } from "react-router-dom";
import { Redirect, Route } from "react-router";
import CommanderDashboard from "./PerformanceTabs/CommanderDashboard/CommanderDashboard";
import PerformanceQualifications from "./PerformanceTabs/PerformanceQualifications/PerformanceQualifications";
import PerformanceDetails from "./PerformanceTabs/PerformanceDetails/PerformanceDetails";
import StationView from "./PerformanceTabs/StationView/StationView";
import PerformanceLeaders from "./PerformanceTabs/PerformanceLeaders/PerformanceLeaders";
import TabItem from "../../../Interfaces/ITabItem";
import PerformanceTrends from "./PerformanceTabs/PerformanceTrends/PerformanceTrends";
import { useTranslation } from "react-i18next";
import IForceTreeNode from "../../../Interfaces/IForceTreeNode";
import { defaults } from "react-chartjs-2";
import { exportToPdfAndCsv } from "../../../components/Desktop/ToExcel/exportToPdfAndCsv";
import { UserContext } from "../../../context/UserContext/userContext";
import { getForceFullName } from "../../../services/helpers";
import IPlan from "../../../Interfaces/IPlan";
import ILabel from "../../../Interfaces/ILabel";
import { useQuery } from "react-query";
import IThreshold from "../../../Interfaces/IThreshold";
import useGetPerformanceGrades from "../../../components/CustomHooks/useGetPerformanceGrades";
import useForcesChecked from "../../../components/CustomHooks/useForcesChecked";
import useInitGraphsData from "../../../components/CustomHooks/useInitGraphsData";
import { IForceAllData } from "../../../Interfaces/IForceAllData";
import PMTitle from "../../../components/themeComponents/PMTitle";
import { userRoles } from "../../../services/routeRoles";
import { pageview } from "react-ga";
import { ITrainingPlanResult } from "../../../Interfaces/dataCalculator";
import usePlans from "../../../components/CustomHooks/usePlans";
import {
  fetchActivePlanData,
  getTrainingTypesDataForTrainingPlan,
} from "../../../apis/PlansAPI";
import "./PerformanceDisplay.css";

defaults.global.defaultFontFamily = "Regular";

const PerformanceDisplay: FC = (): JSX.Element => {
  const { isAdmin } = useContext(UserContext);
  const [orbatForces, setOrbatForces] = useState<IForceTreeNode[]>([]);
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false);
  const [loadingCompetency, setCompetencyLoading] = useState<boolean>(false);
  const [fromTab, setFromTab] = useState<boolean>(true);
  const [detailsPlan, setDetailsPlan] = useState<IPlan>();

  //details
  const [thresholdsDetails, setThresholdsDetails] = useState<IThreshold[]>(
    [] as IThreshold[]
  );
  const [labelsDetailsActivePlan, setLabelsDetailsActivePlan] = useState<
    ILabel[]
  >([] as ILabel[]);
  //details
  const [thresholdsDetailsActivePlan, setThresholdsDetailsActivePlan] =
    useState<IThreshold[]>([] as IThreshold[]);
  const [detailsPlanId, setDetailsPlanId] = useState<number>();

  const [labelsDetails, setLabelsDetails] = useState<ILabel[]>([] as ILabel[]);

  const { user, isTaggingActive } = useContext(UserContext);
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(() => {
    if (isTaggingActive) return false;
    else return true;
  });

  const [isToDisplayDashboard, setIsToDisplayDashboard] =
    useState<boolean>(true);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const location = useLocation();

  const { t, i18n } = useTranslation();

  document.body.dir = i18n.dir();

  const PerformanceTabMenu: TabItem[] = [
    {
      url: "/performance/commanderDashboard",
      title: t("commanderDashboard"),
    },
    {
      url: "/performance/qualifications",
      title: t("qualifications"),
    },
    {
      url: "/performance/details",
      title: t("details"),
    },

    {
      url: "/performance/trends",
      title: t("trends"),
    },
    {
      url: "/performance/leaders",
      title: t("leaders"),
    },
  ];
  const {
    checkedForces,
    forcesToRemove,
    newForces,
    setForceTaggingRemoved,
    setNewForces,
    relevantPlansIds,
  } = useForcesChecked(orbatForces);
  const { onPlanSelectedHandler, plansOptions, selectedPlan } =
    usePlans(relevantPlansIds);

  const { data: graphsData } = useQuery("graphsData", fetchActivePlanData);

  useEffect(() => {
    pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    setLabelsDetails(
      labelsDetailsActivePlan.map((l) => ({ ...l, label: t(l.label.trim()) }))
    );
  }, [labelsDetailsActivePlan]);

  useEffect(() => {
    setThresholdsDetails(thresholdsDetailsActivePlan);
    return () => {
      setThresholdsDetails([]);
    };
  }, [thresholdsDetailsActivePlan]);

  useEffect(() => {
    let isMounted = true; // in order to ensure that the data wont be presented if "mounted" is false

    const setDetailsPlanThresholdsAndLabels = async (
      detailsPlanId: number | undefined,
      isMounted: boolean
    ) => {
      getTrainingTypesDataForTrainingPlan(detailsPlanId).then((res) => {
        if (!checkedForces.length) setLoadingDetails(false);
        if (!res) return;
        isMounted && setDetailsPlan(res.data.planData);
        isMounted &&
          setThresholdsDetailsActivePlan(() =>
            res.data.trainingTypesData.map(
              (item: {
                trainingTypeData: {
                  id: number;
                  label: string;
                  threshold: number;
                  weight: number;
                };
              }) => ({
                id: item.trainingTypeData.id,
                threshold: item.trainingTypeData.threshold,
              })
            )
          );
        isMounted &&
          setLabelsDetailsActivePlan(() =>
            res.data.trainingTypesData.map(
              (item: {
                trainingTypeData: {
                  id: number;
                  label: string;
                  threshold: number;
                  weight: number;
                  indicatorId: number;
                };
              }) => ({
                id: item.trainingTypeData.id,
                label: item.trainingTypeData.label,
                indicatorId: item.trainingTypeData.indicatorId,
              })
            )
          );
      });
    };
    if (!detailsPlanId) {
      isMounted && setDetailsPlan(undefined);
      return;
    }
    if (isMounted && Number(detailsPlanId) != Number(selectedPlan?.id)) {
      setLoadingDetails(true);
      setLabelsDetails([]);
      setThresholdsDetails([]);
      setDetailsPlanThresholdsAndLabels(detailsPlanId, isMounted);
    }
    return () => {
      isMounted = false;
    };
  }, [detailsPlanId]);

  useEffect(() => {
    let isToDisplayDashboard =
      user.role === userRoles.Admin ||
      (!user.relatedForce?.is_soldier &&
        user.forceToDisplayInOrbat?.forceType !== "כיתה" &&
        user.forceToDisplayInOrbat?.forceType !== "קבוצה");
    setIsToDisplayDashboard(isToDisplayDashboard);
  }, [user]);

  const {
    labelsCompetency,
    labelsTrends,
    thresholdsCompetency,
    thresholdsTrends,
    trendsIndicators,
    setActivePlanID,
  } = useInitGraphsData(
    graphsData,
    t,
    undefined,
    setLabelsDetailsActivePlan,
    setThresholdsDetailsActivePlan,
    detailsPlan,
    fromTab
  );

  const {
    indicatorsGrades,
    activePlanFinalGrades,
    trainingTypesGrades,
    planDetailsFinalGrades,
    setIsToRefreshCompetency,
    setIsToRefreshDetails,
    setCompetencyFinalGrades,
    forcesResults,
  } = useGetPerformanceGrades(
    selectedPlan,
    checkedForces,
    newForces,
    forcesToRemove,
    setCompetencyLoading,
    setLoadingDetails,
    detailsPlan
  );

  useEffect(() => {
    setActivePlanID((prev) => {
      if (selectedPlan && selectedPlan?.id !== prev) {
        if (orbatForces.length) {
          setLoadingDetails(true);
          setCompetencyLoading(true);
        }
        return selectedPlan.id;
      }
      return prev;
    });
    setDetailsPlanId(undefined);
  }, [selectedPlan]);

  useEffect(() => {
    //competency plan values updated and there is no selected forces
    if (!checkedForces.length) setCompetencyLoading(false);
  }, [labelsCompetency, thresholdsCompetency]);

  useEffect(() => {
    //performance details plan values updated and there is no selected forces
    if (!checkedForces.length) setLoadingDetails(false);
  }, [labelsDetails, thresholdsDetails]);

  useEffect(() => {
    //selected plan changed
    setLoadingDetails(true);
    setCompetencyLoading(true);
  }, [selectedPlan]);

  const exportToCsv = async (image: string) => {
    if (checkedForces.length === 0) return;
    setDownloadLoading(true);
    let forcesData: IForceAllData[] = [];
    orbatForces.map((force) => {
      let forceData: ITrainingPlanResult | undefined = forcesResults?.find(
        (forceResult) => Number(forceResult.forceId) === Number(force.id)
      );
      if (forceData)
        forcesData.push({
          forceDetails: force,
          forceGradesData: {
            pic1: image,
            isSoldier: force.is_soldier,
            forceResults: forceData,
            affiliation: "",
            isAttended: forceData.grade !== null,
            pic2: "",
            pic3: "",
          },
        });
    });

    await exportToPdfAndCsv(forcesData, selectedPlan, t);
    setDownloadLoading(false);
  };
  const tabClicked = (data: any) => {
    if (data?.detail?.tab === t("details")) setFromTab(true);
    else setFromTab(false);
  };

  const onCheckForce = async (forces: IForceTreeNode[]) => {
    setOrbatForces(
      forces.map((force: IForceTreeNode) => {
        let currName: string = force.name;
        currName = currName.includes(force.force_type)
          ? currName
          : getForceFullName(force.name, force.force_type, t);

        return {
          ...force,
          name: currName,
        };
      })
    );
  };
  const exportHandler = () => {
    window.open("/performance/reportModal", "_blank");
  };

  return (
    <div className="perform-grid">
      <IonTabs
        onIonTabsWillChange={tabClicked}
        className="pm-tabs full-opacity"
      >
        <IonRouterOutlet
          id="main"
          className="routerOutlet"
          style={{ margin: 0, marginInlineStart: 0 }}
        >
          <Route
            path="/"
            render={() => <Redirect to="/performance" />}
            exact={true}
          />

          <Route
            path="/performance"
            render={() =>
              isTaggingActive ? (
                <Redirect to="/performance/details" />
              ) : (
                <Redirect to="/performance/qualifications" />
              )
            }
            exact={true}
          />

          <Route
            path="/performance/commanderDashboard"
            exact={true}
            render={() =>
              !isToDisplayDashboard ? (
                <Redirect to="/performance/qualifications" />
              ) : (
                <CommanderDashboard
                  exportToCsv={exportToCsv}
                  isAdmin={isAdmin}
                  isDrawerOpen={isDrawerOpen}
                  setDrawerOpen={setDrawerOpen}
                />
              )
            }
          />

          <Route
            path="/performance/qualifications"
            exact={true}
            render={() => (
              <PerformanceQualifications
                setChecked={onCheckForce}
                checkedForces={checkedForces}
                orbatForces={orbatForces}
                exportToCsv={exportToCsv}
                isAdmin={isAdmin}
                exportHandler={exportHandler}
                downloadLoading={downloadLoading}
                labels={labelsCompetency}
                thresholds={thresholdsCompetency}
                plan={selectedPlan!}
                indicatorsGrades={indicatorsGrades}
                finalGrades={activePlanFinalGrades}
                forcesToRemove={forcesToRemove}
                newForces={newForces ? newForces : checkedForces}
                loading={loadingCompetency}
                setIsToRefresh={setIsToRefreshCompetency}
                onPlanSelectedHandler={onPlanSelectedHandler}
                allPlansOptions={plansOptions}
                isDrawerOpen={isDrawerOpen}
                setDrawerOpen={setDrawerOpen}
              />
            )}
          />

          <Route
            path="/performance/details"
            render={() => (
              <PerformanceDetails
                setChecked={onCheckForce}
                checkedForces={checkedForces}
                orbatForces={orbatForces}
                exportToCsv={exportToCsv}
                fromTab={fromTab}
                isAdmin={isAdmin}
                exportHandler={exportHandler}
                downloadLoading={downloadLoading}
                setLoading={setLoadingDetails}
                labels={labelsDetails}
                thresholds={thresholdsDetails}
                trainingTypesGrades={trainingTypesGrades}
                finalGrades={planDetailsFinalGrades}
                plan={detailsPlan ? detailsPlan : selectedPlan!}
                selectedPlan={selectedPlan!}
                forcesToRemove={forcesToRemove}
                newForces={newForces ? newForces : checkedForces}
                setDetailsPlanId={setDetailsPlanId}
                loading={loadingDetails}
                setIsToRefresh={setIsToRefreshDetails}
                setForceTaggingRemoved={setForceTaggingRemoved}
                allPlansOptions={plansOptions}
                onPlanSelectedHandler={onPlanSelectedHandler}
                isDrawerOpen={isDrawerOpen}
                setDrawerOpen={setDrawerOpen}
              />
            )}
            exact={true}
          />

          <Route
            path="/performance/trends"
            render={() => (
              <PerformanceTrends
                checkedForces={checkedForces}
                orbatForces={orbatForces}
                setChecked={onCheckForce}
                isAdmin={isAdmin}
                plan={selectedPlan!}
                thresholds={thresholdsTrends}
                labels={labelsTrends} //if we want relevant plans just switch to relevantLabelsTrends
                forcesToRemove={forcesToRemove}
                newForces={newForces ? newForces : checkedForces}
                finalGrades={activePlanFinalGrades}
                indicators={trendsIndicators}
                setCompetencyFinalGrades={setCompetencyFinalGrades}
                isDrawerOpen={isDrawerOpen}
                setDrawerOpen={setDrawerOpen}
              />
            )}
            exact={true}
          />
          <Route
            path="/performance/leaders"
            exact={true}
            render={() => (
              <PerformanceLeaders
                selectedPlan={selectedPlan!}
                isPersonalZone={
                  user.relatedForce !== undefined &&
                  user.role !== userRoles.Admin
                }
                setChecked={onCheckForce}
                checkedForces={orbatForces}
                allPlansOptions={plansOptions}
                onPlanSelectedHandler={onPlanSelectedHandler}
                isDrawerOpen={isDrawerOpen}
                setDrawerOpen={setDrawerOpen}
              />
            )}
          />
        </IonRouterOutlet>

        <IonTabBar
          slot="top"
          className="tab-bar ion-justify-content-start"
          aria-disabled="true"
        >
          {PerformanceTabMenu.map((item: TabItem, index: number) => {
            return item.url === "/performance/commanderDashboard" &&
              !isToDisplayDashboard ? null : (
              <IonTabButton
                className={location.pathname === item.url ? "tab-selected" : ""}
                tab={item.title}
                href={item.url}
                key={index}
              >
                <PMTitle fontSize="medium" fontFamily="Light" fontColor="light">
                  {item.title}
                </PMTitle>
              </IonTabButton>
            );
          })}
        </IonTabBar>
      </IonTabs>
    </div>
  );
};

export default PerformanceDisplay;
