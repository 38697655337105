import { IonItem, IonLabel } from "@ionic/react";
import React, { Dispatch, SetStateAction, useState } from "react";
import "./Plan.css";
import IPlan from "../../../Interfaces/IPlan";
import { trash, pencil, copy, star, starOutline } from "ionicons/icons";
import { useDispatch } from "react-redux";
import { SELECTED_PLAN } from "../../../redux/actions/plansActions";
import { convertDateToString } from "../../../services/helpers";
import PopoverMenu from "../../Shared/Popover/PopoverMenu";
import { useTranslation } from "react-i18next";
import PMLabel from "../../themeComponents/PMLabel";
import PopoverItem from "../../themeComponents/PopoverItem";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";

interface IPlanProps {
  plan: IPlan;
  handlePlanEdit: (id: number) => void;
  handlePlanDuplicate: (id: number) => void;
  handlePlanDelete: (id: number) => void;
  handlePlanActive: (id: number) => void;
  isAdmin: boolean;
  isGlobal: boolean;
  isEditable: boolean;
  isUnsaved: () => boolean;
  setIsShowUnsaveModal: Dispatch<SetStateAction<boolean>>;
  setPlanToChange: Dispatch<SetStateAction<IPlan | undefined>>;
  setIsShowEditMode: Dispatch<SetStateAction<boolean>>;
}
const Plan: React.FC<IPlanProps> = (props: IPlanProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });

  const onPlanClick = () => {
    props.setIsShowEditMode(false);
    if (props.isUnsaved()) {
      props.setIsShowUnsaveModal(true);
      props.setPlanToChange(props.plan);
    } else {
      dispatch({
        type: SELECTED_PLAN,
        selectedPlan: props.plan,
      });
    }
  };

  return (
    <>
      <IonItem lines="none" color="sidebar" className="planItem">
        <div className={`planDetailsDiv`} onClick={onPlanClick}>
          <PMLabel
            fontColor="light"
            cssClass={`planDetails planHeader ${
              !props.isGlobal ? "localPlanHeader" : "globalPlanHeader"
            }`}
          >
            {props.plan.name}
          </PMLabel>
          {!props.isGlobal && (
            <IonLabel
              className={`planDetails planDate ${
                !props.isGlobal ? "localPlanHeader" : null
              }`}
            >
              {convertDateToString(props.plan.endDate!)} -{" "}
              {convertDateToString(props.plan.startDate!)}
            </IonLabel>
          )}
        </div>
        <div className="menuParentDiv">
          {props.isAdmin ? (
            <PMIcon
              color="light"
              size="medium"
              iconSrc={EIconsSrc.MENU_ICON}
              onClick={(e: any) => {
                e.persist();
                setShowPopover({ showPopover: true, event: e });
              }}
            ></PMIcon>
          ) : null}

          <PopoverMenu
            popoverState={popoverState}
            onDismiss={() =>
              setShowPopover({ showPopover: false, event: undefined })
            }
          >
            <PopoverItem
              hidden={!props.isEditable}
              fontColor="light"
              onClickHandler={() => {
                setShowPopover({ showPopover: false, event: undefined });
                props.handlePlanEdit(props.plan.id);
              }}
              background="Blight"
            >
              <PMLabel fontFamily="Light" fontSize="medium">
                {t("edit")}
              </PMLabel>
              <PMIcon size="small" color="dark" iconSrc={pencil}></PMIcon>
            </PopoverItem>
            <PopoverItem
              fontColor="light"
              onClickHandler={() => {
                setShowPopover({ showPopover: false, event: undefined });
                props.handlePlanActive(props.plan.id);
              }}
              background="Blight"
            >
              <PMLabel fontFamily="Light" fontSize="medium">
                {t("setActive")}
              </PMLabel>
              <PMIcon
                size="small"
                color="dark"
                iconSrc={props.plan.isActive ? star : starOutline}
              ></PMIcon>
            </PopoverItem>
            <PopoverItem
              hidden={props.isGlobal}
              fontColor="light"
              onClickHandler={() => {
                setShowPopover({ showPopover: false, event: undefined });
                props.handlePlanDelete(props.plan.id);
              }}
              background="Blight"
            >
              <PMLabel fontFamily="Light" fontSize="medium">
                {t("delete")}
              </PMLabel>
              <PMIcon size="small" color="dark" iconSrc={trash}></PMIcon>
            </PopoverItem>
            <PopoverItem
              hidden={props.isGlobal}
              fontColor="light"
              onClickHandler={() => {
                setShowPopover({ showPopover: false, event: undefined });
                props.handlePlanDuplicate(props.plan.id);
              }}
              background="Blight"
            >
              <PMLabel fontFamily="Light" fontSize="medium">
                {t("duplicatePlan")}
              </PMLabel>
              <PMIcon size="small" color="dark" iconSrc={copy}></PMIcon>
            </PopoverItem>
          </PopoverMenu>
          <div className="activeIcon">
            {props.plan.isActive ? (
              <PMIcon
                size="medium"
                color="dark"
                iconSrc={props.plan.isActive ? star : starOutline}
              ></PMIcon>
            ) : null}
          </div>
        </div>
      </IonItem>
    </>
  );
};

export default Plan;
