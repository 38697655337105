import React from "react";
import { IonInput, IonItem } from "@ionic/react";
import "./SmallInput.css";
import { useTranslation } from "react-i18next";

interface IInputProps {
  inputType: "number";
  inputValue?: string | number;
  length: "smallShort" | "smallLong";
  onChange: (field: string, value: string) => void;
  inputName: string;
  isPercentageInput?: boolean | false;
  isDisabled?: false | boolean;
  invalid?: false | boolean;
  units?: string | "a";
  cssClass?: string;
}

const SmallInput: React.FC<IInputProps> = (props: IInputProps) => {
  const { t } = useTranslation();
  return (
    <IonItem
      className={`smallInputItem ${props.length} ${
        props.invalid ? "invalidInput" : null
      } ${props.cssClass}`}
      onClick={(e) => e.stopPropagation()}
    >
      <IonInput
        className={`smallInputField ${
          props.isPercentageInput ? "smallInputPercentage" : null
        }`}
        value={props.inputValue}
        type={props.inputType}
        onIonChange={(e) => {
          props.onChange(props.inputName, e.detail.value!);
        }}
        disabled={props.isDisabled}
        pattern=" 0+\.[0-9]*[1-9][0-9]*$"
        min="0"
      ></IonInput>
      {props.units ? <div className="units">{t(props.units)}</div> : null}
    </IonItem>
  );
};

export default SmallInput;
