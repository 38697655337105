import React, { useEffect } from "react";
import "./DashboardDetailsCard.css";
import { ICardProps } from "../../../Interfaces/IDashboardDetailsCard";
import PMLabel from "../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import VerticalStackedBar from "../VerticalProgressBar/VerticalStackedBar";

const DashboardDetailsCard: React.FC<ICardProps> = (
  props: ICardProps
): JSX.Element => {
  const { element, totalAmountValue, indicatorId, trainingType, onCardClick } =
    props;
  const { t, i18n } = useTranslation();
  const almostRequired = Math.round(
    Number(
      !element.negative ? element.upperThreshold : element.lowerThreshold
    ) / 2
  );

  const getLabel = (key: string): string => {
    if (key === "green") {
      if (!element.negative) {
        return `${
          Number(element.requiredThreshold) <= almostRequired
            ? t("under")
            : t("upper")
        } ${element.requiredThreshold} ${t(element.thresholdType)}`;
      } else {
        return t("under")
          .concat(" ")
          .concat(element.requiredThreshold)
          .concat(" ")
          .concat(t(element.thresholdType));
      }
    } else if (key === "yellow") {
      if (!element.negative) {
        return Number(element.requiredThreshold) === almostRequired &&
          almostRequired === 0
          ? "0 - 0"
          : `${
              Number(element.requiredThreshold) >= almostRequired
                ? Number(element.requiredThreshold) === 0
                  ? Number(element.requiredThreshold)
                  : Number(element.requiredThreshold) - 1
                : almostRequired === 0
                ? almostRequired
                : almostRequired - 1
            } - ${
              Number(element.requiredThreshold) >= almostRequired
                ? almostRequired + 1
                : Number(element.requiredThreshold) + 1
            } ${t(element.thresholdType)}`;
      } else {
        return `${Number(almostRequired) - 1} - ${
          Number(element.requiredThreshold) + 1
        } ${t(element.thresholdType)}`;
      }
    } else if (key === "red") {
      if (!element.negative) {
        return `${
          Number(element.requiredThreshold) > almostRequired
            ? t("under")
            : t("upper")
        } ${almostRequired} ${t(element.thresholdType)}`;
      } else {
        return `${t("upper")} ${almostRequired} ${t(element.thresholdType)}`;
      }
    }
    return "";
  };

  const typesArray: ("green" | "yellow" | "red")[] =
    i18n.language == "he"
      ? ["green", "yellow", "red"]
      : ["red", "yellow", "green"];
  return (
    <div
      className="detailsCard CCol"
      onClick={() => onCardClick(element.name, trainingType, indicatorId)}
    >
      <div>
        <div className="CRow">
          <PMLabel
            fontSize="large"
            fontColor="xLight"
            fontFamily="Regular"
            cssClass="titleCard"
          >
            {t(element.name)}
          </PMLabel>
        </div>
      </div>
      <div className="CRow">
        {typesArray.map((key: "green" | "yellow" | "red", index) => (
          <div key={key} className={"CCol "}>
            <div className="labelGrade">
              <PMLabel
                fontSize={"small"}
                fontColor={"xLight"}
                fontFamily="Regular"
              >
                {`${totalAmountValue}/`}
              </PMLabel>
              <PMLabel
                fontSize={"small"}
                cssClass={key + "TextColor"}
                fontColor={"xLight"}
                fontFamily="Regular"
              >
                {`${element.forcesElementData[key]
                  .map((v: any) => v.value)
                  .reduce((accumulator: any, current: any) => {
                    return accumulator + current;
                  }, 0)}`}
              </PMLabel>
            </div>
            <div className="VerticalStackedBar">
              <VerticalStackedBar
                key={`${index} ${indicatorId} ${element.id} ${trainingType.id}`}
                barData={[...element.forcesElementData[key]]}
                hundredPercentsValue={totalAmountValue}
              ></VerticalStackedBar>
            </div>
            <div className="PLabel">
              <PMLabel
                fontSize="small"
                fontFamily="Regular"
                textAlign="center"
                cssClass={key + "TextColor"}
              >
                {key === "green"
                  ? t("passed")
                  : key === "yellow"
                  ? t("almostPasssed")
                  : t("failed")}
              </PMLabel>
              <PMLabel
                fontSize="xSmall"
                fontColor="xLight"
                fontFamily="Light"
                textAlign="center"
                cssClass="thresholdLabel"
              >
                {getLabel(key)}
              </PMLabel>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardDetailsCard;
