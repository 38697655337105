import React, { Dispatch, useContext, useEffect, useState } from "react";
import { IonCol, IonGrid, IonPage, IonRow } from "@ionic/react";
import TrainingPlansSideBar from "../../../components/Desktop/TrainingPlan/TrainingPlansSideBar";
import TrainingPlansMainView from "../../../components/Desktop/TrainingPlan/TrainingPlansMainView";
import AdminPassword from "../../../components/Desktop/AdminPassword/AdminPassword";
import { UserContext } from "../../../context/UserContext/userContext";
import { useTranslation } from "react-i18next";
import { checkPassword } from "../../../services/passwordsHandler";
import IUnsavedPlan from "../../../Interfaces/IUnsavedPlan";
import IPlan from "../../../Interfaces/IPlan";
import { flattenObject } from "../../../services/helpers";
import "./TrainingPlan.css";
import { useDispatch } from "react-redux";
import { SET_EDIT_MODE } from "../../../redux/actions/plansActions";

const TrainingPlan: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch<any>>();

  const { authTp, isTpAuthed } = useContext(UserContext);
  const [invalid, setInvalid] = useState<boolean>(false);
  const [isUnsavedPlan, setIsUnsavedPlan] = useState<IUnsavedPlan>();
  const [isShowUnsaveModal, setIsShowUnsaveModal] = useState<boolean>(false);
  const [planToChange, setPlanToChange] = useState<IPlan>();
  const [isShowEditMode, setIsShowEditMode] = useState<boolean>(false);
  const [enableGlobalPlansEditing, setEnableGlobalPlansEditing] =
    useState<boolean>(false);
  const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout>();
  const MAX_SUPER_SOFER_PASSWORD_VALID_MINUTES = 60;
  useEffect(() => {
    if (enableGlobalPlansEditing) {
      //if the plan is opened more then 60 minutes close the the editing mode
      const timeoutID = setTimeout(function () {
        setEnableGlobalPlansEditing(false);
        dispatch({ type: SET_EDIT_MODE, editMode: false });
      }, MAX_SUPER_SOFER_PASSWORD_VALID_MINUTES * 60 * 1000);
      setTimeoutID(timeoutID);
    }
  }, [enableGlobalPlansEditing]);

  useEffect(() => {
    return () => {
      //clear timeout when leveeing the page
      clearTimeout(timeoutID);
    };
  }, []);

  const onSendPasswordHandler = async (password: string) => {
    setInvalid(false);
    const isPasswordValid = await checkPassword(password, setInvalid);
    if (isPasswordValid) authTp();
  };

  const isUnsaved = (): boolean => {
    if (!!flattenObject(isUnsavedPlan).find((item) => item === true)) {
      return true;
    } else {
      return false;
    }
  };

  return isTpAuthed ? (
    <IonPage>
      <div className="contentTP">
        <IonGrid className="viewsGrid">
          <IonRow className="viewsRow">
            <IonCol size="2" className="viewCol sideBar">
              <TrainingPlansSideBar
                isUnsaved={isUnsaved}
                setIsShowUnsaveModal={setIsShowUnsaveModal}
                setPlanToChange={setPlanToChange}
                setIsShowEditMode={setIsShowEditMode}
                setEnableGlobalPlansEditing={setEnableGlobalPlansEditing}
                enableGlobalPlansEditing={enableGlobalPlansEditing}
              ></TrainingPlansSideBar>
            </IonCol>
            <IonCol size="10" className="viewCol mainView">
              <TrainingPlansMainView
                isShowUnsaveModal={isShowUnsaveModal}
                planToChange={planToChange}
                setIsUnsavedPlan={setIsUnsavedPlan}
                setIsShowUnsaveModal={setIsShowUnsaveModal}
                isUnsaved={isUnsaved}
                isShowEditMode={isShowEditMode}
                setEnableGlobalPlansEditing={setEnableGlobalPlansEditing}
              ></TrainingPlansMainView>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  ) : (
    <IonPage>
      <div className="contentTP">
        <AdminPassword
          onSendPasswordHandler={(password: string) =>
            onSendPasswordHandler(password)
          }
          text={t("enterPassword")}
          invalid={invalid}
        ></AdminPassword>
      </div>
    </IonPage>
  );
};

export default TrainingPlan;
