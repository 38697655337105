import plansReducer from "./plans";
import indicatorsReducer from "./indicator";
import stationsReducer from "./stations";
import elementsReducer from "./elements";
import orbatTree from "./orbatTree";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  elements: elementsReducer,
  indicators: indicatorsReducer,
  stations: stationsReducer,
  plans: plansReducer,
  orbatTree: orbatTree,
});

export default rootReducer;
