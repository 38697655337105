import {
  FETCH_PLANS_FAILURE,
  FETCH_PLANS_REQUEST,
  FETCH_PLANS_SUCCESS,
  SAVE_PLAN_BUTTON_CHANGE,
  SELECTED_PLAN,
  UPDATE_PLAN,
  DELETE_PLAN_REQUEST,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_FAILURE,
  UPDATE_PLAN_ID,
  SET_EDIT_MODE,
  SAVE_PLAN_ATTRIBUTES_REQUEST,
  SAVE_PLAN_ATTRIBUTES_SUCCESS,
  SAVE_PLAN_ATTRIBUTES_FAILURE,
  SET_ACTIVE_SUCCESS,
  REMOVE_ACTIVE_PLAN,
  DUPLICATE_PLAN_REQUEST,
  DUPLICATE_PLAN_SUCCESS,
  DUPLICATE_PLAN_FAILURE,
  INCREMENT_UPDATES_COUNTER,
} from "./../actions/plansActions";
import {
  ADD_PLAN,
  DELETE_PLAN,
  EDIT_PLAN,
  PlanActionTypes,
} from "../actions/plansActions";
import IPlan from "../../Interfaces/IPlan";

export interface IPlansReducer {
  plans: IPlan[];
  loading: boolean;
  error: Error | "";
  selectedPlan?: IPlan | undefined;
  savePlan: number;
  editMode: boolean;
  activePlan?: number | undefined;
  updatesCounter: number;
}

const planReducerDefaultState: IPlansReducer = {
  plans: [],
  loading: false,
  error: "",
  savePlan: 0,
  editMode: false,
  updatesCounter: 0,
};

const plansReducer = (
  state = planReducerDefaultState,
  action: PlanActionTypes
) => {
  switch (action.type) {
    case ADD_PLAN: {
      return { ...state, plans: [...state.plans, action.plan] };
    }

    case EDIT_PLAN:
      return {
        ...state,
        plans: [
          ...state.plans.map((plan) => {
            if (plan.id === action.plan.id) {
              return { ...plan, ...action.plan };
            } else {
              return plan;
            }
          }),
        ],
      };

    case DELETE_PLAN:
      return {
        ...state,
        plans: [...state.plans.filter(({ id }) => id !== action.planId)],
        activePlan: state.activePlan === action.planId ? -1 : state.activePlan,
      };

    case FETCH_PLANS_REQUEST:
      return { ...state, loading: true };

    case FETCH_PLANS_SUCCESS:
      return { ...state, loading: false, plans: action.plans, error: "" };

    case FETCH_PLANS_FAILURE:
      return { ...state, loading: false, plans: [], error: action.error };

    case SELECTED_PLAN: {
      return {
        ...state,
        selectedPlan: action.selectedPlan,
        savePlan: 0,
        editMode: action.editMode ? action.editMode : false,
      };
    }

    case SAVE_PLAN_BUTTON_CHANGE: {
      return { ...state, savePlan: state.savePlan + 1, editMode: false };
    }

    case SAVE_PLAN_ATTRIBUTES_REQUEST:
      return { ...state, loading: true };

    case SAVE_PLAN_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        loading: false,
        plans: [
          ...state.plans.map((plan) => {
            if (plan.id === action.plan.id) {
              return { ...plan, ...action.plan };
            } else {
              return plan;
            }
          }),
        ],
      };

    case SAVE_PLAN_ATTRIBUTES_FAILURE:
      return {
        ...state,
        plans: action.initialPlan
          ? [
              ...state.plans.map((plan) => {
                if (plan.id === action.initialPlan?.id) {
                  return { ...plan, ...action.initialPlan };
                } else {
                  return plan;
                }
              }),
            ]
          : state.plans,
        loading: false,
      };

    case UPDATE_PLAN: {
      return {
        ...state,
        plans: [
          ...state.plans.map((plan) => {
            if (plan.id === action.plan.id) {
              return { ...plan, ...action.plan };
            } else {
              return plan;
            }
          }),
        ],
      };
    }

    case DELETE_PLAN_REQUEST: {
      return { ...state, loading: true };
    }

    case DELETE_PLAN_SUCCESS: {
      return { ...state, loading: false };
    }

    case DELETE_PLAN_FAILURE: {
      return { ...state, loading: false, error: action.error };
    }

    case UPDATE_PLAN_ID: {
      return {
        ...state,
        plans: [
          ...state.plans.map((plan) => {
            if (plan.id === -1) {
              return { ...plan, id: action.id };
            } else {
              return plan;
            }
          }),
        ],
      };
    }

    case SET_EDIT_MODE: {
      return {
        ...state,
        editMode: action.editMode ? action.editMode : !state.editMode,
      };
    }

    case SET_ACTIVE_SUCCESS: {
      return {
        ...state,
        activePlan: action.planId,
        plans: [
          ...state.plans.map((plan) => {
            if (plan.id === action.planId) {
              return { ...plan, isActive: true };
            } else {
              return plan;
            }
          }),
        ],
      };
    }

    case DUPLICATE_PLAN_REQUEST:
      return { ...state, loading: true };

    case DUPLICATE_PLAN_SUCCESS:
      return { ...state, loading: false, plans: [...state.plans, action.plan] };

    case DUPLICATE_PLAN_FAILURE:
      return { ...state, loading: false };

    case REMOVE_ACTIVE_PLAN: {
      return {
        ...state,
        plans: [
          ...state.plans.map((plan) => {
            if (plan.isActive) {
              return { ...plan, isActive: false };
            } else {
              return plan;
            }
          }),
        ],
      };
    }

    case INCREMENT_UPDATES_COUNTER:
      return { ...state, updatesCounter: state.updatesCounter + 1 };
    default:
      return state;
  }
};

export default plansReducer;
