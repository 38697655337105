import IForceTreeNode from "../Interfaces/IForceTreeNode";

export const removeTempForce = (tree: IForceTreeNode) => {
  let tempNodes: IForceTreeNode[] | undefined = tree?.nodes?.filter(
    (node: IForceTreeNode) => node.id > 0
  );

  tempNodes && Object.assign(tree, { ...tree, nodes: tempNodes });

  tree?.nodes &&
    tree.nodes.forEach((node: IForceTreeNode) => {
      removeTempForce(node);
    });
};
export const isTwoLevelsAtleast = (force: IForceTreeNode) => {
  return !(
    force &&
    (force.nodes === null ||
      !force.nodes.filter((f) => f.nodes !== null).length)
  );
};
