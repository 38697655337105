import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import "./UIFMobileElement.css";
import { UIFGrades } from "../../../../Interfaces/IUrbanInstructorFeedback";
import {
  IonButton,
  IonCol,
  IonInput,
  IonItem,
  IonList,
  IonRow,
  IonText,
} from "@ionic/react";
import UFIElementHook from "../../../CustomHooks/UFIElementHook";
import PMLabel from "../../../themeComponents/PMLabel";
import PMButton from "../../../themeComponents/PMButton";
export interface IProps {
  title: string;
  elements: string[];
  dataType: string;
  setGrade: Dispatch<SetStateAction<UIFGrades[]>>;
  resetAll: boolean;
  setResetAll: (state: boolean) => void;
  index: number;
  isCompleted: boolean;
  postData: () => void;
}
const UIFMobileElement: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    title,
    elements,
    dataType,
    setGrade,
    resetAll,
    setResetAll,
    index,
    isCompleted,
    postData,
  } = props;
  const { t } = useTranslation();

  const { elementsGrades, setElementGrade, finalGrade } = UFIElementHook(
    elements,
    dataType,
    setGrade,
    resetAll,
    setResetAll
  );
  return (
    <React.Fragment>
      <IonCol className="UFIMobileElement">
        <div className="elementsRowM">
          <IonRow>
            <PMLabel
              fontSize="xxLarge"
              fontFamily="Light"
              fontColor="light"
              underline
            >
              {title}
            </PMLabel>
          </IonRow>
          <IonRow className="elementsRowMobile">
            <IonCol size="8.5" className="colIFM leftBorder">
              <IonList className="listIFM">
                {elements.map((element) => (
                  <IonRow key={element} className="rowIFM">
                    <IonItem className="itemIFM" lines="none">
                      <IonText className="labelIFM">{element}</IonText>
                    </IonItem>
                  </IonRow>
                ))}
              </IonList>
            </IonCol>
            <IonCol size="3.5" className="colIFM">
              <IonList className="listIFM">
                {elementsGrades.map((element, index) => (
                  <IonRow key={element.name} className="rowIFM">
                    <IonItem className="itemIFM" lines="none">
                      <IonInput
                        key={index}
                        onIonChange={(event) =>
                          setElementGrade(element.name, event.detail.value)
                        }
                        onIonFocus={(event: any) => {
                          event.target.placeholder = "";
                        }}
                        onIonBlur={(event: any) => {
                          event.target.placeholder = t("grade");
                        }}
                        placeholder={t("grade")}
                        value={element.grade}
                        className="inputIFM"
                        type="number"
                        max="10"
                        min="1"
                      ></IonInput>
                    </IonItem>
                  </IonRow>
                ))}
              </IonList>
            </IonCol>
          </IonRow>
        </div>
        <IonRow>
          <PMLabel
            fontSize="xxLarge"
            fontFamily="Light"
            fontColor="light"
            underline
          >
            {t("avgGrade")}
          </PMLabel>
        </IonRow>
        <IonRow className="finalGradeRowUFI">
          <PMLabel
            fontSize="xxLarge"
            fontFamily="Bold"
            fontColor="light"
            textAlign="center"
          >
            {finalGrade}
          </PMLabel>
        </IonRow>
        <IonRow className="rowIFMButton">
          <PMButton
            isDisabled={!isCompleted}
            onClickHandler={postData}
            size="medium"
            color="tertiary"
            label={{
              fontColor: "light",
              fontSize: "medium",
              fontFamily: "Regular",
            }}
          >
            {t("send")}
          </PMButton>
        </IonRow>
      </IonCol>
    </React.Fragment>
  );
};

export default UIFMobileElement;
