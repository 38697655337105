import { FC, useContext, useState } from "react";
import { IonButton, IonHeader, IonText, IonToolbar } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../context/UserContext/userContext";
import { useMsal } from "@azure/msal-react";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import Cookies from "universal-cookie";
import ForcesContext from "../../../context/ForcesContext/forcesContext";
import HeaderMenu from "../../Desktop/HeaderMenu/HeaderMenu";
import "./MobileHeader.css";

interface IProps {
  iconEndSrc?: string;
  iconStartSrc?: string;
  iconEndHandler?: () => void;
  iconStartHandler?: () => void;
  title?: string;
  isUserDisplay?: boolean;
  isHideForcesTree?: boolean;
}

const MobileHeader: FC<IProps> = (props: IProps) => {
  const {
    iconEndSrc,
    iconStartSrc,
    iconEndHandler,
    iconStartHandler,
    title,
    isUserDisplay,
    isHideForcesTree,
  } = props;
  const { t, i18n } = useTranslation();
  const { instance } = useMsal();

  const { isForcesTreeOpen, setIsForcesTreeOpen } = useContext(ForcesContext);

  const { user, logout, toggleDarkModeHandler, darkMode } =
    useContext(UserContext);

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const cookie = new Cookies();

  const logoutUser = () => {
    logout();
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  const localSiteLogout = async () => {
    cookie.remove("user");
    logout();
    window.location.reload();
  };

  return (
    <IonHeader>
      <IonToolbar>
        <div className="row-header">
          <div className="page-header">
            <IonButton
              hidden={iconStartSrc === undefined}
              fill="default"
              className="header-button"
              onClick={iconStartHandler}
            >
              {iconStartSrc ? (
                <PMIcon size="large" iconSrc={iconStartSrc} color="light" />
              ) : null}
            </IonButton>
            {isUserDisplay === false ? null : (
              <IonText className="mobile-user-text">
                {t("hello") + ", " + user.displayName}
              </IonText>
            )}

            <IonText
              className={iconStartSrc === undefined ? "header-title" : ""}
            >
              {title}
            </IonText>
          </div>
          <div className="more-details">
            <HeaderMenu
              popoverState={popoverState}
              darkMode={darkMode}
              setDarkMode={toggleDarkModeHandler}
              onDismiss={() =>
                setShowPopover({
                  showPopover: false,
                  event: undefined,
                })
              }
              isMobile={true}
            />
            <div className="orbat-tree-icon">
              <PMIcon
                hidden={isForcesTreeOpen === undefined || isHideForcesTree}
                iconSrc={EIconsSrc.TREE_ICON_EN}
                size="large"
                color="light"
                onClick={() => setIsForcesTreeOpen((prev) => !prev)}
              />
            </div>
            <PMIcon
              iconSrc={EIconsSrc.MENU_DOTS}
              size="large"
              color="light"
              onClick={(e: any) => {
                e.persist();
                setShowPopover({ showPopover: true, event: e });
              }}
            />
          </div>
        </div>
      </IonToolbar>
    </IonHeader>
  );
};

export default MobileHeader;
