import { useState, useEffect, SetStateAction, Dispatch } from "react";
import {
  UIFGrades,
  IElementGrade,
} from "../../Interfaces/IUrbanInstructorFeedback";
const UFIElementHook = (
  elements: string[],
  dataType: string,
  setGrade: Dispatch<SetStateAction<UIFGrades[]>>,
  resetAll: boolean,
  setResetAll: (state: boolean) => void
) => {
  const [finalGrade, setFinalGrade] = useState<number>(0);

  const setState = () => {
    let elementsGradesArray: IElementGrade[] = [];
    elements.map((element) => {
      elementsGradesArray.push({ name: element, grade: undefined });
    });
    return elementsGradesArray;
  };
  const [elementsGrades, setElementsGrades] = useState<IElementGrade[]>(
    setState()
  );

  const setElementGrade = (elementName: string, grade: any) => {
    setElementsGrades((prev) => {
      return prev.map((element) => {
        return element.name === elementName
          ? {
              ...element,
              grade: isNaN(Number(grade))
                ? undefined
                : Number(grade) >= 10
                ? 10
                : Number(grade) <= 1
                ? 1
                : Number(grade),
            }
          : element;
      });
    });
  };
  useEffect(() => {
    let finalGrade: number = 0;
    elementsGrades.map((element) => {
      if (element.grade === undefined || isNaN(element.grade)) return;

      finalGrade +=
        element.grade === undefined || isNaN(element.grade)
          ? 1
          : element.grade <= 1
          ? 1
          : element.grade >= 10
          ? 10
          : element.grade;
    });
    finalGrade =
      finalGrade / (elementsGrades.length ? elementsGrades.length : 1);
    finalGrade = Number(finalGrade.toFixed(1));

    let newArray: UIFGrades[] = [];
    if (!isNaN(finalGrade)) setFinalGrade(finalGrade);
    setGrade((prev: UIFGrades[]) => {
      prev.map((elementDataType) => {
        newArray.push(
          elementDataType.dataType === dataType
            ? {
                ...elementDataType,
                value: Number(finalGrade),
                isComplete:
                  elementsGrades.find(
                    (element) => element.grade === undefined
                  ) === undefined,
              }
            : elementDataType
        );
      });
      return newArray;
    });
  }, [elementsGrades]);
  useEffect(() => {
    setResetAll(false);
    setElementsGrades(setState());
  }, [resetAll]);
  return { elementsGrades, setElementGrade, finalGrade };
};
export default UFIElementHook;
