import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import "./NotFoundPlan.css";
interface IProps {
  text: string;
}
const NotFoundPlan: FC<IProps> = (props: IProps) => {
  const { t } = useTranslation();

  return <span className="textInsteadOfGraph">{props.text}</span>;
};

export default NotFoundPlan;
