import { AxiosResponse } from "axios";
import axios from "../Axios";
import { APIs } from "../Configurations/urls";
import ITrackingLogins from "../Interfaces/ITrackingLogins";

const addUserForTracking = async (userId: number): Promise<void> => {
  try {
    await axios.post(APIs.trackingLogins.addUserToTracking(), {
      id: userId,
    });
  } catch (error) {
    throw error;
  }
};

const getAllLoginsTracking = async (): Promise<
  AxiosResponse<ITrackingLogins[] | undefined>
> => {
  try {
    return await axios.get(APIs.trackingLogins.getAll());
  } catch (error) {
    throw error;
  }
};

export { addUserForTracking, getAllLoginsTracking };
