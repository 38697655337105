import { IonRow, IonCol } from "@ionic/react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Collapse } from "react-collapse";
import IStation from "../../../Interfaces/IStation";
import SmallInput from "./SmallInput";
import Elements from "./Elements";
import { useSelector } from "react-redux";
import { IPlansReducer } from "../../../redux/reducers/plans";
import { AppState } from "../../../redux/store/plansStore";
import { useTranslation } from "react-i18next";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import PMIcon from "../../themeComponents/PMIcon";
import PMLabel from "../../themeComponents/PMLabel";
import { units, validateInput } from "../DataPosting/inputValidations";
import IUnsavedPlan from "../../../Interfaces/IUnsavedPlan";
import IElement from "../../../Interfaces/IElement";
import "./Station.css";

interface IStationProps {
  station: IStation;
  stationIndex: number;
  updateStation: (station: IStation) => void;
  setIsUnsavedPlan: Dispatch<SetStateAction<IUnsavedPlan | undefined>>;
  currentElements: IElement[];
}

const Station: React.FC<IStationProps> = (props: IStationProps) => {
  const [isElementsOpened, setIsElementsOpened] = useState<boolean>(false);
  const [station, setStation] = useState<IStation>({} as IStation);
  const { t } = useTranslation();

  const editMode = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).editMode;

  const { i18n } = useTranslation();

  useEffect(() => {
    setStation(props.station);
  }, [props.station]);

  const onChangeStationDetailsHandler: Function = (
    field: string,
    value: string,
    units: string,
    inputIndex: number
  ): void => {
    let errs: string[] | undefined;
    errs = station.errors?.map((err: string, index: number) =>
      index === inputIndex ? t(validateInput(value, units)) : err
    );

    setStation({
      ...station!,
      [field]: value !== "" ? value : null,
      errors: errs,
    });
  };

  return (
    <IonCol size="12" className={`stationCol`}>
      <IonRow
        className={`stationRow ${
          isElementsOpened ? "openElementsStationRow" : ""
        }`}
        onClick={() => setIsElementsOpened(!isElementsOpened)}
        onBlur={() => props.updateStation(station)}
      >
        <IonCol size="2.6" className="stationHeader stCol">
          <div className="iconNameDiv">
            {isElementsOpened ? (
              <PMIcon
                color="light"
                iconSrc={EIconsSrc.ARROW_DOWN}
                size="large"
              ></PMIcon>
            ) : (
              <PMIcon
                color="light"
                iconSrc={
                  i18n.language === "he"
                    ? EIconsSrc.CHEVRON_LEFT
                    : EIconsSrc.CHEVRON_RIGHT
                }
                size="large"
              ></PMIcon>
            )}
            <PMLabel
              fontColor="light"
              fontSize="medium"
              fontFamily="Light"
              cssClass="stationName"
            >
              {t(station?.name ? station?.name.trim() : "")}
            </PMLabel>
          </div>
        </IonCol>
        <IonCol
          size="2.58"
          className={` stCol stationValue stationWeight ${props.stationIndex} ${
            editMode ? "" : "viewMode"
          }`}
        >
          <SmallInput
            inputType="number"
            length="smallLong"
            onChange={(field: string, value: string) =>
              onChangeStationDetailsHandler(field, value, units.Weight, 0)
            }
            inputName="weight"
            inputValue={station?.weight}
            isPercentageInput
            isDisabled={!editMode}
            cssClass={`tpSmallInput ${
              station.errors && station.errors[0] !== "" ? "tpInputError" : null
            }`}
          ></SmallInput>
        </IonCol>
        <IonCol
          size="2.58"
          className={` ${props.stationIndex} ${
            editMode ? "test" : "viewMode"
          } stCol`}
        >
          <div className="stationThreshold ">
            <SmallInput
              inputType="number"
              length="smallLong"
              onChange={(field: string, value: string) =>
                onChangeStationDetailsHandler(field, value, units.Grade, 1)
              }
              inputValue={station?.threshold}
              inputName="threshold"
              isDisabled={!editMode}
              cssClass={`tpSmallInput ${
                station.errors && station.errors[1] !== ""
                  ? "tpInputError"
                  : null
              }`}
            ></SmallInput>
          </div>
        </IonCol>
        <IonCol size="2.58"></IonCol>
        <IonCol size="1.33"></IonCol>
      </IonRow>
      <IonRow className="elementsRow">
        <Collapse isOpened={isElementsOpened} className="elementsCollapse">
          <Elements
            stationId={station?.id!}
            setIsUnsavedPlan={props.setIsUnsavedPlan}
            currentElements={props.currentElements}
          ></Elements>
        </Collapse>
      </IonRow>
    </IonCol>
  );
};

export default Station;
