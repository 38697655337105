import { IonCol, IonLabel, IonRow } from "@ionic/react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import IStation from "../../../Interfaces/IStation";
import { IPlansReducer } from "../../../redux/reducers/plans";
import { useTranslation } from "react-i18next";
import { IStationReducer } from "../../../redux/reducers/stations";
import { AppState } from "../../../redux/store/plansStore";
import Station from "./Station";
import {
  EDIT_STATION,
  SET_STATIONS,
} from "../../../redux/actions/stationsActions";
import IUnsavedPlan from "../../../Interfaces/IUnsavedPlan";
import { compareToInitialArrayOfObjects } from "../../../services/helpers";
import IElement from "../../../Interfaces/IElement";
import "./Stations.css";

interface IStationsProps {
  indicatorId: number;
  setIsUnsavedPlan: Dispatch<SetStateAction<IUnsavedPlan | undefined>>;
  currentElements: IElement[];
}

const Stations: React.FC<IStationsProps> = (props: IStationsProps) => {
  const { indicatorId, setIsUnsavedPlan, currentElements } = props;
  const { t } = useTranslation();
  const [initialStations, setInitialStations] = useState<IStation[]>([]);
  const [localStations, setLocalStations] = useState<IStation[]>([]);
  const [mounted, setMounted] = useState<boolean>(true);

  const dispatch = useDispatch<Dispatch<any>>();
  const stationsState = useSelector<AppState, IStationReducer>(
    (state) => state.stations
  );
  const editMode = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).editMode;

  const savePlan = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).savePlan;

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    if (editMode && !stationsState.loading) {
      setInitialStations(stationsState.stations);
    }
  }, [editMode]);

  useEffect(() => {
    if (
      !editMode &&
      savePlan <= 0 &&
      !stationsState.loading &&
      initialStations.length !== 0
    ) {
      dispatch({
        type: SET_STATIONS,
        stations: initialStations,
      });
      setLocalStations(initialStations);
    }
  }, [editMode]);

  useEffect(() => {
    if (mounted) setLocalStations(stationsState.stations);
  }, [stationsState.stations]);

  const updateStation = (station: IStation) => {
    setLocalStations((prev: IStation[]) =>
      prev.map((prevStation: IStation) =>
        prevStation.id === station.id ? station : prevStation
      )
    );
    dispatch({ type: EDIT_STATION, station: station });
  };

  useEffect(() => {
    if (editMode) {
      setIsUnsavedPlan((prev: IUnsavedPlan | undefined) => ({
        ...prev!,
        indicators: {
          ...prev?.indicators!,
          stations: {
            ...prev?.indicators.stations!,
            isStationsUnsaved: compareToInitialArrayOfObjects(
              initialStations,
              localStations
            ),
          },
        },
      }));
    }
  }, [localStations]);

  return (
    <IonCol size="12" className="stationsCol ">
      <IonRow className="headersWrapper">
        <IonCol size="12" className="stationsCol ">
          <IonRow className="stationsHeadersRow ">
            <IonCol size="2.55">
              <IonLabel className="stationsHeader">{t("station")}</IonLabel>
            </IonCol>
            <IonCol size="2.58">
              <IonLabel className="gradeHeader stationsHeader">
                {t("stationWeight")}
              </IonLabel>
            </IonCol>
            <IonCol size="2.58" className="stThresholdCol">
              <IonLabel className="thresholdHeader stationsHeader">
                {t("thresholdGrade")}
              </IonLabel>
            </IonCol>
            <IonCol size="2.58"></IonCol>
            <IonCol size="1.33"></IonCol>
          </IonRow>
        </IonCol>
      </IonRow>

      <IonRow className="stationsRow">
        {localStations &&
          localStations
            .filter((station) => station.indicatorId === indicatorId)
            .map((station, index) => (
              <Station
                key={station.id}
                station={station}
                stationIndex={index}
                updateStation={updateStation}
                setIsUnsavedPlan={setIsUnsavedPlan}
                currentElements={currentElements}
              ></Station>
            ))}
      </IonRow>
    </IonCol>
  );
};

export default Stations;
