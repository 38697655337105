import { AxiosResponse } from "axios";
import axios from "../Axios";
import { APIs } from "../Configurations/urls";
import IShootingHeat from "../Interfaces/IShootingHeat";
import IShootingDay from "../Interfaces/IShootingDay";

const getAllShootingDays = async (
  isMobile?: boolean
): Promise<AxiosResponse<IShootingDay[] | undefined>> => {
  try {
    return await axios.get(APIs.stations.shootingRange.shootingDays.getAll(), {
      params: { isMobile: isMobile },
    });
  } catch (error) {
    throw error;
  }
};

const getAllHeats = async (): Promise<
  AxiosResponse<IShootingHeat[] | undefined>
> => {
  try {
    return await axios.get(
      APIs.stations.shootingRange.shootingDays.heats.getAll()
    );
  } catch (error) {
    throw error;
  }
};

const getHeatsByShootingDayId = async (
  shootingDayId: number
): Promise<AxiosResponse<IShootingHeat[] | undefined>> => {
  try {
    return await axios.get(
      APIs.stations.shootingRange.shootingDays.heats.getByShootingDayId(
        shootingDayId
      )
    );
  } catch (error) {
    throw error;
  }
};

const getHeatById = async (
  shootingDayId: number,
  heatId: number
): Promise<AxiosResponse<IShootingHeat | undefined>> => {
  try {
    return await axios.get(
      APIs.stations.shootingRange.shootingDays.heats.getHeatById(
        shootingDayId,
        heatId
      )
    );
  } catch (error) {
    throw error;
  }
};

const getShootingDayById = async (
  shootingDayId: number
): Promise<AxiosResponse<IShootingDay | undefined>> => {
  try {
    return await axios.get(
      APIs.stations.shootingRange.shootingDays.getShootingDayById(shootingDayId)
    );
  } catch (error) {
    throw error;
  }
};

export {
  getAllShootingDays,
  getHeatsByShootingDayId,
  getHeatById,
  getShootingDayById,
  getAllHeats,
};
