import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
  memo,
} from "react";
import "./PerformanceTrends.css";
import { IonRow } from "@ionic/react";
import IndicatorBarChart from "../../../../../components/Desktop/IndicatorBarChart/IndicatorBarChart";
import ILabel from "../../../../../Interfaces/ILabel";
import IForceTreeNode from "../../../../../Interfaces/IForceTreeNode";
import { useTranslation } from "react-i18next";
import IForceFromOrbat from "../../../../../Interfaces/IForceFromOrbat";
import IFinalGradeDisplay from "../../../../../Interfaces/IFinalGradeDisplay";
import FinalGradeDisplay from "../../../../../components/Shared/FinalGradeDisplay/FinalGradeDisplay";
import IPlan from "../../../../../Interfaces/IPlan";
import IThreshold from "../../../../../Interfaces/IThreshold";
import ITrendIndicator from "../../../../../Interfaces/ITrendIndicator";

import MultiSelectDropdown from "../../../../../components/Desktop/MultySelectDropdown/MultiSelectDropdown";
import ISelectionOption from "../../../../../Interfaces/ISelectionOption";
import useGetPerformanceTrends from "../../../../../components/CustomHooks/UseGetPerformaneTrends";
import EIconsSrc from "../../../../../Interfaces/EIconsSrc";
import PMIcon from "../../../../../components/themeComponents/PMIcon";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";
import PerformanceTemplate from "../../performanceTemplate";
interface IProps {
  orbatForces: IForceTreeNode[];
  checkedForces: IForceFromOrbat[];
  setChecked: (forces: IForceTreeNode[]) => void;
  isAdmin?: boolean | true;
  plan: IPlan;
  thresholds: IThreshold[];
  labels: ILabel[];
  newForces: IForceFromOrbat[];
  forcesToRemove: IForceFromOrbat[];
  finalGrades: IFinalGradeDisplay[];
  indicators: ITrendIndicator[];
  setCompetencyFinalGrades: Dispatch<SetStateAction<IFinalGradeDisplay[]>>;
  isDrawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PerformanceTrends: FC<IProps> = (props: IProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const {
    orbatForces,
    setChecked,
    plan,
    thresholds,
    labels,
    forcesToRemove,
    checkedForces,
    newForces,
    finalGrades,
    indicators,
    setCompetencyFinalGrades,
    isDrawerOpen,
    setDrawerOpen,
  } = props;
  const [swiperRef, setSwiperRef] = useState<any>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedLabels, setSelectedLabels] = useState<ILabel[]>(
    [] as ILabel[]
  );
  const [currentSwiperPage, setCurrentSwiperPage] = useState<number>(0);
  const [myNewForces, setMyNewForces] = useState<IForceFromOrbat[]>(
    [] as IForceFromOrbat[]
  );
  useEffect(() => {
    setMyNewForces((prev) => {
      if (!prev) return checkedForces;
      return newForces;
    });
  }, [newForces]);

  const getSelectionPlans = () => {
    let tempArray: ISelectionOption[] = [];
    labels?.map((plan: ILabel) => {
      tempArray.push({ option: plan, isSelected: true });
    });
    return tempArray;
  };
  const [selectedPlans, setSelectedPlans] = useState<ISelectionOption[]>(
    getSelectionPlans()
  );
  const [lineOrBarTypeToggle, setLineOrBarTypeToggle] = useState<
    "line" | "scatter"
  >("scatter");
  const { trendsBarChartsData, setIsToRefresh } = useGetPerformanceTrends(
    plan,
    labels?.map((plan) => plan.plan),
    myNewForces,
    forcesToRemove,
    checkedForces,
    indicators,
    setLoading,
    undefined,
    undefined,
    setCompetencyFinalGrades
  );

  useEffect(() => {
    setSelectedPlans(getSelectionPlans());
  }, [labels]);

  useEffect(() => {
    let newLabels: ILabel[] = [];
    selectedPlans.map((plan) => {
      if (plan.isSelected) newLabels.push(plan.option);
    });
    let isChanged = false;
    selectedLabels?.map((label) => {
      let newLabel = newLabels.find(
        (newLabel) => Number(newLabel.id) === Number(label.id)
      );
      if (!newLabel) isChanged = true;
    });
    newLabels?.map((label) => {
      let newLabel = selectedLabels?.find(
        (newLabel) => newLabel.id === label.id
      );
      if (!newLabel) isChanged = true;
    });

    if (isChanged || !selectedLabels?.length) {
      setMyNewForces((prev) => {
        return JSON.stringify(prev) === JSON.stringify(checkedForces)
          ? prev
          : checkedForces;
      });
      setSelectedLabels(newLabels);
    }
  }, [selectedPlans]);
  const chart = (
    <div className="slidesWrap">
      {indicators.length > 0 && (
        <div className="graph-colT">
          <div className="barT">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              allowTouchMove
              loop
              onSlideChange={(event) => {
                setCurrentSwiperPage(event.realIndex);
              }}
              onSwiper={setSwiperRef}
            >
              {indicators.map((indicator: ITrendIndicator) => (
                <SwiperSlide key={indicator.id}>
                  <div className="trendSlide" key={indicator.id}>
                    <IndicatorBarChart
                      key={indicator.id}
                      indicatorId={indicator.id}
                      thresholds={thresholds}
                      labels={selectedLabels}
                      selectedForces={checkedForces}
                      newForces={myNewForces}
                      forcesToRemove={forcesToRemove}
                      barChartsData={trendsBarChartsData}
                      isDesktop={true}
                      plan={plan}
                      trends
                      lineOrBarTypeToggle={lineOrBarTypeToggle}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="arrows">
              <IonRow class="ion-justify-content-center">
                <PMIcon
                  isButton
                  iconSrc={
                    i18n.language === "he"
                      ? EIconsSrc.CHEVRON_RIGHT
                      : EIconsSrc.CHEVRON_LEFT
                  }
                  onClick={() => swiperRef.slidePrev()}
                  size="xLarge"
                  color="light"
                />
                <PMIcon
                  isButton
                  iconSrc={
                    i18n.language === "he"
                      ? EIconsSrc.CHEVRON_LEFT
                      : EIconsSrc.CHEVRON_RIGHT
                  }
                  onClick={() => swiperRef.slideNext()}
                  size="xLarge"
                  color="light"
                />
              </IonRow>
            </div>
          </div>
        </div>
      )}
    </div>
  );
  return (
    <PerformanceTemplate
      key={"trends"}
      loadingText={t("loadingData")}
      title={t(indicators[currentSwiperPage]?.name)}
      chart={chart}
      finalGrades={finalGrades}
      isDrawerOpen={isDrawerOpen}
      loading={loading}
      onClickFirstButton={() => {
        setLineOrBarTypeToggle((prev) =>
          prev === "scatter" ? "line" : "scatter"
        );
      }}
      firstButtonText={t(
        lineOrBarTypeToggle === "scatter" ? "line" : "scatter"
      )}
      onClickSecondButton={() => setIsToRefresh(true)}
      secondButtonText={t("refresh")}
      trendsDropdown={
        <MultiSelectDropdown
          selectionPotions={selectedPlans}
          setChecked={setSelectedPlans}
          title={t("chooseTrainingPlans")}
          placeHolder={t("searchPlan")}
        />
      }
      selectedPlan={plan}
      setChecked={setChecked}
      setDrawerOpen={setDrawerOpen}
      orbatForces={orbatForces}
    ></PerformanceTemplate>
  );
};

export default memo(PerformanceTrends);
