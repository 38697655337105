import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { environment } from "../../../Configurations/consts";
import i18n from "../../../services/i18next";
import { routeRoles } from "../../../services/routeRoles";
import About from "../../Shared/About/About";
import PopoverMenu from "../../Shared/Popover/PopoverMenu";
import SecurityContact from "../../Shared/SecurityContact/SecurityContact";
import PMLabel from "../../themeComponents/PMLabel";
import PopoverItem from "../../themeComponents/PopoverItem";
import { useMsal } from "@azure/msal-react";
import { UserContext } from "../../../context/UserContext/userContext";
import Cookies from "universal-cookie";
import IPopoverState from "../../../Interfaces/IPopoverState";
import customToast from "../../Shared/Toast/CustomToast";
import { useTranslation } from "react-i18next";
import LastSiteSync from "../../Shared/LastSiteSync/LastSiteSync";
import ILastSync from "../../../Interfaces/ILastSync";
import ToggleThemeColor from "../../Shared/ToggleThemeColor/ToggleThemeColor";
import TrackingUsersLogins from "../../Shared/TrackingUsersLogins/TrackingUsersLogins";
import EEnvironment from "../../../Enums/EEnvironment";
import { getLastSync, syncData, comparePlans } from "../../../apis/SyncAPI";
import { AxiosResponse } from "axios";
import PlansDifferencesDetails from "../../Shared/PlansDifferencesDetails/PlansDifferencesDetails";

interface IHeaderMenuProps {
  setDarkMode: Dispatch<SetStateAction<boolean>>;
  darkMode: boolean;
  popoverState: IPopoverState;
  onDismiss: () => void;
  isMobile?: boolean;
  onComparePlansClicked?: () => Promise<void>;
}

const HeaderMenu = (props: IHeaderMenuProps) => {
  const { instance } = useMsal();
  const { user, logout } = useContext(UserContext);
  const cookie = new Cookies();
  const { t } = useTranslation();
  const [lastSynced, setLastSynced] = useState<ILastSync[]>([]);

  useEffect(() => {
    let mounted: boolean = true; // in order to ensure that the data wont be presented if "mounted" is false

    mounted && getLastSynced();
    return () => {
      mounted = false;
    };
  }, []);

  const logoutUser = () => {
    logout();
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  const getLastSynced = async () => {
    await getLastSync().then((response: AxiosResponse<ILastSync[]>) =>
      setLastSynced(response.data)
    );
  };

  const localSiteLogout = async () => {
    cookie.remove("user");
    logout();
    window.location.reload();
  };

  const synchronizeData = async () => {
    props.onDismiss();
    try {
      await syncData().then(() => {
        getLastSynced();
        customToast.success(t("syncDataSuccessMgs"));
      });
    } catch (error: any) {
      console.error(error);
      if (error.response.status === 429)
        customToast.error(t("syncServiceAlreadyInProgress"));
      else customToast.error(t("syncDataErrorMgs"));
    }
  };

  return (
    <PopoverMenu popoverState={props.popoverState} onDismiss={props.onDismiss}>
      {
        /* <PopoverItem
        fontColor="light"
        fontFamily="Light"
        fontSize="medium"
        background="Blight"
      >
        <ToggleThemeColor
          darkMode={props.darkMode}
          setDarkMode={props.setDarkMode}
        />
      </PopoverItem> */
        //Don't remove!! for now theme toggle is disabled
      }
      {environment && environment.toString() !== "localSite" ? (
        <PopoverItem
          fontColor="light"
          onClickHandler={logoutUser}
          background="Blight"
        >
          <PMLabel textAlign="center" fontFamily="Light" fontSize="medium">
            {t("logout")}
          </PMLabel>
        </PopoverItem>
      ) : (
        <PopoverItem
          fontColor="light"
          onClickHandler={localSiteLogout}
          background="Blight"
        >
          <PMLabel textAlign="center" fontFamily="Light" fontSize="medium">
            {t("logout")}
          </PMLabel>
        </PopoverItem>
      )}
      <PopoverItem
        fontColor="light"
        fontFamily="Light"
        fontSize="medium"
        background="Blight"
      >
        <SecurityContact />
      </PopoverItem>
      <PopoverItem
        fontColor="light"
        fontFamily="Light"
        fontSize="medium"
        background="Blight"
        onClickHandler={() => {
          let curentLang = localStorage.getItem("language");
          i18n.changeLanguage(curentLang === "he" ? "en" : "he");
          localStorage.setItem("language", curentLang === "he" ? "en" : "he");
        }}
      >
        <PMLabel
          textAlign="center"
          fontColor="light"
          fontFamily="Light"
          fontSize="medium"
        >
          {i18n.language === "he" ? "English" : "עברית"}
        </PMLabel>
      </PopoverItem>
      <PopoverItem
        fontColor="light"
        fontFamily="Light"
        fontSize="medium"
        background="Blight"
      >
        <About />
      </PopoverItem>
      {!props.isMobile ? (
        <>
          {environment === EEnvironment.production ? (
            <PopoverItem
              fontColor="light"
              fontFamily="Light"
              fontSize="medium"
              background="Blight"
            >
              <TrackingUsersLogins />
            </PopoverItem>
          ) : null}
          {environment === EEnvironment.localSite ? (
            <>
              <PopoverItem
                background="Blight"
                onClickHandler={props.onComparePlansClicked}
              >
                <PMLabel
                  textAlign="center"
                  fontColor="light"
                  fontFamily="Light"
                  fontSize="medium"
                >
                  {t("comparePlans")}
                </PMLabel>
              </PopoverItem>
            </>
          ) : null}
          <PopoverItem
            fontColor="light"
            fontFamily="Light"
            fontSize="medium"
            background="Blight"
            onClickHandler={() => getLastSynced()}
          >
            <LastSiteSync lastSynced={lastSynced} />
          </PopoverItem>
          {routeRoles.Admins.includes(user.role) &&
          environment === EEnvironment.localSite ? (
            <>
              <PopoverItem
                background="BxLight"
                onClickHandler={() => synchronizeData()}
              >
                <PMLabel
                  textAlign="center"
                  fontColor="light"
                  fontFamily="Light"
                  fontSize="medium"
                >
                  {t("syncData")}
                </PMLabel>
              </PopoverItem>
            </>
          ) : null}
        </>
      ) : (
        <></>
      )}
    </PopoverMenu>
  );
};

export default HeaderMenu;
