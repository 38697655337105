import React, { FC } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import PerformanceDisplay from "./PerformanceDisplay";

const PerformanceDisplayQCP: FC = (): JSX.Element => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <PerformanceDisplay></PerformanceDisplay>
    </QueryClientProvider>
  );
};

export default PerformanceDisplayQCP;
