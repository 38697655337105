import React, { useState } from "react";
import { environment } from "../../Configurations/consts";
import EEnvironment from "../../Enums/EEnvironment";
import IForceTreeNode from "../../Interfaces/IForceTreeNode";
import IUser from "../../Interfaces/IUser";
import { routeRoles } from "../../services/routeRoles";
import { addRoomIO } from "../../services/socketIo";

interface IUserContext {
  user: IUser;
  setUser: (user: IUser) => void;
  login: (user: IUser) => void;
  logout: () => void;
  setTaggingMode: () => void;
  isLoggedIn: boolean | "unknown";
  authTp: () => void;
  authBISTUrban: () => void;
  isTpAuthed: boolean;
  isBISTUrbanAuthed: boolean;
  isTaggingActive: boolean;
  isAdmin: boolean;
  isInstructor: boolean;
  force: IForceTreeNode;
  setForce: (force: IForceTreeNode) => void;
  darkMode: boolean;
  setDarkMode: (state: boolean) => void;
  toggleDarkModeHandler: () => void;
}

export const UserContext = React.createContext<IUserContext>({
  setDarkMode: () => {},
  toggleDarkModeHandler: () => {},
  login: () => {},
  setUser: () => {},
  logout: () => {},
  authTp: () => {},
  authBISTUrban: () => {},
  setTaggingMode: () => {},
  force: {} as IForceTreeNode,
  setForce: () => {},
  user: {
    role: "Unauthorized",
    displayName: "",
    id: -1,
    emailAddress: "",
    relatedForce: undefined,
    forceToDisplayInOrbat: { id: -1, forceType: "" },
  },
  isLoggedIn: false,
  isTpAuthed: false,
  isBISTUrbanAuthed: false,
  isTaggingActive: JSON.parse(
    String(localStorage.getItem("taggingConnection"))
  ),
  isAdmin: false,
  isInstructor: false,
  darkMode: true,
});

const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<IUser>({
    role: "Unauthorized",
    displayName: "",
    id: -1,
    emailAddress: "",
    relatedForce: undefined,
    forceToDisplayInOrbat: { id: -1, forceType: "" },
  });
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isTpAuthed, setIsTpAuthed] = useState<boolean>(
    environment?.toString() === EEnvironment.localSite ? false : true
  );
  const [isBISTUrbanAuthed, setIsBistUrbanAuthed] = useState<boolean>(false);
  const [isTaggingActive, setIsTaggingActive] = useState<boolean>(
    JSON.parse(String(localStorage.getItem("taggingConnection")))
  );
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isInstructor, setIsInstructor] = useState<boolean>(false);
  const [force, setForce] = useState<IForceTreeNode>({} as IForceTreeNode);
  const [darkMode, setDarkMode] = useState<boolean>(true);

  const login = (user: IUser) => {
    if (user.role === routeRoles.Unauthorized[0]) return;
    setUser(user);
    if (environment?.toString() !== "production") {
      addRoomIO("check-in-out-room");
      addRoomIO("register-room");
      addRoomIO("tagging-room");
    }
    if (user.role === routeRoles.Admins[0]) setIsAdmin(true);
    if (user.role === routeRoles.Instructors[0]) setIsInstructor(true);
    setIsLoggedIn(true);
  };

  const logout = () => {
    setUser({
      role: "",
      displayName: "",
      id: -1,
      emailAddress: "",
      relatedForce: undefined,
      forceToDisplayInOrbat: { id: -1, forceType: "" },
    });
    setIsLoggedIn(false);
    setIsAdmin(false);
    if (localStorage.getItem("user")) localStorage.removeItem("user");
  };

  const authTp = () => {
    setIsTpAuthed(true);
  };
  const authBISTUrban = () => {
    setIsBistUrbanAuthed(true);
  };

  const setTaggingMode = () => {
    localStorage.setItem("taggingConnection", String(!isTaggingActive));
    setIsTaggingActive((prev: boolean) => !prev);
  };

  // Sets theme
  const toggleDarkModeHandler = () => {
    document.body.classList.toggle("dark-theme");
    setDarkMode((prev) => {
      if (prev) localStorage.setItem("theme", "light");
      else localStorage.setItem("theme", "dark");
      return !prev;
    });
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isLoggedIn,
        login,
        logout,
        authTp,
        authBISTUrban,
        isBISTUrbanAuthed,
        isTpAuthed,
        isTaggingActive,
        setTaggingMode,
        isAdmin,
        isInstructor,
        force,
        setForce,
        darkMode,
        setDarkMode,
        toggleDarkModeHandler,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
