import React, { FC } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import ReportGenerator from "./ReportGenerator";

const ReportGeneratorQCP: FC = (): JSX.Element => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ReportGenerator></ReportGenerator>
    </QueryClientProvider>
  );
};

export default ReportGeneratorQCP;
