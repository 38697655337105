import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

/*  HOW TO USE IN COMPONENTS:
      1. Add a translation here in the wanted language json
         name:"שם"
      2. In the component to want to translate something, add the useTranslation hook
         import {useTranslation} from 'react-i18next'
      3. Create a const to handle the translation
         const {t} = useTranslation();
      4. Send the name of the translation
         t("name");

    Example for interpolating value in translation:
    "Name": {"1":"שלום, {{username}}"}
*/
const Languages = ["he", "en"];

const resources = {
  he: {
    translation: {
      hello: "שלום",
      name: "שם",
      qualifications: "כשירות מבצעית",
      details: "פירוט תחנות",
      trends: "מגמות",
      leaders: "מובילים",
      urban: 'לש"ביה דירה',
      magnet: 'לש"ביה מגנט',
      iest: "ירי תגובתי",
      panorama: "ירי היקפי",
      overallTime: "זמן מצטבר",
      send: "שלח",
      threatNeutralized: "ניטרול האיום",
      securing: "אבטחה",
      stopBleeding: "עצירת דימום",
      report: "דיווח",
      siteManagement: "פריסת מתאמנים",
      overallInSite: 'סה"כ מתאמנים באתר:',
      lastUpdate: "עודכן לאחרונה ב - ",
      online: "מעודכן",
      militaryId: "מספר אישי",
      search: "חפש",
      notFound: "לא נמצא החייל",
      unitsTitle: "יחידות",
      individualsTitle: "לוחמים",
      trainingRadio: "אימונים",
      testRadio: "בחנים",
      addSoldierToStation: "בחר חייל",
      OK: "אשר",
      traineesList: "חיילים בתחנה",
      remove: "הסר",
      lethality: "קטלניות",
      Lethality: "קטלניות",
      combatCapacity: "כושר לחימה",
      export: "ייצא",
      deleteQues: "האם אתה בטוח שברצונך להסיר את",
      loadingData: "טוען נתונים...",
      fromStation: "מהתחנה",
      leadersMobileTitle: "כשירות מבצעית",
      checkout: "בצע CHECKOUT ",
      refresh: "רענן",
      tooltipRemoveSoldier: "לחץ להסרת חייל מתחנה",
      date: "תאריך",
      forcesTree: "עץ מתאמנים",
      mrk: "מטווח סימולטיבי",
      medical: "טיפול רפואי",
      treadmill: 'מסילת חד"כ',
      liveShooting: "מטווח חי",
      weaponType: "סוג נשק",
      numberOfShots: "מספר יריות",
      numberOfHits: "מספר פגיעות",
      score: "ניקוד",
      level: "רמה",
      grouping: "מקבץ",
      lane: "מסלול",
      soldierName: "שם חייל",
      lastHeat: "מקצה אחרון",
      sendingData: "שולח נתונים...",
      reset: "איפוס",
      dataSendSuccess: "הנתונים נשלחו בהצלחה",
      dataSendError: "תקלה בשליחת הנתונים",
      lethalityThreshold: "רף קטלניות",
      lethalityMeasurements: "מדדי הקטלניות",
      cancel: "בטל",
      save: "שמור",
      cancelChangesAlert: "האם אתה בטוח שברצונך לבטל את השינויים שבוצעו?",
      examName: "שם בוחן",
      trainingName: "שם אימון",
      addExam: "הוסף בוחן",
      addTraining: "הוסף אימון",
      deletePlanAlert: "האם אתה בטוח שברצונך למחוק תכנית זאת?",
      duplicatePlanAlert: "האם אתה בטוח שברצונך לשכפל תכנית זאת?",
      setPlanActiveAlert:
        "האם אתה בטוח שברצונך להגדיר את התוכנית הזו כתוכנית פעילה?",
      add: "הוסף",
      loadingPlans: "טוען תכניות...",
      station: "תחנה",
      weight: "משקל",
      thresholdGrade: "ציון סף למעבר",
      measurement: "מדד",
      measurementWeight: "משקל מדד",
      gradeElement: "מרכיב ציון",
      elementWeight: "משקל מרכיב",
      lowerThreshold: "רף תחתון",
      upperThreshold: "רף עליון",
      grade: "ציון (1-10)",
      "קרב מגע": "קרב מגע",
      קליעה: "קליעה",
      "כושר קרבי": "כושר קרבי",
      addMeasurement: "הוסף מדד",
      thresholdForOperationalQualification: "רף כשירות מבצעית",
      noActivePlanSelected: "לא נבחרה תוכנית פעילה",
      activePlan: "תוכנית פעילה:",
      compareBetweenForces: "הצג כוחות להשוואה",
      select: "בחר",
      settings: "הגדרות",
      stations: "תחנות",
      performance: "כשירות",
      forceUpdateSuccessMsg: "הנתונים עודכנו בהצלחה",
      forceAddedSuccessfullyMsg: "המתאמן נוסף בהצלחה",
      soldierIdMissingAlert: "יש להזין מספר אישי",
      tagIdExixstAlert:
        "שים לב!  מזהה הצמיד כבר קיים בעץ הכוחות. האם ברצונך לדרוס את הנתון עם הערך החדש?",
      forceHasTagIdAlert:
        "השדה מכיל נתון קודם, האם ברצונך לדרוס את הנתון עם הערך החדש?",
      newForce: "מתאמן חדש",
      forcesNotExist: "לא קיימים כוחות",
      forcesNotAssigned: "אין עבורך כוח משוייך",
      maxChoosingHeader1: "ניתן לבחור עד",
      maxChoosingHeader2: "כוחות",
      cancelChoosing: "עליך לבטל בחירה קיימת בכדי להוסיף את הבחירה המבוקשת.",
      verifyingDeletionAlert: "האם אתה בטוח שברצונך למחוק את",
      reorderAlert1: "האם אתה בטוח שברצונך לשבץ את",
      reorderAlert2: "תחת",
      fileProcessing: "מעבד קובץ",
      checkoutFailed: "תקלה בעת ביצוע CHECKOUT עבור",
      checkoutSucceed: "CHECKOUT בוצע בהצלחה עבור",
      personalInfo: "נתונים אישיים",
      personalId: "מספר ת.ז.",
      tagId: "מזהה צמיד",
      weaponDetails: "פרטי נשק",
      weaponId: "מס' נשק",
      weapon_id: "מס' נשק",
      simulatedWeaponType: "סוג נשק סימולטיבי",
      weaponSightType: "סוג כוונת",
      showForces: "הצג כוחות להשוואה",
      siteHeader: "אבק דיגיטלי",
      settingsPage: "הגדרות",
      orbatTreePage: "הגדרת עץ מתאמנים",
      PlansPage: "תוכניות הכשרה",
      siteManagementPage: "פריסת מתאמנים",
      DataPostingPage: "הזנת תוצאות",
      stationPage: "תחנות",
      leadersPage: "מובילים",
      trendsPage: "מגמות",
      historyPage: "היסטורית תוצאות",
      trainingPlan: "תוכנית הכשרה",
      trainingPlans: "תוכניות הכשרה",
      arrowIcon: "chevronBackOutline",
      logout: "התנתק",
      security: "אבטחה",
      detailsHeader: "פרטים",
      weaponSightID: "מס' כוונת",
      ForceType: "סוג כוח",
      noSelectedSoldier: "לא נבחר חייל",
      startTooltipAlign: "right",
      chooseTrainingPlans: "בחר תוכניות הכשרה",
      invalidPostData: "קיימים שדות לא תקינים",
      avgGrade: "ציון ממוצע",
      invalidInput: "קלט לא תקין",
      soldierNotFound: "חייל לא נמצא",
      errorToLoadData: "תקלה בשליפת נתוני החיילים",
      "מספר מקצים": "מספר מקצים", //tooltip data
      "סוג נשק": "סוג נשק",
      "כמות יריות": "כמות יריות",
      "ממוצע יריות": "ממוצע יריות",
      "ממוצע פגיעות": "ממוצע פגיעות",
      "ממוצע החטאות": "ממוצע החטאות",
      "ממוצע זמן ביצוע תרגיל": "ממוצע זמן ביצוע תרגיל",
      "זמן עצירת דימום": "זמן עצירת דימום",
      "ממוצע מטרות שנוטרלו": "ממוצע מטרות שנוטרלו",
      "ממוצע מטרות בתרגיל": "ממוצע מטרות בתרגיל",
      "ממוצע מקבצים": "ממוצע מקבצים",
      "מקבץ מוביל": "מקבץ מוביל",
      "אחוז פגיעה": "אחוז פגיעה",
      "ניקוד ממוצע": "ניקוד ממוצע",
      "מקצה אחרון": "מקצה אחרון",
      "מקצה אחרון (בסוגריים - הרמה המקסימלית)":
        "מקצה אחרון (בסוגריים - הרמה המקסימלית)",
      זיהוי: "זיהוי",
      "זמן תגובה": "זמן תגובה",
      "מיקוד וריכוז": "מיקוד וריכוז",
      "ראייה מרחבית": "ראייה מרחבית",
      "": "",
      "ממוצע מטרות שהושמדו": "ממוצע מטרות שהושמדו",
      "ממוצע פגיעות באויב": "ממוצע פגיעות באויב",
      "ממוצע שחיקה": "ממוצע שחיקה",
      "ממוצע מטרות": "ממוצע מטרות",
      "עוצמה מצטברת ממוצעת": "עוצמה מצטברת ממוצעת",
      "ממוצע זמן ביצוע": "ממוצע זמן ביצוע",
      "ממוצע נטרול האיום": "ממוצע נטרול האיום",
      "ממוצע אבטחה": "ממוצע אבטחה",
      "ממוצע עצירת דימום": "ממוצע עצירת דימום",
      "ממוצע דיווח": "ממוצע דיווח",
      "אחוז מבצעים": "אחוז מבצעים",
      "לוחמה בשטח בנוי": "לוחמה בשטח בנוי",
      "מספר פגיעות": "מספר פגיעות",
      "זמן מצטבר": "זמן מצטבר",
      "עוצמה מצטברת": "עוצמה מצטברת",
      ירי: "ירי",
      לחימה: "לחימה",
      קוגניטיבי: "קוגניטיבי",
      קטלניות: "קטלניות",
      קוגנטיבי: "קוגנטיבי",
      standardDeviation: "סטיית תקן",
      searchPlan: "חפש תוכנית",
      selectStartTime: "תחילת אימון",
      selectEndTime: "סיום אימון",
      percentage: "אחוזים",
      אחוזים: "%",
      units: "יחידות",
      amount: "כמות",
      score: "ניקוד",
      seconds: "שניות",
      targets: "מטרות",
      rounds: "חזרות",
      hits: "פגיעות",
      tooltipPosition: "left",
      hours: "שעות",
      trainName: "שם אימון",
      testName: "שם בוחן",
      downloadFile: 'יוצר דו"ח',
      closeModal: "סגור",
      enterPassword: "הזן סיסמת אדמין",
      enterSuperUserPassword: "הקש סיסמת Super User",
      submit: "הזן",
      start: "התחל",
      stop: "עצור",
      trainingDate: "תאריך אימון",
      testDate: "תאריך בוחן",
      finalGrade: "ציון מסכם",
      excellent: "מצטיין",
      veryGoog: "טוב מאוד",
      goodGrade: "טוב",
      notGoodGrade: "לא עומד בהישג הנדרש",
      achievementRequired: "הישג נדרש",
      presentDetails: "לפירוט תחנות לחץ על העמודה",
      notFoundPlanText: "תוכנית ההכשרה עדיין ריקה ממדדים",
      wrongPassword: "סיסמה שגויה",
      duplicate: "שכפל לכל השורות",
      edit: "ערוך",
      delete: "מחק",
      setActive: "הפוך לפעילה",
      dataHistory: "היסטוריית תוצאות",
      searchTrainee: "סנן מתאמנים",
      enterNamesOrId: "שם או מספר אישי",
      mustSelectForce: "חובה לבחור כוח",
      Date: "תאריך",
      Hour: "שעה",
      soldierId: "מספר אישי",
      Duration: "משך זמן",
      NumOfTargets: "כמות מטרות",
      NumOfShots: "כמות יריות",
      RedHits: "כמות פגיעות",
      CleanTargets: "מטרות נקיות",
      TargetsDestroyed: "מטרות שהושמדו",
      DamagePercentage: "אחוז שחיקה",
      SumOfHitsPower: "סך עוצמת פגיעות",
      NumOfHits: "כמות מכות",
      StrongestHitPower: "עוצמת פגיעה מקסימלית",
      Topic: "נושא",
      Level: "רמה",
      LaneID: "מספר מסלול",
      Grouping: "מקבץ",
      Score: "ניקוד",
      LastHeat: "מקצה אחרון",
      Neutralization: "נטרול האיום",
      Secure: "אבטחה",
      StopBleeding: "עצירת דימום",
      Report: "דיווח",
      WeaponType: "סוג נשק",
      RowId: `מס' שורה`,
      allDay: "כל היום",
      stationWeight: "משקל תחנה",
      checkoutItc: "הסר מתאמנים ממאמן כושר הלחימה",
      removeItcQuestion:
        "על מנת להסיר את המתאמנים ממאמן כושר הלחימה הקש סיסמת אדמין",
      checkoutITCSucceed: "מתאמנים הוסרו מהמאמן בהצלחה.",
      checkoutITCFailed: "שגיעה בעת הסרת מתאמנים מהמאמן.",
      mustSelectForce: "חובה לבחור כוח",
      DownloadFileSuccessfully: 'דו"ח הופק בהצלחה',
      DownloadFileUnsuccessfully: 'שגיאה בהנפקת דו"ח',
      indicatorsGrades: "ציוני מדדים:",
      traineeReport: 'דו"ח מתאמן',
      generalReport: 'דו"ח מתאמנים',
      selectHashtags: "תיוגים",
      selectTooltips: "נתוני תחנות",
      selectGraphs: "גרפים",
      selectIndicatorsAndTrainingTypesDetails: "ציוני מדדים ותחנות",
      hashtags: "תיוגים",
      tooltips: "נתוני תחנות",
      graphs: "גרפים",
      indicatorsAndTrainingTypesDetails: "ציוני מדדים ותחנות",
      "נשק מחלקתי": "נשק מחלקתי",
      taggingMode: "Tagging Mode",
      on: "פעיל",
      off: "כבוי",
      notAttended: "לא ניגש",
      urbanInstructorFeedback: 'הערכת מדריך לש"ב',
      Practice: "תרגולת",
      Practice1:
        "עבודה על פי 3 דגשי טיהור: אבטחת הכוח, חיפויים הדדיים, בידוד שטחים, עקרון הדחיקה",
      Practice2: "עבודת נצרה נכונה ובטיחות",
      Practice3: "פתיחת חדר על פי דגשי תרגולת פתחים",
      Practice4: "לוחמת הפרט: קו קנה, קו מבט, מיקום האצבע וכו'",
      Practice5: "תרגולת זריקת רימון",
      Practice6: 'פק"ל קנים בחוליה',
      Practice7: "מיצוי כלל הזוויות בסריקה",
      Covering: "חיפויים",
      Covering1: "צמצום חציית קו קנה",
      Covering2: "מיקום החיפוי בהתאם למיקום האיום",
      Covering3: "בידוד שטחים וריכוז מאמן על מוקד אחד",
      Covering4: "חוליה אחרונה - חיפוי לאחור",
      Covering5: "חיפויים דינמיים במקרה של גמר אפשרויות",
      Decisions: "קבלת החלטות",
      Decisions1:
        "עבודה על פי סדר עדיפויות בלחימה: אויב בעין, איום קרוב ממד גובה, איום רחוק",
      Decisions2: "מ.ח פוקד על החיילים",
      Decisions3: "במקרה של פצוע, הסרת האיום - אפשרות לטפל תוך כדי הסרת האיום",
      Decisions4: 'מיצוי האמל"ח בחוליה',
      Decisions5: "ריבוי פצועים - החלטה על המשך לחימה או עצירה",
      Decisions6: "עיבוי כוחות במקרה הצורך",
      Sequence: "רצף לחימה",
      Sequence1: "טיהור כלל החדרים לפני התקדמות",
      Sequence2: "לחימה שוטפת - לא למהר מדי, לא לתקוע את הלחימה",
      Sequence3: "תגובות מיידיות לאיום מיידי",
      Sequence4:
        "בניית נתיב התקדמות - מהקרוב לרחוק, מהקל אל הקשה (על פי עקרון הדחיקה)",
      Sequence5: "ערנות לנסיון אויב למשוך את כוחותינו (לא לרוץ להתקלה)",
      CommandAndControl: 'פו"ש',
      CommandAndControl1: "פקודות קצרות ותכליתיות",
      CommandAndControl2: "דיווח לרמה ממונה",
      CommandAndControl3: "הפעלת מפקדי משנה",
      CommandAndControl4: "בחירת תרגולת מתאימה לסיטואציה",
      CommandAndControl5: "חלוקת גזרות לחיפוי ותנועה",
      CommandAndControl6: "העברת תמונות למצב כוח",
      CommandAndControl7: 'פק"ל סוף הסתערות ולחימה',
      guest: "אורח",
      filter: "סנן תיוגים",
      noHashtagsFound: "לא נמצאו תיוגים",
      maximumIndicators: "כמות מדדים מקסימלית",
      clearHashtagsFilter: "נקה בחירה",
      notNullMsg1: "השדה",
      notNullMsg2: "לא יכול להיות ריק",
      soldierIdLengthAlert: "מספר אישי חייב להיות בעל 7 ספרות",
      deleteForceMsg: "הכוח נמחק בהצלחה",
      addBtnTooltip: "הוסף כוח",
      ImportBtnTooltip: "יבא כוחות",
      ReorderBtnTooltip: "שנה סדר עץ הכוחות",
      rank: "דירוג:",
      חטיבה: "חטיבה",
      'בא"ח': 'בא"ח',
      כיתה: "כיתה",
      מחלקה: "מחלקה",
      פלוגה: "פלוגה",
      גדוד: "גדוד",
      קבוצה: "קבוצה",
      notificationsPage: "התראות והמלצות",
      trainingPage: "הכשרות",
      selectPlan: "בחר תכנית",
      rowDeletedSuccessfully: "המחיקה בוצעה בצלחה",
      errorWhileDeleteRow: "שיגאה במהלך ביצוע המחיקה",
      deleteSuperUser: "הקש סיסמת Super User למחיקה",
      deleteDataHistoryRow: "מחיקת שורות",
      failedLoadOrbatTreeMsg: "תקלה בעת טעינת עץ הכוחות",
      selectionsOptions: 'פרטי מידע לדו"ח',
      selectAll: "בחר הכל",
      clearAll: "נקה הכל",
      alignment: "right",
      resoreForceAlertMsg:
        "המס' האישי שהוזן שייך לכוח שנמחק. האם ברצונך לשחזר אותו?",
      resoreForceAlertSubMsg:
        "שים לב! פעולה זו תשחזר את הציונים והמידע של החייל שנמחק",
      importTreeSucceededMsg: "הכוחות יובאו בהצלחה",
      importTreeErrorMsg: "מספר כוחות נכשלו בהזנה. אנא בדוק את תקינות הנתונים",
      duplicateTags: "שכפל תיוגים",
      importTreeErrorMsg:
        "מספר כוחות נכשלו בהזנה. אנא בדוק את תקינות הנתונים הנמצאים בקובץ שירד",
      manageUsers: "ניהול משתמשים",

      // TODO: need to get funcionalities and make sure its disabled correctly
      // also find other input fields where is disabled doesn't work correctly
      addUserHeader: "הוספת משתמש חדש",
      userId: "תעודת זהות",
      role: "רמת הרשאה",
      displayName: "שם",
      usersList: "רשימת משתמשים",
      loadingUsers: "טוען משתמשים...",
      deleteUser: "האם אתה בטוח שאתה רוצה למחוק את המשתמש?",
      userDeleteSuccess: "המשתמש נמחק בהצלחה",
      userDeleteFail: "תקלה במחיקת המשתמש",
      userAddSuccess: "המשתמש נוסף בהצלחה",
      userAddFail: "תקלה בהוספת המשתמש",
      getRolesError: "תקלה בשליפת ההרשאות",
      getRolesError: "תקלה בשליפת ההרשאות",
      Admin: "אדמין",
      Personal: "אישי",
      Instructor: "מדריך",
      Viewer: "צופה",
      emailAddress: "E-Mail",
      emailOrUsername: "E-Mail/Username",
      missingInformation: "אנא מלא את כל השדות",
      selectForces: "בחירת כוחות",
      selectForcesOptional: "בחירת כוחות (אופציונלי)",
      reportGenerator: 'מחולל דו"חות',
      selectAllHashtags: "בחר הכל",
      clearHashtags: "נקה בחירה",
      clickForDetails: "בחר מדד על מנת לראות את פירוט הביצועים בתחנות",
      commanderDashboard: "לוח מפקד",
      personalZone: "איזור אישי",
      soldier_id: "מס' אישי",
      weapon_id: "מס' נשק",
      weapon_sight_id: "מס' כוונת",
      already_exists: "כבר קיים",
      Editor: "עורך",
      pageUnauthorized: "אין הרשאה לגשת לעמוד המבוקש",
      noAccess: "אין גישה למערכת, פנה למנהל",
      selectForce: "בחר כוח",
      force: "כוח משוייך",
      invalidEmailAddress: "כתובת מייל לא תקינה",
      userName: "שם משתמש",
      password: "סיסמה",
      login: "התחבר",
      loginError: "תקלה בנסיון ההתחברות",
      loginSucceed: "התחברת למערכת בהצלחה",
      wrongCredentials: "שם משתמש או סיסמה שגויים",
      localUser: "משתמש אתר מקומי",
      indicatorsLoadError: "תקלה בשליפת המדדים",
      indicatorsSaveErrors: "תקלה בשמירת המדדים",
      orbatLoadError: "תקלה בעת טעינת עץ הכוחות",
      loadPlansError: "תקלה בשליפת התכניות",
      planSavedSuccessfull: "התכנית נשמרה בהצלחה",
      planAddedSuccessfull: "התכנית נוספה בהצלחה",
      errorWhileSavePlan: "תקלה בשמירת התכנית",
      planDeletedSuccessfull: "התכנית נמחקה בהצלחה",
      deletePlanError: "תקלה במחיקת התכנית",
      activetedPlanSuccessfull: "התכנית הוגדרה כפעילה",
      errorToActivetePlan: "תקלה בהגדרת התכנית כפעילה",
      planDuplicatedSuccefuly: "התכנית שוכפלה בהצלחה",
      errorDuplicatePlan: "תקלה בשכפול התכנית",
      'לש"ביה דירה': 'לש"ביה דירה',
      'לש"ביה מכולות': 'לש"ביה מכולות',
      "קרב מגע": "קרב מגע",
      "בובת קרב מגע": "בובת קרב מגע",
      'מסילת חד"כ': 'מסילת חד"כ',
      "ירי אישי": "ירי אישי",
      "ירי קוגניטיבי אישי": "ירי קוגניטיבי אישי",
      "ירי היקפי": "ירי היקפי",
      "ירי קוגניטיבי היקפי": "ירי קוגניטיבי היקפי",
      "ירי תגובתי": "ירי תגובתי",
      'לש"ביה מגנט': 'לש"ביה מגנט',
      "מטווח חי": "מטווח חי",
      "מטווח סימולטיבי": "מטווח סימולטיבי",
      "טיפול רפואי": "טיפול רפואי",
      'נמ"ח תגובתי': 'נמ"ח תגובתי',
      'נמ"ח סימולטיבי': 'נמ"ח סימולטיבי',
      'הערכת מדריך לש"ב': 'הערכת מדריך לש"ב',
      "מאמן כושר לחימה": "מאמן כושר לחימה",
      "מאמן אישי": "מאמן אישי",
      competencyPage: "כשירות",
      "נטרול איום": "נטרול איום", //elements
      אבטחה: "אבטחה",
      "עצירת דימום": "עצירת דימום",
      דיווח: "דיווח",
      "כמות פגיעות באויב": "כמות פגיעות באויב",
      "מיקוד וריכוז (רמה)": "מיקוד וריכוז (רמה)",
      "זמן תגובה (רמה)": "זמן תגובה (רמה)",
      "זיהוי (רמה)": "זיהוי (רמה)",
      "ראייה מרחבית (רמה)": "ראייה מרחבית (רמה)",
      "זיהוי (ציון משוקלל)": "זיהוי (ציון משוקלל)",
      "מיקוד וריכוז (ציון משוקלל)": "מיקוד וריכוז (ציון משוקלל)",
      "זמן תגובה (ציון משוקלל)": "זמן תגובה (ציון משוקלל)",
      "ראייה מרחבית (ציון משוקלל)": "ראייה מרחבית (ציון משוקלל)",
      ניקוד: "ניקוד",
      תרגולת: "תרגולת",
      'פו"ש': 'פו"ש',
      "קבלת החלטות": "קבלת החלטות",
      "ממוצע מקבץ": "ממוצע מקבץ",
      "רצף לחימה": "רצף לחימה",
      "אחוז מטרות שנוטרלו": "אחוז מטרות שנוטרלו",
      חיפויים: "חיפויים",
      "עוצמת פגיעה מצטברת": "עוצמת פגיעה מצטברת",
      "זמן ביצוע": "זמן ביצוע",
      "אחוז שחיקה מקסימאלי": "אחוז שחיקה מקסימאלי",
      "מספר חזרות": "מספר חזרות",
      "מספר לא ניגשים": "מספר לא ניגשים",
      "זמן ביצוע כולל": "זמן ביצוע כולל",
      "כדורים שנורו": "כדורים שנורו",
      reports: 'דו"חות',
      competency: "כשירות",
      notificationsAndRecommendations: "התראות והמלצות",
      מתאמן: "מתאמן",
      קבוצה: "קבוצה",
      טוראי: "טוראי",
      'רב"ט': 'רב"ט',
      סמל: "סמל",
      'סמ"ר': 'סמ"ר',
      'סג"מ': 'סג"מ',
      'רס"ל': 'רס"ל',
      סגן: "סגן",
      'רס"ן': 'רס"ן',
      אלוף: "אלוף",
      'רס"ר': 'רס"ר',
      'רס"ב': 'רס"ב',
      'רנ"מ': 'רנ"מ',
      'רנ"ג': 'רנ"ג',
      סרן: "סרן",
      'סא"ל': 'סא"ל',
      'אל"מ': 'אל"מ',
      'תא"ל': 'תא"ל',
      "רב אלוף": "רב אלוף",
      errorWhileLoadingData: "תקלה בשליפת הנתונים",
      noRelevantPlans: "לא קיימות תכניות עבור הכוח המבוקש",
      isForDashboard: "לוח מפקד",
      noSelectedForces: "לא נבחרו כוחות",
      noSelectedData: "לא נבחרו נתונים להזנה",
      noForceDataInDate: "אין נתונים לכוח זה בתאריך הנבחר עבור התוכנית הפעילה",
      noHeatsFound: "לא נמצאו מקצים",
      noHeatsExist: "לא קיימים מקצים",
      platoonAndUP: "לוח מפקד נועד למחלקה ומעלה",
      syncData: "סנכרן נתונים",
      syncDataSuccessMgs: "הסנכרון בוצע בהצלחה",
      syncDataErrorMgs: "תקלה בסנכרון הנתונים",
      indicators: "מדדים",
      numOfTargets: "כמות מטרות",
      numOfShots: "כמות יריות",
      numOfHits: "כמות פגיעות",
      numOfDestroyed: "כמות מטרות שהושמדו",
      exerciseName: "שם תרגיל",
      hashtagsExcName: "תיוגים (שם תרגיל)",
      fillMagnetError: "חובה להזין כמות מטרות וכמות מטרות שהושמדו",
      fillBISTUrbanError: "נא להזין את כל שדות החובה",
      editUserHeader: "עריכת פרטי משתמש",
      editUserSuccess: "פרטי המשתמש נשמרו",
      editUserError: "תקלה בעריכת פרטי המשתמש",
      editUser: "עדכון פרטי משתמש",
      update: "עדכן",
      trainingPlanTool: "שינוי תכנית הכשרה לנתונים",
      selectDestPlan: "בחר תכנית יעד",
      switchPlanSuccess: "הנתונים שויכו לתכנית ההכשרה החלופית",
      switchPlanFailure: "תקלה בהחלפת תכנית הכשרה",
      allPlans: "כלל תכניות ההכשרה",
      magazineDetails: "פרטי מחסנית",
      magazineId: "מס' מחסנית",
      laserId: "מס' לייזר",
      headSensorId: "מס' חיישן ראש",
      magazine_id: "מס' מחסנית",
      laser_id: "מס' לייזר",
      head_sensor_id: "מס' חיישן ראש",
      requiredThreshold: "רף נדרש",
      startingSwitchProcess: "מתחיל שינוי תכנית...",
      loading: "טוען...",
      version: "גרסה",
      about: "אודות",
      credits: `מערכת מעקב הביצועים פותחה ע"י בגירה מערכות בע"מ בשיתוף עם מדור מעבדת מאמנים, בענף מאמנים בחט"ל של צה"ל.`,
      creditsDevelopers: `לקחו חלק במלאכה: שמוליק חכם, שולמית טנג'ר, רס"ל טום אדרי, רס"ל דוד ריינהולד, סגן רן שליטין, סגן רועי מלכה וסרן דוד אלקבס`,
      selectOne: "בחר קבוצה בלבד ",
      deselectOne: "בטל קבוצה בלבד",
      selectSub: "בחר מתאמנים",
      deselectSub: "בטל בחירת מתאמנים",
      weaponIdEnglishOnlyAlert:
        "מספר נשק יכול להכיל אותיות באנגלית ומספרים בלבד",
      forceInSite: "כח או אחד מילדיו ביצע Check - in באתר",
      deleteError: "שגיאה בעת ביצוע מחיקה",
      positiveNumbersOnly: "מספר חיובי",
      mustBeBetween: "חייב להיות בין",
      to: "ל",
      mustBeUnder: "חייב להיות <=",
      positiveDecimalNumbersOnly: "מספר דצימלי חיובי",
      heat: "מקצה",
      heats: "מקצים",
      selectHeat: "בחר מקצה",
      searchHeat: "חפש מקצה",
      manualInput: "הזן ידנית",
      SoldierName: "שם חייל",
      SoldierId: "מספר אישי",
      filterHeader: "סנן",
      filterAdvence: "סינון מתקדם",
      'הנחת ח"ע': 'הנחת ח"ע',
      hoursInput: "תאריך",
      trainingPlanInput: "תכנית הכשרה",
      selectInputType: "בחר סוג הזנה",
      selectPrevPlan: "תכנית הכשרה קודמת (שגויה)",
      prevPlan: "בחר תוכנית הכשרה קודמת",
      attended: "ניגשו",
      notAttended: "לא ניגשו",
      passed: "רף נדרש",
      almostPasssed: "כמעט עבר",
      failed: "נכשל",
      under: "= <",
      upper: "= >",
      soldiers: "חיילים",
      elementsError: "תקלה בשליפת הנתונים",
      responsiveShooting: "ירי תגובתי",
      deletingInProgress: "מחיקת כוח מתבצעת",
      forceDeletePassword: "הקשר סיסמה למחיקת",
      rowIndex: "#",
      duplicatePlan: "שכפל תכנית",
      class: "כיתה",
      newUnit: "מסגרת חדשה",
      displayData: "הצג נתוני",
      zeroHundredError: "הערך חייב להיות בין 0 ל 100",
      positiveError: "הערך חייב להיות גדול מ-0",
      savePlanError: "תקלה בשמירת התכנית, ערכים לא תקינים",
      savingPlan: "שומר תכנית...",
      scatter: "עמודות",
      line: "רציף",
      duplicate: "שכפול",
      unauthorizedToDeleteRow: "אינך מורשה למחוק שורה זו.",
      chooseTrainingPlan: "בחר תוכנית הכשרה",
      selectShootingDay: "בחירת יום קליעה",
      selectHeatType: "בחירת סוג מקצה",
      zeroHundredError: "הערך חייב להיות בין 0 ל 100",
      positiveError: "הערך חייב להיות גדול מ-0",
      savingPlan: "שומר תכנית...",
      site1: "תל ערד",
      site2: "קציעות",
      site3: "ביסלח",
      site4: "גולני",
      site5: "צנחנים",
      TelArad: "תל ערד",
      Kziot: "קציעות",
      Bislach: "ביסלח",
      Golani: "גולני",
      Tzanhanim: "צנחנים",
      lastSynced: "סונכרן לאחרונה",
      resetMemorizationDeleteQ:
        "האם אתה בטוח שברצונך לאפס את ציוני התכנית הפעילה?",
      resetMemorization: "איפוס ציונים שמורים",
      successfullyResetPlansGrades: "ציוני התוכנית הפעילה אופסו בהצלחה.",
      errorWhileResetPlansGrades: "תקלה בעת איפוס ציונים שמורים.",
      result: "תוצאה",
      saveResults: "שמירת תוצאות",
      passToNextHeat: "מעבר למקצה הבא",
      trainingTypeName: "שם תחנה",
      allStations: "כלל התחנות",
      hour: "שעה",
      true: "עבר",
      false: "נכשל",
      updateError: "שגיאה במהלך איפוס צמידים.",
      resetBracelets: "נקה צמידים",
      'לש"ביה מטרות': 'לש"ביה מטרות',
      comingSoon: "עובדים על זה",
      resetBraceletsQuestion: "האם אתה בטוח כי ברצונך לאפס את הצמידים מהכוחות?",
      noValue: "אין נתון",
      // TODO: need to get funcionalities and make sure its disabled correctly
      // also find other input fields where is disabled doesn't work correctly
      chooseForce: "בחר כוח מבצע", //for mobile tooltip
      chooseForceStation: "בחר תחנה",
      moreDetails: "פירוט נוסף",
      shootingDay2: "יום קליעה 2 - יסודות",
      shootingDay3: "יום קליעה 3 - מחסות",
      shootingDay4: "יום קליעה 4 - טווחים רחוקים",
      shootingDay5: "יום קליעה 5 - בחנים",
      shootingDay6: "יום קליעה 6 - מעצורים ומעבר בין מטרות",
      shootingDay7: `יום קליעה 7 - יל"מ`,
      shootingDay8: "יום קליעה 8 - יישור מקורב",
      shootingDay9: "יום קליעה 9 - טווחים בינוניים",
      shootingDay10: "יום קליעה 10 - טווחים רחוקים",
      shootingDay11: "יום קליעה 11 - מסכם",
      shootingDay12: "יום קליעה 12 - מחסות",
      shootingDay13: "יום קליעה 13 - מטרות נעות",
      shootingDay14: "יום קליעה 14 - מסכם",
      noSavePlanChanges: "לא שמרת את השינויים, בטוח שברצונך לעזוב?",
      noSaveResults: "קיימות תוצאות שלא נשמרו",
      reachedLastHeat: "הגעת למקצה האחרון ביום זה",
      saveResultsAndPassToNextHeat: "שמירת תוצאות ומעבר למקצה הבא",
      applyDataHistoryButton: "החל",
      clearDataHistoryButton: "נקה",
      instructorOrViewerMustHaveRelatedForce: "ובה לשייך כוח עבור מדריך/צופה",
      selectForceToDisplay: "בחר כוח להצגה",
      active: "פעילה",
      duplicateDetails: "לפחות אחד הפרטים משוייך כבר לכוח אחר",
      isLocalSite: "משתמש אתר מקומי",
      editRow: "ערוך שורה",
      errorInOnOfTheFields: "לפחות אחד השדות אינו תקין",
      errorWhileEditRow: "עריכת השורה נכשלה",
      rowEditedSuccessfully: "השורה נערכה בהצלחה",
      brigade: "חטיבה",
      goBack: "חזור",
      trackingUsersHistory: "נתוני כניסות",
      entries: "מספר כניסות",
      site: "אתר",
      tooltipRemoveAllSelectedSoldier: "הסר את כל המתאמנים שנבחרו",
      removeMultipleTraineesQuestion:
        "האם אתה בטוח כי ברצונך להסיר מהתחנות את כל המתאמנים שנבחרו?",
      removeTrainees: "הסר מתאמנים",
      updateForcesError: "שגיאה במהלך ביצוע עדכון נתוני כוח",
      hoursRange: "טווח שעות",
      selectStartDate: "בחר תאריך התחלה",
      selectEndDate: "בחר תאריך סיום",
      lower_threshold: "רף תחתון",
      upper_threshold: "רף עליון",
      threshold: "ציון סף",
      required_threshold: "רף נדרש",
      training_type_name: "שם תחנה",
      indicator_name: "שם מדד",
      element_name: "שם מרכיב",
      compareLocalAndDestinationPlans: "פערים בערכי תוכניות הכשרה גלובליות",
      comparePlans: "השווה תוכנית הכשרה",
      comparePlansError: "תקלה בעת השוואת תוכניות הכשרה",
      localSite: "אתר מקומי",
      selectDateRange: "בחר טווח תאריכים",
      canNotMoveForceIntoItsORBAT: "לא ניתן למקם כוח תחת העץ שלו",
      syncServiceAlreadyInProgress: "הסינכרון בתהליך",
      enterSuperSoferPassword: "הקש סיסמת Super Sofer",
      stationView: "תצוגת תחנה",
      traineeGrade: "ציון חניך",
      missilesFired: "טילים שנורו",
      missilesFiredSum: "כמות טילים שנורו",
      sumOfHits: "כמות פגיעות",
      doubleHits: "פגיעות כפולות",
      outOfSector: "טילים מחוץ לסקטור",
      DoubleHits: "פגיעות כפולות",
      OutOfSector: "טילים מחוץ לסקטור",
      stationDetails: "פירוט תחנה",
      SPIKEDetails: "פירוט נתוני תחנת SPIKE",
      clearFilter: "נקה סינון",
      SPIKE: "גיל",
      traineeName: "שם מתאמן",
      ID: "מספר אישי",
      traineeIdNotExist: "מתאמן לא נמצא במערכת",
      searchForce: "חפש כוח",
      outOf: "מתוך",
      cm: 'ס"מ',
    },
  },

  //! -------------------------------- from here is ENGLISH ------------------------------

  en: {
    translation: {
      hello: "Hello",
      name: "Name",
      qualifications: "Competency",
      details: "Stations Details",
      "Stations Details": "Stations Details",
      trends: "Trends",
      leaders: "Leaders",
      urban: "Urban",
      iest: "IEST",
      magnet: "Magnet Targets",
      panorama: "Panorama",
      overallTime: "Overall Time",
      send: "Send",
      threatNeutralized: "Threat Neutralized",
      securing: "Securing",
      stopBleeding: "Stop Bleeding",
      report: "Report",
      siteManagement: "Site Status",
      overallInSite: "Total Trainees in Site:",
      lastUpdate: "Last updated in -",
      online: "online",
      militaryId: "Personal ID",
      search: "Search",
      notFound: "Soldier not found",
      unitsTitle: "Units",
      individualsTitle: "Soldiers",
      trainingRadio: "Trainings",
      testRadio: "Tests",
      addSoldierToStation: "Select soldier",
      OK: "OK",
      traineesList: "Soldiers at station",
      remove: "remove",
      lethality: "Lethality",
      Lethality: "Lethality",
      lethalityThreshold: "Lethality Threshold",
      combatCapacity: "Competency",
      export: "Export",
      deleteQues: "Are you sure you want to delete the soldier",
      loadingData: "loading data...",
      fromStation: "from the station",
      leadersMobileTitle: "leadersMobileTitle",
      checkout: "Checkout",
      refresh: "Refresh",
      tooltipRemoveSoldier: "click to remove soldier",
      date: "Date",
      forcesTree: "Forces Tree",
      mrk: "IEST MRK",
      medical: "Medical Care",
      treadmill: "Treadmill",
      liveShooting: "Live Shooting",
      weaponType: "Weapon Type",
      RowId: "Row Number",
      numberOfShots: "Number of Shots",
      numberOfHits: "Number of Hits",
      score: "Score",
      grouping: "Grouping",
      lane: "Lane",
      soldierName: "Soldier Name",
      SoldierName: "Soldier Name",
      lastHeat: "Last Heat",
      sendingData: "Sending data...",
      reset: "Reset",
      dataSendSuccess: "Data sent successfully",
      dataSendError: "Error in data sending",
      lethalityMeasurements: "Lethality Measurements:",
      cancelChangesAlert: "?Are you sure you want to cancel the changes",
      examName: "Exam name",
      trainingName: "Training name",
      addExam: "Add Exam",
      addTraining: "Add Training",
      deletePlanAlert: "?Are you sure you want to delete this plan",
      duplicatePlanAlert: "?Are you sure you want to duplicate this plan",
      setPlanActiveAlert: "?Are you sure you want to make this plan active",
      add: "Add",
      loadingPlans: "Loading plans...",
      station: "Station",
      weight: "Weight",
      thresholdGrade: "Threshold Grade",
      gradeElement: "Grade Element",
      elementWeight: "Element Weight",
      lowerThreshold: "Lower Threshold",
      upperThreshold: "Upper Threshold",
      grade: "Grade (1-10)",
      measurement: "Measurement",
      measurementWeight: "Measurement Weight",
      "קרב מגע": "Krav Maga",
      קליעה: "Shooting",
      "לוחמה בשטח בנוי": "Urban Warfare",
      "נשק מחלקתי": "Department Weapon",
      "טיפול רפואי": "Medical Care",
      "כושר קרבי": "Combat Fitness",
      ירי: "Shooting",
      לחימה: "Combat Fighting",
      קוגניטיבי: "Cognitive",
      'הנחת ח"ע': "Arterial Tourniquet",
      'לש"ביה מטרות': "MAGNET Targets",
      קוגנטיבי: "Cognitive",
      addMeasurement: "Add Measurement",
      dataInput: "Data Input",
      trainingPlan: "Training Plan",
      trainingPlans: "Training Plans",
      thresholdForOperationalQualification: "Operational Competency",
      noActivePlanSelected: "No active plan selected",
      activePlan: "Active plan:",
      compareBetweenForces: "Compare between forces",
      select: "Select",
      settings: "Settings",
      stations: "Stations",
      performance: "Performance",
      forceUpdateSuccessMsg: "Changes saved successfully",
      forceAddedSuccessfullyMsg: "New force has successfully created",
      soldierIdMissingAlert: "Soldier id is required",
      cancel: "Cancel",
      save: "Save",
      tagIdExixstAlert:
        "?Note! The bracelet ID already exists in the forces tree. Do you want to overwrite the data with the new value",
      forceHasTagIdAlert:
        "?The field contains a previous data, do you want to overwrite the data with the new value",
      newForce: "new force",
      forcesNotExist: "forces not exist",
      forcesNotAssigned: "There is no associated force for you",

      maxChoosingHeader1: "Only ",
      maxChoosingHeader2: "forces can be selected",
      cancelChoosing:
        "You must deselect an existing selection to add the desired selection.",
      verifyingDeletionAlert: "Are you sure you want to delete the ",
      reorderAlert1: "Are you sure you want to place",
      reorderAlert2: "under",
      fileProcessing: "in process",
      checkoutFailed: "CHECKOUT failed",
      checkoutSucceed: "A CHECKOUT was performed successfully",
      personalInfo: "Personal Information",
      soldierId: "Trainee ID",
      personalId: "E-Mail",
      tagId: "Tag ID",
      weaponDetails: "Weapon Details",
      weaponId: "Serial Number",
      weapon_id: "Serial Number",
      simulatedWeaponType: "Simulated Weapon",
      weaponSightType: "Weapon Sight Type",
      showForces: "Show forces",
      siteHeader: "Digital Dust",
      settingsPage: "Settings",
      orbatTreePage: "ORBAT",
      PlansPage: "Training Plans",
      siteManagementPage: "Site Status",
      DataPostingPage: "Data Posting",
      stationPage: "Stations",
      leadersPage: "Leaders",
      trendsPage: "Trends",
      historyPage: "Data history",
      arrowIcon: "chevronForwardOutline",
      logout: "Logout",
      security: "Security",
      detailsHeader: "Details",
      weaponSightID: "Sight ID",
      ForceType: "Force Type",
      noSelectedSoldier: "Require selected soldier",
      startTooltipAlign: "left",
      chooseTrainingPlans: "Select Training Plan",
      invalidPostData: "Not vaild input exist.",
      avgGrade: "Average Grade",
      invalidInput: "Invalid Input",
      soldierNotFound: "Soldier Not Found",
      errorToLoadData: "Load Data Error",
      "מספר מקצים": "Number of Rounds", //tooltip data
      "סוג נשק": "Weapon Type",
      "כמות יריות": " Number of Shots",
      "ממוצע יריות": "Average Shots Number",
      "ממוצע פגיעות": "Average Hits Number",
      "ממוצע החטאות": "Average Sins Number",
      "ממוצע זמן ביצוע תרגיל": "Average Exercise Time",
      "ממוצע מטרות שנוטרלו": "Average Neutralized Targets",
      "ממוצע מטרות בתרגיל": "Average Drill Targets",
      "ממוצע מקבצים": "Average Grouping",
      "מקבץ מוביל": "Leader Grouping",
      "אחוז פגיעה": "Hits Percentage",
      "ניקוד ממוצע": "Average Score",
      "מקצה אחרון": "Last Heat ",
      "מקצה אחרון (בסוגריים - הרמה המקסימלית)":
        "Last Heat (In parentheses - the maximum level)",
      זיהוי: "Identification",
      "זמן תגובה": "Response Time",
      "זמן עצירת דימום": "Hemostasis Time",
      "מיקוד וריכוז": "Focus and Concentration",
      "ראייה מרחבית": "Spatial Vision",
      "ממוצע מטרות שהושמדו": "Average Targets Destroyed",
      "ממוצע פגיעות באויב": "Average Enemy Vulnerability",
      "ממוצע שחיקה": "Average Abrasion",
      "ממוצע מטרות": "Average Targets",
      "עוצמה מצטברת ממוצעת": "Average Cumulative Power",
      "ממוצע זמן ביצוע": "Average Execution Time",
      "ממוצע נטרול האיום": "Threat Neutralization Average",
      "ממוצע אבטחה": "Security Average",
      "ממוצע עצירת דימום": "Stop Bleeding Average",
      "ממוצע דיווח": "Reporting Average",
      "אחוז מבצעים": "Attendance",
      "מספר פגיעות": "Number Of Hits",
      "כמות מטרות מושמדות": "Number Of Destroyed Targets",
      קטלניות: "Lethality",
      standardDeviation: "Standard Deviation",
      searchPlan: "Search Plan",
      selectStartTime: "Start Time",
      selectEndTime: "End Time",
      percentage: "Percentage",
      אחוזים: "%",
      units: "Units",
      targets: "Targets",
      rounds: "Rounds",
      hits: "Hits",
      score: "Score",
      level: "Level",
      seconds: "Seconds",
      tooltipPosition: "right",
      hours: "Hours",
      trainName: "Train Name",
      testName: "Test Name",
      downloadFile: "Creating file",
      closeModal: "Close",
      enterPassword: "Enter Admin Password",
      enterSuperUserPassword: "Enter Super User Password",
      submit: "Submit",
      start: "Start",
      stop: "Stop",
      trainingDate: "Training Date",
      testDate: "Test Date",
      finalGrade: "Final Grade",
      excellent: "Excellent",
      veryGoog: "Very Goog",
      goodGrade: "Good",
      notGoodGrade: "Doesn't meet required standard",
      presentDetails: "For Stations Details click on the bar",
      notFoundPlanText: "The training plan is still empty of metrics",
      wrongPassword: "Wrong Password",
      duplicate: "Duplicate to all rows",
      edit: "Edit",
      delete: "Delete",
      setActive: "Set Active",
      dataHistory: "Data History",
      searchTrainee: "Search Trainees",
      enterNamesOrId: "Name or Military id",
      mustSelectForce: "Force must be selected",
      Date: "Date",
      Hour: "Hour",
      Duration: "Duration",
      NumOfTargets: "Num Of Targets",
      NumOfShots: "Num Of Shots",
      RedHits: "Red Hits",
      CleanTargets: "Clean Targets",
      TargetsDestroyed: "Targets Destroyed",
      DamagePercentage: "Damage Percentage",
      NumOfHits: "Num Of Hits",
      SumOfHitsPower: "Sum Of Hit Power",
      StrongestHitPower: "Strongest Hit Power",
      Topic: "Topic",
      Level: "Level",
      LaneID: "LaneID",
      Grouping: "Grouping",
      Score: "Score",
      LastHeat: "LastHeat",
      Neutralization: "Neutralization",
      Secure: "Secure",
      StopBleeding: "Stop Bleeding",
      Report: "Report",
      WeaponType: "Weapon Type",
      allDay: "All-Day",
      stationWeight: "Station Weight",
      checkoutItc: "Remove Trainees from ITC",
      removeItcQuestion:
        "Enter Admin password in order to remove soldiers from ITC",
      checkoutITCSucceed: "Trainees successfully removed from ITC",
      checkoutITCFailed: "Error while removing trainees from ITC",
      mustSelectForce: "Force must be selected",
      DownloadFileSuccessfully: "Report generated successfully",
      DownloadFileUnsuccessfully: "Error while generating the report",
      indicatorsGrades: "Indicators Grades:",
      traineeReport: "Trainee Report",
      generalReport: "Trainees Report",
      hashtags: "Hashtags",
      tooltips: "Tooltips",
      graphs: "Graphs",
      indicatorsAndTrainingTypesDetails: "Indicators And Stations Grades",
      selectHashtags: "Hashtags",
      selectTooltips: "Tooltips",
      selectGraphs: "Graphs",
      selectIndicatorsAndTrainingTypesDetails: "Indicators and Stations Grades",
      taggingMode: "Tagging Mode",
      on: "On",
      off: "Off",
      urbanInstructorFeedback: "Urban Instructor Feedback",
      Practice: "Practice",
      Practice1:
        "Work according to 3 emphases: the security of power, mutual coverings, isolation of areas, the principle of suppression",
      Practice2: "Correct gunlock work and safety",
      Practice3: "Opening a room according to the emphasis of Petahim practice",
      Practice4:
        "Individual warfare: line of sight, line of sight, finger position, etc.",
      Practice5: "Practice throwing a grenade",
      Practice6: "Commander Cane in the squad",
      Practice7: "Extracting all the angles in the scan",
      Covering: "Covering",
      Covering1: "Reducing the crossing of the barrel line",
      Covering2:
        "The location of the cover according to the location of the threat",
      Covering3:
        "Isolation of areas and concentration of a coach on one focal point",
      Covering4: "Last Link - Covering Back",
      Covering5: "Dynamic overlays in the camera of possible finals",
      Decisions: "Decisions",
      Decisions1:
        "Working according to order of priorities in combat: enemy in sight, near threat from height, distant threat",
      Decisions2: "MH commands the soldiers",
      Decisions3:
        "In the case of an injured person, removal of the threat - possibility of treatment while removing the threat",
      Decisions4: "Extraction of the NLH in the squad",
      Decisions5: "Many wounded - decision to continue the war or stop it",
      Decisions6: "Consolidation of forces in case of need",
      Sequence: "Sequence",
      Sequence1: "Purification of all the rooms before progressing",
      Sequence2:
        "Combat washing - with an emphasis on not rushing too much, however not to jam the combat",
      Sequence3: "Immediate responses to an immediate threat",
      Sequence4:
        "Building a path of progress - from rot to far, from easy to hard (according to the principle of suppression)",
      Sequence5:
        "Vigilance for an enemy's attempt to withdraw our forces (not to run into trouble)",
      CommandAndControl: "Command And Control",
      CommandAndControl1: "Short and purposeful commands",
      CommandAndControl2: "Report to the Chief Minister",
      CommandAndControl3: "Activating sub-commanders",
      CommandAndControl4: "Choosing an appropriate practice for the situation",
      CommandAndControl5: "Division of sections for cladding and movement",
      CommandAndControl6: "Transferring pictures to power mood",
      CommandAndControl7: "Commander at the end of assault and fighting",
      filter: "Filter Hashtags",
      noHashtagsFound: "No Hashtags Found",
      maximumIndicators: "Maximum amount of indicators added",
      clearHashtagsFilter: "Clear Selection",
      notNullMsg1: "The field ",
      notNullMsg2: "could not be empty",
      soldierIdLengthAlert: "Soldier ID must be 7 digits long",
      deleteForceMsg: "The force has deleted successfully",
      addBtnTooltip: "Add new force",
      ImportBtnTooltip: "Import forces",
      ReorderBtnTooltip: "Reorder forces tree",
      rank: "Rank:",
      'בא"ח': 'בא"ח',
      כיתה: "Squad",
      מחלקה: "Platoon",
      פלוגה: "Company",
      גדוד: "Battalion",
      קבוצה: "Team",
      חטיבה: "Brigade",
      notificationsPage: "Notifications and Recommendations",
      trainingPage: "Training",
      guest: "Guest",
      selectPlan: "Select Plan",
      rowDeletedSuccessfully: "Row Deleted Successfully",
      errorWhileDeleteRow: "Error While Delete Row",
      deleteSuperUser: "Enter Super User password to delete",
      deleteDataHistoryRow: "Delete Row",
      failedLoadOrbatTreeMsg: "Failed to load the Orbat Tree",
      selectionsOptions: "Report Inforamtion Details",
      selectAll: "Select All",
      clearAll: "Clear All",
      alignment: "left",
      resoreForceAlertMsg:
        "?The soldier id is belong to a force that has been deleted. Do you want to recover him",
      resoreForceAlertSubMsg:
        "Be Advised! this operation will recover the data & grades as well",
      importTreeSucceededMsg: "The forces has been successfully imported",
      importTreeErrorMsg:
        "One or more forces could not be imported. Please verify the details in the downloded file",
      duplicateTags: "Duplicate tags",
      manageUsers: "Users",
      selectForces: "Select Forces",
      selectForcesOptional: "Select Forces (Optional)",
      reportGenerator: "Reports Generator",
      "Reports Generator": "Reports Generator",
      //need to add all stations to translation
      addUserHeader: "Add user",
      userId: "User ID",
      role: "Role",
      displayName: "Name",
      usersList: "Users List",
      loadingUsers: "Loading Users...",
      deleteUser: "?Are you sure you want to delete the user",
      userDeleteSuccess: "User deleted successfully",
      userDeleteFail: "Error deleting user",
      userAddSuccess: "User Added successfully",
      userAddFail: "Error Adding user",
      getRolesError: "Failed getting roles",
      Admin: "Admin",
      Instructor: "Instructor",
      Personal: "Personal",
      Viewer: "Viewer",
      emailAddress: "Email Address",
      missingInformation: "Please fill all required fields",
      selectAllHashtags: "Select all",
      clearHashtags: "Clear all",
      clickForDetails: "Select indicator in order to see performance details",
      commanderDashboard: "Commander's Dashboard",
      personalZone: "Personal Zone",
      pageUnauthorized: "No authorization to access the requested page",
      noAccess: "No access, contact admin",
      selectForce: "Select Force",
      force: "Force",
      invalidEmailAddress: "Invalid email address",
      userName: "User name",
      password: "Password",
      login: "Login",
      loginError: "There was en error trying to log you in",
      loginSucceed: "You Logged In Successfully",
      wrongCredentials: "Wrong user or password",
      localUser: "Local Site User",
      indicatorsLoadError: "Error while loading indicators",
      indicatorsSaveErrors: "Error while saveing indicators",
      orbatLoadError: "Error while loading ORBAT tree",
      loadPlansError: "Error while loading plans",
      planSavedSuccessfull: "Plan saved successfully",
      planAddedSuccessfull: "Plan added successfully",
      errorWhileSavePlan: "Error while saveing Plan",
      planDeletedSuccessfull: "Plan deleted successfully",
      deletePlanError: "Error while deleting plan",
      activetedPlanSuccessfull: "Plan defined as active",
      errorToActivetePlan: "Error while activate plan",
      planDuplicatedSuccefuly: "Plan duplicated successfully",
      errorDuplicatePlan: "Error while duplicating plan",
      pageUnauthorized: "Page Unauthorized",
      'לש"ביה דירה': "BIST Urban",
      'לש"ביה מכולות': "MAGNET Targets",
      "בובת קרב מגע": "B-Fit",
      'מסילת חד"כ': "Treadmill",
      "ירי אישי": "Personal Shooting",
      "ירי קוגניטיבי אישי": "Personal Cognitive Shooting",
      "ירי היקפי": "Panorama Shooting",
      "ירי קוגניטיבי היקפי": "Panorama Cognitive Shooting",
      "ירי תגובתי": "Responsive Shooting",
      'לש"ביה מגנט': "MAGNET Urban",
      "מטווח חי": "Shooting Range",
      "מטווח סימולטיבי": "Simulative Range",
      "טיפול רפואי": "Medical Care",
      "מטווח סימולטיבי": "Simulative Shooting",
      'נמ"ח תגובתי': "Responsive Department Weapon",
      'נמ"ח סימולטיבי': "Simulative Department Weapon",
      'הערכת מדריך לש"ב': "Urban Instructor Feedback",
      "מאמן כושר לחימה": "ITC",
      "מאמן אישי": "Panther",
      competencyPage: "Competency",
      "נטרול איום": "Neutralize Threat", //elements
      אבטחה: "Security",
      "עצירת דימום": "Stop Bleeding",
      דיווח: "Reporting",
      "כמות פגיעות באויב": "Num of Hits",
      "מיקוד וריכוז (רמה)": "Focus (Level)",
      "זמן תגובה (רמה)": "Responsivity (Level)",
      "זיהוי (רמה)": "Recognition (Level)",
      "ראייה מרחבית (רמה)": "Spatial Vision (Level)",
      "זיהוי (ציון משוקלל)": "Recognition (Avg Grade)",
      "ראייה מרחבית (ציון משוקלל)": "Spatial Vision (Avg Grade)",
      "מיקוד וריכוז (ציון משוקלל)": "Focus (Avg Grade)",
      "זמן תגובה (ציון משוקלל)": "Responsivity (Avg Grade)",
      ניקוד: "Points",
      תרגולת: "Practice",
      'פו"ש': "Command",
      "קבלת החלטות": "Decisions",
      "ממוצע מקבץ": "Avg Grouping",
      "רצף לחימה": "Continuation",
      "אחוז מטרות שנוטרלו": "Destroyed Targets",
      חיפויים: "Covering",
      "עוצמת פגיעה מצטברת": "Avg Intensity Hit",
      "זמן ביצוע": "Time",
      "אחוז שחיקה מקסימאלי": "Max Wear Percentage",
      "מספר חזרות": "Number of Repetitions",
      "מספר לא ניגשים": "Number Of Unattended",
      "זמן ביצוע כולל": "Overall Performance Time",
      "כדורים שנורו": "Bullets Fired",
      'הנחת ח"ע': 'הנחת ח"ע',
      "זמן מצטבר": "Accumulated time",
      "עוצמה מצטברת": "Accumulated power",
      reports: "Reports",
      Competency: "Competency",
      competency: "Competency",
      notificationsAndRecommendations: "Notifications & Recommendations",
      מתאמן: "Trainee",
      טוראי: "Pte",
      'רב"ט': "CPL",
      סמל: "Sgt",
      'סמ"ר': "SSgt",
      'סג"מ': "2nd Lt",
      סגן: "Major",
      'רס"ן': "Maj",
      אלוף: "Maj Gen",
      'רס"ר': "WO2",
      חטיבתי: "Brigade",
      'בא"ח': "Site",
      'רס"מ': "Sgt",
      'רס"ל': "SMA",
      'רס"ר': "MSgt",
      'רס"ב': "SgtMaj",
      'רנ"מ': "CSM",
      'רנ"ג': "SgtMaj",
      סרן: "Captain",
      'סא"ל': "Lt. Col.",
      'אל"מ': "Col.",
      'תא"ל': "BG",
      "רב אלוף": "LTG",
      errorWhileLoadingData: "Error While Loading Data",
      noRelevantPlans: "Selected force has no training plans",
      isForDashboard: "Commander's Dashboard",
      noSelectedForces: "No selected forces",
      noSelectedData: "No selected data",
      noForceDataInDate:
        "There isn't data on the selected force in the active plan on the selected date",
      noHeatsFound: "No heats found",
      noHeatsExist: "No heats exist",
      platoonAndUP: "Commander's dashboard available for platoon and up",
      syncData: "Synchronize data",
      syncDataSuccessMgs: "The Synchronize has succeedded",
      syncDataErrorMgs: "An Error occured while synchroniize the data",
      indicators: "Indicators",
      numOfShots: "Number of shots",
      numOfHits: "Number of hits",
      numOfDestroyed: "Number of targets destroyed",
      numOfTargets: "Number of targets",
      exerciseName: "Exercise Name",
      hashtagsExcName: "Hashtags (Exercise Name)",
      fillMagnetError:
        "Number of targets and Number of targets destroyed are mendatory",
      fillBISTUrbanError: "Please fill in all required fields",
      editUserHeader: "Edit User Details",
      editUserSuccess: "User details saved",
      editUserError: "Error updating user details",
      editUser: "Edit user details",
      update: "Update",
      trainingPlanTool: "Training Plan Tool",
      selectDestPlan: "Select destination plan",
      switchPlanSuccess: "Switch Plan Success",
      switchPlanFailure: "Switch Plan Failure",
      allPlans: "All Training Plans",
      magazineDetails: "Magazine Details",
      magazineId: "Magazine Number",
      laserId: "Laser Number",
      headSensorId: "Head Sensor Number",
      magazine_id: "Magazine Number",
      laser_id: "Laser Number",
      head_sensor_id: "Head Sensor Number",
      requiredThreshold: "Required Threshold",
      startingSwitchProcess: "Starting...",
      loading: "Loading...",
      version: "Version",
      about: "About",
      credits: `The Performance Manager was developed by Bagira Systems in collaboration with "Trainers" branch development of the IDF.`,
      creditsDevelopers: `Took Place: Shmulik Haham, Shulamit Tanger, SM Tom Edry, SG David Reinhold, SM Ran Shalitin, FL Roei Malka`,
      selectOne: "Select Only Group",
      deselectOne: "Cancel Only Group",
      selectSub: "Select All Trainees",
      deselectSub: " Cancel All Trainees",
      'הנחת ח"ע': "Medical care",
      weaponIdEnglishOnlyAlert:
        "Only English and Numeric characters are allowed in weapon id.",
      forceInSite: "Force or its child checked in site",
      deleteError: "Error while deleting force",
      positiveNumbersOnly: "Positive number",
      mustBeBetween: "Must be between",
      to: "to",
      mustBeUnder: "Must be <=",
      positiveDecimalNumbersOnly: "Positive decimal number",
      heat: "Heat",
      heats: "Heats",
      selectHeat: "Select Heat",
      searchHeat: "Search Heat",
      manualInput: "Enter manually",
      SoldierId: "Soldier Id",
      filterHeader: "Filter by",
      filterAdvence: "Advanced filtering",
      'הנחת ח"ע': "Medical",
      hoursInput: "Hours",
      trainingPlanInput: "Training Plan",
      selectInputType: "Select input type",
      selectPrevPlan: "Previous training plan (wrong)",
      prevPlan: "Select previous training plan ",
      attended: " Attended",
      notAttended: "Didn't Attend",
      passed: "Passed",
      almostPasssed: "Almost Passed",
      failed: "Failed",
      upper: "= <",
      soldiers: "Soldiers",
      elementsError: "Error getting elements.",
      responsiveShooting: "Responsive Shooting",
      deletingInProgress: "Deleting In Progress",
      forceDeletePassword: "Enter Password in order to delete",
      rowIndex: "#",
      amount: "Amount",
      duplicatePlan: "Duplicate plan",
      class: "Class",
      newUnit: "New Unit",
      displayData: "Display data for",
      zeroHundredError: "Value must be between 0 and 100",
      positiveError: "Value must be greater than 0",
      savePlanError: "Error saving plan, invalid inputs",
      savingPlan: "Saving plan...",
      scatter: "Bar Chart",
      line: "Line Chart",
      duplicate: "Duplicate",
      unauthorizedToDeleteRow: "You are unauthorized to delete this heat.",
      chooseTrainingPlan: "Choose Training Plan",
      site1: "Tel-Arad",
      site2: "Kziot",
      site3: "Bislach",
      site4: "Golani",
      site5: "Tzanhanim",
      TelArad: "Tel Arad",
      Kziot: "Kziot",
      Bislach: "Bislach",
      Golani: "Golani",
      Tzanhanim: "Tzanhanim",
      lastSynced: "Last Synced",
      selectShootingDay: "Select Shooting Day",
      selectHeatType: "Select Heat Type",
      resetMemorizationDeleteQ:
        "?Are you shure you want to reset memorization gardes",
      resetMemorization: "Reset Memorized Grades",
      successfullyResetPlansGrades: "successfully Reset Active Plan Grades",
      errorWhileResetPlansGrades: "Error While Reset Active Plan Grades",
      achievementRequired: "Achievement required",
      result: "Result",
      saveResults: "Save Results",
      passToNextHeat: "Pass To The Next Heat",
      chooseForce: "choose the operate force", //for mobile tooltip
      chooseForceStation: "choose station",
      moreDetails: "more details",
      trainingTypeName: "Training Type Name",
      allStations: "All Stations History",
      hour: "Hour",
      true: "passed",
      false: "falied",
      updateError: "Error While Reset Bracelets.",
      resetBracelets: "Reset the Forces Bracelets",
      resetBraceletsQuestion: "?Are You Sure You Want to Remove all Bracelets",
      noValue: "no value",
      shootingDay1: "Shooting Day 1 - Resets",
      shootingDay2: "Shooting Day 2 - Basics",
      shootingDay3: "Shooting Day 3 - Covers",
      shootingDay4: "Shooting Day 4 - Long Ranges",
      shootingDay5: "Shooting Day 5 - Tests",
      shootingDay6:
        "Shooting Day 6 - Restraints and transition between targets",
      shootingDay7: "Shooting Day 7 - Shooting without targets",
      shootingDay8: "Shooting Day 8 - Approximate alignment",
      shootingDay9: "Shooting Day 9 - Middle Ranges",
      shootingDay10: "Shooting Day 10 - Long Ranges",
      shootingDay11: "Shooting Day 11 - Final",
      shootingDay12: "Shooting Day 12 - Covers",
      shootingDay13: "Shooting Day 13 - Moving targets",
      shootingDay14: "Shooting Day 14 - Final",
      isLocalSite: "is local site",
      noSavePlanChanges: "?You didn't save the changes, sure you want to leave",
      noSaveResults: "Not All The Results Saved",
      reachedLastHeat: "You Reached The Last Heat In This Shooting Day",
      saveResultsAndPassToNextHeat: "Save results and pass to next heat",
      instructorOrViewerMustHaveRelatedForce:
        "Instructor or Viewer Must Have Related Force",
      comingSoon: "Coming Soon...",
      brigade: "Brigade",
      selectForceToDisplay: "Select force to display",
      active: "Active",
      duplicateDetails: "One or More from the details are duplicated",
      goBack: "Go back",
      emailOrUsername: "E-Mail/Username",
      selectForceToDisplay: "Select force to display",
      active: "Active",
      duplicateDetails: "One or More from the details are duplicated",
      editRow: "Edit Row",
      errorInOnOfTheFields: "One of the fields are not valid",
      errorWhileEditRow: "Edit row failed",
      rowEditedSuccessfully: "Row edited successfully",
      trackingUsersHistory: "History of logins",
      entries: "NumberOfEntries",
      site: "site",
      tooltipRemoveAllSelectedSoldier: "Remove all selected trainees",
      removeMultipleTraineesQuestion:
        "?Are you sure you want to remove all the selected trainees from the stations",
      removeTrainees: "Remove Trainees",
      updateForcesError: "Error while update force details",
      hoursRange: "Hours Range",
      selectStartDate: "select start date",
      selectEndDate: "select end date",
      lower_threshold: "Lower Threshold",
      upper_threshold: "Upper Threshold",
      threshold: "Threshold",
      required_threshold: "Required Threshold",
      training_type_name: "Training Type Name",
      indicator_name: "Indicator Name",
      element_name: "Element Name",
      compareLocalAndDestinationPlans: "Gaps Between Training Plans",
      comparePlans: "Compare Plans",
      comparePlansError: "Error while comparing plans",
      localSite: "Local Site",
      selectDateRange: "Select Date Range",
      canNotMoveForceIntoItsORBAT: "Can not move a force into its' ORBAT",
      syncServiceAlreadyInProgress:
        "Synchronization Service Already In Progress",
      enterSoferSoferPassword: "Enter Super Sofer Password",
      stationView: "Station View",
      traineeGrade: "Trainee Grade",
      missilesFired: "Missiles Fired",
      missilesFiredSum: "Sum of Missiles Fired",
      sumOfHits: "Sum of Hits",
      doubleHits: "Double Hits",
      outOfSector: "Out Of Sector",
      DoubleHits: "Double Hits",
      OutOfSector: "Out Of Sector",
      stationDetails: "Station Details",
      SPIKEDetails: "SPIKE Station Details",
      clearFilter: "Clear Filter",
      SPIKE: "SPIKE",
      traineeName: "Trainee Name",
      ID: "ID",
      traineeIdNotExist: "Trainee doesn't exist in the system",
      "יום קליעה 1-4 - איפוסים": "Shooting Day 1-4 - Resets",
      "יום קליעה 5-6 - מצבים טווחים קרובים ומעצורים":
        "Shooting Day 5-6 - Close Range and Stops",
      "יום קליעה 5-6 - מצבים טווחים קצרים ומעצורים (לילה)":
        "Shooting Day 5-6 - Short Range and Stops (Night)",
      'יום קליעה 7 - יל"מ': "Shooting Day 7 - JLM",
      "יום קליעה 8 - יישור מקורב": "Shooting Day 8 - Close Alignment",
      "יום קליעה 8 - יישור מקורב (לילה)":
        "Shooting Day 8 - Close Alignment (Night)",
      "יום קליעה 9 - טווחים בינוניים": "Shooting Day 9 - Medium Range",
      "יום קליעה 10 - טווחים רחוקים": "Shooting Day 10 - Long Range",
      "יום קליעה 11 - מסכם": "Shooting Day 11 - Summary",
      "יום קליעה 12 - ירי מאחורי מחסות":
        "Shooting Day 12 - Shooting from Behind Cover",
      "יום קליעה 13 - מטרות בתנועה": "Shooting Day 13 - Moving Targets",
      "יום קליעה 14 - מסכם": "Shooting Day 14 - Summary",
      "יום קליעה 15 - תכני ליבה": "Shooting Day 15 - Core Exercises",
      "יום קליעה 15 - תכני ליבה (לילה)":
        "Shooting Day 15 - Core Exercises (Night)",
      "יום קליעה 16 - שטח בנוי מתקדם":
        "Shooting Day 16 - Advanced Constructed Area",
      "יום קליעה 16 - שטח בנוי מתקדם (לילה)":
        "Shooting Day 16 - Advanced Constructed Area (Night)",
      "יום קליעה 17 - שטח פתוח": "Shooting Day 17 - Open Area",
      "יום קליעה 17 - שטח פתוח (לילה)": "Shooting Day 17 - Open Area (Night)",
      'יום קליעה 18 - בחנים מסכמים (תחצ"ן 07)':
        "Shooting Day 18 - Final Assess",
      "מאמן כושר לחימה 1 - מקור": "Infantry Trainer 1 - global",
      "מאמן כושר לחימה 2 - מקור": "Infantry Trainer 2 - global",
      "מאמן כושר לחימה 3 - מקור": "Infantry Trainer 3 - global",
      "מאמן כושר לחימה 4 - מקור": "Infantry Trainer 4 - global",
      "בוחן לוחם - מקור": "Warrior Test - original",
      "מאמן כושר לחימה 1": "Infantry Trainer 1",
      "מאמן כושר לחימה 2": "Infantry Trainer 2",
      "מאמן כושר לחימה 3": "Infantry Trainer 3",
      "מאמן כושר לחימה 4": "Infantry Trainer 4",
      "בוחן לוחם": "Warrior Test",
      searchForce: "Search Force",
      "בוחן 100 (1)": "Exam 100 (1)",
      "בוחן 100(2)": "Exam 100 (2)",
      "בוחן 150": "Exam 150",
      "בוחן 300": "Exam 300",
      "בוחן 300 טווח קצר": "Exam 300 Short Range",
      "בוחן טווח קצר": "Short Range Exam",
      "בוחן טווח קרוב": "Close Range Exam",
      "בוחן טווחים קצרים (1)": "Short Range Exams (1)",
      "בוחן טווחים קצרים (2)": "Short Range Exams (2)",
      'בוחן יל"מ': "Combat Exam",
      'בוחן יל"מ (1)': "Combat Exam (1)",
      'בוחן יל"מ (2)': "Combat Exam (2)",
      "בוחן רמה": "Level Exam",
      "בוחן רמה (1)": "Level Exam (1)",
      "בוחן רמה (2)": "Level Exam (2)",
      "בתקלות מעבר בין מצבים": "Transitional State Problems",
      "גמר 1 (לילה)": "Final 1 (Night)",
      "גמר 2 קרמי (לילה)": "Final 2 Ceremonial (Night)",
      הסטים: "The Sets",
      "הסטים - טווח קצר": "The Sets - Short Range",
      "100 מטר": "100 meters",
      'אזורי פגיעה - "מק פורק" קרמי': "Impact areas - 'Mak Fork' ceramic",
      "אזורי פגיעה 2": "Impact areas 2",
      "אזורי פגיעה גמר 1": "Final impact areas 1",
      "אזורי פגיעה גמר 2": "Final impact areas 2",
      "איפוס (1)": "Reset (1)",
      "איפוס (2)": "Reset (2)",
      "איפוס (3)": "Reset (3)",
      "איפוס (4)": "Reset (4)",
      "איפוס (5)": "Reset (5)",
      "איפוס (6)": "Reset (6)",
      "איפוס ל10 (1)": "Reset to 10 (1)",
      "איפוס ל10 (2)": "Reset to 10 (2)",
      "איפוס ל10 (3)": "Reset to 10 (3)",
      "איפוס ל25 (1)": "Reset to 25 (1)",
      "איפוס ל25 (2)": "Reset to 25 (2)",
      "איפוס ל25 (3)": "Reset to 25 (3)",
      "איפוס ל25 (4)": "Reset to 25 (4)",
      "איפוס ל25 (5)": "Reset to 25 (5)",
      "בוחן 100": "Examiner 100",

      "הסטים טווח קצר": "Short Range Sets",
      "התקלות טווח קצר": "Short Range Malfunctions",
      "התקלות טווח קצר - קרמי ותיק": "Short Range Malfunctions - Vintage",
      "התקלות טווח קצר (1)": "Short Range Malfunctions (1)",
      "התקלות טווח קצר (2)": "Short Range Malfunctions (2)",
      "התקלות מעבר בין מצבים": "Transitional Malfunctions",
      "ווידוא איפוס": "Reset Verification",
      "ווידוא איפוס (1)": "Reset Verification (1)",
      "ווידוא איפוס (2)": "Reset Verification (2)",
      "ווידוא איפוס ל50 (1)": "Reset Verification for 50 (1)",
      "ווידוא איפוס ל50 (2)": "Reset Verification for 50 (2)",
      "ווידוא איפוס ל50 (3)": "Reset Verification for 50 (3)",
      "יישור מקורב 1 (1)": "Close Alignment 1 (1)",
      "יישור מקורב 1 (2)": "Close Alignment 1 (2)",
      "יישור מקורב 1 (3)": "Close Alignment 1 (3)",
      "יישור מקורב 1 (לילה)": "Close Alignment 1 (Night)",
      "יישור מקורב 2": "Close Alignment 2",
      "יישור מקורב 2 (לילה)": "Close Alignment 2 (Night)",
      "יישור מקורב 2 קרמי (לילה)": "Close Alignment 2 Ceramic (Night)",
      "יישור מקורב 3 קרמי": "Close Alignment 3 Ceramic",
      'יל"מ טווח מוגדל': "Archery Advanced Range",
      'יל"מ כריעה (1)': "Archery Recurve (1)",
      'יל"מ כריעה (2)': "Archery Recurve (2)",
      'יל"מ כריעה 1 (1)': "Archery Recurve 1 (1)",
      'יל"מ כריעה 1 (2)': "Archery Recurve 1 (2)",
      'יל"מ כריעה 2 (1)': "Archery Recurve 2 (1)",
      'יל"מ כריעה 2 (2)': "Archery Recurve 2 (2)",
      "יסודות הקליעה טווח בינוני 1 (1)":
        "Archery Basics Intermediate Range 1 (1)",
      "יסודות הקליעה טווח בינוני 1 (2)":
        "Archery Basics Intermediate Range 1 (2)",
      "יסודות הקליעה טווח בינוני 2": "Archery Basics Intermediate Range 2",
      "יסודות הקליעה טווח בינוני 2 קרמי":
        "Archery Basics Intermediate Range 2 Ceramic",
      "יסודות הקליעה טווח בינוני 3": "Archery Basics Intermediate Range 3",
      "יסודות הקליעה טווח רחוק 1 (1)": "Archery Basics Long Range 1 (1)",
      "יסודות הקליעה טווח רחוק 1 (2)": "Archery Basics Long Range 1 (2)",
      "יסודות הקליעה טווח רחוק 2 (1)": "Archery Basics Long Range 2 (1)",
      "יסודות הקליעה טווח רחוק 2 (2)": "Archery Basics Long Range 2 (2)",
      "יסודות הקליעה טווח רחוק 3 (1)": "Archery Basics Long Range 3 (1)",
      "יסודות הקליעה טווח רחוק 3 (2)": "Archery Basics Long Range 3 (2)",
      "יסודות הקליעה טווחים בינוניים 2": "Archery Basics Intermediate Ranges 2",
      "יסודות הקליעה טווחים רחוקים התנסות":
        "Archery Basics Long Ranges Practice",
      "יסודות הקליעה טווחים רחוקים התנסות (לילה) - קלעים":
        "Long Range Shooting Fundamentals - Night Practice (Ammunition)",
      "יסודות הקליעה טווחים רחוקים ירי מותנה 1":
        "Long Range Shooting Fundamentals - Shoulder Shooting 1",
      "יסודות הקליעה טווחים רחוקים ירי מותנה 2":
        "Long Range Shooting Fundamentals - Shoulder Shooting 2",
      "ירי מחסות 1": "Shelter Shooting 1",
      "ירי מחסות 1 קרמי ותיק": "Shelter Shooting 1 - Ceramic and Old",
      "ירי מחסות 2": "Shelter Shooting 2",
      "ירי מחסות 2 קרמי ותיק": "Shelter Shooting 2 - Ceramic and Old",
      "ירי מחסות 3": "Shelter Shooting 3",
      "ירי מחסות 3 קרמי ותיק": "Shelter Shooting 3 - Ceramic and Old",
      "ירי מחסות 4": "Shelter Shooting 4",
      "ירי מחסות 4 קרמי ותיק": "Shelter Shooting 4 - Ceramic and Old",
      "ירי מחסות 5": "Shelter Shooting 5",
      "ירי מחסות 5 קרמי ותיק": "Shelter Shooting 5 - Ceramic and Old",
      "ישיבה מזרחית": "Eastern Seating",
      "ישיבת מארבים": "Ambush Seating",
      "ישיבת מארבים (לילה) - קלעים": "Ambush Seating (Night) - Ammunition",
      "מטרות בתנועה 1 - עמידה": "Moving Targets 1 - Standing",
      "מטרות בתנועה 2 - כריעה": "Moving Targets 2 - Kneeling",
      "מטרות בתנועה 3 - שכיבה": "Moving Targets 3 - Prone",
      "מטרות בתנועה 4 - ישיבה": "Moving Targets 4 - Sitting",
      "מעבר בין מטרות 1": "Transition between Objectives 1",
      "מעבר בין מטרות 1 (לילה)": "Transition between Objectives 1 (Night)",
      "מעבר בין מטרות 2": "Transition between Objectives 2",
      "מעבר בין מטרות 2 קרמי": "Transition between Objectives 2 Creamy",
      "מעצור גמר": "Final Stop",
      "מעצור גמר (לילה)": "Final Stop (Night)",
      "מקבץ הישגי (ווידוא איפוס)": "Achievement Cluster (Reset Verification)",
      "מקבץ הישגי (ווידוא איפוס) (1)":
        "Achievement Cluster (Reset Verification) (1)",
      "מקבץ הישגי (ווידוא איפוס) (2)":
        "Achievement Cluster (Reset Verification) (2)",
      "מקבץ הישגי ל100": "Achievement Cluster for 100",
      "מקבץ ל25 (1)": "Cluster for 25 (1)",
      "מקבץ ל25 (2)": "Cluster for 25 (2)",
      "מקבץ ל25 (3)": "Cluster for 25 (3)",
      "עמידה אינסטנקטיבי 3": "Instinctive Stand 3",
      "עמידה אינסטנקטיבית 1": "Instinctive Stand 1",
      "עמידה אינסטנקטיבית 2": "Instinctive Stand 2",
      "עמידה אינסטנקטיבית 2 (לילה)": "Instinctive Stand 2 (Night)",
      "רד חפה 1": "Free Ride 1",
      "רד חפה 2": "Free Ride 2",
      "רד חפה 2 (לילה)": "Free Ride 2 (Night)",
      "רד חפה 3": "Rad Hood 3",
      "רד חפה שטח פתוח": "Open Area Rad Hood",
      "שישה מקבצים (מקבץ חמישי)": "Six Heats (Fifth Set)",
      "שישה מקבצים (מקבץ ראשון)": "Six Heats (First Set)",
      "שישה מקבצים (מקבץ רביעי)": "Six Heats (Fourth Set)",
      "שישה מקבצים (מקבץ שישי)": "Six Heats (Sixth Set)",
      "שישה מקבצים (מקבץ שלישי)": "Six Heats (Third Set)",
      "שישה מקבצים (מקבץ שני)": "Six Heats (Second Set)",
      "שישה מקבצים (מקצה 1)": "Six Heats (End 1)",
      "שישה מקבצים (מקצה 2)": "Six Heats (End 2)",
      "שישה מקבצים (מקצה 3)": "Six Heats (End 3)",
      "שישה מקבצים (מקצה 4)": "Six Heats (End 4)",
      "שישה מקבצים (מקצה 5)": "Six Heats (End 5)",
      "שישה מקבצים (מקצה 6)": "Six Heats (End 6)",
      outOf: "out of",
      cm: ".cm",
    },
  },
};
i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "he",
    debug: false,
    whitelist: Languages,
    resources,
    lng: "he",
  });

export default i18n;
