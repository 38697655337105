import React, { FC, useState } from "react";
import { IonContent, IonGrid, IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import MobileHeader from "../../MobileHeader/MobileHeader";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import MobileTitleRow from "../../MobileHeader/MobileTitleRow";
import HeatsList from "./HeatsList";
import IHeat from "../../../../Interfaces/IHeat";
import "./HeatSelection.css";

const HeatSelection: FC = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [heat, setHeat] = useState<IHeat | undefined>();

  const station = useParams<{
    trainingTypeId: string;
    stationId: string;
    stationName: string;
    planId: string;
  }>();
  const trainingTypeId = station.trainingTypeId;
  const stationName = station.stationName;
  const stationId = station.stationId;
  const planId = station.planId;

  const ok = () => {
    history.push(
      heat?.id
        ? `/searchSoldier/${planId}/${stationName}/${stationId}/${trainingTypeId}/${heat?.id}`
        : `/searchSoldier/${planId}/${stationName}/${stationId}/${trainingTypeId}`
    );
  };

  const backHandler = () => {
    history.goBack();
  };

  return (
    <IonPage className="pageHS">
      <IonContent className="contentContainerHS">
        <MobileHeader
          iconEndSrc={EIconsSrc.MENU_ICON}
          iconStartSrc={
            i18n.language === "he"
              ? EIconsSrc.BACK_ICON_HE
              : EIconsSrc.BACK_ICON_EN
          }
          title={t("selectHeat")}
          iconStartHandler={backHandler}
        />
        <IonGrid className="itemsContainerHS">
          <div className="heat-selection-wrap">
            <MobileTitleRow title={t("selectHeat")} />
            <HeatsList
              heat={heat}
              okHandler={ok}
              setHeat={setHeat}
              isDesktop={false}
            />
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default HeatSelection;
