import React, { useContext, useState } from "react";
import { TextEditor } from "react-data-grid";
import DropdownEditor from "./DropdownEditor";
import DrillsDataTable from "./DrillsDataTable";
import { useTranslation } from "react-i18next";
import customToast from "../../Shared/Toast/CustomToast";
import DesktopManualInputTemplate from "./DesktopManualInputTemplate";
import "./BISTUrban.css";

import {
  handleSave,
  IbasicRow,
  resetRowsData,
  sendDrillToBBackend,
  XDefaultColumn,
} from "./dataPostingHelpers";
import UseDataPosting from "../../CustomHooks/UseDataPosting";
import {
  ComparisonFormatter,
  PositiveNumberFormatter,
} from "./InputFormatters";
import SoldiersListEditor from "./SoldiersListEditor";
import IDataPosting from "../../../Interfaces/IDataPosting";
import { checkSuperUserPassword } from "../../../services/passwordsHandler";
import { IonPage } from "@ionic/react";
import AdminPassword from "../AdminPassword/AdminPassword";
import { UserContext } from "../../../context/UserContext/userContext";
import { isBigIntLiteral } from "typescript";

const messageType: number = 6;
const rowsNumber: number = 14;
const trainingTypeId: number = 1;

// BIST urban row (ui) interface
interface IBISTUrbanRow extends IbasicRow {
  weaponType: string;
  numberOfTargets: number | "";
  numberOfShots: number | "";
  numberOfHits: number | "";
  targetsDestroyed: number | "";
  duration: number | "";
  cleanTargets: number | "";
  hashtags: string | "";
  soldierId: number | "";
  weapons: string[];
}

// BIST urban message interface
interface BISTUrbanMessage {
  MessageType: number;
  TimeStamp: Date;
  TrainingTypeID: number;
  SoldierID: number | "";
  DrillStartTime: Date;
  WeaponType: string;
  Duration: number | "";
  NumOfTargets: number | "";
  NumOfShots: number | "";
  RedHits: number | "";
  TargetsDestroyed: number | "";
  CleanTargets: number | "";
  Hashtags: string[];
  PlanId: number | undefined;
}

const BISTUrban: React.FC<IDataPosting> = (
  props: IDataPosting
): JSX.Element => {
  const { t } = useTranslation();
  const XDefaultRow: IBISTUrbanRow = {
    weaponType: " ",
    numberOfShots: "",
    numberOfHits: "",
    numberOfTargets: "",
    targetsDestroyed: "",
    cleanTargets: "",
    duration: "",
    weapons: [],
    hashtags: "",
    soldierId: "",
    soldierName: "",
    soldiersInLanes: [],
  };
  const [invalid, setInvalid] = useState<boolean>(false);
  const { isBISTUrbanAuthed, authBISTUrban } = useContext(UserContext);

  const {
    isSuccess,
    setIsSuccess,
    rows,
    setRows,
    refreshRowsWrap,
    resetRowsDataWrap,
  } = UseDataPosting(XDefaultRow, rowsNumber);

  // Columns for each input type
  const columns = [
    {
      ...XDefaultColumn,
      key: "soldierName",
      name: t("soldierName"),
      editor: SoldiersListEditor,
      cellClass: "celClassExtra",
    },
    {
      ...XDefaultColumn,
      key: "soldierId",
      name: t("militaryId"),
      formatter: PositiveNumberFormatter,
    },
    {
      ...XDefaultColumn,

      key: "weaponType",
      name: t("weaponType"),
      editor: DropdownEditor,
      cellClass: "celClassExtra",
    },
    {
      ...XDefaultColumn,
      key: "duration",
      name: t("Duration"),
      formatter: PositiveNumberFormatter,
    },
    {
      ...XDefaultColumn,
      key: "numberOfTargets",
      name: t("numOfTargets"),
      formatter: PositiveNumberFormatter,
    },
    {
      ...XDefaultColumn,
      key: "numberOfShots",
      name: t("numberOfShots"),
      formatter: PositiveNumberFormatter,
    },
    {
      ...XDefaultColumn,

      key: "numberOfHits",
      name: t("numberOfHits"),
      editorOptions: {
        editOnClick: true,
        editOnFocus: true,
      },
      editor: TextEditor,

      cellClass: "celClass", // remove left and right padding
      formatter: (event: any) => (
        <ComparisonFormatter comparisonKey="numberOfShots" event={event} />
      ),
    },
    {
      ...XDefaultColumn,
      key: "targetsDestroyed",
      name: t("numOfDestroyed"),
      formatter: PositiveNumberFormatter,
    },
    {
      ...XDefaultColumn,
      key: "cleanTargets",
      name: t("CleanTargets"),
      formatter: PositiveNumberFormatter,
    },
    {
      ...XDefaultColumn,
      key: "hashtags",
      name: t("hashtagsExcName"),
      cellClass: "celClassExtra",
    },
  ];

  // Manages data sending
  const sendData = async (row: IBISTUrbanRow) => {
    // To avoid sending empty rows, check if all elements exist
    if (row.soldierId !== "") {
      if (
        row.soldierName === "" ||
        row.numberOfTargets === "" ||
        row.numberOfHits === "" ||
        row.numberOfShots === "" ||
        row.targetsDestroyed === "" ||
        row.cleanTargets === ""
      ) {
        customToast.error(t("fillBISTUrbanError"));
      } else {
        // Sets the loading
        setIsSuccess(-1);
        let rowToSend: BISTUrbanMessage;
        let weaponType = row.weaponType === " " ? "None" : row.weaponType;
        {
          rowToSend = {
            SoldierID: row.soldierId,
            MessageType: messageType,
            TimeStamp: new Date(),
            TrainingTypeID: trainingTypeId,
            WeaponType: weaponType,
            NumOfShots: row.numberOfShots,
            RedHits: row.numberOfHits,
            NumOfTargets: row.numberOfTargets,
            TargetsDestroyed: row.targetsDestroyed,
            Hashtags: row.hashtags.split(" "),
            DrillStartTime: new Date(),
            PlanId: props.selectedPlan?.id,
            CleanTargets: row.cleanTargets,
            Duration: row.duration,
          };
          // Then sends it
          sendDrillToBBackend(
            rowToSend,
            () => setIsSuccess(200),
            () => setIsSuccess(500)
          );
        }
      }
    }
  };

  const onSendPasswordHandler = async (password: string) => {
    let isValid = await checkSuperUserPassword(password, setInvalid);
    if (isValid) authBISTUrban();
  };

  return !isBISTUrbanAuthed ? (
    <IonPage>
      <div className="bistUrbanPassword">
        <AdminPassword
          onSendPasswordHandler={onSendPasswordHandler}
          text={t("enterSuperUserPassword")}
          invalid={invalid}
        ></AdminPassword>
      </div>
    </IonPage>
  ) : (
    <DesktopManualInputTemplate
      selectedPlan={props.selectedPlan}
      plansOptions={props.plansOptions}
      onPlanSelectedHandler={props.onPlanSelectedHandler}
      pageName={"urban"}
      onClickFirstButton={() => {
        resetRowsDataWrap(XDefaultRow);
      }}
      onClickSecondButton={() => {
        refreshRowsWrap(XDefaultRow);
      }}
      onClickThirdButton={() => {
        handleSave(rows, sendData, t);
      }}
    >
      <DrillsDataTable
        columns={columns}
        isSuccess={isSuccess}
        resetRows={() => {
          resetRowsData(setRows, rowsNumber, XDefaultRow);
        }}
        setRows={setRows}
        rows={rows}
        isDataPosting
      />
    </DesktopManualInputTemplate>
  );
};

export default BISTUrban;
