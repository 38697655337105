import React, { Dispatch, SetStateAction } from "react";
import IPlan from "../../../Interfaces/IPlan";
import Plan from "./Plan";

interface IPlansListProps {
  isGlobal: boolean;
  plans: IPlan[];
  selectedPlan: IPlan | undefined;
  handlePlanEdit: (id: number) => void;
  handlePlanDuplicate: (id: number) => void;
  handlePlanDelete: (id: number) => void;
  handlePlanActive: (id: number) => void;
  isAdmin: boolean;
  isUnsaved: () => boolean;
  setIsShowUnsaveModal: Dispatch<SetStateAction<boolean>>;
  setPlanToChange: Dispatch<SetStateAction<IPlan | undefined>>;
  setIsShowEditMode: Dispatch<SetStateAction<boolean>>;
}
const PlansList = (props: IPlansListProps) => {
  const {
    isGlobal,
    plans,
    selectedPlan,
    handlePlanEdit,
    handlePlanDuplicate,
    handlePlanDelete,
    handlePlanActive,
    isAdmin,
    isUnsaved,
    setIsShowUnsaveModal,
    setPlanToChange,
    setIsShowEditMode,
  } = props;

  return (
    <div className="">
      {plans &&
        plans
          .filter((plan: IPlan) => (isGlobal ? plan.isGlobal : !plan.isGlobal))
          .sort(
            (currentPlan: IPlan, nextPlan: IPlan) =>
              currentPlan.id - nextPlan.id
          )
          .map((plan: IPlan) => {
            return (
              <div
                className={`planDiv ${
                  selectedPlan && selectedPlan.id === plan.id
                    ? "selectedPlan"
                    : null
                }`}
                key={plan.id}
              >
                <Plan
                  handlePlanEdit={handlePlanEdit}
                  handlePlanDuplicate={handlePlanDuplicate}
                  handlePlanDelete={handlePlanDelete}
                  handlePlanActive={handlePlanActive}
                  plan={plan}
                  isAdmin={isAdmin}
                  isGlobal={plan.isGlobal}
                  isEditable={plan.isEditable}
                  isUnsaved={isUnsaved}
                  setIsShowUnsaveModal={setIsShowUnsaveModal}
                  setPlanToChange={setPlanToChange}
                  setIsShowEditMode={setIsShowEditMode}
                />
              </div>
            );
          })}
    </div>
  );
};

export default PlansList;
