import { IonCol, IonLabel, IonRow } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import IElement from "../../../Interfaces/IElement";
import SmallInput from "./SmallInput";
import "./Element.css";
import { useSelector } from "react-redux";
import { IPlansReducer } from "../../../redux/reducers/plans";
import { AppState } from "../../../redux/store/plansStore";
import { useTranslation } from "react-i18next";
import Checkbox from "../../Shared/Checkbox/Checkbox";
import { units, validateInput } from "../DataPosting/inputValidations";
import { userRoles } from "../../../services/routeRoles";
import { UserContext } from "../../../context/UserContext/userContext";

interface IElementProps {
  element: IElement;
  updateElement: (element: IElement) => void;
}

const Element: React.FC<IElementProps> = (props: IElementProps) => {
  const [element, setElement] = useState<IElement>();
  const [isForDashboard, setIsForDashboard] = useState<boolean>();
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const editMode = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).editMode;

  useEffect(() => {
    setElement(props.element);
  }, [props.element]);

  const onChangeElementDetailsHandler: Function = async (
    field: string,
    value: string,
    units: string,
    inputIndex: number
  ) => {
    let errs: string[] | undefined;
    errs = element?.errors?.map((err: string, index: number) =>
      index === inputIndex ? t(validateInput(value, units)) : err
    );

    if (field === "isForDashboard") {
      setIsForDashboard(true);
    } else {
      setIsForDashboard(false);
    }
    setElement((prev) => {
      return {
        ...prev!,
        [field]: value !== "" ? value : null,
        errors: errs,
      };
    });
  };

  return element ? (
    <IonCol size="12">
      <IonRow
        className="elementsHeadersRow"
        onBlur={() => (isForDashboard ? null : props.updateElement(element))}
        onClick={() => (isForDashboard ? props.updateElement(element) : null)}
      >
        <IonCol size="2.8" className="elNameCol">
          <IonLabel className="elementName">{t(element?.name.trim())}</IonLabel>
        </IonCol>
        <IonCol size="2.8">
          <SmallInput
            inputValue={element?.weight}
            inputType="number"
            length="smallLong"
            onChange={(field: string, value: string) =>
              onChangeElementDetailsHandler(field, value, units.Weight, 0)
            }
            inputName="weight"
            isPercentageInput
            isDisabled={
              !editMode ||
              props.element.viewOnly ||
              props.element.isOnlyForDashboard
            }
            cssClass={`tpSmallInput ${
              element.errors && element.errors[0] !== "" ? "tpInputError" : null
            }`}
          ></SmallInput>
        </IonCol>
        <IonCol size="1">
          <SmallInput
            cssClass={`tpSmallInput ${
              element.errors && element.errors[1] !== "" ? "tpInputError" : null
            }`}
            inputValue={element?.lowerThreshold}
            inputType="number"
            length="smallShort"
            onChange={(field: string, value: string) =>
              onChangeElementDetailsHandler(field, value, element.units, 1)
            }
            inputName="lowerThreshold"
            units={props.element.units}
            isDisabled={!editMode}
          ></SmallInput>
        </IonCol>
        <IonCol size="1">
          <SmallInput
            cssClass={`tpSmallInput ${
              element.errors && element.errors[2] !== "" ? "tpInputError" : null
            }`}
            inputValue={element?.upperThreshold}
            inputType="number"
            length="smallShort"
            onChange={(field: string, value: string) =>
              onChangeElementDetailsHandler(field, value, element.units, 2)
            }
            inputName="upperThreshold"
            units={props.element.units}
            isDisabled={!editMode}
          ></SmallInput>
        </IonCol>
        <IonCol size="1">
          <SmallInput
            cssClass={`tpSmallInput ${
              element.errors && element.errors[3] !== "" ? "tpInputError" : null
            }`}
            inputValue={element?.requiredThreshold}
            inputType="number"
            length="smallShort"
            onChange={(field: string, value: string) =>
              onChangeElementDetailsHandler(field, value, element.units, 3)
            }
            inputName="requiredThreshold"
            units={props.element.units}
            isDisabled={!editMode}
          ></SmallInput>
        </IonCol>
        <IonCol size="1" className="checkboxHeader checkboxRow">
          <div className="isForDashboardCheckbox">
            <Checkbox
              isDisabled={!editMode && user.role !== userRoles.Admin}
              isChecked={element?.isForDashboard}
              onChange={(e) => {
                if (e && e.detail)
                  onChangeElementDetailsHandler(
                    "isForDashboard",
                    e.detail.checked,
                    "null",
                    4
                  );
              }}
            ></Checkbox>
          </div>
        </IonCol>
      </IonRow>
      <div className="elementBorder"></div>
    </IonCol>
  ) : null;
};

export default Element;
