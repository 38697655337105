import React, { FC, useContext, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import ForcesContext from "../../../../../context/ForcesContext/forcesContext";
import EIconsSrc from "../../../../../Interfaces/EIconsSrc";
import { URLs } from "../../../../../Configurations/urls";
import useNavigation from "../../../../CustomHooks/useNavigation";
import NotExistLabel from "../../../../Shared/NotExistLabel/NotExistLabel";
import Spinner from "../../../../Shared/Spinner/Spinner";
import List from "../../../List/List";
import MobileHeader from "../../../MobileHeader/MobileHeader";
import MobileTitleRow from "../../../MobileHeader/MobileTitleRow";
import OrbatTreeModal from "../../../OrbatTreeModal/OrbatTreeModal";
import IPlan from "../../../../../Interfaces/IPlan";
import usePlans from "../../../../CustomHooks/usePlans";
import "./PlanSelectionScreen.css";

type IProps = {};

const PlanSelectionScreen: FC<IProps> = (props: IProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { navigate } = useNavigation();
  const [loading, setLoading] = useState<boolean>(true);
  const { forces, isForcesTreeOpen, setIsForcesTreeOpen } =
    useContext(ForcesContext);
  const { plans } = usePlans();

  useEffect(() => {
    if (plans && plans.length !== 0) {
      setLoading(false);
    }
  }, [plans]);

  const plansToDisplay = (plans: IPlan[]): IPlan[] => {
    const sortedPlans = plans.sort(
      (currentPlan: IPlan, nextPlan: IPlan) =>
        new Date(nextPlan.startDate!).getTime() -
        new Date(currentPlan.startDate!).getTime()
    );

    return moveActivePlanToFirst(sortedPlans);
  };

  const moveActivePlanToFirst = (plans: IPlan[]): IPlan[] => {
    let tempArray = [...plans];
    const activePlanIndex = plans.findIndex((plan: IPlan) => plan.isActive);
    const removed = tempArray.splice(activePlanIndex, 1);
    tempArray.unshift({
      ...removed[0],
      name: `${removed[0].name} (${t("active")})`,
    });
    return tempArray;
  };

  const navigateToShootingDays = (item: IPlan) => {
    const currentPlan: IPlan | undefined = plans?.find(
      (currentPlan) => currentPlan.id === item.id
    );
    navigate(`${URLs.stations.shootingRange.shootingDays.getURL()}`, {
      planId: currentPlan?.id,
    });
  };

  const returnToPreviousPage = () => {
    navigate(`${URLs.stations.getURL()}`);
  };

  return (
    <div className="plans-selection">
      <MobileHeader
        isUserDisplay={false}
        title={t("liveShooting")}
        iconStartSrc={
          i18n.language === "he"
            ? EIconsSrc.BACK_ICON_HE
            : EIconsSrc.BACK_ICON_EN
        }
        iconStartHandler={returnToPreviousPage}
      />
      <div className="plans-list">
        {forces.length > 0 ? (
          <>
            <MobileTitleRow title={t("chooseTrainingPlan")} />
            {loading ? (
              <Spinner />
            ) : (
              <List
                items={plansToDisplay(plans!)}
                handleClick={(listItem: IPlan | undefined) =>
                  navigateToShootingDays(listItem!)
                }
              />
            )}
          </>
        ) : (
          <NotExistLabel
            text="noSelectedForces"
            className="no-selected-forces"
          />
        )}
      </div>
      <OrbatTreeModal
        limit={1}
        isOpen={isForcesTreeOpen}
        close={() => setIsForcesTreeOpen(false)}
        mode="secondary"
      />
    </div>
  );
};

export default PlanSelectionScreen;
