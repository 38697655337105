import { IonRow, IonCol } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EEventType from "../../../../Interfaces/EEventType";
import customToast from "../../Toast/CustomToast";

import "./SPIKEComponent.css";
import { sendDrillToBBackend } from "../../../Desktop/DataPosting/dataPostingHelpers";
import PMInput from "../../../Desktop/TrainingPlan/PMInput";
import ISimpleTrainee from "../../../../Interfaces/ISimpleTrainee";
interface IProps {
  selectedTrainee: ISimpleTrainee | undefined;
  trainingTypeId: number;
  afterPostHandler?: () => void;
  isSendClicked?: boolean;
  isComplete: boolean;
  setIsComplete: (state: boolean) => void;
  planId: number | undefined;
}
const SPIKEComponent: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    selectedTrainee,
    trainingTypeId,
    afterPostHandler,
    isSendClicked,
    setIsComplete,
  } = props;
  const { t } = useTranslation();
  const [trainee, setTrainee] = useState<ISimpleTrainee>();
  const [spikeInputsData, setSpikeInputsData] = useState<{
    hits: number | undefined;
    doubleHits: number | undefined;
    outOfSector: number | undefined;
  }>({
    hits: undefined,
    doubleHits: undefined,
    outOfSector: undefined,
  });
  const startDateTime: Date = new Date();

  /**
   * post the data to b-backend
   */
  const postData = () => {
    if (!trainee) {
      customToast.error(t("noSelectedSoldier"));
      return;
    }
    const timeStamp: Date = new Date();
    const SPIKEDrill = {
      MessageType: EEventType.SPIKE_OEM_MESSAGE_TYPE,
      TimeStamp: timeStamp,
      TrainingTypeID: trainingTypeId,
      SoldierID: trainee.id,
      DrillStartTime: startDateTime,
      DrillEndTime: timeStamp,
      OutOfSector: spikeInputsData.outOfSector,
      DoubleHits: spikeInputsData.doubleHits,
      RedHits: spikeInputsData.hits,
      PlanId: props.planId,
    };
    const displayToast = true;

    sendDrillToBBackend(
      SPIKEDrill,
      afterPostHandler,
      () => {},
      t,
      displayToast
    );
  };

  useEffect(() => {
    if (isSendClicked) postData();
  }, [isSendClicked]);

  useEffect(() => {
    if (trainee?.id !== selectedTrainee?.id && selectedTrainee)
      setTrainee(selectedTrainee);
  }, [selectedTrainee]);

  useEffect(() => {
    if (
      spikeInputsData.hits !== undefined &&
      spikeInputsData.doubleHits !== undefined &&
      spikeInputsData.outOfSector !== undefined &&
      trainee?.id !== undefined
    ) {
      setIsComplete(true);
    }
  }, [spikeInputsData, trainee]);
  return (
    <React.Fragment>
      <IonRow className="spikeComponentWrap">
        <IonCol size="10">
          <IonRow className="rowSpikeComponent">
            <PMInput
              fontSize="large"
              inputType={"text"}
              inputName="traineeName"
              width="long"
              inputValue={trainee?.name}
              onChangeHandler={(inputName, inputValue: string) => {
                setTrainee((prev) =>
                  prev
                    ? {
                        ...prev,
                        name: inputValue,
                        uniqId: prev.uniqId,
                      }
                    : {
                        name: inputValue,
                        id: undefined,
                        uniqId: -1,
                      }
                );
              }}
              placeholder={t("traineeName")}
            />
            <PMInput
              fontSize="large"
              inputType={"number"}
              inputName="ID"
              width="long"
              inputValue={trainee?.id}
              onChangeHandler={(inputName, inputValue: string) => {
                setTrainee((prev) =>
                  prev
                    ? {
                        ...prev,
                        id: inputValue ? Number(inputValue) : undefined,
                        uniqId: prev.uniqId,
                      }
                    : {
                        name: "",
                        id: inputValue ? Number(inputValue) : undefined,
                        uniqId: -1,
                      }
                );
              }}
              placeholder={t("ID")}
            />
          </IonRow>
          <IonRow className="rowSpikeComponent">
            <PMInput
              fontSize="large"
              inputType={"number"}
              inputName="hits"
              width="long"
              inputValue={spikeInputsData?.hits}
              onChangeHandler={(inputName, inputValue: string) => {
                setSpikeInputsData((prev) => ({
                  ...prev,
                  hits: inputValue ? Number(inputValue) : undefined,
                }));
              }}
              placeholder={t("hits")}
              cssClass="shortPMInput"
            />
            <PMInput
              fontSize="large"
              inputType={"number"}
              inputName="doubleHits"
              width="long"
              inputValue={spikeInputsData?.doubleHits}
              onChangeHandler={(_inputName, inputValue: string) => {
                setSpikeInputsData((prev) => ({
                  ...prev,
                  doubleHits: inputValue ? Number(inputValue) : undefined,
                }));
              }}
              placeholder={t("doubleHits")}
              cssClass="shortPMInput"
            />
            <PMInput
              fontSize="large"
              inputType={"number"}
              inputName="outOfSector"
              width="long"
              inputValue={spikeInputsData?.outOfSector}
              onChangeHandler={(_inputName, inputValue: string) => {
                setSpikeInputsData((prev) => ({
                  ...prev,
                  outOfSector: inputValue ? Number(inputValue) : undefined,
                }));
              }}
              placeholder={t("outOfSector")}
              cssClass="shortPMInput"
            />
          </IonRow>
        </IonCol>
      </IonRow>
    </React.Fragment>
  );
};

export default SPIKEComponent;
