import React, { FC, useState } from "react";

import { IonPage, IonContent, IonGrid } from "@ionic/react";
import "./MedicalStation.css";
import { useHistory, useParams } from "react-router-dom";
import MobileHeader from "../../../Mobile/MobileHeader/MobileHeader";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import MobileTitleRow from "../../../Mobile/MobileHeader/MobileTitleRow";
import MedicalComponent from "./MedicalComponent";
import { useTranslation } from "react-i18next";

const MedicalStation: FC = () => {
  const { i18n, t } = useTranslation();
  const params = useParams<{
    traineeName: string;
    traineeId: string;
    trainingTypeId: string;
    stationId: string;
    stationName: string;
    planId: string;
  }>();

  const backHandler = () => {
    history.goBack();
  };
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const traineeId = parseInt(params.traineeId);
  const traineeName = params.traineeName;
  const trainingTypeId = parseInt(params.trainingTypeId);
  const stationId = parseInt(params.stationId);

  const stationName = params.stationName;
  const history = useHistory();

  return (
    <IonPage>
      <IonContent className="contentContainerM">
        <MobileHeader
          iconEndSrc={EIconsSrc.MENU_ICON}
          title={t(stationName)}
          iconStartSrc={
            i18n.language === "he"
              ? EIconsSrc.BACK_ICON_HE
              : EIconsSrc.BACK_ICON_EN
          }
          iconStartHandler={backHandler}
        />

        <IonGrid className="itemsContainerM">
          <MobileTitleRow title={traineeName} />
          <MedicalComponent
            traineeId={traineeId}
            trainingTypeId={trainingTypeId}
            afterPostHandler={backHandler}
            isComplete={isComplete}
            setIsComplete={setIsComplete}
            planId={undefined}
          ></MedicalComponent>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MedicalStation;
