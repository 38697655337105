export enum userRoles {
  Admin = "Admin",
  Instructor = "Instructor",
  Viewer = "Viewer",
  Unauthorized = "Unauthorized",
}

enum adminRoutes {
  trainingPlan = "/trainingPlan",
  orbatSettings = "/orbatSettings",
}

export enum routes {
  performance = "/performance",
  siteManagement = "/site-management",
  noAccess = "/no-access",
  unauthorized = "/unauthorized",
  notFound = "/not-found",
  personal = "/personal",
  dataPosting = "/dataPosting",
  dataHistory = "/data-history",
  orbatSettings = "/orbat-settings",
  trainingPlan = "/training-plan",
  reportGenerator = "/report-generator",
  stationView = "/station-view",
}

export enum mobileRoutes {
  performance = "/performance",
  trends = "/trends",
  leaders = "/leaders",
  stations = "/stations",
  settings = "/settings",
  personal = "/personal",
  dataHistory = "/history",
}

export const routeRoles = {
  Admins: [String(userRoles.Admin)],
  Instructors: [String(userRoles.Instructor)],
  Viewers: [String(userRoles.Viewer)],
  Unauthorized: [String(userRoles.Unauthorized)],
};
