import {
  IonRow,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonCol,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./SearchComponents.css";
import ISimpleTrainee from "../../../../Interfaces/ISimpleTrainee";
import { toast } from "react-toastify";
import { loadAllSoldiersDataFromDB } from "../../../../services/helpers";
import { UserContext } from "../../../../context/UserContext/userContext";
import ForcesListContext from "../../../../context/ForcesContext/forcesListContext";
import Spinner from "../../Spinner/Spinner";
import { userRoles } from "../../../../services/routeRoles";
import InfiniteScroll from "react-infinite-scroll-component";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import PMIcon from "../../../themeComponents/PMIcon";

interface IProps {
  setSoldierDetails: React.Dispatch<
    React.SetStateAction<ISimpleTrainee | undefined>
  >;
  soldierDetails: ISimpleTrainee | undefined;
  okHandler?: (soldier: ISimpleTrainee | undefined) => void;
  isDesktop?: boolean;
}

const TRAINEES_RENDER_CHUNK_SIZE = 100;
const SearchComponents: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { user } = useContext(UserContext);
  const { setSoldierDetails, isDesktop, soldierDetails, okHandler } = props;
  const [traineesArray, setTraineesArray] = useState<ISimpleTrainee[]>([]);

  const [hasMoreTrainees, setHasMoreTrainees] = useState<boolean>(true);
  const [listCounter, setListCounter] = useState({
    prev: 0,
    next: TRAINEES_RENDER_CHUNK_SIZE,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [displayedArray, setDisplayedArray] = useState<any[]>([]);

  const [searchText, setSearchText] = useState<string>("");
  const { t } = useTranslation();
  const { soldiersNamesAndIds, setSoldiersNamesAndIds } =
    useContext(ForcesListContext);

  /** when we've reached the end of the list increase the counter*/
  const loadMoreData = () => {
    // If we've reached the end of the list ,set hasMore to be false
    if (displayedArray.length === traineesArray.length) {
      setHasMoreTrainees(false);
      return;
    }

    setListCounter((prevState) => ({
      prev: prevState.prev + TRAINEES_RENDER_CHUNK_SIZE,
      next: prevState.next + TRAINEES_RENDER_CHUNK_SIZE,
    }));
  };

  /** set the selected trainee when clicked
   */
  const onClickHandler = (soldier: ISimpleTrainee) => {
    setSoldierDetails((prev: ISimpleTrainee | undefined) =>
      prev?.uniqId === soldier.uniqId ? undefined : soldier
    );

    if (okHandler) okHandler(soldier);
  };

  /** load all trainees from DB if not exist in context */
  useEffect(() => {
    let isMounted = true;
    const loadAllSoldiersData = () => {
      setLoading(true);
      if (soldiersNamesAndIds?.length) {
        setTraineesArray(soldiersNamesAndIds);
      } else {
        (user.role === userRoles.Admin || user.forceToDisplayInOrbat) &&
          loadAllSoldiersDataFromDB(Number(user.forceToDisplayInOrbat?.id))
            .then((res) => {
              if (isMounted) {
                setLoading(false);
                setSoldiersNamesAndIds(res.data);
                setTraineesArray(res.data ? res.data : []);
              }
            })
            .catch((error) => {
              toast.error(t("errorToLoadData"));
              console.error(error);
            });
      }
    };
    loadAllSoldiersData();
    setSoldierDetails(undefined);
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    //when search text changed filter the trainees array to be only with the searched text
    setTraineesArray(
      soldiersNamesAndIds.filter(
        (soldier) =>
          String(soldier.id).includes(searchText) ||
          soldier.name
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
      )
    );
  }, [searchText]);

  useEffect(() => {
    //if the traineesArray changes set the DisplayedArray
    setDisplayedArray(traineesArray.slice(0, listCounter.next));
  }, [traineesArray]);

  //when the counter in changed add more trainees to the displayedArray
  useEffect(() => {
    setDisplayedArray(
      displayedArray.concat(
        traineesArray.slice(listCounter.prev, listCounter.next)
      )
    );
  }, [listCounter]);

  useEffect(() => {
    //turn off the loading if there is trainees to display
    if (displayedArray.length) {
      setLoading(false);
    }
  }, [displayedArray]);

  return (
    <div className="searchCompo">
      {loading ? (
        <Spinner className="spinner-trainees-list" />
      ) : (
        <div
          className={
            isDesktop ? "searchCompWrapDesktop" : "searchCompWrapMobile"
          }
        >
          <IonRow>
            <IonItem
              mode="md"
              lines={"none"}
              className={
                "itemMS container labelInputColor" +
                `${isDesktop ? "" : " mobileLabelInput"}`
              }
              detailIcon=""
            >
              <IonRow className="searchCompRow">
                <IonCol className="searchCompCol">
                  <IonLabel className="searchTextLabel" position="floating">
                    {t("searchForce")}
                  </IonLabel>
                  <IonInput
                    className={"numberColor " + (isDesktop ? "border" : "")}
                    value={searchText}
                    onIonChange={(event) => {
                      setSearchText(
                        event.detail.value && event.detail.value !== null
                          ? event.detail.value
                          : ""
                      );
                    }}
                  ></IonInput>
                </IonCol>
                <PMIcon iconSrc={EIconsSrc.SEARCH} size="large" />
              </IonRow>
            </IonItem>
          </IonRow>
          <IonRow className="soldiersListRow">
            {displayedArray.length ? (
              <InfiniteScroll
                dataLength={displayedArray.length}
                next={loadMoreData}
                hasMore={hasMoreTrainees}
                loader={<Spinner className="spinner-trainees-list" />}
                className={isDesktop ? "soldiersList" : "mobileSoldiersList"}
                height={500}
              >
                {displayedArray.map((trainee: any, index: number) => (
                  <IonItem
                    key={trainee.uniqId}
                    mode="md"
                    button
                    className={
                      "soldierFound itemMS " +
                      (isDesktop
                        ? "backgroundSearch "
                        : "soldierFoundMobile ") +
                      (Number(soldierDetails?.uniqId) === Number(trainee.uniqId)
                        ? " selectedDesktopSearch"
                        : "")
                    }
                    lines={"full"}
                    onClick={() => {
                      onClickHandler(trainee);
                    }}
                  >
                    <IonText className="traineeNameSC">{`${trainee.name}  `}</IonText>
                  </IonItem>
                ))}
              </InfiniteScroll>
            ) : (
              <IonItem
                mode="md"
                className={
                  "notFoundSoldier itemMS" +
                  (isDesktop ? "" : "soldierFoundMobile")
                }
                lines={"none"}
              >
                <IonText className={"notFoundSoldier"}>
                  {t("soldierNotFound")}
                </IonText>
              </IonItem>
            )}
          </IonRow>
        </div>
      )}
    </div>
  );
};

export default SearchComponents;
