import React, { Dispatch, FC, RefObject, SetStateAction } from "react";
import { Collapse } from "react-collapse";
import IForceTreeNode from "../../../../Interfaces/IForceTreeNode";
import PMInput from "../../../Desktop/TrainingPlan/PMInput";
import OrbatTree from "../OrbatTree/OrbatTree";
import "./OrbatInput.css";

type Props = {
  limit?: number;
  isTreeOpened: boolean;
  inputValue: string;
  placeholder: string;
  selectedForces: IForceTreeNode[];
  setSelectedForces: Dispatch<SetStateAction<IForceTreeNode[]>>;
  isForDropDown?: boolean;
  isRootDisable?: boolean;
  onClickInput: () => void;
  filterRef: RefObject<HTMLDivElement>;
  inputWidth: "long" | "short" | "mid";
  collapseCssClass: string;
  wrapperCss: string;
};

const OrbatInput: FC<Props> = (props: Props): JSX.Element => {
  const {
    limit,
    isTreeOpened,
    inputValue,
    selectedForces,
    setSelectedForces,
    placeholder,
    isForDropDown,
    isRootDisable,
    onClickInput,
    filterRef,
    inputWidth,
    collapseCssClass,
    wrapperCss,
  } = props;

  return (
    <div className={wrapperCss}>
      <div className="orbatInput" onClick={onClickInput}>
        <PMInput
          inputName="name"
          placeholder={placeholder}
          inputType="text"
          inputValue={inputValue}
          onChangeHandler={() => {}}
          width={inputWidth}
          isReadonly
        ></PMInput>
      </div>
      <div>
        <Collapse isOpened={isTreeOpened}>
          <div
            ref={filterRef}
            className={collapseCssClass}
            hidden={!isTreeOpened}
          >
            <OrbatTree
              limit={limit}
              isForDropDown={isForDropDown}
              checked={setSelectedForces}
              checkedForces={selectedForces}
              isRootDisable={isRootDisable}
              readonly
            />
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default OrbatInput;
