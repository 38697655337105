// Dependencies
import { IonIcon, IonInput, IonItem } from "@ionic/react";
import React from "react";

// CSS
import "./PMInput.css";
import PMLabel from "../../themeComponents/PMLabel";

interface IInputProps {
  iconInInput?: boolean | false;
  inputType: "text" | "number" | "password" | "email";
  inputName: string;
  placeholder?: string | "";
  inputValue?: string | number | null;
  isDisabled?: false | boolean;
  width: "short" | "long" | "mid";
  height?: "short" | "long" | "mid" | "settingsHeight";
  onChangeHandler?: ((field: string, value: string) => void) | undefined;
  onBlurHandler?: () => void;
  invalid?: false | boolean;
  isPassword?: boolean;
  icon?: string;
  onIconClick?: () => void;
  cssClass?: string;
  isReadonly?: boolean;
  errorCss?: string;
  fontSize?: "medium" | "large";
}

const PMInput: React.FC<IInputProps> = (props: IInputProps) => {
  return (
    <IonItem
      disabled={props.isDisabled}
      lines="none"
      className={`PMInput ${props.errorCss} ${props.cssClass} ${
        props.width
      }WPMI ${props.height}HPMI ${
        props.isDisabled ? "PMInputDisabledPMI" : ""
      } ${props.invalid ? "invalidInputPMI" : null}`}
    >
      <div className="labelOmInputDiv">
        <PMLabel
          position="floating"
          cssClass={`${props.cssClass} tpLabelPMI`}
          fontColor="light"
          fontFamily="Light"
          fontSize={props.fontSize ? props.fontSize : "medium"}
        >
          {props.isReadonly ? "" : props.placeholder}
        </PMLabel>
        {props.icon ? (
          <div className="InputWrapWithIconPMI">
            <IonInput
              readonly={props.isReadonly}
              name={props.inputName}
              value={props.inputValue}
              type={props.inputType}
              min="0"
              maxlength={30}
              onIonChange={(e) => {
                props.onChangeHandler
                  ? props.onChangeHandler(props.inputName, e.detail.value!)
                  : console.log();
              }}
              onBlur={props.onBlurHandler}
              className={`inputTextPMI `}
              placeholder={props.placeholder}
            ></IonInput>
            {props.iconInInput ? (
              <div
                className="eyeIconDiv"
                onClick={() => (props.onIconClick ? props.onIconClick() : null)}
              >
                <IonIcon
                  className={`${
                    props.inputName === "password" ? "eyeIcon" : "calendarIcon"
                  }`}
                  icon={props.icon}
                />
              </div>
            ) : null}
          </div>
        ) : (
          <IonInput
            class="inputPM"
            readonly={props.isReadonly}
            name={props.inputName}
            value={props.inputValue}
            type={props.inputType}
            min="0"
            maxlength={30}
            onIonChange={(e) => {
              props.onChangeHandler
                ? props.onChangeHandler(props.inputName, e.detail.value!)
                : console.log();
            }}
            onBlur={props.onBlurHandler}
            className="inputTextPMI"
          ></IonInput>
        )}
      </div>
    </IonItem>
  );
};

export default PMInput;
