import React, { useEffect, useRef, useState } from "react";
import "./Legend.css";
import PMLabel from "../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import PopoverMenu from "../../Shared/Popover/PopoverMenu";
import PopoverItem from "../../themeComponents/PopoverItem";
import IForceTreeNode from "../../../Interfaces/IForceTreeNode";
import { isTwoLevelsAtleast } from "../../../services/treeSharedFunctions";
import FadeIn from "react-fade-in";

interface IProps {
  forceName: string;
  color: string;
  isSelected: boolean | undefined;
  setSelectedForcesID: React.Dispatch<React.SetStateAction<number[]>>;
  forceId: number;
  selectLowLevelForce: ((forces: IForceTreeNode[]) => void) | undefined;
  force: IForceTreeNode | undefined;
}
const Legend: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const {
    color,
    force,
    forceId,
    isSelected,
    selectLowLevelForce,
    setSelectedForcesID,
    forceName,
  } = props;
  // const [crtlClicked, setCrtlClicked] = useState<boolean>(false); //Do not remove
  const [popoverState, setShowPopover] = useState<{
    showPopover: boolean;
    event: MouseEvent | undefined;
    text: string;
  }>({
    showPopover: false,
    event: undefined,
    text: "",
  });
  // document.addEventListener("keydown", function (event) {
  //   setCrtlClicked(event.ctrlKey);
  // });
  // document.addEventListener("keyup", function (event) {
  //   setCrtlClicked(event.ctrlKey);
  // });

  const handleRightClick = (e: any) => {
    if (!force || !isTwoLevelsAtleast(force)) return;
    e.preventDefault();

    e.persist();
    setShowPopover({
      showPopover: true,
      event: e,
      text: `${t("displayData")} ${force?.force_type}`,
    });
  };
  const addForceIDToSelectedForces = () => {
    setSelectedForcesID((prev) => {
      if (prev.length && Number(prev[0]) === Number(forceId)) return [];
      return [Number(forceId)];

      //multiple select do not remove!!

      // if (crtlClicked) {
      //   if (prev?.includes(forceId))
      //     return prev.filter((rowId) => rowId !== forceId);
      //   return [...prev, forceId];
      // } else {
      //   if (prev?.includes(forceId) && prev.length === 1) return [];
      //   else return [forceId];
      // }
    });
  };

  return (
    <FadeIn className="fadeLegendClass" key={String(forceId)}>
      <div
        className={`legend`}
        onClick={addForceIDToSelectedForces}
        onContextMenu={handleRightClick}
        id={String(forceId)}
      >
        <PopoverMenu
          popoverState={popoverState}
          onDismiss={() =>
            setShowPopover({
              showPopover: false,
              event: undefined,
              text: "",
            })
          }
        >
          <PopoverItem
            background="BxDark"
            onClickHandler={() =>
              selectLowLevelForce && force
                ? selectLowLevelForce([force])
                : () => {}
            }
          >
            <PMLabel fontColor="light" fontFamily="Light" textAlign="center">
              {popoverState.text}
            </PMLabel>
          </PopoverItem>
        </PopoverMenu>
        <span
          className="dotLegend"
          id={String(forceId)}
          style={{ background: color }}
        ></span>
        <PMLabel
          fontSize="medium"
          fontColor="xLight"
          fontFamily={isSelected ? "Bold" : "Light"}
          lineThrough={isSelected === false}
          id={String(forceId)}
        >
          {forceName}
        </PMLabel>
      </div>
    </FadeIn>
  );
};

export default Legend;
