enum EIconsSrc {
  BACK_ICON_EN = "./assets/icon/left-arrow.svg",
  BACK_ICON_HE = "./assets/icon/right-arrow.svg",
  MENU_ICON = "./assets/icon/menu.svg",
  TRASH_ICON = "./assets/icon/trash.svg",
  CHECK_ICON = "./assets/icon/check.svg",
  TREE_ICON = "./assets/icon/Orbat.svg",
  TREE_ICON_EN = "./assets/icon/treeIcon-ltr.svg",
  DATA_POSTING = "./assets/icon/dataPosting.svg",
  EXPORT_ICON = "./assets/icon/export.svg",
  IMPORT_ICON = "./assets/icon/import.svg",
  REFRESH_ICON = "./assets/icon/refresh.svg",
  MENU_DOTS = "./assets/icon/kababMenu.svg",
  SWITCH_FORCE = "./assets/icon/group569.svg",
  DELETE_OUTLINE = "./assets/icon/deleteVector.svg",
  ARROW_UP = "./assets/icon/arrowDropUp.svg",
  ARROW_DOWN = "./assets/icon/arrowDropDown.svg",
  CHEVRON_RIGHT = "./assets/icon/chevronRight.svg",
  CHEVRON_LEFT = "./assets/icon/chevronLeft.svg",
  ADD_FORCE = "./assets/icon/addUser.svg",
  REPORT_GENERATOR = "./assets/icon/reportGeneratorIcon.svg",
  HISTORY = "./assets/icon/baseline-history.svg",
  UP_ARROW_FULL = "./assets/icon/fill.svg",
  DOWN_ARROW_FULL = "./assets/icon/dropdown.svg",
  PERSONAL_ZONE = "./assets/icon/person.svg",
  COMPETENCY = "./assets/icon/competency.svg",
  DATA_HISTORY = "./assets/icon/data-history.svg",
  LEADERS = "./assets/icon/leaders.svg",
  NOTIFICATIONS = "./assets/icon/notifications.svg",
  TRAINING = "./assets/icon/training.svg",
  PENCIL = "./assets/icon/Pencil.svg",
  BAGIRA_TEXT_LOGO = "./assets/icon/Bagira_text_logo.svg",
  FILTER = "./assets/icon/filter.svg",
  FILTER_OFF = "./assets/icon/filterOff.svg",
  CLOSE = "./assets/icon/close.svg",
  ARROWS = "./assets/icon/arrows.svg",
  SEARCH = "./assets/icon/search.svg",
  SEARCH_OUTLINE = "./assets/icon/search-outline.svg",
  DATE_RANGE = "./assets/icon/dateRange.svg",
}
export default EIconsSrc;
