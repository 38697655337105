import React, {
  useDebugValue,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  IonCol,
  IonGrid,
  IonItem,
  IonRadio,
  IonRadioGroup,
  IonRow,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import Dropdown from "../../Shared/Dropdown/Dropdown";
import DatePicker from "../DataPosting/DatePicker";
import Input from "../TrainingPlan/TrainingPlanInput";
import { Option } from "react-dropdown";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import customToast from "../../Shared/Toast/CustomToast";
import PMButton from "../../themeComponents/PMButton";
import PMLoading from "../../Shared/Alert/PMLoading";
import PMLabel from "../../themeComponents/PMLabel";
import DragModal from "../DragModal/DragModal";
import "./TrainingPlanTool.css";
import PMTitle from "../../themeComponents/PMTitle";
import OrbatInput from "../../Shared/Orbat/Input/OrbatInput";
import IForceTreeNode from "../../../Interfaces/IForceTreeNode";

interface ITrainingPlanToolProps {
  isOpen: boolean;
  onDismiss: () => void;
  plansOptions: Option[];
}

const TrainingPlanTool = (props: ITrainingPlanToolProps) => {
  const { plansOptions } = props;
  const { t } = useTranslation();
  const [prevPlan, setPrevPlan] = useState<Option>();
  const [plan, setPlan] = useState<Option>();
  const [isInProgress, setIsInProgress] = useState<boolean>(false);
  const [isTimeError, setIsTimeError] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(new Date());
  const [time, setTime] = useState<{ startTime: string; endTime: string }>();
  const [inputType, setInputType] = useState<string>("hours");
  const inputOptions: string[] = [t("selectPrevPlan"), t("hoursInput")];
  const filterRef = useRef<HTMLDivElement>(null);
  const [isTreeOpened, setIsTreeOpened] = useState<boolean>(false);
  const [selectedForces, setSelectedForces] = useState<IForceTreeNode[]>(
    [] as IForceTreeNode[]
  );
  // Handles date changes
  const dateChangeHandler = (range: any) => {
    setDate(new Date(range));
  };
  const handleViewClick = (event: MouseEvent) => {
    if (!filterRef.current?.contains(event.target as HTMLElement)) {
      setIsTreeOpened(false);
    }
  };
  // Handles time changes
  const onChangeTimeHandler = (time: string) => {
    const startTime = time.split(" - ")[0];
    const endTime = time.split(" - ")[1];
    const MAX_HOURS = 23;
    const MAX_MINUTES = 59;

    setIsTimeError(
      +startTime.split(":")[0] > MAX_HOURS ||
        +startTime.split(":")[1] > MAX_MINUTES ||
        +endTime.split(":")[0] > MAX_HOURS ||
        +endTime.split(":")[1] > MAX_MINUTES ||
        +startTime.split(":")[0] > +endTime.split(":")[0]
    );

    setTime({ startTime: startTime, endTime: endTime });
  };

  const changeInputTypeHandler = (option: Option) => {
    setInputType(option.value);
  };

  // Handles  plan changes
  const planChangeHandler = (option: Option) => {
    setPlan(option);
  };

  // Handles previous plan changes
  const prevPlanChangeHandler = (option: Option) => {
    setPrevPlan(option);
  };

  // Handles plan switch
  const switchPlanHandler = async () => {
    if (plan) {
      setIsInProgress(true);
      try {
        let res;
        customToast.info(t("startingSwitchProcess"));
        if (date && time) {
          if (isTimeError) {
            customToast.error(t("timeError"));
          } else {
            let startDate = new Date(date);
            let endDate = new Date(date);
            //define start-date time
            startDate.setHours(+time.startTime.split(":")[0]);
            startDate.setMinutes(+time.startTime.split(":")[1]);

            //define end-date time
            endDate.setHours(+time.endTime.split(":")[0]);
            endDate.setMinutes(+time.endTime.split(":")[1]);

            //transfer end-date and start-date to utc date
            let utcStartDate = startDate.toISOString();
            let utcEndDate = endDate.toISOString();

            res = await Axios.post(`${baseUrlPMBackend}plans/switchPlan`, {
              planId: plan.value,
              startDate: utcStartDate,
              endDate: utcEndDate,
              selectedForcesIds: selectedForces.map((force) => force.id),
            });
          }
        } else {
          res = await Axios.post(`${baseUrlPMBackend}plans/switchPlan`, {
            planId: plan.value,
            prevPlanId: prevPlan?.value,
            selectedForcesIds: selectedForces.map((force) => force.id),
          });
        }
        if (res?.status === 200) {
          customToast.success(t("switchPlanSuccess"));
          setTime(undefined);
          setPlan(undefined);
          setIsInProgress(false);
          setPrevPlan(undefined);
        } else {
          customToast.error(t("switchPlanFailure"));
          setIsInProgress(false);
        }
      } catch (error) {
        console.log(error);
        customToast.error(t("switchPlanFailure"));
        setIsInProgress(false);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleViewClick);

    return () => {
      document.removeEventListener("mousedown", handleViewClick);
    };
  }, []);
  return (
    <>
      <DragModal
        cssClass="trainingPlanModal"
        onDismiss={props.onDismiss}
        isOpen={props.isOpen}
      >
        <div className="loader" hidden={!isInProgress}>
          <PMLoading message={t("loading")} isOpen={isInProgress}></PMLoading>
        </div>
        <IonGrid className="planToolGrid">
          <IonRow className="titleRowTPT">
            <PMTitle fontSize="xxLarge" fontColor="light" fontFamily="Regular">
              {t("trainingPlanTool")}
            </PMTitle>
          </IonRow>
          <IonRow className="toolInputsRow">
            <IonCol size="3" className="fieldColumnTPT">
              <OrbatInput
                wrapperCss=""
                filterRef={filterRef}
                inputValue={`${t("selectForcesOptional")}`}
                placeholder={`${t("selectForcesOptional")}`}
                onClickInput={() => {
                  setIsTreeOpened((prev) => !prev);
                }}
                inputWidth="long"
                isForDropDown
                isTreeOpened={isTreeOpened}
                collapseCssClass="treeCollapse scrollM"
                selectedForces={selectedForces}
                setSelectedForces={setSelectedForces}
              />
            </IonCol>
            <IonCol size="6" className="fieldColumnTPT sourceData">
              <IonRow className="radioRow">
                <IonRadioGroup
                  value={inputType}
                  onIonChange={(e: any) => {
                    setInputType(e.detail.value);
                    setTime(undefined);
                    setPrevPlan(undefined);
                  }}
                  className="radioGroup"
                >
                  <IonItem className="radioItem" lines="none">
                    <PMLabel
                      fontColor="light"
                      fontFamily="Light"
                      fontSize="medium"
                      textAlign="center"
                    >
                      {t("selectPrevPlan")}
                    </PMLabel>
                    <IonRadio
                      className="radioMedical"
                      slot="start"
                      value={t("selectPrevPlan")}
                    />
                  </IonItem>
                  <IonItem className="radioItem" lines="none">
                    <PMLabel
                      fontColor="light"
                      fontFamily="Light"
                      fontSize="medium"
                      textAlign="center"
                    >
                      {t("hoursInput")}
                    </PMLabel>
                    <IonRadio
                      className="radioMedical"
                      slot="start"
                      value={t("hoursInput")}
                    />
                  </IonItem>
                </IonRadioGroup>
              </IonRow>
              {inputType === t("hoursInput") ? (
                <IonRow className="toolHoursRow toolInputRow">
                  <DatePicker
                    mode="calendar"
                    date={date}
                    setDate={dateChangeHandler}
                  ></DatePicker>
                  <Input
                    isCalendarInput
                    placeholder={t("hoursRange")}
                    inputName="hours"
                    inputType="hours"
                    startTime={time?.startTime}
                    endTime={time?.endTime}
                    onChangeTimeHandler={onChangeTimeHandler}
                    length="short"
                    cssClass={`toolHoursInput ${
                      isTimeError ? "timeError" : ""
                    }`}
                  ></Input>
                </IonRow>
              ) : inputType === t("selectPrevPlan") ? (
                <IonRow className="oldPlanRow">
                  <Dropdown
                    options={plansOptions}
                    onChange={prevPlanChangeHandler}
                    value={`${
                      prevPlan && prevPlan?.value !== undefined
                        ? prevPlan.label
                        : t("prevPlan")
                    }`}
                    placeholder={t("prevPlan")}
                    controlClassName="Dropdown DWidth"
                    arrowClassName="DropdownArrow"
                    placeholderClassName="DropdownPlaceholderCompetency"
                    menuClassName="DropdownMenuCompetency DropdownMenu"
                    disabled={false}
                  ></Dropdown>
                </IonRow>
              ) : null}
            </IonCol>
            <IonCol className="fieldColumnTPT">
              <IonRow>
                <Dropdown
                  options={plansOptions}
                  onChange={planChangeHandler}
                  value={`${
                    plan && plan?.value !== undefined
                      ? plan.label
                      : t("selectDestPlan")
                  }`}
                  placeholder={t("measurement")}
                  controlClassName="Dropdown DWidth"
                  arrowClassName="DropdownArrow"
                  placeholderClassName="DropdownPlaceholderCompetency"
                  menuClassName="DropdownMenuCompetency DropdownMenu"
                  disabled={false}
                ></Dropdown>
              </IonRow>
            </IonCol>
          </IonRow>
          <IonRow className="planToolButtonRow">
            <PMButton
              onClickHandler={switchPlanHandler}
              isDisabled={!plan || isInProgress || isTimeError}
              color="tertiary"
              size="small"
              label={{
                fontColor: "light",
                fontSize: "medium",
                fontFamily: "Regular",
              }}
            >
              {t("update")}
            </PMButton>
          </IonRow>
        </IonGrid>
      </DragModal>
    </>
  );
};

export default TrainingPlanTool;
