import React, { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";
import {
  getHeatsByShootingDayId,
  getShootingDayById,
} from "../../../../../apis/ShootingRangeAPI";
import EIconsSrc from "../../../../../Interfaces/EIconsSrc";
import List from "../../../List/List";
import MobileHeader from "../../../MobileHeader/MobileHeader";
import MobileTitleRow from "../../../MobileHeader/MobileTitleRow";
import SearchBar from "../../../../Shared/SearchBar/SearchBar";
import { URLs } from "../../../../../Configurations/urls";
import { AxiosResponse } from "axios";
import useNavigation from "../../../../CustomHooks/useNavigation";
import Spinner from "../../../../Shared/Spinner/Spinner";
import NotExistLabel from "../../../../Shared/NotExistLabel/NotExistLabel";
import OrbatTreeModal from "../../../OrbatTreeModal/OrbatTreeModal";
import ForcesContext from "../../../../../context/ForcesContext/forcesContext";
import { searchOutline } from "ionicons/icons";
import IShootingHeat from "../../../../../Interfaces/IShootingHeat";
import IShootingDay from "../../../../../Interfaces/IShootingDay";
import "./HeatSelectionScreen.css";

type IProps = {};

const HeatSelectionScreen: FC<IProps> = (props: IProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { navigate } = useNavigation();
  const { state } = useLocation<{
    planId: number;
    shootingDayName: string;
  }>();
  const params = useParams<{ shootingDayId: string }>();
  const [heats, setHeats] = useState<IShootingHeat[]>();
  const [currentShootingDayName, setCurrentShootingDayName] =
    useState<string>();
  const [inputHidden, setInputHidden] = useState<boolean>(true);
  const [filteredHeats, setFilteredHeats] = useState<IShootingHeat[]>();
  const [searchText, setSearchText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const { isForcesTreeOpen, setIsForcesTreeOpen } = useContext(ForcesContext);

  useEffect(() => {
    initializeData();
  }, []);

  useEffect(() => {
    filterList(searchText);
  }, [searchText]);

  useEffect(() => {
    if (heats) {
      setLoading(false);
    }
    setFilteredHeats(heats);
  }, [heats]);

  const initializeData = () => {
    getHeats();
    if (!state) {
      getCurrentShootingDay();
    } else {
      setCurrentShootingDayName(() => state.shootingDayName);
    }
  };

  const getCurrentShootingDay = async () => {
    await getShootingDayById(+params.shootingDayId).then(
      (response: AxiosResponse<IShootingDay | undefined>) =>
        setCurrentShootingDayName(response.data?.name)
    );
  };

  const getHeats = async () => {
    await getHeatsByShootingDayId(+params.shootingDayId).then(
      (response: AxiosResponse<IShootingHeat[] | undefined>) =>
        setHeats(response.data)
    );
  };

  const navigateToResults = (item: IShootingHeat) => {
    navigate(
      `${URLs.stations.shootingRange.shootingDays.heats.results.getURL(
        +params.shootingDayId,
        item.id
      )}`,
      {
        planId: state.planId,
        shootingDayName: currentShootingDayName,
        heats: heats,
        selectedHeat: heats?.find((currentHeat) => currentHeat.id === item.id),
      }
    );
  };

  const changeSearchView = (): void => {
    setInputHidden((prev) => !prev);
    setSearchText("");
  };

  const filterList = (text: string): void => {
    setFilteredHeats(heats?.filter((items) => items.name.includes(text)));
  };

  const isHeatsExist = (): boolean =>
    !!(heats && filteredHeats && heats.length > 0);

  const isShowable = (): boolean => isHeatsExist() && filteredHeats!.length > 0;

  const returnToPreviousPage = () => {
    navigate(`${URLs.stations.shootingRange.shootingDays.getURL()}`, {
      planId: state.planId,
    });
  };

  return (
    <div className="heats-selection">
      <MobileHeader
        isUserDisplay={false}
        title={t("liveShooting")}
        iconStartSrc={
          i18n.language === "he"
            ? EIconsSrc.BACK_ICON_HE
            : EIconsSrc.BACK_ICON_EN
        }
        iconStartHandler={returnToPreviousPage}
      />
      <div className="heats-list">
        <div className="heats-search">
          {inputHidden ? (
            <MobileTitleRow
              icon={true}
              iconSrc={searchOutline}
              title={t("selectHeatType")}
              onIconClick={changeSearchView}
            />
          ) : (
            <SearchBar
              value={searchText}
              placeholder={t("searchHeat")}
              onChange={(text) => setSearchText(text)}
              onClear={changeSearchView}
            />
          )}
        </div>
        {isShowable() ? (
          <List
            items={filteredHeats!}
            handleClick={(listItem: IShootingHeat | undefined) =>
              navigateToResults(listItem!)
            }
          />
        ) : isHeatsExist() ? (
          <NotExistLabel className="no-heats" text="noHeatsFound" />
        ) : loading ? (
          <Spinner className="spinner-container" />
        ) : (
          <NotExistLabel className="no-heats" text="noHeatsExist" />
        )}
      </div>
      <OrbatTreeModal
        limit={1}
        isOpen={isForcesTreeOpen}
        close={() => setIsForcesTreeOpen(false)}
        mode="secondary"
      />
    </div>
  );
};

export default HeatSelectionScreen;
