import { IIndicatorResult } from "../../../Interfaces/dataCalculator";
import {
  IExportDataForForce,
  IExportData,
  IIdGrade,
  ITrainingTypeAndIndicator,
} from "../../../Interfaces/IExportDataForForce";
import {
  ITooltipsOptions,
  IIndicatorsSelection,
} from "../../../Interfaces/IExportReport";
import { IForceAllData } from "../../../Interfaces/IForceAllData";
import ILabel from "../../../Interfaces/ILabel";
import IRadarInfo from "../../../Interfaces/IRadarInfo";
import ITooltipData from "../../../Interfaces/ITooltipData";
import ITooltipTrainingType from "../../../Interfaces/ITooltipTrainingType";

const switchTrainingTypesArray = (trainingTypeTooltipsArray: string[][]) => {
  let maxLength: number = 0;
  let finalTooltipArray: string[][] = [];
  trainingTypeTooltipsArray.map((trainingTypeTooltipArray: string[]) => {
    maxLength =
      trainingTypeTooltipArray.length > maxLength
        ? trainingTypeTooltipArray.length
        : maxLength;
  });

  for (let i = 0; i < maxLength; i++) {
    let columnArray: string[] = [];
    for (let j = 0; j < trainingTypeTooltipsArray.length; j++) {
      if (trainingTypeTooltipsArray[j]) {
        const element = trainingTypeTooltipsArray[j][i];
        if (element) {
          columnArray.push(element);
        } else {
          columnArray.push("");
        }
      } else {
        columnArray.push("");
      }
    }
    finalTooltipArray.push(columnArray);
  }

  return finalTooltipArray;
};

const addNewForceToDesktop = (
  prev: IRadarInfo[],
  forcesToAdd: IRadarInfo[]
) => {
  let temp = prev;
  for (let forceIndex = 0; forceIndex < forcesToAdd.length; forceIndex++) {
    let isAlreadyExist: boolean = !!temp.find(
      (radarItem: IRadarInfo) => radarItem.id === forcesToAdd[forceIndex].id
    );

    if (!isAlreadyExist) {
      temp.push(forcesToAdd[forceIndex]);
    }
  }

  return [...temp];
};

const convertTooltipDataToExportData = (
  tooltipData: ITooltipData[] | undefined,
  tooltipsTitlesSelection: ITooltipsOptions[]
): IExportData[] => {
  if (!tooltipData) return [];
  let exportData: IExportData[] = [];
  tooltipData.map((tooltip) => {
    if (
      tooltipsTitlesSelection.find(
        (tooltipS) => tooltipS.trainingTypeName === tooltip.trainingTypeName
      )?.selectionsOptions
    ) {
      let ttTooltips = tooltipsTitlesSelection.find(
        (trainingType) =>
          Number(trainingType.trainingTypeId) === Number(tooltip.trainingTypeId)
      );
      if (ttTooltips?.isSelected) {
        let selectiiosj: ITooltipTrainingType[] = [];
        let selectedTooltips: string[] = [];
        ttTooltips.selectionsOptions.map((tooltipI) => {
          if (tooltipI.isSelected) selectedTooltips.push(tooltipI.option.label);
        });

        tooltip.trainingTypeTooltip.map((tooltips) => {
          if (selectedTooltips.includes(tooltips.name))
            selectiiosj.push(tooltips);
        });
        if (selectiiosj.length)
          exportData.push({
            exportSingleData: selectiiosj,
            id: tooltip.trainingTypeId,
            name: tooltip.trainingTypeName,
            grade: undefined,
          });
      }
    }
  });

  return exportData;
};
const convertHashtagsGradesToExportData = (
  hashtags: any,
  hashtagsSelections: ITooltipsOptions[]
) => {
  let exportDataForHashtags: IExportData[] = [];
  hashtagsSelections.map((trainingType) => {
    if (trainingType.isSelected) {
      let hashtagsData: IExportData;
      let hashtagsGradesArray:
        | { hashtag: string; grade: number }[]
        | undefined = hashtags
        ? hashtags[trainingType.trainingTypeId]
        : undefined;

      let newTTArray: ITooltipTrainingType[] = [];
      hashtagsGradesArray?.map(
        (hashtag: { hashtag: string; grade: number }) => {
          if (
            trainingType.selectionsOptions.find(
              (option) => option.option.label === hashtag.hashtag
            )?.isSelected
          )
            newTTArray.push({
              value: Number(hashtag?.grade),
              name: hashtag.hashtag,
              id: `${hashtag.hashtag}_${trainingType.trainingTypeId}`,
              isAttended: true,
            });
        }
      );
      if (newTTArray.length) {
        hashtagsData = {
          exportSingleData: newTTArray,
          id: trainingType.trainingTypeId,
          name: trainingType.trainingTypeName,
          grade: undefined,
        };
        exportDataForHashtags.push(hashtagsData);
      }
    }
  });
  return exportDataForHashtags;
};
const convertRadarDataToExportData = (
  forceIndicatorsData: IIdGrade[],
  forceTrainingTypesData: IIdGrade[],
  planAndIndicatorsToTrainingTypes: ILabel[],
  indicatorsSelections?: IIndicatorsSelection[]
) => {
  let exportDataForIndicators: IExportData[] = [];
  indicatorsSelections?.map((indicator) => {
    if (indicator.isSelected) {
      let indicatorData: IExportData;
      let indicatorGrade: IIdGrade | undefined = forceIndicatorsData.find(
        (indicatorGrade: IIdGrade) =>
          Number(indicatorGrade.id) === Number(indicator.id)
      );
      let trainingTypeArray = planAndIndicatorsToTrainingTypes.filter(
        (trainingType) =>
          Number(trainingType.indicatorId) === Number(indicator.id)
      );
      let newTTArray: ITooltipTrainingType[] = [];
      trainingTypeArray.map((tt) => {
        let ttSelection = indicator.trainingTypeSelections.find(
          (trainingTypeSArray) =>
            Number(trainingTypeSArray.id) === Number(tt.id)
        );
        if (ttSelection?.isSelected) {
          let grade = forceTrainingTypesData?.find(
            (ttGrade: IIdGrade) => Number(ttGrade.id) === Number(tt.id)
          );

          newTTArray.push({
            value: Number(grade?.grade),
            name: tt.label,
            id: tt.id,
            isAttended: grade?.isAttended ? grade?.isAttended : false,
          });
        }
      });
      if (newTTArray.length) {
        indicatorData = {
          exportSingleData: newTTArray,
          id: Number(indicator.id),
          name: indicator.label,
          grade: indicatorGrade?.grade,
        };
        exportDataForIndicators.push(indicatorData);
      }
    }
  });
  return exportDataForIndicators;
};
const convertDataForNotModal = (
  trainingTypesLabels: any,
  indicatorsLabels: ILabel[] | undefined,
  forceData: IForceAllData
): IExportData[] => {
  try {
    if (indicatorsLabels && forceData) {
      let exportData: IExportData[] = [];

      indicatorsLabels?.map((indicator) => {
        let indicatorData: IExportData;
        let indicatorGrade: IIdGrade | undefined =
          forceData.forceGradesData.forceResults.indicatorResults?.find(
            (indicatorGrade: IIdGrade) =>
              Number(indicatorGrade.id) === Number(indicator.id)
          );
        let trainingTypeArray = trainingTypesLabels.filter(
          (trainingType: ITrainingTypeAndIndicator) =>
            Number(trainingType.indicatorId) === Number(indicator.id)
        );
        let newTTArray: ITooltipTrainingType[] = [];

        trainingTypeArray.map((tt: ITrainingTypeAndIndicator) => {
          let grade = forceData.forceGradesData.forceResults.indicatorResults
            .map((indicator: IIndicatorResult) => indicator.trainingTypeResults)
            .flat(1)
            ?.find((ttGrade: IIdGrade) => Number(ttGrade.id) === Number(tt.id));
          if (grade)
            newTTArray.push({
              value: grade?.grade,
              name: tt.label,
              id: tt.id,
              isAttended: grade?.participants > 0,
            });
        });
        indicatorData = {
          exportSingleData: newTTArray,
          id: Number(indicator.id),
          name: indicator.label,
          grade: indicatorGrade?.grade,
        };
        exportData.push(indicatorData);
      });

      return exportData;
    }
  } catch (error) {
    console.log(error);
  }

  return [];
};

export {
  switchTrainingTypesArray,
  convertTooltipDataToExportData,
  convertHashtagsGradesToExportData,
  convertRadarDataToExportData,
};
