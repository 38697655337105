import IndicatorsProvider from "./IndicatorsContext/IndicatorsContext";

import { combineComponents } from "./CombineComponents";
import UserProvider from "./UserContext/userContext";
import ForcesContextProvider from "./ForcesContext/forcesContextProvider";
import ForcesListContextProvider from "./ForcesContext/forcesListContextProvider";
//all the context provider need to be in the index.tsx page. we use combineComponents in order to avoid multiple components in the index page
const providers = [
  IndicatorsProvider,
  UserProvider,
  ForcesContextProvider,
  ForcesListContextProvider,
];

export const AppContextProvider = combineComponents(...providers);
