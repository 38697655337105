import React from "react";
import { Route } from "react-router-dom";
import { Redirect, useLocation } from "react-router-dom";
// ionic imports
import {
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";

// components
import ReportGeneratorQCP from "./ReportGeneratorQCP";
import "./ReportGeneratorTabs.css";
// interfaces
import TabItem from "./../../../Interfaces/ITabItem";
import { useTranslation } from "react-i18next";
import { routes } from "../../../services/routeRoles";
import PMLabel from "../../../components/themeComponents/PMLabel";

const ReportGeneratorTabs: React.FC = (): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation();
  const TabsOptions: TabItem[] = [
    {
      url: `${routes.reportGenerator}/generator`,
      title: t("reportGenerator"),
    },
  ];

  return (
    <IonTabs className="pm-tabs">
      <IonRouterOutlet id="main-site-mana">
        <Route
          path={routes.reportGenerator}
          render={() => <Redirect to={`${routes.reportGenerator}/generator`} />}
          exact={true}
        />

        <Route
          path={`${routes.reportGenerator}/generator`}
          component={ReportGeneratorQCP}
          exact
        />
      </IonRouterOutlet>
      <IonTabBar slot="top" className="ion-justify-content-start">
        {TabsOptions.map((item: TabItem, index: number) => {
          return (
            <IonTabButton
              className={location.pathname === item.url ? "tab-selected" : ""}
              tab={item.title}
              href={item.url}
              key={index}
            >
              <PMLabel fontColor="light" fontFamily="Light" fontSize="medium">
                {t(item.title)}
              </PMLabel>
            </IonTabButton>
          );
        })}
      </IonTabBar>
    </IonTabs>
  );
};
export default ReportGeneratorTabs;
