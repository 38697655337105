import {
  FETCH_STATIONS_REQUEST,
  FETCH_STATIONS_SUCCESS,
  FETCH_STATIONS_FAILURE,
  FETCH_STATIONS_TYPES_REQUEST,
  FETCH_STATIONS_TYPES_SUCCESS,
  FETCH_STATIONS_TYPES_FAILURE,
  UPDATE_STATION,
  INCREMENT_STATIONS_UPDATE_COUNTER,
  RESET_STATIONS_UPDATE_COUNTER,
  DECREMENT_STATIONS_RENDER_COUNTER,
  INCREMENT_STATIONS_RENDER_COUNTER,
  RESET_STATIONS_RENDER_COUNTER,
} from "./../actions/stationsActions";
import IStation from "../../Interfaces/IStation";
import {
  SET_STATIONS,
  ADD_STATION,
  DELETE_STATION,
  EDIT_STATION,
  StationActionTypes,
} from "../actions/stationsActions";
import IStationType from "../../Interfaces/IStationType";

// Define reducer interface
export interface IStationReducer {
  stations: IStation[];
  loading: boolean;
  error: Error | "";
  stationsTypes: IStationType[];
  stationsUpdatesCounter: number;
  stationsRendersCounter: number;
}

// Initialize reducer
const stationReducerDefaultState: IStationReducer = {
  stations: [],
  loading: false,
  error: "",
  stationsTypes: [],
  stationsUpdatesCounter: 0,
  stationsRendersCounter: 0,
};

const stationsReducer = (
  state = stationReducerDefaultState,
  action: StationActionTypes
) => {
  switch (action.type) {
    case SET_STATIONS:
      return { ...state, stations: action.stations };

    case ADD_STATION:
      return [...state.stations, action.station];

    case EDIT_STATION: {
      return {
        ...state,
        stations: [
          ...state.stations.map((station) => {
            if (station.id === action.station.id) {
              return {
                ...station,
                weight: action.station.weight,
                threshold: action.station.threshold,
                errors: action.station.errors,
              };
            } else {
              return station;
            }
          }),
        ],
      };
    }

    case DELETE_STATION:
      return state.stations.filter(({ id }) => id !== action.id);

    case FETCH_STATIONS_REQUEST:
      return { ...state, loading: true };

    case FETCH_STATIONS_SUCCESS:
      return { ...state, loading: false, stations: action.stations, error: "" };

    case FETCH_STATIONS_FAILURE:
      return { ...state, loading: false, stations: [], error: action.error };

    case FETCH_STATIONS_TYPES_REQUEST:
      return { ...state, loading: true };

    case FETCH_STATIONS_TYPES_SUCCESS:
      return { ...state, stationsTypes: action.stationsTypes };

    case FETCH_STATIONS_TYPES_FAILURE:
      return { loading: false, stationsTypes: [], error: action.error };

    case UPDATE_STATION: {
      return {
        ...state,
        stations: [
          ...state.stations.map((station) => {
            if (station.id === action.station.id) {
              return { ...station, ...action.station };
            } else {
              return station;
            }
          }),
        ],
      };
    }

    case INCREMENT_STATIONS_UPDATE_COUNTER: {
      return {
        ...state,
        stationsUpdatesCounter: (state.stationsUpdatesCounter += 1),
      };
    }

    case RESET_STATIONS_UPDATE_COUNTER: {
      return { ...state, stationsUpdatesCounter: 0 };
    }

    case INCREMENT_STATIONS_RENDER_COUNTER: {
      return {
        ...state,
        stationsRendersCounter: (state.stationsRendersCounter += 1),
      };
    }

    case DECREMENT_STATIONS_RENDER_COUNTER: {
      return {
        ...state,
        stationsRendersCounter: (state.stationsRendersCounter -= 1),
      };
    }

    case RESET_STATIONS_RENDER_COUNTER: {
      return { ...state, stationsRendersCounter: 0 };
    }

    default:
      return state;
  }
};

export default stationsReducer;
