import { FC, useEffect, useState } from "react";
import "./StationView.css";
import { useTranslation } from "react-i18next";
import PerformanceTemplate from "../../performanceTemplate";
import PMLabel from "../../../../../components/themeComponents/PMLabel";
import DashboardDoughnut from "../../../../../components/Desktop/DashboardDoughnut/DashboardDoughnut";
import VerticalProgressBar from "../../../../../components/Desktop/VerticalProgressBar/VerticalProgressBar";
import usePlans from "../../../../../components/CustomHooks/usePlans";
import IForceTreeNode from "../../../../../Interfaces/IForceTreeNode";
import domtoimage from "dom-to-image-improved";
import EExportType from "../../../../../Interfaces/EExportType";
import { exportToPdfAndCsv } from "../../../../../components/Desktop/ToExcel/exportToPdfAndCsv";
import customToast from "../../../../../components/Shared/Toast/CustomToast";

import { IonCol, IonRow } from "@ionic/react";
interface IProps {}
interface IMapReader {
  value: string | number;
  threshold: string | number;
  percent100: string | number;
  valueColor: string;
  valueText: string;
}
const getRandomValues = (
  max: number,
  min: number,
  onlyValueAndThresholds?: boolean
) => {
  if (max === 0 && min == 0)
    return {
      value: 0,
      percent100: 100,
      threshold: 0,
      color: "gary",
    };
  let percent100 = onlyValueAndThresholds
    ? max
    : max === 100
    ? 100
    : Math.round(Math.random() * (max - min) + min);
  max = percent100;
  let threshold =
    max === 100 ? 70 : Math.round(Math.random() * (max - min) + min);
  let value = Math.round(Math.random() * (max - min) + min);
  let color =
    value > threshold ? "green" : value > threshold / 2 ? "yellow" : "red";
  return {
    value: value,
    percent100: percent100,
    threshold: threshold,
    color: color,
  };
};
const Circe = (props: {
  type: "threshold" | "percent100" | "value";
  color?: string;
}) => (
  <span className={`circleSV ${props.type}SV valueSV${props.color} `}></span>
);

const MapReader = (props: IMapReader) => {
  const { t } = useTranslation();

  return (
    <IonRow className="SVrow">
      <div className="SVColumn">
        <IonRow className="SVrow">
          <Circe type="threshold"></Circe>

          <PMLabel fontSize="small" fontColor="xLight" fontFamily="Regular">
            {t("requiredThreshold")}
          </PMLabel>
        </IonRow>
      </div>
      <div className="SVColumn">
        <IonRow className="SVrow">
          <Circe type="value" color={props.valueColor}></Circe>

          <PMLabel fontSize="small" fontColor="xLight" fontFamily="Regular">
            {props.valueText}
          </PMLabel>
        </IonRow>
      </div>
      <div className="SVColumn">
        <IonRow className="SVrow">
          <Circe type="percent100"></Circe>

          <PMLabel fontSize="small" fontColor="xLight" fontFamily="Regular">
            {t("missilesFired")}
          </PMLabel>
        </IonRow>
      </div>
    </IonRow>
  );
};
const StationView: FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { selectedPlan, plansOptions, onPlanSelectedHandler } = usePlans();
  const [orbatForces, setOrbatForces] = useState<IForceTreeNode[]>(
    [] as IForceTreeNode[]
  );
  const [doughnutData, setDoughnutData] = useState<{
    value: number;
    threshold: number;
    color: string;
  }>(getRandomValues(0, 0));
  const [hitsData, setHitsData] = useState<{
    value: number;
    threshold: number;
    percent100: number;
    color: string;
  }>(getRandomValues(0, 0));
  const [doubleHitsData, setDoubleHitsData] = useState<{
    value: number;
    threshold: number;
    percent100: number;
    color: string;
  }>(getRandomValues(0, 0));
  const [outOfSector, setOutOfSector] = useState<{
    value: number;
    threshold: number;
    percent100: number;
    color: string;
  }>(getRandomValues(0, 0));

  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(true);

  useEffect(() => {
    if (orbatForces.length) {
      setHitsData(getRandomValues(82, 5));
      setDoughnutData(getRandomValues(100, 0));
    } else {
      setHitsData(getRandomValues(0, 0));
      setDoughnutData(getRandomValues(0, 0));
      setOutOfSector(getRandomValues(0, 0));
      setDoubleHitsData(getRandomValues(0, 0));
    }
  }, [orbatForces, selectedPlan]);

  useEffect(() => {
    if (orbatForces.length) {
      setOutOfSector(getRandomValues(hitsData.percent100, 0, true));
      setDoubleHitsData(getRandomValues(hitsData.percent100, 0, true));
    }
  }, [hitsData]);
  const getImageAndSendToCsv = async () => {
    let imageUrl = await domtoimage.toJpeg(
      document.getElementById("csvStationViewImage")
    );
    let forcesData = [];
    if (orbatForces.length)
      forcesData.push({
        forceDetails: orbatForces[0],
        forceGradesData: {
          pic1: imageUrl,
          isSoldier: orbatForces[0].is_soldier,
          forceResults: {
            forceId: orbatForces[0].id,
            grade: doughnutData.value,
            id: selectedPlan!.id,
            indicatorResults: [
              {
                grade: doughnutData.value,
                id: 0,
                name: "SPIKE",
                requiredThreshold: 0,
                rounds: 0,
                threshold: doughnutData.threshold,
                weight: 0,
                trainingTypeResults: [
                  {
                    grade: hitsData.percent100,
                    id: 13,
                    name: `${t("missilesFiredSum")}`,
                    participants: 0,
                    requiredThreshold: 0,
                    rounds: 0,
                    soldierMissed: 0,
                    threshold: hitsData.threshold,
                    weight: 0,
                    elements: [],
                    tooltipResults: {
                      tooltipElementsResults: [],
                      standardDeviation: 0,
                    },
                    hashtagsResults: [],
                    elementResults: [],
                  },
                  {
                    grade: hitsData.value,
                    id: 13,
                    name: `${t("sumOfHits")}`,
                    participants: 0,
                    requiredThreshold: 0,
                    rounds: 0,
                    soldierMissed: 0,
                    threshold: hitsData.value,
                    weight: 0,
                    elements: [],
                    tooltipResults: {
                      tooltipElementsResults: [],
                      standardDeviation: 0,
                    },
                    hashtagsResults: [],
                    elementResults: [],
                  },
                  {
                    grade: doubleHitsData.value,
                    id: 13,
                    name: `${t("doubleHits")}`,
                    participants: 0,
                    requiredThreshold: 0,
                    rounds: 0,
                    soldierMissed: 0,
                    threshold: hitsData.value,
                    weight: 0,
                    elements: [],
                    tooltipResults: {
                      tooltipElementsResults: [],
                      standardDeviation: 0,
                    },
                    hashtagsResults: [],
                    elementResults: [],
                  },
                  {
                    grade: outOfSector.value,
                    id: 13,
                    name: `${t("outOfSector")}`,
                    participants: 0,
                    requiredThreshold: 0,
                    rounds: 0,
                    soldierMissed: 0,
                    threshold: hitsData.value,
                    weight: 0,
                    elements: [],
                    tooltipResults: {
                      tooltipElementsResults: [],
                      standardDeviation: 0,
                    },
                    hashtagsResults: [],
                    elementResults: [],
                  },
                ],
              },
            ],
            isAttendAllTrainingPlan: true,
            name: selectedPlan!.name,
            threshold: doughnutData.threshold,
            rounds: 4,
            rankings: [],
            participantsAtLeastOnes: 5,
          },
          affiliation: "",
          isAttended: true,
        },
      });
    else {
      customToast.error(t("noSelectedForces"));
    }
    await exportToPdfAndCsv(
      forcesData,
      selectedPlan,
      t,
      false,
      `${t("stationDetails")}`,
      500,
      1200
    );
  };

  const chart = (
    <div id="csvStationViewImage" className="stationDetailsContent scrollL">
      <div>
        <div className="stationDoughnutRow">
          <div className="stationsAndForceDetails">
            <PMLabel
              fontSize="large"
              fontColor="xLight"
              fontFamily="Regular"
              cssClass="titleCard"
            >
              {orbatForces.length
                ? `${orbatForces[0]?.force_type} ${orbatForces[0]?.name}`
                : ""}
            </PMLabel>{" "}
          </div>
        </div>

        <div className="stationDoughnutRow">
          <div className="stationDoughnut">
            <DashboardDoughnut
              key={"DashboardDoughnut"}
              setSelectedIndicator={() => {}}
              indicatorId={2}
              indicatorName={`${doughnutData.value}`}
              isDisableAll={false}
              selectedIndicator={undefined}
              chartData={[
                {
                  label: `${t("traineeGrade")}`,
                  value: doughnutData.value,
                  color: doughnutData.color,
                },
              ]}
            ></DashboardDoughnut>
          </div>
        </div>
      </div>
      <div className="stationDoughnutRow">
        <div className="progressBarCard">
          <VerticalProgressBar
            percent100={hitsData.percent100}
            value={hitsData.value}
            threshold={hitsData.threshold}
            color={hitsData.color}
          ></VerticalProgressBar>
          <PMLabel
            fontSize="large"
            fontColor="xLight"
            fontFamily="Regular"
            cssClass="titleCard"
          >
            {t("sumOfHits")}
          </PMLabel>
          <MapReader
            percent100={hitsData.percent100}
            value={hitsData.value}
            threshold={hitsData.threshold}
            valueColor={hitsData.color}
            valueText={t("sumOfHits")}
          ></MapReader>
        </div>{" "}
        <div className="progressBarCard">
          <VerticalProgressBar
            percent100={doubleHitsData.percent100}
            value={doubleHitsData.value}
            threshold={doubleHitsData.threshold}
            color={doubleHitsData.color}
          ></VerticalProgressBar>
          <PMLabel
            fontSize="large"
            fontColor="xLight"
            fontFamily="Regular"
            cssClass="titleCard"
          >
            {t("doubleHits")}
          </PMLabel>{" "}
          <MapReader
            percent100={doubleHitsData.percent100}
            value={doubleHitsData.value}
            threshold={doubleHitsData.threshold}
            valueColor={doubleHitsData.color}
            valueText={t("doubleHits")}
          ></MapReader>
        </div>
        <div className="progressBarCard">
          <VerticalProgressBar
            percent100={outOfSector.percent100}
            value={outOfSector.value}
            threshold={outOfSector.threshold}
            color={outOfSector.color}
          ></VerticalProgressBar>
          <PMLabel
            fontSize="large"
            fontColor="xLight"
            fontFamily="Regular"
            cssClass="titleCard"
          >
            {t("outOfSector")}
          </PMLabel>{" "}
          <MapReader
            percent100={outOfSector.percent100}
            value={outOfSector.value}
            threshold={outOfSector.threshold}
            valueColor={outOfSector.color}
            valueText={t("outOfSector")}
          ></MapReader>
        </div>
      </div>
    </div>
  );

  return (
    <PerformanceTemplate
      key={"stationView"}
      loadingText={t("loadingData")}
      chart={chart}
      isDrawerOpen={isDrawerOpen}
      loading={false}
      orbatForces={orbatForces}
      selectedPlan={selectedPlan}
      plansOptions={plansOptions}
      onPlanSelectedHandler={onPlanSelectedHandler}
      setChecked={(force) => {
        setOrbatForces(force);
      }}
      setDrawerOpen={setDrawerOpen}
      title={t("SPIKEDetails")}
      limit={1}
      onClickSecondButton={getImageAndSendToCsv}
      secondButtonText={t("export")}
    ></PerformanceTemplate>
  );
};

export default StationView;
