// Dependencies
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { add } from "ionicons/icons";

import { IonCol, IonRow } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Input from "./TrainingPlanInput";
import { IPlansReducer } from "../../../redux/reducers/plans";
import Indicators from "./Indicators";
import {
  ADD_INDICATOR,
  fetchIndicatorsTypes,
  saveIndicators,
} from "../../../redux/actions/indicatorsActions";
import { AppState } from "../../../redux/store/plansStore";

// Interfaces
import IPlan from "../../../Interfaces/IPlan";

// CSS
import "./TrainingPlansMainView.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  savePlanAttributes,
  SAVE_PLAN_BUTTON_CHANGE,
  SELECTED_PLAN,
  SET_EDIT_MODE,
  UPDATE_PLAN,
} from "../../../redux/actions/plansActions";
import { SET_ELEMENTS } from "../../../redux/actions/elementsActions";
import { IIndicatorReducer } from "../../../redux/reducers/indicator";
import { IStationReducer } from "../../../redux/reducers/stations";
import { saveStations } from "../../../redux/actions/stationsActions";
import { saveElements } from "../../../redux/actions/elementsActions";
import { IElementsReducer } from "../../../redux/reducers/elements";
import Alert from "../../Shared/Alert/Alert";
import { UserContext } from "../../../context/UserContext/userContext";
import DatePicker from "../DataPosting/DatePicker";
import { IDateRange } from "../../../Interfaces/IDatePicker";
import customToast from "../../Shared/Toast/CustomToast";
import PMButton from "../../themeComponents/PMButton";
import IElement from "../../../Interfaces/IElement";
import IIndicator from "../../../Interfaces/IIndicator";
import IStation from "../../../Interfaces/IStation";
import SaveCancelButtons from "../../Shared/Buttons/SaveCancelButtons";
import { validateInput, units } from "../DataPosting/inputValidations";
import { Prompt } from "react-router";
import IUnsavedPlan from "../../../Interfaces/IUnsavedPlan";
import { compareToInitialObject } from "../../../services/helpers";

type IProps = {
  isShowUnsaveModal: boolean;
  planToChange: IPlan | undefined;
  setIsUnsavedPlan: Dispatch<SetStateAction<IUnsavedPlan | undefined>>;
  setIsShowUnsaveModal: Dispatch<SetStateAction<boolean>>;
  isUnsaved: () => boolean;
  isShowEditMode: boolean;
  setEnableGlobalPlansEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

const TrainingPlansMainView: React.FC<IProps> = (props: IProps) => {
  const {
    isShowUnsaveModal,
    setIsUnsavedPlan,
    setIsShowUnsaveModal,
    isUnsaved,
    planToChange,
    isShowEditMode,
    setEnableGlobalPlansEditing,
  } = props;
  const { isAdmin } = useContext(UserContext);
  const { t } = useTranslation();
  const [plan, setPlan] = useState<IPlan | undefined>();
  const [initialPlan, setInitialPlan] = useState<IPlan | undefined>();
  const [isShowCancelModal, setIsShowCancelModal] = useState<boolean>(false);
  const [leavePlan, setLeavePlan] = useState<boolean>(false);
  const [currentElements, setCurrentElements] = useState<IElement[]>([]);

  // Timeout before saving
  const SAVE_TIMEOUT = 1500;

  // Selects states
  const plansState = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  );

  const indicators = useSelector<AppState, IIndicatorReducer>(
    (state) => state.indicators
  );

  const stations = useSelector<AppState, IStationReducer>(
    (state) => state.stations
  );

  const elements = useSelector<AppState, IElementsReducer>(
    (state) => state.elements
  );

  const editMode = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).editMode;

  const savePlan = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).savePlan;

  const dispatch = useDispatch<Dispatch<any>>();

  // Fetches indicator type (for indicator dropdown)
  useEffect(() => {
    dispatch(fetchIndicatorsTypes());
    initUnsavedPlan();
  }, []);

  useEffect(() => {
    if (leavePlan) {
      setLeavePlan(false);
      handleConfirmationToLeavePlan();
    }
  }, [planToChange, leavePlan]);

  const initUnsavedPlan = (): void => {
    const initPlan = {
      isPlanUnsaved: false,
      indicators: {
        isIndicatorsUnsaved: false,
        stations: {
          isStationsUnsaved: false,
          elements: {
            isElementsUnsaved: false,
          },
        },
      },
    };
    setIsUnsavedPlan(initPlan);
  };

  useEffect(() => {
    const { initialFixedPlan, originalFixedPlan } = fixPlanDateForCompare(
      plan,
      initialPlan
    );
    setIsUnsavedPlan((prev: IUnsavedPlan | undefined) => ({
      ...prev!,
      isPlanUnsaved: compareToInitialObject(
        initialFixedPlan,
        originalFixedPlan
      ),
    }));
  }, [plan]);

  const fixPlanDateForCompare = (
    plan: IPlan | undefined,
    initialPlan: IPlan | undefined
  ) => {
    let tempPlan = {
      ...plan!,
      startDate: new Date(plan?.startDate!).setHours(0, 0, 0, 0),
      endDate: new Date(plan?.endDate!).setHours(0, 0, 0, 0),
    };
    let tempInitialPlan = {
      ...initialPlan!,
      startDate: new Date(initialPlan?.startDate!).setHours(0, 0, 0, 0),
      endDate: new Date(initialPlan?.endDate!).setHours(0, 0, 0, 0),
    };
    return { initialFixedPlan: tempInitialPlan, originalFixedPlan: tempPlan };
  };

  // Input validations
  const checkInputErrors = (data: (IElement | IIndicator | IStation)[]) => {
    return (
      data.filter((object: IElement | IIndicator | IStation) =>
        object.errors?.find((error: string) => error !== "")
      ).length === 0
    );
  };

  // Saving plans details
  useEffect(() => {}, [plansState.savePlan]);

  // Sets plan state to the selected plan
  useEffect(() => {
    setPlan(plansState.selectedPlan);
    setInitialPlan(plansState.selectedPlan);
  }, [plansState.selectedPlan]);

  // Sets plans according to edit mode
  useEffect(() => {
    if (!editMode && savePlan <= 0) {
      setPlan(plansState.selectedPlan);
    }
  }, [editMode]);

  // Handles Add measurement clicks
  const addMeasurementHandler = () => {
    // If didn't reach maximum indicators number
    if (indicators.indicators.length < indicators.indicatorsTypes.length) {
      dispatch({
        type: ADD_INDICATOR,
        indicator: {
          id: indicators.indicators.length + 1,
          name: "",
          weight: 0,
          threshold: 0,
          indicatorRenderId: indicators.indicators.length + 1,
        },
      });
    } else {
      customToast.error(t("maximumIndicators"));
    }
  };

  // Handles changes in date
  const dateChangeHandler = (range: IDateRange) => {
    setPlan({
      ...plan!,
      startDate: range.startDate,
      endDate: range.endDate,
    });
  };

  // Handles change in plan details (Mainly name/lethality)
  const onChangePlanDetailsHandler = (field: string, value: string): void => {
    let err: string = validateInput(value, units.Grade);
    setPlan({ ...plan!, [field]: value !== "" ? value : null, error: err });
  };

  // Handles plan save
  const onSaveChanges = () => {
    //on save the plan close the global plans editing
    setEnableGlobalPlansEditing(false);
    if (isAdmin) {
      // If there're errors, raise toast
      if (
        !checkInputErrors(indicators.indicators) ||
        !checkInputErrors(stations.stations) ||
        !checkInputErrors(elements.elements) ||
        plan?.error
      )
        customToast.error(t("savePlanError"));
      else {
        customToast.info(t("savingPlan"));
        dispatch({ type: SET_EDIT_MODE });
        dispatch({ type: SAVE_PLAN_BUTTON_CHANGE });
        dispatch({
          type: UPDATE_PLAN,
          plan: {
            id: plan?.id,
            name: plan?.name,
            startDate: plan?.startDate,
            endDate: plan?.endDate,
            lethality: plan?.lethality,
            isTest: plan?.isTest,
          },
        });
        setTimeout(() => {
          dispatch(savePlanAttributes(plan!, initialPlan, "savePlan", t));
          dispatch(
            saveIndicators(
              indicators.indicators,
              plansState.selectedPlan?.id!,
              t
            )
          );
          dispatch(
            saveStations(stations.stations, plansState.selectedPlan?.id!, t)
          );
          dispatch(
            saveElements(elements.elements, plansState.selectedPlan?.id!, t)
          );
        }, SAVE_TIMEOUT);
      }
      setCurrentElements(elements.elements);
      initUnsavedPlan();
    }
  };

  const onCancelChanges = () => {
    if (isUnsaved()) {
      setIsShowCancelModal(true);
    } else {
      handleConfirmation();
    }
  };

  const handleConfirmation = () => {
    //on cancel the plan saving close the global plans editing
    setEnableGlobalPlansEditing(false);

    if (editMode) {
      dispatch({ type: SET_EDIT_MODE });
    } else {
      initSelectedPlan();
    }
    dispatch({
      type: SET_ELEMENTS,
      elements: currentElements,
    });
    initUnsavedPlan();
  };

  const initSelectedPlan = () => {
    setPlan(plansState.selectedPlan);
    setInitialPlan(plansState.selectedPlan);
  };

  const handleConfirmationToLeavePlan = () => {
    dispatch({
      type: SET_ELEMENTS,
      elements: currentElements,
    });
    dispatch({
      type: SELECTED_PLAN,
      selectedPlan: planToChange,
      editMode: isShowEditMode,
    });
    initUnsavedPlan();
  };

  useEffect(() => {
    if (elements.elements.length !== 0 && currentElements.length === 0) {
      setCurrentElements(elements.elements);
    }
  }, [elements]);

  return plan ? (
    <div className="planGrid">
      <Prompt when={isUnsaved()} message={t("noSavePlanChanges")} />
      <IonRow className="row1TP">
        <IonCol
          size="2.93"
          className={`${plansState.editMode ? "" : "viewMode"}`}
        >
          <Input
            inputName="name"
            placeholder={t("name")}
            inputType="text"
            inputValue={plan?.name}
            onChangeHandler={onChangePlanDetailsHandler}
            length="long"
            cssClass="tpInput"
          ></Input>
        </IonCol>
        {!plan.isGlobal && (
          <IonCol
            size="2.58"
            className={`${plansState.editMode ? "" : "viewMode"}`}
          >
            <div>
              <DatePicker
                mode="range"
                rangeDate={{
                  startDate: new Date(Date.parse(plan?.startDate!.toString())),
                  endDate: new Date(Date.parse(plan?.endDate!.toString())),
                }}
                setRangeDate={dateChangeHandler}
              />
            </div>
          </IonCol>
        )}
        <IonCol
          size="2.58"
          className={`${plansState.editMode ? "" : "viewMode"}`}
        >
          <Input
            inputName="lethality"
            placeholder={t("lethalityThreshold")}
            inputType="number"
            inputValue={plan?.lethality}
            onChangeHandler={onChangePlanDetailsHandler}
            length="short"
            cssClass={`tpInput ${plan.error !== "" ? "tpInputError" : null}`}
          ></Input>
        </IonCol>
        <IonCol
          size="3.9"
          className={` addButtonTp`}
          hidden={!plansState.editMode}
        >
          {isAdmin ? (
            <div className="buttonWrap">
              <PMButton
                color="tertiary"
                fill="outline"
                icon={{ iconSrc: add, color: "light", size: "xLarge" }}
                onClickHandler={addMeasurementHandler}
                isDisabled={!plansState.editMode}
                label={{
                  fontSize: "medium",
                  fontColor: "light",
                  fontFamily: "Regular",
                }}
                cssClass="tpAddButton"
              >
                {t("addMeasurement")}
              </PMButton>
            </div>
          ) : null}
        </IonCol>
      </IonRow>
      <h1 className="lethalityTitle">{t("lethalityMeasurements")}</h1>
      <IonRow className="indicatorsRow">
        <Indicators
          setIsUnsavedPlan={setIsUnsavedPlan}
          currentElements={currentElements}
          setCurrentElements={setCurrentElements}
        ></Indicators>
      </IonRow>
      <IonRow className="row3TP">
        {plansState.editMode || (isUnsaved && isUnsaved()) ? (
          <IonRow className="buttons">
            <SaveCancelButtons
              onCancelClickHandler={onCancelChanges}
              onSaveClickHandler={onSaveChanges}
            ></SaveCancelButtons>
          </IonRow>
        ) : null}
      </IonRow>
      <Alert
        header={t("cancelChangesAlert")}
        isOpen={isShowCancelModal}
        setIsOpen={setIsShowCancelModal}
        actionOnSave={handleConfirmation}
        actionOnCancel={() => setIsShowCancelModal(false)}
      />
      <Alert
        header={t("noSavePlanChanges")}
        isOpen={isShowUnsaveModal}
        setIsOpen={setIsShowUnsaveModal}
        actionOnSave={() => setLeavePlan(true)}
        actionOnCancel={() => setIsShowUnsaveModal(false)}
      />
    </div>
  ) : null;
};

export default TrainingPlansMainView;
