import { IonButton, IonContent, IonDatetime, IonModal } from "@ionic/react";

import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import "./DateDrawer.css";
import { IDateRange } from "../../../Interfaces/IDatePicker";
import PMLabel from "../../themeComponents/PMLabel";
import EDateDrawer from "../../../Enums/EDateDrawer";

type Props = {
  idToControl: string;
  rangeDate: IDateRange | undefined;
  setRangeDate: React.Dispatch<React.SetStateAction<IDateRange | undefined>>;
  drawerState: EDateDrawer.selectEnd | EDateDrawer.selectStart;
  setDrawerState: React.Dispatch<
    React.SetStateAction<EDateDrawer.selectStart | EDateDrawer.selectEnd>
  >;
};

const DateDrawer = (props: Props) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const { t } = useTranslation();
  const dateTimeRef = useRef<null | HTMLIonDatetimeElement>(null);
  const [tempDate, setTempDate] = useState<IDateRange>({
    startDate: new Date(new Date().setHours(0, 0, 0, 0)),

    endDate: new Date(new Date().setHours(23, 59, 59)),
  });
  const [flagToEdit, setFlagToEdit] = useState<boolean>(false);

  const saveSelectedDate = () => {
    if (dateTimeRef !== null) {
      let realDate: any = dateTimeRef!.current;
      let year = realDate.activePartsClone.year;
      let month = realDate.activePartsClone.month;
      let day = realDate.activePartsClone.day;

      const tempDateToCheck = new Date(year, month - 1, day, 0, 0, 0);

      // checking if we already selected the starting date
      if (props.drawerState === EDateDrawer.selectStart) {
        //if there is an error with the selected date, choose the date of today
        if (tempDateToCheck.toString() === "Invalid Date") {
          setTempDate((prev) => {
            return {
              startDate: new Date(new Date().setHours(0, 0, 0, 0)),
              endDate: prev?.endDate,
            };
          });
        } else {
          setTempDate((prev) => {
            return {
              startDate: new Date(year, month - 1, day, 0, 0, 0),
              endDate: prev?.endDate,
            };
          });
        }
        // after the first date has been selected, change the state of the drawer
        props.setDrawerState(EDateDrawer.selectEnd);

        // checking if we on the second state of the drawer
      } else if (props.drawerState === EDateDrawer.selectEnd) {
        //if there is an error with the selected date, choose the date of today
        if (tempDateToCheck.toString() === "Invalid Date") {
          setTempDate((prev) => {
            return {
              startDate: prev?.startDate,
              endDate: new Date(new Date().setHours(23, 59, 59)),
            };
          });
        } else {
          setTempDate((prev) => {
            return {
              startDate: prev?.startDate,
              endDate: new Date(year, month - 1, day, 23, 59, 59),
            };
          });
          setFlagToEdit(true);
        }
      }
    }
  };

  useEffect(() => {
    // in order to deal with race condition between setTempDate &  props.setDrawerState.
    if (tempDate?.startDate && tempDate?.endDate && flagToEdit) {
      // if we get here its means that we have the start date and the end date.
      props.setRangeDate(tempDate);

      //close the drawer and reset all the variables
      modal.current?.dismiss();
      props.setDrawerState(EDateDrawer.selectStart);
      setFlagToEdit(false);
    }
  }, [tempDate]);

  const clearSelectedDate = () => {
    dateTimeRef.current?.reset();
  };

  return (
    <div>
      <IonModal
        ref={modal}
        className="modal-sheet"
        trigger={props.idToControl}
        initialBreakpoint={0.5}
        breakpoints={[0, 0.4, 0.6, 1]}
        keepContentsMounted={true}
      >
        <IonContent className="drawer-container">
          <div className="drawer-div-inside-container">
            <div className="drawer-header-bottom-padding">
              <PMLabel
                fontSize="large"
                fontColor="xLight"
                fontFamily="Bold"
                margin
              >
                {props.drawerState === EDateDrawer.selectStart ? (
                  t("selectStartDate")
                ) : props.drawerState === EDateDrawer.selectEnd ? (
                  t("selectEndDate")
                ) : (
                  <></> // shouldn't get here. just in case we will want to add a different drawer state in the future.
                )}
              </PMLabel>
            </div>
            <IonDatetime
              key="dateTime"
              ref={dateTimeRef}
              size="cover"
              presentation="date"
              preferWheel={true}
              locale="he-IL"
              color={"tertiary"}
            ></IonDatetime>
          </div>
          <div className="drawer-buttons-wrapper">
            <IonButton
              className="drawer-solid-button"
              fill="solid"
              onClick={() => saveSelectedDate()}
            >
              {t("applyDataHistoryButton")}
            </IonButton>
            <IonButton
              className="drawer-outline-button"
              onClick={() => clearSelectedDate()}
              fill="outline"
            >
              {t("clearDataHistoryButton")}
            </IonButton>
          </div>
        </IonContent>
      </IonModal>
    </div>
  );
};

export default DateDrawer;
