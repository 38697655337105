import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Axios from "../../../../Axios";
import { baseUrlPMBackend } from "../../../../Configurations/consts";
import ForcesContext from "../../../../context/ForcesContext/forcesContext";
import IFinalGradeDisplay from "../../../../Interfaces/IFinalGradeDisplay";
import IForceFromOrbat from "../../../../Interfaces/IForceFromOrbat";
import IForceTreeNode from "../../../../Interfaces/IForceTreeNode";
import ILabel from "../../../../Interfaces/ILabel";
import IPlan from "../../../../Interfaces/IPlan";
import IThreshold from "../../../../Interfaces/IThreshold";
import useGetPerformanceGrades, {
  FilteredIndicatorsGrades,
} from "../../../CustomHooks/useGetPerformanceGrades";
import useGetPerformanceTrends from "../../../CustomHooks/UseGetPerformaneTrends";
import useInitGraphsData from "../../../CustomHooks/useInitGraphsData";
import { Option } from "react-dropdown";
import "./Competency.css";
import Dropdown from "../../../Shared/Dropdown/Dropdown";
import i18n from "../../../../services/i18next";
import RadarGraph from "../../../Shared/Charts/Radar/RadarGraph";
import FinalGradeDisplay from "../../../Shared/FinalGradeDisplay/FinalGradeDisplay";
import BarChart from "../../../Shared/Charts/Bar/BarChart";
import IndicatorBarChart from "../../IndicatorBarChart/IndicatorBarChart";

import { Swiper, SwiperSlide } from "swiper/react";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import ITrendIndicator from "../../../../Interfaces/ITrendIndicator";
import PMIcon from "../../../themeComponents/PMIcon";
import Spinner from "../../../Shared/Spinner/Spinner";
import PMLabel from "../../../themeComponents/PMLabel";
import { getRelevantPlans } from "../../../../services/plansSharedFunctions";
import PMLoading from "../../../Shared/Alert/PMLoading";
import { UserContext } from "../../../../context/UserContext/userContext";
import usePlans from "../../../CustomHooks/usePlans";

const Competency = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const { plans, plansOptions, selectedPlan, onPlanSelectedHandler } =
    usePlans();
  const [isPlansLoading, setIsPlansLoading] = useState<boolean>(true);
  const [isGradesLoading, setIsGradesLoading] = useState<boolean>(true);
  const [isTrendsLoading, setIsTrendsLoading] = useState<boolean>(true);
  // const checkedForces: IForceTreeNode[] = useContext(ForcesContext).forces;
  const [force, setForce] = useState<IForceFromOrbat[]>([]);
  const [plan, setPlan] = useState<IPlan>();
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [detailsThresholds, setDetailsThresholds] = useState<IThreshold[]>(
    [] as IThreshold[]
  );
  const [relevantPlansIds, setRelevantPlansIds] = useState<number[]>([]);

  const [detailsLabels, setDetailsLabels] = useState<ILabel[]>([] as ILabel[]);
  const [currentSwiperPage, setCurrentSwiperPage] = useState<number>(0);
  const [swiperRef, setSwiperRef] = useState<any>(null);

  useEffect(() => {
    if (user.relatedForce) {
      setForce([
        {
          forceType: user.relatedForce.force_type,
          id: user.relatedForce.id,
          isSoldier: user.relatedForce.nodes === null,
          name: user.relatedForce.name,
          color: "#2334B5",
        },
      ]);
    }
  }, [user]);
  useEffect(() => {
    force.length &&
      getRelevantPlans(force.map((force) => force.id)).then((response: any) => {
        response.data &&
          setRelevantPlansIds(
            response.data.map((plan: IPlan) => Number(plan.id))
          );
      });
  }, [force]);

  const {
    activePlanFinalGrades,
    indicatorsGrades,
    trainingTypesGrades,
    planDetailsFinalGrades,
  } = useGetPerformanceGrades(
    selectedPlan,
    force,
    [],
    [],
    setIsGradesLoading,
    undefined,
    undefined
  );

  const {
    labelsCompetency,
    labelsTrends,
    // relevantLabelsTrends,
    thresholdsCompetency,
    thresholdsTrends,
    trendsIndicators,
  } = useInitGraphsData(
    undefined,
    t,
    selectedPlan,
    setDetailsLabels,
    setDetailsThresholds
  );

  const { trendsBarChartsData } = useGetPerformanceTrends(
    selectedPlan,
    plans,
    force,
    [],
    [],
    trendsIndicators,
    setIsTrendsLoading,
    true
  );

  useEffect(() => {
    if (selectedPlan) {
      setIsPlansLoading(false);
    }
  }, [selectedPlan]);
  useEffect(() => {
    if (
      !isPlansLoading &&
      !trainingTypesGrades.length &&
      !planDetailsFinalGrades.length &&
      !indicatorsGrades.length
    )
      setIsDataLoading(true);
    else {
      setIsDataLoading(false);
    }
  }, [
    isPlansLoading,
    trainingTypesGrades,
    planDetailsFinalGrades,
    indicatorsGrades,
  ]);

  return (
    <IonPage>
      <IonContent>
        <PMLoading
          isOpen={isDataLoading}
          message={t("loadingData")}
          spinner="bubbles"
        ></PMLoading>{" "}
        {isPlansLoading ? (
          <Spinner className="competency-spinner" />
        ) : (
          <IonGrid className="compGrid scrollL">
            <IonRow className="compDropRow">
              <IonCol size="12">
                <div className="headerPersonalRow">
                  <PMLabel
                    fontColor="light"
                    fontFamily="Regular"
                    fontSize="large"
                  >
                    {force[0].name}
                  </PMLabel>
                  <Dropdown
                    disabled={false}
                    onChange={onPlanSelectedHandler}
                    placeholder={t("selectPlan")}
                    value={`${
                      selectedPlan && selectedPlan?.id !== undefined
                        ? `${selectedPlan.name} ${
                            selectedPlan.isActive ? `(${t("active")})` : ""
                          }`
                        : t("selectPlan")
                    }`}
                    options={plansOptions}
                    controlClassName="Dropdown mobileDropdown"
                    arrowClassName="DropdownArrow"
                    placeholderClassName="DropdownPlaceholder"
                    menuClassName="DropdownMenu mobileDropdownMenu"
                  ></Dropdown>
                </div>
              </IonCol>
            </IonRow>
            <IonRow className="stRow">
              <IonCol size="3" className="compCol lethality">
                {planDetailsFinalGrades.map(
                  (grade: IFinalGradeDisplay, gradeIdx: number) => (
                    <FinalGradeDisplay
                      key={gradeIdx}
                      soldierFinalGrade={grade.soldierFinalGrade}
                      threshold={grade.threshold}
                      isLegend={false}
                    />
                  )
                )}
              </IonCol>
              <IonCol size="3.73" className="compCol">
                <RadarGraph
                  id="csvRadarImage"
                  radarData={FilteredIndicatorsGrades(
                    indicatorsGrades,
                    labelsCompetency
                  )}
                  selectedForces={force}
                  thresholds={thresholdsCompetency}
                  labels={labelsCompetency}
                  newForces={force}
                  forcesToRemove={undefined}
                  plan={plan}
                />
              </IonCol>
              <IonCol size="5" className="compCol">
                <BarChart
                  id="csvBarImage"
                  barChartData={trainingTypesGrades}
                  thresholds={detailsThresholds}
                  labels={detailsLabels}
                  selectedForces={force}
                  newForces={force}
                  forcesToRemove={undefined}
                  barsType={"trainingTypes"}
                  plan={plan}
                  isDesktop
                />
              </IonCol>
            </IonRow>
            <IonRow className="ndRow">
              <IonCol size="11.9" className="compCol">
                {trendsBarChartsData.length ? (
                  <div className="barPersonalZone">
                    <PMLabel
                      fontColor="light"
                      fontSize="large"
                      fontFamily="Light"
                      cssClass="trendsTitle"
                    >
                      {trendsIndicators[currentSwiperPage].name}
                    </PMLabel>
                    <Swiper
                      className="pzSwiper"
                      spaceBetween={50}
                      slidesPerView={1}
                      allowTouchMove
                      loop
                      onSlideChange={(event) => {
                        setCurrentSwiperPage(event.realIndex);
                      }}
                      onSwiper={setSwiperRef}
                    >
                      {trendsIndicators.map((indicator: ITrendIndicator) => (
                        <SwiperSlide key={indicator.id}>
                          <div className="trendSlide" key={indicator.id}>
                            <IndicatorBarChart
                              key={indicator.id}
                              indicatorId={indicator.id}
                              thresholds={thresholdsTrends}
                              labels={labelsTrends} //if we want relevant plans just switch to relevantLabelsTrends
                              selectedForces={force}
                              newForces={force}
                              forcesToRemove={undefined}
                              barChartsData={trendsBarChartsData}
                              isDesktop={true}
                              plan={plan}
                              trends
                              lineOrBarTypeToggle={"scatter"}
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className="arrows">
                      <IonRow class="ion-justify-content-center">
                        <PMIcon
                          isButton
                          iconSrc={
                            i18n.language === "he"
                              ? EIconsSrc.CHEVRON_RIGHT
                              : EIconsSrc.CHEVRON_LEFT
                          }
                          onClick={() => swiperRef.slidePrev()}
                          size="xLarge"
                          color="light"
                        />
                        <PMIcon
                          isButton
                          iconSrc={
                            i18n.language === "he"
                              ? EIconsSrc.CHEVRON_LEFT
                              : EIconsSrc.CHEVRON_RIGHT
                          }
                          onClick={() => swiperRef.slideNext()}
                          size="xLarge"
                          color="light"
                        />
                      </IonRow>
                    </div>
                  </div>
                ) : (
                  <Spinner className="competency-spinner" />
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Competency;
