import React, { FC, useContext, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";
import {
  getHeatById,
  getHeatsByShootingDayId,
  getShootingDayById,
} from "../../../../../apis/ShootingRangeAPI";
import ForcesContext from "../../../../../context/ForcesContext/forcesContext";
import EIconsSrc from "../../../../../Interfaces/EIconsSrc";
import useNavigation from "../../../../CustomHooks/useNavigation";
import Spinner from "../../../../Shared/Spinner/Spinner";
import MobileHeader from "../../../MobileHeader/MobileHeader";
import OrbatTreeModal from "../../../OrbatTreeModal/OrbatTreeModal";
import ResultsScreenHeader from "./ResultsScreenHeader/ResultsScreenHeader";
import TraineesList from "./ShootingRangeTraineesList/TraineesList";
import NotExistLabel from "../../../../Shared/NotExistLabel/NotExistLabel";
import IShootingRangeForce from "../../../../../Interfaces/IShootingRangeForce";
import ResultsButtons from "./ResultsButtons/ResultsButtons";
import { sendDrillToBBackend } from "../../../../Desktop/DataPosting/dataPostingHelpers";
import EEventType from "../../../../../Interfaces/EEventType";
import { getActivePlan } from "../../../../../apis/PlansAPI";
import IPlan from "../../../../../Interfaces/IPlan";
import IShootingHeat from "../../../../../Interfaces/IShootingHeat";
import IShootingHeatType from "../../../../../Interfaces/IShootingHeatType";
import { URLs } from "../../../../../Configurations/urls";
import IShootingDay from "../../../../../Interfaces/IShootingDay";
import { getChildrenSoldiers } from "../../../../../services/helpers";
import { getFinalResultsByTypes } from "../../../../../services/shootingRangeFunctions";
import "./ResultsScreen.css";

type Props = {};
const TRAINING_TYPE_ID: number = 11;

const ResultsScreen: FC<Props> = (props: Props): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { navigate } = useNavigation();
  const [hashtags, setHashtags] = useState<string>("");
  const [currentShootingDayName, setCurrentShootingDayName] =
    useState<string>();
  const [isFirstEnter, setIsFirstEnter] = useState<boolean>(true);
  const [isLast, setIsLast] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [unsavedResults, setUnsavedResults] = useState<boolean>();
  const [selectedPlanId, setSelectedPlanId] = useState<number | undefined>();
  const [trainees, setTrainees] = useState<IShootingRangeForce[]>();
  const [traineesToSave, setTraineesToSave] = useState<IShootingRangeForce[]>(
    []
  );
  const { state } = useLocation<{
    planId: number;
    shootingDayName: string;
    heats: IShootingHeat[];
    selectedHeat: IShootingHeat;
  }>();
  const params = useParams<{ shootingDayId: string; heatId: string }>();
  const [currentHeat, setCurrentHeat] = useState<IShootingHeat>();
  const [heats, setHeats] = useState<IShootingHeat[]>();
  const {
    forces: checkedForce,
    isForcesTreeOpen,
    setIsForcesTreeOpen,
  } = useContext(ForcesContext);

  useEffect(() => {
    initializeData();
  }, []);

  useEffect(() => {
    setUnsavedResults(traineesToSave.length !== 0 && isValid);
  }, [traineesToSave, isValid]);

  useEffect(() => {
    if (!isFirstEnter) {
      initializeData();
    }
    setIsFirstEnter(false);
  }, [params.heatId]);

  useEffect(() => {
    if (trainees) {
      setLoading(false);
      checkValidation();
    }
  }, [trainees]);

  useEffect(() => {
    if (currentHeat) {
      getHashtags();
    }
  }, [currentHeat]);

  useEffect(() => {
    getTraineesByCheckedForce();
  }, [checkedForce]);

  useEffect(() => {
    if (heats && currentHeat) {
      setIsLast(lastIndex(heats, currentHeat.id) === heats.length);
    }
  }, [heats, currentHeat]);

  const initializeData = () => {
    if (trainees) {
      setLoading(false);
    }
    if (!state) {
      getCurrentActivePlan();
      getCurrentShootingDay();
      getCurrentHeat();
      getHeats();
    } else {
      setSelectedPlanId(() => state.planId);
      setCurrentShootingDayName(() => state.shootingDayName);
      setCurrentHeat(() => state.selectedHeat);
      setHeats(state.heats);
    }
    getTraineesByCheckedForce();
  };

  const getCurrentHeat = async () => {
    await getHeatById(+params.shootingDayId, +params.heatId).then(
      (response: AxiosResponse<IShootingHeat | undefined>) =>
        setCurrentHeat(response.data)
    );
  };

  const getCurrentShootingDay = async () => {
    await getShootingDayById(+params.shootingDayId).then(
      (response: AxiosResponse<IShootingDay | undefined>) =>
        setCurrentShootingDayName(response.data?.name)
    );
  };

  const getHeats = async () => {
    await getHeatsByShootingDayId(+params.shootingDayId).then(
      (response: AxiosResponse<IShootingHeat[] | undefined>) =>
        setHeats(response.data)
    );
  };

  const getCurrentActivePlan = async () => {
    await getActivePlan().then((response: AxiosResponse<IPlan | undefined>) =>
      setSelectedPlanId(response.data?.id)
    );
  };

  const getHashtags = () => {
    setHashtags(
      `${currentHeat?.name.replaceAll(
        " ",
        "_"
      )} ${state.shootingDayName.replaceAll(" ", "_")}`
    );
  };

  const getTraineesByCheckedForce = () => {
    setLoading(true);
    if (checkedForce[0]) {
      setTrainees(getChildrenSoldiers(checkedForce[0], []));
    }
  };

  const checkValidation = () =>
    setIsValid(
      () =>
        !trainees!.some(
          (trainee: IShootingRangeForce) => trainee.isValidResult === false
        )
    );

  const onUpdateData = (): void => {
    saveData();
    setTraineesToSave([]);
  };

  const saveData = async () => {
    await Promise.all(
      traineesToSave.map(async (trainee: IShootingRangeForce, index: number) =>
        saveDataOfTraineeById(trainee, index === traineesToSave.length - 1)
      )
    );
  };

  const saveDataOfTraineeById = async (
    trainee: IShootingRangeForce,
    showToast: boolean
  ) => {
    const time: Date = new Date();
    let types: IShootingHeatType = getFinalResultsByTypes(
      trainee.result,
      currentHeat!.type
    );
    const shootingRangeDrill = {
      MessageType: EEventType.SHOOTING_RANGE_MESSAGE_TYPE,
      TimeStamp: time,
      SoldierID: trainee.id,
      TrainingTypeID: TRAINING_TYPE_ID,
      WeaponType: trainee.weaponType,
      NumOfShots: currentHeat?.bullets,
      RedHits: types.numberOfHits,
      Grouping: types.grouping,
      Score: types.score,
      PlanId: selectedPlanId,
      DrillStartTime: time,
      Hashtags: hashtags.split(" "),
    };

    sendDrillToBBackend(shootingRangeDrill, undefined, undefined, t, showToast);
  };

  const lastIndex = (heatsList: IShootingHeat[], currentId: number): number =>
    heatsList.findIndex((heat: IShootingHeat) => heat.id === currentId) + 1;

  const goToNextHeat = (unsavedResults: boolean | undefined) => {
    const nextHeatIndex: number = lastIndex(heats!, currentHeat!.id);
    if (unsavedResults) {
      setOpenAlert(true);
    }
    if (nextHeatIndex !== heats?.length && !unsavedResults) {
      navigate(
        `${URLs.stations.shootingRange.shootingDays.heats.results.getURL(
          +params.shootingDayId,
          heats![nextHeatIndex].id
        )}`,
        {
          planId: state.planId,
          shootingDayName: currentShootingDayName,
          heats: heats,
          selectedHeat: heats![nextHeatIndex],
        }
      );
    } else {
      if (nextHeatIndex !== heats?.length) {
        setIsLast(true);
      }
      setOpenAlert(true);
    }
  };

  const returnToPreviousPage = () => {
    navigate(
      `${URLs.stations.shootingRange.shootingDays.heats.getURL(
        +params.shootingDayId
      )}`,
      {
        planId: state.planId,
        shootingDayName: currentShootingDayName,
      }
    );
  };

  return (
    <div className="results-screen-wrapper">
      <MobileHeader
        isUserDisplay={false}
        title={t("liveShooting")}
        iconStartSrc={
          i18n.language === "he"
            ? EIconsSrc.BACK_ICON_HE
            : EIconsSrc.BACK_ICON_EN
        }
        iconStartHandler={returnToPreviousPage}
      />
      <div className="results-screen">
        {checkedForce.length > 0 ? (
          <div className="results-list">
            <ResultsScreenHeader currentHeat={currentHeat} />
            {loading ? (
              <Spinner />
            ) : (
              <>
                <TraineesList
                  trainees={trainees!}
                  setTrainees={setTrainees}
                  modifiedTrainees={traineesToSave}
                  setModifiedTrainees={setTraineesToSave}
                  heat={currentHeat}
                />
                <ResultsButtons
                  onUpdateData={onUpdateData}
                  goToNextHeat={goToNextHeat}
                  openAlert={openAlert}
                  setOpenAlert={setOpenAlert}
                  isLastHeat={isLast}
                  unsavedResults={unsavedResults}
                />
              </>
            )}
          </div>
        ) : (
          <NotExistLabel
            text="noSelectedForces"
            className="no-selected-forces"
          />
        )}
      </div>
      <OrbatTreeModal
        limit={1}
        isOpen={isForcesTreeOpen}
        close={() => setIsForcesTreeOpen(false)}
        mode="secondary"
      />
    </div>
  );
};

export default ResultsScreen;
