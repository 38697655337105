import React from "react";
import { Bar } from "react-chartjs-2";
import "./VerticalProgressBar.css";
import Colors from "../../../Interfaces/Colors";
import "chartjs-plugin-datalabels";

interface IProps {
  value: number;
  threshold: number;
  percent100: number;
  color?: "green" | "red" | "yellow" | "gray" | "purple" | string;
}
const VerticalProgressBar: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { percent100, value, threshold, color } = props;

  const percent = Math.round((value / percent100) * 100);
  const percentageThreshold = Math.round((threshold / percent100) * 100);
  return (
    <div className="progress">
      <div className="bar">
        <div
          className={`percent ${color}VPB`}
          style={{ top: `${100 - percent}%`, height: `${percent}%` }}
        ></div>
        {value !== percent100 ? (
          <span className="percentValue" style={{ top: `${102 - percent}%` }}>
            {value}
          </span>
        ) : null}
      </div>
      <span className="percentValue">{percent100}</span>
      <div
        className="threshold"
        style={{
          top: `${100 - percentageThreshold}%`,
          height: `${percentageThreshold}%`,
        }}
      ></div>{" "}
      {threshold !== percent100 && threshold !== value ? (
        <span
          className="percentValue"
          style={{ top: `${102 - percentageThreshold}%` }}
        >
          {threshold}
        </span>
      ) : null}
    </div>
  );
};

export default VerticalProgressBar;
