import React, { FC, useContext, useEffect, useState } from "react";
import { IonCol, IonGrid, IonRow, IonContent, IonPage } from "@ionic/react";
import "./Stations.css";
import StationButtonComp from "../../../components/Mobile/SiteManagement/StationButtonComp";
import IStationDetails from "../../..//Interfaces/IStationDetails";
import Axios from "../../../Axios";
import { useTranslation } from "react-i18next";
import {
  baseUrlPMBackend,
  environment,
  mode,
  trainingTypeMode,
} from "../../../Configurations/consts";
import MobileHeader from "../../../components/Mobile/MobileHeader/MobileHeader";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import MobileTitleRow from "../../../components/Mobile/MobileHeader/MobileTitleRow";
import Dropdown from "../../../components/Shared/Dropdown/Dropdown";
import IPlan from "../../../Interfaces/IPlan";
import { Option } from "react-dropdown";
import EEnvironment from "../../../Enums/EEnvironment";
import OrbatTreeModal from "../../../components/Mobile/OrbatTreeModal/OrbatTreeModal";
import ForcesContext from "../../../context/ForcesContext/forcesContext";
import { SHOOTING_RANGE_STATION_NAME } from "../../../Configurations/shootingRangeConsts";
import usePlans from "../../../components/CustomHooks/usePlans";
import EMode from "../../../Enums/EMode";

const Stations: FC = () => {
  const { t } = useTranslation();
  const [stationsList, setStationsList] = useState<IStationDetails[]>([]);
  const { plansOptions, selectedPlan, onPlanSelectedHandler } = usePlans();
  const cloudStationsNames = [SHOOTING_RANGE_STATION_NAME, 'לש"ביה מגנט'];
  const { isForcesTreeOpen, setIsForcesTreeOpen } = useContext(ForcesContext);

  const loadStations: Function = async () => {
    let stations: IStationDetails[] = [];
    try {
      const URI = `${baseUrlPMBackend}siteManagement/getStations`;
      const stationsObject = await Axios.get(URI);
      stations = stationsObject.data;
    } catch (error) {
      stations = [];
    }
    return stations;
  };

  // Fetches stations
  const fetchStations: Function = async () => {
    const result = await loadStations();
    return result;
  };
  useEffect(() => {
    let isCancelled = false;
    fetchStations().then((data: IStationDetails[]) => {
      if (!isCancelled) {
        setStationsList(data);
      }
    });

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <IonPage className="contentContainerSBL">
      <IonContent className="contentContainerSBL" scrollY={false}>
        <MobileHeader iconEndSrc={EIconsSrc.MENU_ICON} title={t("stations")} />
        <IonGrid className="gridContainerSBL">
          <MobileTitleRow title={t("siteManagement")} />
          {environment === EEnvironment.production ? (
            <IonRow className="inputPlanRow">
              <Dropdown
                disabled={false}
                onChange={onPlanSelectedHandler}
                placeholder={t("selectPlan")}
                value={selectedPlan?.name || ""}
                options={plansOptions}
                controlClassName="Dropdown mobileDropdown inputsDropdown"
                arrowClassName="DropdownArrow"
                placeholderClassName="DropdownPlaceholder competencyPlaceholder"
                menuClassName="DropdownMenu mobileDropdownMenu competencyDropdownMenu "
              ></Dropdown>
            </IonRow>
          ) : null}

          <div className="scrollContainerSBL mobileScroll">
            <IonRow className="rowContainerSBL">
              {stationsList
                .filter(
                  (station) =>
                    mode === EMode.site ||
                    (mode === EMode.station &&
                      station.stationName === trainingTypeMode)
                )
                ?.map((station, index) =>
                  // If production or development
                  environment === EEnvironment.production ||
                  environment === EEnvironment.development ? (
                    // Check if current station name is in cloud stations or development env
                    cloudStationsNames.includes(station.stationName) ||
                    environment === EEnvironment.development ? (
                      <IonCol key={station.stationId}>
                        <StationButtonComp
                          station={station}
                          planId={selectedPlan?.id}
                        ></StationButtonComp>
                      </IonCol>
                    ) : null
                  ) : station.stationName !== SHOOTING_RANGE_STATION_NAME ? (
                    // If we're not in production, just generate all stations
                    <IonCol
                      className={index % 2 === 0 ? "left" : "right"}
                      key={station.stationId}
                    >
                      <StationButtonComp
                        station={station}
                        planId={selectedPlan?.id}
                      ></StationButtonComp>
                    </IonCol>
                  ) : null
                )}
            </IonRow>
          </div>
          <OrbatTreeModal
            limit={3}
            isOpen={isForcesTreeOpen}
            close={() => setIsForcesTreeOpen(false)}
            mode="secondary"
          />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Stations;
