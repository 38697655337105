import { IonItem, IonLabel } from "@ionic/react";
import React, { FC } from "react";
import "./PopoverItem.css";
import { IPopoverItem } from "../../Interfaces/IPMTheme";
const PopoverItem: FC<IPopoverItem> = (props: IPopoverItem) => {
  const {
    children,
    background,
    onClickHandler,
    fontColor,
    fontFamily,
    fontSize,
    hidden,
  } = props;
  return (
    <div className={`popOverDiv ${background} `}>
      {!hidden ? (
        <IonItem
          mode="md"
          button
          onClick={onClickHandler}
          className={`popoverItem ${background} ${fontColor} ${fontFamily} ${fontSize}PI`}
        >
          {children}
        </IonItem>
      ) : null}
    </div>
  );
};
export default PopoverItem;
