import "./AdminPassword.css";
import React, { SyntheticEvent, useEffect, useState } from "react";
import Input from "../TrainingPlan/TrainingPlanInput";
import { IonButton, IonLabel } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import customToast from "../../Shared/Toast/CustomToast";
import PMButton from "../../themeComponents/PMButton";
import PMInput from "../TrainingPlan/PMInput";

interface passModalProps {
  onSendPasswordHandler: (password: string) => void;
  invalid: boolean;
  text: string;
  onCancelHandler?: () => void;
}
const AdminPassword = (props: passModalProps) => {
  const [password, setPassword] = useState<string>("");
  const [inputType, setInputType] = useState<"password" | "text">("password");
  const [icon, setIcon] = useState<string>(eyeOutline);
  const [invalidInput, setInvalidInput] = useState<boolean>(false);
  const { t } = useTranslation();

  // Handles text change
  const onPasswordChangeHandler = (name: string, value: string) => {
    setPassword(value);
    password.length > 0 ? setInvalidInput(false) : console.log();
  };

  // Handles icon click
  const onIconClickHandler = () => {
    setIcon((prev) => (prev === eyeOutline ? eyeOffOutline : eyeOutline));
    setInputType((prev) => (prev === "password" ? "text" : "password"));
  };

  // Handles form submit
  const formSubmitHandler = (e: SyntheticEvent) => {
    props.onSendPasswordHandler(password);
    e.preventDefault();
  };

  // Changes text box and invalid according to correct/incorrect password
  useEffect(() => {
    if (props.invalid) {
      setPassword("");
    }
    setInvalidInput(props.invalid === true);
  }, [props.invalid]);

  useEffect(() => {
    if (invalidInput) customToast.error(t("wrongPassword"));
  }, [invalidInput]);

  return (
    <form className="form" onSubmit={formSubmitHandler}>
      <div className="passDiv">
        <IonLabel className="passLabel">{props.text}</IonLabel>

        <PMInput
          inputType={inputType}
          inputName="password"
          width="long"
          isPassword
          inputValue={password}
          onChangeHandler={onPasswordChangeHandler}
          icon={icon}
          iconInInput
          onIconClick={onIconClickHandler}
          invalid={invalidInput}
        />
        <div className="buttonsRow">
          <PMButton
            label={{
              fontColor: "light",
              fontFamily: "Light",
              fontSize: "medium",
            }}
            type="submit"
            color="tertiary"
            size="small"
            onClickHandler={formSubmitHandler}
          >
            {t("submit")}
          </PMButton>
          {props.onCancelHandler ? (
            <PMButton
              onClickHandler={() =>
                props.onCancelHandler ? props.onCancelHandler() : () => {}
              }
              label={{
                fontColor: "light",
                fontFamily: "Light",
                fontSize: "medium",
              }}
              fill={"clear"}
              color="tertiary"
              size="small"
            >
              {t("cancel")}
            </PMButton>
          ) : null}
        </div>
      </div>
    </form>
  );
};

export default AdminPassword;
