import React from "react";
import "./DragModal.css";
import Draggable from "react-draggable";
import { createPortal } from "react-dom";

interface IDragModal {
  children?: JSX.Element[] | JSX.Element;
  isOpen: boolean;
  onDismiss: () => void;
  cssClass?: string;
}

const portalDiv = document.getElementById("root")!;
const DragModal = (props: IDragModal) => {
  return createPortal(
    <>
      {props.isOpen && (
        <div className="backgroundBlackPage" onClick={props.onDismiss}>
          <Draggable>
            <div
              className={`modal ${props.cssClass}`}
              onClick={(e) => e.stopPropagation()}
            >
              {props.children}
            </div>
          </Draggable>
        </div>
      )}
    </>,
    portalDiv
  );
};

export default DragModal;
