import { useMsal } from "@azure/msal-react";
import { AxiosResponse } from "axios";
import { useContext, useEffect, useState } from "react";
import Axios from "../../Axios";
import {
  scope,
  apiUri,
  environment,
  baseUrlPMBackend,
} from "../../Configurations/consts";
import { UserContext } from "../../context/UserContext/userContext";
import EEnvironment from "../../Enums/EEnvironment";
import IUser from "../../Interfaces/IUser";
import { userRoles } from "../../services/routeRoles";

const useLogin = (setIsLoginLoading: (state: boolean) => void) => {
  const { login } = useContext(UserContext);
  const { instance } = useMsal();
  const [userEmailAddress, setUserEmailAddress] = useState<string>("");
  const [isPersonalZone, setIsPersonalZone] = useState<boolean>(false);

  useEffect(() => {
    if (environment === EEnvironment.localSite) {
      let user = JSON.parse(localStorage.getItem("user")!);
      if (user && user.role !== userRoles.Unauthorized) {
        login(user);
        setIsLoginLoading(false);
        setIsPersonalZone(user.relatedForce !== undefined);
      } else {
        setIsLoginLoading(false);
      }
    } else {
      const tokenHandler = async () => {
        try {
          let tokenResponse = await instance.handleRedirectPromise();
          let accountObj;
          if (tokenResponse) {
            accountObj = tokenResponse.account;
          } else {
            accountObj = await instance.getAllAccounts()[0];
          }

          if (accountObj && tokenResponse) {
            console.log(
              "[AuthService.init] Got valid accountObj and tokenResponse"
            );
            setUserEmailAddress(accountObj.username);
            Axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${tokenResponse.accessToken}`;
          } else if (accountObj) {
            console.log(
              "[AuthService.init] User has logged in, but no tokens."
            );

            try {
              tokenResponse = await instance.acquireTokenSilent({
                account: instance.getAllAccounts()[0],
                scopes: [
                  scope ? apiUri!.toString() + "/" + scope.toString() : "",
                ],
              });
            } catch (err) {
              tokenResponse = await instance.acquireTokenSilent({
                scopes: [
                  scope ? apiUri!.toString() + "/" + scope.toString() : "",
                ],
              });
              console.error(err);
            }
            if (tokenResponse) {
              setUserEmailAddress(accountObj.username);
              localStorage.setItem("access_token", tokenResponse.accessToken);
              Axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${tokenResponse.accessToken}`;
            }
          } else {
            console.log(
              "[AuthService.init] No accountObject or tokenResponse present. User must now login."
            );
            await instance.loginRedirect({
              scopes: [
                scope ? apiUri!.toString() + "/" + scope.toString() : "",
              ],
            });
            window.location.reload();
          }
        } catch (error) {
          console.error(
            "[AuthService.init] Failed to handleRedirectPromise()",
            error
          );
          localStorage.clear();
          window.location.reload();
          return false;
        }
      };

      tokenHandler();
    }
  }, []);

  const authorizeUser = async () => {
    // Checks if logged user (Azure) is a user in db
    let userResponse: any = await Axios.get(
      `${baseUrlPMBackend}users/authUser`,
      {
        params: { emailAddress: userEmailAddress },
      }
    ).catch((error) => console.log(error));
    let user: IUser = userResponse?.data;
    if (user?.role !== userRoles.Unauthorized) {
      login(user);
      setIsLoginLoading(false);
      setIsPersonalZone(user.relatedForce !== undefined);
    } else {
      setIsLoginLoading(false);
    }
  };
  useEffect(() => {
    // If got user email address from azure login, start user authorization
    if (userEmailAddress !== "") {
      authorizeUser();
    }
  }, [userEmailAddress]);

  return { isPersonalZone };
};

export default useLogin;
