import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonPage,
  IonRow,
} from "@ionic/react";
import "./SiteManagement.css";
import SquaresList from "../../../components/Desktop/SiteManagement/SquaresList";
import NumbersList from "../../../components/Desktop/SiteManagement/NumbersList";
import IStationsTraineesDetails from "../../../Interfaces/IStationsTraineesDetails";
import ITraineeDetails from "../../../Interfaces/ITraineeDetails";
import ICheckInOutData from "../../../Interfaces/ICheckInData";
import { useWebsocketCheckIn } from "../../../services/siteManagementSocket";
import Axios from "../../../Axios";
import { useTranslation } from "react-i18next";

import { baseUrlPMBackend, environment } from "../../../Configurations/consts";
import Alert from "../../../components/Shared/Alert/Alert";
import PMLoading from "../../../components/Shared/Alert/PMLoading";
import IPMCheckOut from "../../../Interfaces/IPMCheckOut";
import customToast from "../../../components/Shared/Toast/CustomToast";
import AdminPasswordModal from "../../../components/Desktop/AdminPassword/AdminPasswordModal";
import EEventType from "../../../Interfaces/EEventType";
import PMButton from "../../../components/themeComponents/PMButton";
import EEnvironment from "../../../Enums/EEnvironment";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import Tooltip from "react-tooltip-lite";
import PMLabel from "../../../components/themeComponents/PMLabel";

const SiteManagement: React.FC = () => {
  const { t } = useTranslation();
  const [receivedMessage] = useWebsocketCheckIn();
  const MIN_COL_LENGTH = 15;
  const [maxColumnLength, setMaxColumnLength] =
    useState<number>(MIN_COL_LENGTH);
  const [invalid, setInvalid] = useState<boolean>(false);
  const [isClearITC, serIsClearITC] = useState<boolean>(false);
  const [traineesInStations, setTraineesList] = useState<
    IStationsTraineesDetails[]
  >([]);
  const [singleTraineeToRemove, setSingleTraineeToRemove] = useState<
    ITraineeDetails | undefined
  >();
  const [selectedTrainees, setSelectedTrainees] = useState<ITraineeDetails[]>(
    []
  );
  const [openDeleteAlert, setOpenDeleteAlert] = useState<boolean>(false);
  const [isToRemoveMultipleTrainees, setIsToRemoveMultipleTrainees] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalTraineesInSite, setTraineesInSite] = useState<number>(0);

  const onSendPasswordHandler = async (password: string) => {
    setInvalid(false);
    const isPasswordValid = await Axios.post(
      `${baseUrlPMBackend}users/authAdminPassword`,
      {
        params: { password: password },
      }
    );
    if (isPasswordValid.data) {
      removeTraineeFromITC();
      serIsClearITC(false);
    } else {
      setInvalid(true);
    }
  };

  /**
   *create empty List for every ion-item
   */
  const createEmptyList: Function = (maxColumnLen: number) => {
    let emptyTraineesList: ITraineeDetails[] = new Array<ITraineeDetails>(
      maxColumnLen
    ).fill({
      traineeId: -1,
      traineeName: "",
      laneId: null,
      newInColumn: false,
      stationId: -1,
      uniqId: -1,
      forceType: "",
    });
    return emptyTraineesList;
  };

  /**
   *gets check in data - with station id and trainee details and remove them from all stations
   */
  const removeTraineeFromOtherStations: Function = (
    ICheckInData: ICheckInOutData
  ) => {
    let traineesInStationsList: IStationsTraineesDetails[] = [
      ...traineesInStations,
    ];

    for (let index = 0; index < traineesInStationsList.length; index++) {
      const element = traineesInStationsList[index];
      const isSameTrainee = (trainee: ITraineeDetails) =>
        Number(trainee.uniqId) === Number(ICheckInData.traineeDetails.uniqId);
      const elementIndex: number =
        element.traineesDetails.findIndex(isSameTrainee);
      if (elementIndex !== -1) {
        element.traineesDetails.splice(elementIndex, 1);
        element.columnLength = element.columnLength - 1;
      }
    }
    setTraineesList(traineesInStationsList); //update the UI
    updateMaxColLength(traineesInStationsList); //update the maximum List length anf -> UI of every station to the maximum
  };
  const removeTraineeFromStation = (checkInData: ICheckInOutData) => {
    const isSameStation = (element: IStationsTraineesDetails) =>
      element.stationDetails.stationId === checkInData.stationId;
    let traineesInStationsList: IStationsTraineesDetails[] = [
      ...traineesInStations,
    ];
    const index: number = traineesInStationsList.findIndex(isSameStation); // get the station index in the list
    if (index === -1) return;
    const element = traineesInStationsList[index];
    const isSameTrainee = (trainee: ITraineeDetails) =>
      Number(trainee.uniqId) === Number(checkInData.traineeDetails.uniqId);
    const elementIndex: number =
      element.traineesDetails.findIndex(isSameTrainee);
    if (elementIndex !== -1) {
      element.traineesDetails.splice(elementIndex, 1);
      element.columnLength = element.columnLength - 1;
    }

    setTraineesList(traineesInStationsList); //update the UI
    updateMaxColLength(traineesInStationsList); //update the maximum List length anf -> UI of every station to the maximum
  };
  /**
   * the const get check-in data with station id
   * and trainee stationName and adds it to the the station trainees list.
   * @param ICheckInData
   */
  const addTraineeToStation: Function = (checkInData: ICheckInOutData) => {
    const isSameStation = (element: IStationsTraineesDetails) =>
      element.stationDetails.stationId === checkInData.stationId;
    let traineesInStationsList: IStationsTraineesDetails[] = [
      ...traineesInStations,
    ];
    const index: number = traineesInStationsList.findIndex(isSameStation); // get the station index in the list
    if (index === -1) return;
    removeTraineeFromOtherStations(checkInData);

    const isEmpty = (element: ITraineeDetails) => element.traineeName === "";
    const emptyPlaceIndex: number =
      traineesInStationsList[index].traineesDetails.findIndex(isEmpty); // get the index of next empty place in the list

    if (emptyPlaceIndex !== -1) {
      //empty place exists
      traineesInStationsList[index].traineesDetails[emptyPlaceIndex] =
        checkInData.traineeDetails;
    } else {
      traineesInStationsList[index].traineesDetails = [
        ...traineesInStationsList[index].traineesDetails,
        checkInData.traineeDetails,
      ];
    }
    traineesInStationsList[index].columnLength =
      traineesInStationsList[index].columnLength + 1;
    setTraineesList(traineesInStationsList); // update UI
    updateMaxColLength(traineesInStationsList); //update the maximum length of List
  };
  /**
   *update all others station trainees list length with empty rows - UI only
   */
  const updateAllStationsUI: Function = (
    maxLength: number,
    traineesInStationsList: IStationsTraineesDetails[]
  ) => {
    for (let index = 0; index < traineesInStationsList.length; index++) {
      const emptyList: ITraineeDetails[] = createEmptyList(maxLength);
      traineesInStationsList[index].traineesDetails = traineesInStationsList[
        index
      ].traineesDetails
        .concat(emptyList)
        .slice(0, maxLength);
    }
    setTraineesList(traineesInStationsList);
  };

  //find the maximum length of every station traineesList and update the UI
  const updateMaxColLength: Function = (
    traineesInStationsList: IStationsTraineesDetails[]
  ) => {
    let max = 0,
      sum = 0;
    traineesInStationsList.map((list: IStationsTraineesDetails) => {
      if (list.stationDetails.isCheckIn) {
        sum = sum + list.columnLength;
        max = max > list.columnLength ? max : list.columnLength;
      }
    });
    max = max < MIN_COL_LENGTH ? MIN_COL_LENGTH : max;
    updateAllStationsUI(max, traineesInStationsList);
    setTraineesInSite(sum);
    setMaxColumnLength(max);
  };

  const setNewColumnForTrainee = (uniqId: number, stationId: number) => {
    setTraineesList((prev: IStationsTraineesDetails[]) => {
      const isSameStation = (
        stationsTraineesDetails: IStationsTraineesDetails
      ) => stationsTraineesDetails.stationDetails.stationId === stationId;
      const stationIndex: number = prev?.findIndex(isSameStation);
      if (stationIndex === -1) return prev;
      const isSameTrainee = (traineesDetails: ITraineeDetails) =>
        Number(traineesDetails.uniqId) === Number(uniqId);
      const traineeIndex: number =
        prev[stationIndex].traineesDetails.findIndex(isSameTrainee);
      if (traineeIndex === -1) return prev;
      prev[stationIndex].traineesDetails[traineeIndex].newInColumn = false;
      return prev;
    });
  };
  const removeTraineeFromITC = () => {
    Axios.delete(`${baseUrlPMBackend}checkInOutOperation/ITCCheckout`)
      .then((res) => {
        customToast.success(`${t("checkoutITCSucceed")}`);
        setTraineesList((prev: IStationsTraineesDetails[]) => {
          const isSameStation = (
            stationsTraineesDetails: IStationsTraineesDetails
          ) =>
            Number(stationsTraineesDetails.stationDetails.stationId) ===
            Number(res.data.data.stationId);
          const stationIndex: number = prev?.findIndex(isSameStation);
          if (stationIndex === -1) return prev;

          let soldierArrayLength = prev[stationIndex].traineesDetails.filter(
            (traineeData) =>
              traineeData.traineeId !== -1 && traineeData.traineeName !== ""
          ).length;
          prev[stationIndex].traineesDetails = createEmptyList(maxColumnLength);
          setTraineesInSite((prev) => prev - soldierArrayLength);

          return [...prev];
        });
      })
      .catch((error) => {
        customToast.error(`${t("checkoutITCFailed")}`);
        console.error(error);
      });
  };

  /** If the user cancel the operation from the alert */
  const onCancel = () => {
    setSingleTraineeToRemove(undefined);
    setOpenDeleteAlert(false);
    setIsToRemoveMultipleTrainees(false);
  };
  /** Send Pm-checkout for each trainee */
  const onDelete = async () => {
    //close the alert
    setOpenDeleteAlert(false);
    setIsToRemoveMultipleTrainees(false);

    let traineesToRemove: ITraineeDetails[] = [];
    //traineesToRemove is the selected trainees if the multiple trash button clicked
    if (isToRemoveMultipleTrainees) traineesToRemove = selectedTrainees;
    //and the singleTraineeToRemove if the user clicked only on one trainee
    else if (singleTraineeToRemove) traineesToRemove = [singleTraineeToRemove];
    try {
      //send pm-checkout for each trainee
      let results = await Promise.all(
        traineesToRemove.map((trainee) => {
          const pmCheckOut: IPMCheckOut = {
            MessageType: EEventType.PM_CHECKOUT,
            SoldierID: trainee?.traineeId,
            LaneID: String(trainee?.laneId),
            TimeStamp: new Date(),
            ForceId: trainee?.uniqId,
          };
          return Axios.post(
            `${baseUrlPMBackend}checkInOutOperation/PMCheckout`,
            pmCheckOut
          );
        })
      );
      if (results[0].status === 200) {
        customToast.success(`${t("checkoutSucceed")}`);
        //reset the remove multiple trainees states
        if (isToRemoveMultipleTrainees) {
          setSelectedTrainees([]);
        } else {
          //reset the remove single trainee state
          setSingleTraineeToRemove(undefined);
        }
      } else customToast.error(`${t("checkoutFailed")}`);
    } catch (error) {
      customToast.error(`${t("checkoutFailed")}`);
    }
  };

  /**
   * add or remove trainee from selected trainees when clicked
   */
  const onTraineeClicked = (
    traineeDetails: ITraineeDetails,
    isToAdd: boolean
  ) => {
    setSelectedTrainees((prev) => {
      if (isToAdd) return prev ? [...prev, traineeDetails] : [traineeDetails];
      else
        return prev?.filter(
          (trainee) => +trainee.uniqId !== +traineeDetails.uniqId
        );
    });
  };

  useEffect(() => {
    let mounted: boolean = true;

    const loadSiteStatus: Function = async () => {
      let traineesInStations: IStationsTraineesDetails[] = [];
      try {
        const URI = `${baseUrlPMBackend}siteManagement/getSiteStatus`;
        let siteStatus = await Axios.get(URI);
        traineesInStations = siteStatus.data;
      } catch (error) {}
      mounted && updateMaxColLength(traineesInStations);
      return traineesInStations;
    };

    // load all the data and set it in the main array
    const loadsStationsData: Function = async () => {
      const result = await loadSiteStatus();
      mounted && setTraineesList(result); // set the result to the UI
      mounted && setLoading(false);
    };

    loadsStationsData();
    return () => {
      mounted = false;
    };
  }, []);

  /**
   * when we received a massage update the UI
   */
  useEffect(() => {
    if (traineesInStations.length !== 0) {
      switch (receivedMessage?.eventType) {
        case EEventType.CHECK_IN:
          addTraineeToStation(receivedMessage);
          break;
        case EEventType.CHECK_OUT:
          removeTraineeFromStation(receivedMessage);
          break;
        case EEventType.SITE_CHECKOUT:
          removeTraineeFromOtherStations(receivedMessage);
          break;
      }
    }
  }, [receivedMessage]);

  useEffect(() => {
    //if the user clicked the trash button for single trainee or multiple trainees open the alert
    if (isToRemoveMultipleTrainees || singleTraineeToRemove !== undefined)
      setOpenDeleteAlert(true);
  }, [isToRemoveMultipleTrainees, singleTraineeToRemove]);

  return (
    <IonPage className="contentContainerSL">
      <PMLoading
        isOpen={loading}
        message={t("loadingData")}
        spinner={"bubbles"}
      />
      <Alert
        isOpen={openDeleteAlert}
        setIsOpen={setOpenDeleteAlert}
        header={
          isToRemoveMultipleTrainees
            ? t("removeMultipleTraineesQuestion")
            : `${t("deleteQues")} ${singleTraineeToRemove?.traineeName} ${t(
                "fromStation"
              )}?`
        }
        actionOnSave={onDelete}
        actionOnCancel={onCancel}
      />
      <div className="borderSL">
        <div className="SMHeader">
          <div className="totalSL">
            <IonLabel className="totalLabelSL">{t("overallInSite")}</IonLabel>
            <IonLabel className="totalLabelSL">{totalTraineesInSite}</IonLabel>
          </div>
          {selectedTrainees?.length > 1 ? (
            <Tooltip content={t("tooltipRemoveAllSelectedSoldier")}>
              <PMButton
                cssClass={"trashAllButton"}
                fill="clear"
                icon={{
                  iconSrc: EIconsSrc.TRASH_ICON,
                  color: "xLight",
                  size: "large",
                }}
                onClickHandler={() => {
                  setIsToRemoveMultipleTrainees(true);
                }}
                label={{
                  fontSize: "large",
                  fontColor: "xLight",
                  fontFamily: "Regular",
                }}
              >
                {t("removeTrainees")}
              </PMButton>
            </Tooltip>
          ) : (
            <></>
          )}
        </div>

        <IonGrid className="gridSL">
          <IonRow className="scrollContainerSL">
            <div className="wrapStationColumnsSL">
              <div className="numbersListContainerSL">
                <IonCol className="gridColumnSL">
                  <NumbersList length={maxColumnLength} />
                </IonCol>
              </div>
              {traineesInStations.map((station, index) => (
                <IonCol
                  className="gridColumnSL"
                  key={index}
                  hidden={!station?.stationDetails?.isCheckIn}
                >
                  <SquaresList
                    key={index}
                    stationName={station?.stationDetails?.stationName}
                    stationTraineesDetails={station?.traineesDetails}
                    setSingleTraineeToRemove={setSingleTraineeToRemove}
                    isAlertOpen={openDeleteAlert}
                    singleTraineeToRemove={singleTraineeToRemove}
                    setNewColumnForTrainee={setNewColumnForTrainee}
                    onTraineeClicked={onTraineeClicked}
                  ></SquaresList>
                  {station.stationDetails.stationId === 1 ? (
                    <div className="checkoutITCSM">
                      <PMButton
                        color="tertiary"
                        onClickHandler={() =>
                          environment?.toString() === EEnvironment.localSite
                            ? serIsClearITC(true)
                            : removeTraineeFromITC()
                        }
                        label={{
                          fontSize: "large",
                          fontFamily: "Light",
                          fontColor: "light",
                        }}
                      >
                        {t("checkoutItc")}
                      </PMButton>
                    </div>
                  ) : null}
                </IonCol>
              ))}
            </div>
          </IonRow>
        </IonGrid>
      </div>
      <AdminPasswordModal
        onSendPasswordHandler={(password: string) =>
          onSendPasswordHandler(password)
        }
        onCancelHandler={() => serIsClearITC(false)}
        invalid={invalid}
        text={t("removeItcQuestion")}
        isOpen={
          isClearITC === true &&
          environment?.toString() === EEnvironment.localSite
        }
      ></AdminPasswordModal>
    </IonPage>
  );
};

export default SiteManagement;
