import { IonLabel, IonRow } from "@ionic/react";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "../../../Shared/SearchDropdown/SearchDropdown";
import Input from "../../TrainingPlan/TrainingPlanInput";
import Axios from "../../../../Axios";
import {
  baseUrlPMBackend,
  environment,
} from "../../../../Configurations/consts";
import customToast from "../../../Shared/Toast/CustomToast";
import IUser from "../../../../Interfaces/IUser";
import PMButton from "../../../themeComponents/PMButton";
import { Collapse } from "react-collapse";
import IForceTreeNode from "../../../../Interfaces/IForceTreeNode";
import OrbatTree from "../../../Shared/Orbat/OrbatTree/OrbatTree";
import { useSelector } from "react-redux";
import { IOrbatTreeReducer } from "../../../../redux/reducers/orbatTree";
import { orbatTreeState } from "../../../../redux/store/OrbatTreeStore";
import { getForceById } from "../../../../services/helpers";
import SaveCancelButtons from "../../../Shared/Buttons/SaveCancelButtons";
import { userRoles } from "../../../../services/routeRoles";
import EEnvironment from "../../../../Enums/EEnvironment";

import "./AddUser.css";
interface IOption {
  label: string;
  value: string;
}

interface IEditUserProps {
  mode: "edit" | "add";
  user?: IUser | undefined;
  editUserHandler?: (user: IUser) => void;
  addUserToList?: (user: IUser) => void;
  cancelEditMode?: () => void;
  setUpdateUsersTable: Dispatch<SetStateAction<boolean>>;
}

export const validateRelatedForce = (
  role: string,
  relatedForce: IForceTreeNode | undefined
) => {
  if (
    !relatedForce?.id &&
    (role === userRoles.Instructor || role === userRoles.Viewer)
  ) {
    return false;
  }
  return true;
};
const AddUser = (props: IEditUserProps) => {
  const { t } = useTranslation();
  const [roles, setRoles] = useState<IOption[]>([]);
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [role, setRole] = useState<IOption>({} as IOption);
  const [displayName, setDisplayName] = useState<string>("");
  const [isTreeOpen, setIsTreeOpen] = useState<boolean>(false);
  const [isLocalUser, setIsLocalUser] = useState(false);
  const [password, setPassword] = useState<string>("");
  const [selectedSoldier, setSelectedSoldier] = useState<IForceTreeNode[]>(
    [] as IForceTreeNode[]
  );
  const filterRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const orbatTree: IForceTreeNode = useSelector<
    orbatTreeState,
    IOrbatTreeReducer
  >((state) => state.orbatTree).orbatTree;

  // Adds mouse click events (to close forces tree)
  useEffect(() => {
    document.addEventListener("mousedown", handleViewClick);

    return () => {
      document.removeEventListener("mousedown", handleViewClick);
    };
  }, []);

  // Gets roles to add user
  useEffect(() => {
    let mounted: boolean = true;
    const getAllRoles = async () => {
      let res = await Axios.get(`${baseUrlPMBackend}users/getRoles`);
      if (res.status === 200) {
        mounted &&
          setRoles(
            res.data.map((role: { name: string }) => {
              return { label: t(role.name), value: role.name };
            })
          );
        setIsLoading(false);
      } else {
        customToast.error(t("getRolesError"));
      }
    };
    getAllRoles();

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    setIsLocalUser(environment === EEnvironment.localSite);
  }, []);

  useEffect(() => {
    if (props.user !== undefined) {
      // Reset selected soldier
      setSelectedSoldier([]);

      // Apply user current details
      setDisplayName(props.user.displayName);
      setEmailAddress(props.user.emailAddress);
      setRole({ value: props.user.role, label: t(props.user.role) });

      // Checks if has attached force
      const force = getForceById(
        orbatTree,
        Number(props.user.relatedForce?.id)
      );
      if (force) setSelectedSoldier([force]);
    }
  }, [props.user]);

  useEffect(() => {
    if (
      props.mode === "add" &&
      (emailAddress !== "" ||
        displayName !== "" ||
        password !== "" ||
        selectedSoldier.length !== 0) //array !== [] always be true by reference
    ) {
      resetFields();
    }
  }, [props.mode]);

  // Handles view click (and checks if it's outside forces tree)
  const handleViewClick = (event: MouseEvent) => {
    if (!filterRef.current?.contains(event.target as HTMLElement)) {
      setIsTreeOpen(false);
    }
  };

  const validateEmail = (text?: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(text ? text : emailAddress).toLowerCase());
  };

  // Resets fields after a user has been added
  const resetFields = () => {
    setEmailAddress("");
    setRole({} as IOption);
    setDisplayName("");
    setPassword("");
    setSelectedSoldier([]);

    // If in edit mode, cancels it
    if (props.mode === "edit" && props.cancelEditMode) props.cancelEditMode();
  };

  const addUser = async () => {
    if (
      emailAddress &&
      role.value &&
      displayName &&
      (!isLocalUser || (isLocalUser && password))
    ) {
      if (
        (isLocalUser || validateEmail()) &&
        validateRelatedForce(role.value, selectedSoldier[0])
      ) {
        let res = await Axios.post(`${baseUrlPMBackend}users/addUser`, {
          password: password,
          displayName: displayName,
          emailAddress: emailAddress,
          role: role.value,
          forceId: selectedSoldier[0] ? selectedSoldier[0].id : null,
        });

        if (res.status === 200) {
          let newUserId = res.data[0].id;
          customToast.success(t("userAddSuccess"));
          if (props.mode === "add" && props.addUserToList)
            await props.addUserToList({
              id: newUserId,
              emailAddress: emailAddress,
              displayName: displayName,
              role: role.value,
              relatedForce: selectedSoldier[0] ? selectedSoldier[0] : undefined,
              forceToDisplayInOrbat: { id: -1, forceType: "" },
            });
          props.setUpdateUsersTable((prev: boolean) => !prev);
          resetFields();
        } else {
          customToast.error(t("userAddFail"));
        }
      } else {
        !validateEmail()
          ? customToast.error(t("invalidEmailAddress"))
          : customToast.error(t("instructorOrViewerMustHaveRelatedForce"));
      }
    } else {
      customToast.error(t("missingInformation"));
    }
  };

  const editUser = () => {
    if (props.mode === "edit" && props.user && props.editUserHandler) {
      const user = {
        ...props.user,
        displayName: displayName,
        emailAddress: emailAddress,
        password: password,
        relatedForce: selectedSoldier[0] ? selectedSoldier[0] : undefined,
        role: role.value,
      };
      props.editUserHandler(user);
    }
  };

  // return the options for the user according to the role he have. if the user is viewer or instructor he wouldn't be able to see an "Admin" option
  const optionsToShow = (roles: IOption[], role: IOption) => {
    if (role.value === "Admin") {
      return roles.map((role) => {
        return { label: role.label, value: role.value };
      });
    } else {
      return roles
        .filter((role) => role.value !== "Admin")
        .map((role) => {
          return { label: role.label, value: role.value };
        });
    }
  };

  return (
    <IonRow className="addUserRow">
      {roles ? (
        <IonRow className="inputFields">
          <div className="checkboxDiv">
            {props.mode === "add" ? (
              <>
                <IonLabel className="chklbl">{t("addUserHeader")}</IonLabel>
              </>
            ) : (
              <IonLabel className="chklbl">{t("editUser")}</IonLabel>
            )}
          </div>

          <div className="showName">
            <Input
              cssClass="addUserInput"
              inputName="displayName"
              onChangeHandler={(inputName: string, inputValue: string) =>
                setDisplayName(inputValue)
              }
              inputType="text"
              placeholder={t("displayName")}
              length="short"
              inputValue={displayName}
            ></Input>
          </div>

          {!isLocalUser ? (
            <Input
              inputName="emailAddress"
              inputValue={emailAddress}
              onChangeHandler={(inputName: string, inputValue: string) =>
                setEmailAddress(inputValue)
              }
              inputType="text"
              placeholder={t("emailOrUsername")}
              length="short"
              isEmailAddress
            ></Input>
          ) : (
            <Input
              inputName="userName"
              inputValue={emailAddress}
              onChangeHandler={(inputName: string, inputValue: string) =>
                setEmailAddress(inputValue)
              }
              inputType="text"
              placeholder={t("emailOrUsername")}
              length="short"
            ></Input>
          )}
          <div className="rolesDropdown">
            <Dropdown
              options={optionsToShow(roles, role)}
              onSelect={(option) =>
                setRole({
                  label: option?.label ? option.label : "",
                  value: option?.value ? option.value.toString() : "",
                })
              }
              showIcon={false}
              defaultValue={role}
              title={t("role")}
              hasTitle={true}
              isSettingsPage
            ></Dropdown>
          </div>

          {isLocalUser ? (
            <Input
              inputName="password"
              inputValue={password}
              onChangeHandler={(inputName: string, inputValue: string) =>
                setPassword(inputValue)
              }
              inputType="text"
              placeholder={t("password")}
              length="short"
            ></Input>
          ) : null}
          <div className="forceTreeDiv">
            <div
              onClick={() => {
                setIsTreeOpen((prev) => !prev);
              }}
            >
              <Input
                inputName="name"
                placeholder={t("force")}
                inputType="text"
                inputValue={selectedSoldier[0] ? selectedSoldier[0].name : ``}
                onChangeHandler={() => {}}
                length="short"
              ></Input>
            </div>
            <div>
              <Collapse isOpened={isTreeOpen}>
                <div
                  ref={filterRef}
                  className="treeCollapse scroll_m"
                  hidden={!isTreeOpen}
                >
                  <OrbatTree
                    isRootDisable={role.value !== userRoles.Admin}
                    isForDropDown
                    checked={setSelectedSoldier}
                    readonly
                    checkedForces={selectedSoldier}
                    limit={1}
                  />
                </div>
              </Collapse>
            </div>
          </div>
        </IonRow>
      ) : (
        <div></div>
      )}
      <IonRow className="addButtonRow">
        {props.mode === "add" ? (
          <>
            <PMButton
              color="tertiary"
              label={{ fontColor: "light", fontFamily: "Light" }}
              onClickHandler={addUser}
              size="small"
            >
              {t("add")}
            </PMButton>
          </>
        ) : (
          <div className="editButtonsDiv">
            <SaveCancelButtons
              onCancelClickHandler={resetFields}
              onSaveClickHandler={editUser}
            ></SaveCancelButtons>
          </div>
        )}
      </IonRow>
    </IonRow>
  );
};

export default AddUser;
