import React, { useState, useEffect } from "react";
import "./TreeNode.css";

// services
import { useTranslation } from "react-i18next";
import {
  getForceById,
  getForceFullName,
} from "../../../../../services/helpers";

// ionic
import { IonIcon, IonLabel, IonRow } from "@ionic/react";
import { trashOutline, ellipsisVerticalOutline } from "ionicons/icons";

// interfaces
import IForceTreeNode from "../../../../../Interfaces/IForceTreeNode";
import Alert from "../../../Alert/Alert";
import PMIcon from "../../../../themeComponents/PMIcon";
import Checkbox from "../../../Checkbox/Checkbox";
import EIconsSrc from "../../../../../Interfaces/EIconsSrc";
import PopoverMenu from "../../../Popover/PopoverMenu";
import PopoverItem from "../../../../themeComponents/PopoverItem";
import PMLabel from "../../../../themeComponents/PMLabel";
import AdminPasswordModal from "../../../../Desktop/AdminPassword/AdminPasswordModal";
import { checkPassword } from "../../../../../services/passwordsHandler";
import { isTwoLevelsAtleast } from "../../../../../services/treeSharedFunctions";
import customToast from "../../../Toast/CustomToast";

interface IProps {
  node: IForceTreeNode;
  onChoose: (force: IForceTreeNode, isSubordinates?: boolean) => void;
  checkedForces: IForceTreeNode[];
  onDelete: (forceId: IForceTreeNode) => void;
  readonly: boolean;
  limit?: number;
  path: (number | null)[];
  removeFromPath: (id: number | null) => void;
  enableReorder: boolean;
  onReorder: (
    forcesToReorder: IForceTreeNode[],
    newParentId: number
  ) => Promise<void>;
  isAdmin?: boolean | true;
  isReport?: boolean;
  setOpenAlert: React.Dispatch<React.SetStateAction<boolean>>;
  disableEditing?: boolean;
  displayPlatoonAndAbove: boolean;
  isRootDisable?: boolean;
  setDraggedElement?: (element: HTMLDivElement | undefined) => void;
  draggedElement?: HTMLDivElement | undefined;
}

const TreeNode: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { setOpenAlert } = props;
  const [showChilds, setShowChilds] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState<boolean>(false);
  const [showTrashButton, setShowTrashButton] = useState<boolean>(false);
  const [currForce, setCurrForce] = useState<IForceTreeNode>(
    {} as IForceTreeNode
  );
  const [openReorderAlert, setOpenReorderAlert] = useState<boolean>(false);
  const [draggable, setDraggable] = useState<boolean>(false);

  const [forcesToReorder, setForcesToReorder] = useState<IForceTreeNode[]>([]);
  const [invalid, setInvalid] = useState<boolean>(false);
  const [popoverState, setShowPopover] = useState<{
    showPopover: boolean;
    event: MouseEvent | undefined;
  }>({
    showPopover: false,
    event: undefined,
  });
  const { t, i18n } = useTranslation();
  useEffect(() => {
    //the ORBET tree node is draggable if the reorder state is enabled and the node the user try to drag is elected
    setDraggable(
      props.enableReorder &&
        props.checkedForces.find((force) => force.id === props.node.id) !==
          undefined
    );
  }, [props.enableReorder, props.node, props.checkedForces]);
  useEffect(() => {
    setCurrForce(props.node);
  }, [props.node]);

  useEffect(() => {
    setInvalid(false);
  }, [openDeleteAlert]);
  useEffect(() => {
    let anyChildInPath: boolean | undefined = props.node.nodes?.some(
      (f: IForceTreeNode) => props.path.includes(f.id)
    );

    anyChildInPath && setShowChilds(true);
  }, [props.path]);

  useEffect(() => {
    if (
      props.checkedForces.find((f: IForceTreeNode) => +f.id === +currForce.id)
    ) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [currForce, props.checkedForces]);

  useEffect(() => {
    forcesToReorder?.length && setOpenReorderAlert(true);
  }, [forcesToReorder]);

  const onExpendingChange = (): void => {
    // remove the current id from the path - if exist
    let currPath: number | null | undefined = props.path.find(
      (p: number | null) => p === currForce.id
    );

    currPath && props.removeFromPath(currPath);

    setShowChilds((prev: boolean) => !prev);
  };
  const onSendPasswordHandler = async (password: string) => {
    const isPasswordValid = await checkPassword(password, setInvalid);
    if (isPasswordValid) {
      onDelete();
    }
  };
  const onDelete: () => void = (): void => {
    setOpenDeleteAlert(false);

    props.onDelete(currForce);
  };

  const validateChoosing = (
    force: IForceTreeNode,
    isSubordinates?: boolean
  ): void => {
    let currFind: IForceTreeNode | undefined = props.checkedForces.find(
      (f: IForceTreeNode) => +f.id === +force.id
    );

    if (
      props.checkedForces.length === props.limit &&
      props.limit !== 1 &&
      !currFind
    ) {
      setOpenAlert(true);
    } else {
      if (currFind) {
        setChecked(false);
      } else {
        setChecked(true);
      }
      props.onChoose(force, isSubordinates);
    }
  };
  const hebrewOffset = i18n.language === "he" ? 150 : 0;

  const dragHandler = (event: any) => {
    //gets the selected item to drag
    const dragItems = document.getElementsByClassName("drag-item");

    //create a new div and append the cloned selected items into it
    let elem = document.createElement("div");

    for (let i = 0; i < dragItems.length; i++) {
      const clonedItem = dragItems[i].cloneNode(true) as Element;
      clonedItem.classList.remove("drag-item"); // remove class names from cloned item
      elem.appendChild(clonedItem);
    }

    // add a css class name for the dragged div
    elem.classList.add("dragged-item");

    //set the position fot the div to be close to the mouse
    elem.style.top = `${event.clientY - window.pageYOffset}px`;
    elem.style.left = `${event.clientX - window.pageXOffset - hebrewOffset}px`;

    // append the div in the dom
    document.body.appendChild(elem);

    //save the element reference in state in order to be able to remove it
    props.setDraggedElement && props.setDraggedElement(elem);

    //insert empty div to the original dragged item
    let emptyDiv = document.createElement("div");
    event.dataTransfer.setDragImage(emptyDiv, 0, 0);

    //pass the checked forces data to the dragging item
    event.dataTransfer.setData(
      "reorderForce",
      JSON.stringify(props.checkedForces)
    );
  };
  /**
   * checking that the new parent id is not a child of one of the transferring forces
   */
  const checkIsValidTransfer = (
    droppedItems: IForceTreeNode[],
    newParentId: number
  ) => {
    let isValid = true;
    droppedItems.map((force) => {
      let findNewForce = getForceById(force, newParentId);
      if (findNewForce) isValid = false;
    });
    return isValid;
  };
  const dropHandler = (event: any) => {
    //on drop the item remove the dragged element from the dom
    if (props.draggedElement) {
      props.draggedElement.remove();
      props.setDraggedElement && props.setDraggedElement(undefined);
    }

    let droppedItems: IForceTreeNode[] = JSON.parse(
      event.dataTransfer.getData("reorderForce")
    );

    let isValidTransfer = checkIsValidTransfer(droppedItems, props.node.id);
    if (isValidTransfer) setForcesToReorder(droppedItems);
    else customToast.error(t("canNotMoveForceIntoItsORBAT"));
  };

  const onReorder = () => {
    if (forcesToReorder) {
      props.onReorder(forcesToReorder, props.node.id);
    }
  };
  const getSelectedForcesNames = (forcesToReorder: IForceTreeNode[]) => {
    return forcesToReorder
      .map((force) => getForceFullName(force?.name, force?.force_type, t))
      .join(`, `);
  };

  return (
    <div className="node-container">
      <div
        draggable={draggable}
        onDragStart={dragHandler}
        onDragOver={(event) => {
          if (props.draggedElement) {
            //for every mouse movement set the dragged element to the mouse position
            props.draggedElement.style.top = `${
              event.clientY - window.pageYOffset
            }px`;
            props.draggedElement.style.left = `${
              event.clientX - window.pageXOffset - hebrewOffset
            }px`;
          }
          event.preventDefault();
        }}
        onDrop={dropHandler}
        onMouseDown={() => {
          props.draggedElement?.remove();
        }}
      >
        <IonRow
          onMouseEnter={() => setShowTrashButton(true)}
          onMouseLeave={() => setShowTrashButton(false)}
        >
          {props.node.nodes?.length ? (
            <PMIcon
              isButton={false}
              onClick={onExpendingChange}
              iconSrc={
                showChilds
                  ? EIconsSrc.ARROW_DOWN
                  : i18n.language === "he"
                  ? EIconsSrc.CHEVRON_LEFT
                  : EIconsSrc.CHEVRON_RIGHT
              }
              color="light"
              size="large" // className="checkbox-tree"
            />
          ) : (
            <div className="chevron-placeholder" />
          )}

          {props.readonly && (
            <>
              <div className="chevron-placeholder" />

              <Checkbox
                isDisabled={
                  props.isRootDisable && props.node.parent_id === null
                }
                isChecked={checked}
                onClicked={(e: any) => {
                  if (!props.isReport || props.node.nodes === null) {
                    validateChoosing(currForce);
                  } else {
                    e.persist();
                    setShowPopover({
                      showPopover: props.isReport ? props.isReport : false,
                      event: props.isReport ? e : undefined,
                    });
                  }
                }}
              />
            </>
          )}
          <div className="label">
            <IonLabel
              onClick={(e: any) => {
                !props.readonly && validateChoosing(currForce);
              }}
              className={` ${
                props.checkedForces.find((force) => force.id === props.node.id)
                  ? "drag-item"
                  : ""
              } ${
                props.checkedForces.includes(currForce) &&
                currForce.id !== undefined &&
                !props.readonly
                  ? "label-selected"
                  : ""
              }`}
            >
              {getForceFullName(props.node.name, props.node.force_type, t)}
            </IonLabel>
          </div>
          {!props.readonly &&
            (props.isAdmin ? (
              <div className="trash-icon">
                {showTrashButton && props.enableReorder && (
                  <IonIcon icon={ellipsisVerticalOutline} />
                )}
                {showTrashButton &&
                  !props.enableReorder &&
                  !props.disableEditing && (
                    <IonIcon
                      icon={trashOutline}
                      onClick={() => setOpenDeleteAlert(true)}
                    />
                  )}
              </div>
            ) : null)}

          <Alert
            isOpen={
              openDeleteAlert &&
              (currForce.nodes === null ||
                currForce.nodes == undefined ||
                !currForce.nodes.find(
                  (son: IForceTreeNode) =>
                    son.nodes !== null && son.nodes?.length
                ))
            }
            setIsOpen={setOpenDeleteAlert}
            header={`${t("verifyingDeletionAlert")} ${getForceFullName(
              currForce.name,
              currForce.force_type,
              t
            )}?`}
            actionOnSave={onDelete}
            actionOnCancel={() => {}}
          />
          <AdminPasswordModal
            isOpen={
              openDeleteAlert &&
              !(
                currForce.nodes === null ||
                currForce.nodes == undefined ||
                !currForce.nodes.find(
                  (son: IForceTreeNode) =>
                    son.nodes !== null && son.nodes?.length
                )
              )
            }
            onSendPasswordHandler={(password: string) =>
              onSendPasswordHandler(password)
            }
            onCancelHandler={() => {
              setOpenDeleteAlert(false);
            }}
            invalid={invalid}
            text={`${t("forceDeletePassword")} ${getForceFullName(
              currForce.name,
              currForce.force_type,
              t
            )}`}
          ></AdminPasswordModal>
          <Alert
            isOpen={openReorderAlert}
            setIsOpen={setOpenReorderAlert}
            header={`${t("reorderAlert1")} ${
              forcesToReorder && getSelectedForcesNames(forcesToReorder)
            } ${t("reorderAlert2")} ${getForceFullName(
              props.node.name,
              props.node.force_type,
              t
            )}?`}
            actionOnSave={onReorder}
            actionOnCancel={() => {}}
          />
          {popoverState.showPopover ? (
            <PopoverMenu
              popoverState={popoverState}
              onDismiss={() =>
                setShowPopover({
                  showPopover: false,
                  event: undefined,
                })
              }
            >
              <PopoverItem
                fontColor="light"
                onClickHandler={() => {
                  validateChoosing(currForce, true);
                  setShowPopover({
                    showPopover: false,
                    event: undefined,
                  });
                }}
                background="Blight"
              >
                <PMLabel
                  textAlign="center"
                  fontFamily="Light"
                  fontSize="medium"
                >
                  {checked ? t("deselectSub") : t("selectSub")}
                </PMLabel>
              </PopoverItem>
              <PopoverItem
                fontColor="light"
                onClickHandler={() => {
                  validateChoosing(currForce, false);
                  setShowPopover({
                    showPopover: false,
                    event: undefined,
                  });
                }}
                background="Blight"
              >
                <PMLabel
                  textAlign="center"
                  fontFamily="Light"
                  fontSize="medium"
                >
                  {checked ? t("deselectOne") : t("selectOne")}
                </PMLabel>
              </PopoverItem>
            </PopoverMenu>
          ) : null}
        </IonRow>
      </div>
      {showChilds &&
        props.node.nodes &&
        props.node.nodes.map((child: IForceTreeNode) => (
          <div key={child.id}>
            {props.displayPlatoonAndAbove &&
            !isTwoLevelsAtleast(child) ? null : (
              <TreeNode
                node={child}
                onChoose={props.onChoose}
                checkedForces={props.checkedForces}
                onDelete={props.onDelete}
                readonly={props.readonly}
                limit={props.limit}
                path={props.path}
                removeFromPath={props.removeFromPath}
                enableReorder={props.enableReorder}
                onReorder={props.onReorder}
                isAdmin={props.isAdmin}
                isReport={props.isReport}
                setOpenAlert={setOpenAlert}
                disableEditing={props.disableEditing}
                displayPlatoonAndAbove={props.displayPlatoonAndAbove}
                setDraggedElement={props.setDraggedElement}
                draggedElement={props.draggedElement}
              />
            )}
          </div>
        ))}
    </div>
  );
};

export default TreeNode;
