import React from "react";
import "./PMLoading.css";

import { IonSpinner, IonText } from "@ionic/react";

interface IProps {
  message: string;
  isOpen: boolean;
  spinner?:
    | "bubbles"
    | "circles"
    | "circular"
    | "crescent"
    | "dots"
    | "lines"
    | "lines-small"
    | undefined;
}

const PMLoading: React.FC<IProps> = (props: IProps) => {
  return (
    <div className="loadingContainer" hidden={!props.isOpen}>
      <div className="loadingPML">
        <IonSpinner
          name={props.spinner}
          className="spinnerPML"
          color="x-light"
        ></IonSpinner>
        <IonText className="loadingMessagePML">{props.message}</IonText>
      </div>
    </div>
  );
};

export default PMLoading;
