import Axios from "../../../Axios";
import Excel, { BorderStyle, Column } from "exceljs";
import { saveAs } from "file-saver";
import bagiraLogoBase64 from "./bagiraLogoBase64";
const exportToCsv = async (
  filename: string,
  headerRows: number[],
  rows: string[][],
  columnsCount: number,
  finalGradesIndex: number[],
  headersRowsIndexToBold: number[][],
  rowsIndexToBorder: number[][],
  dataBorder: number[][],
  imagesRadar: string[],
  picturesRowsRadarIndex: number[],
  picturesRowsBarIndex: number[],
  picturesRowsBadgeIndex: number[],
  t: any,
  fromModal: boolean,
  imagesBar?: string[],
  imagesBadge?: string[], //for spike station
  pic1Height?: number, //for spike station
  pic1Width?: number //for spike station
) => {
  const numbersToLetters = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
  const borderStyle: BorderStyle = "medium";
  const fontSizeHeader = 35;
  const fontSize = 24;
  const picHeight = pic1Height ? pic1Height : 400; //for spike station
  const picWidth = pic1Width ? pic1Width : 850; //for spike station
  let workbook = new Excel.Workbook();
  let worksheet = workbook.addWorksheet("sheet", {
    pageSetup: {
      paperSize: 1,
      orientation: "portrait",
      fitToWidth: 1,
      fitToHeight: 0,

      margins: {
        bottom: 0,
        footer: 0,
        left: 0.3,
        top: 0,
        right: 0.3,
        header: 0,
      },
    },

    views: [{ rightToLeft: true }],
  });

  let columnsObj = [];
  for (let index = 0; index < columnsCount; index++) {
    columnsObj.push({
      header: "",
      key: String(index),
      style: { font: { size: fontSize, name: "Ariel" } },
    });
  }
  worksheet.columns = columnsObj;

  worksheet.columns.forEach((column: Partial<Column>) => {
    column.width = 60;
    column.alignment = { horizontal: t("alignment") };
  });

  rows.forEach((e: string[], index: number) => {
    const rowIndex = index + 1;
    worksheet.addRow({
      ...e,
      amountRemaining: {
        formula: `=C${rowIndex}-D${rowIndex}`,
      },
      percentRemaining: {
        formula: `=E${rowIndex}/C${rowIndex}`,
      },
    });
  });

  let logo = workbook.addImage({
    base64: bagiraLogoBase64,
    extension: "png",
  });
  if (logo !== undefined)
    worksheet.addImage(logo, {
      tl: {
        col: columnsObj.length < 8 ? 7 : columnsObj.length,
        row: 1,
      },
      ext: { width: 100, height: 120 },
      editAs: "absolute",
      // hyperlinks: { hyperlink: "wwww.google.com", tooltip: "bagira.com" },
    });
  if (!fromModal && !imagesBar && imagesRadar.length) {
    let imageId1 = workbook.addImage({
      base64: imagesRadar[0],
      extension: "png",
    });
    if (imageId1 !== undefined)
      worksheet.addImage(imageId1, {
        tl: {
          col: 0.5,
          row: picturesRowsRadarIndex[picturesRowsRadarIndex.length - 1],
        },
        ext: { width: picWidth, height: picHeight },
      });
  } else {
    if (imagesRadar?.length === picturesRowsRadarIndex.length)
      imagesRadar.map((pic: string, index: number) => {
        if (pic) {
          let imageId1 = workbook.addImage({
            base64: pic,
            extension: "png",
          });
          worksheet.addImage(imageId1, {
            tl: { col: 0.5, row: picturesRowsRadarIndex[index] },
            ext: { width: picWidth, height: picHeight },
          });
        }
      });
    if (imagesBar?.length === picturesRowsBarIndex.length)
      imagesBar?.map((pic: string, index: number) => {
        if (pic) {
          let imageId1 = workbook.addImage({
            base64: pic,
            extension: "png",
          });
          worksheet.addImage(imageId1, {
            tl: { col: 0.5, row: picturesRowsBarIndex[index] },
            ext: { width: picWidth, height: picHeight },
          });
        }
      });
    if (imagesBadge?.length === picturesRowsBadgeIndex.length)
      imagesBadge?.map((pic: string, index: number) => {
        if (pic) {
          let imageId1 = workbook.addImage({
            base64: pic,
            extension: "png",
          });
          worksheet.addImage(imageId1, {
            tl: {
              col: columnsObj.length - 1 < 5 ? 5 : columnsObj.length - 1,
              row: picturesRowsBadgeIndex[index],
            },
            ext: { width: 200, height: 230 },
          });
        }
      });
  }
  finalGradesIndex.forEach((element) => {
    worksheet.getRow(element).font = { bold: true, size: fontSize };
  });
  headersRowsIndexToBold.forEach((element) => {
    worksheet.getRow(element[0]).font = { bold: true, size: fontSize };
    // worksheet.getRow(element ).alignment = { horizontal: "center" };

    numbersToLetters.map((column, index) => {
      if (index < element[1])
        worksheet.getCell(`${[column]}${element[0]}}`).border = {
          top: { style: borderStyle },
          bottom: { style: borderStyle },
          left: {
            style: borderStyle,
          },
          right: {
            style: borderStyle,
          },
        };
    });
  });

  dataBorder.forEach((element) => {
    for (let row = element[0]; row < element[1]; row++) {
      // worksheet.getRow(row ).alignment = { horizontal: "center" };
      numbersToLetters.map((column, index) => {
        if (index < element[2])
          worksheet.getCell(`${column}${row}}`).border = {
            left: { style: borderStyle },
            right: { style: borderStyle },
          };
      });
    }
  });
  rowsIndexToBorder.forEach((element) => {
    numbersToLetters.map((column, index) => {
      if (index < element[1])
        worksheet.getCell(`${column}${element[0]}}`).border = {
          left: { style: borderStyle },
          right: { style: borderStyle },
          bottom: { style: borderStyle },
        };
    });
  });

  headerRows.map((headerRow) => {
    worksheet.mergeCells(`A${headerRow}:I${headerRow}`);

    worksheet.getRow(headerRow).alignment = { horizontal: "center" };
    worksheet.getRow(headerRow).font = {
      bold: true,
      underline: true,
      size: fontSizeHeader,
      name: "Ariel",
    };
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const fileExtension = ".xlsx";
  const blob = new Blob([buffer], { type: fileType });
  saveAs(blob, filename + fileExtension);
  return true;
};
export default exportToCsv;

const autoWidth = (worksheet: any, minimalWidth = 40) => {
  worksheet.columns.forEach((column: any) => {
    let maxColumnLength = 0;
    column.eachCell({ includeEmpty: true }, (cell: any) => {
      maxColumnLength = Math.max(
        maxColumnLength,
        minimalWidth,
        cell.value ? cell.value.toString().length : 0
      );
    });
    column.width = maxColumnLength + 2;
  });
};
