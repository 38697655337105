import { useContext } from "react";
import { IonCol, IonRow } from "@ionic/react";
import { useTranslation } from "react-i18next";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import IUser from "../../../../Interfaces/IUser";
import PMIcon from "../../../themeComponents/PMIcon";
import PMLabel from "../../../themeComponents/PMLabel";
import { userRoles } from "../../../../services/routeRoles";
import { UserContext } from "../../../../context/UserContext/userContext";

import "./User.css";

interface IUserProps {
  user: IUser;
  userDeleteHandler: (id: number) => void;
  setIdToDelete: (id: number) => void;
  setIdToEdit: (id: number) => void;
}

const User = (props: IUserProps) => {
  const { t } = useTranslation();

  // context
  const { user } = useContext(UserContext);

  return (
    <div className="userDiv">
      <IonRow className="userRow">
        <IonCol size="10" className="userCol">
          <IonCol size="2.4" className="userDetail">
            <PMLabel fontColor="light">{props.user.displayName}</PMLabel>
          </IonCol>
          <IonCol size="1.8" className="userDetail">
            <PMLabel fontColor="light">{t(props.user.role)}</PMLabel>
          </IonCol>
          <IonCol size="3.3" className="userDetail emailAddress">
            <PMLabel fontColor="light">{props.user.emailAddress}</PMLabel>
          </IonCol>
          <IonCol size="2.7" className="userDetail ">
            <PMLabel fontColor="light">{props.user.relatedForce?.name}</PMLabel>
          </IonCol>
          <IonCol size="2" className="userDetail ">
            <PMLabel fontColor="light">
              {props.user.relatedForce?.brigade_name}
            </PMLabel>
          </IonCol>
        </IonCol>
        <IonCol size="0.5" className="iconsCol">
          <PMIcon
            iconSrc={EIconsSrc.DELETE_OUTLINE}
            onClick={() => props.setIdToDelete(props.user.id)}
            size="medium"
            disabled={user.role === userRoles.Admin ? false : true} // in order to disable to option to delete for whom is not an admin.
            isButton
          ></PMIcon>
          <PMIcon
            iconSrc={EIconsSrc.PENCIL}
            onClick={() => props.setIdToEdit(props.user.id)}
            disabled={user.role === userRoles.Admin ? false : true} // in order to disable to option to edit for whom is not an admin.
            size="medium"
            isButton
            cssClass="pencilIcon"
          ></PMIcon>
        </IonCol>
      </IonRow>
    </div>
  );
};

export default User;
