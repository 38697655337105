import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IPlan from "../../../Interfaces/IPlan";
import PMLabel from "../../themeComponents/PMLabel";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/store/plansStore";
import { IPlansReducer } from "../../../redux/reducers/plans";
import "./ActivePlan.css";

const ActivePlan: FC = () => {
  const { t } = useTranslation();

  const [activePlan, setActivePlan] = useState<IPlan>();

  const plansState = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  );

  useEffect(() => {
    setActivePlan(
      plansState.plans.find((plan) => plan.id === plansState.activePlan)
    );
  }, [plansState]);

  return (
    <div className="active-plan">
      <PMLabel fontSize="medium" fontColor="light" fontFamily="Light">
        {activePlan
          ? `${t("activePlan")} ${activePlan.name}`
          : t("noActivePlanSelected")}
      </PMLabel>
    </div>
  );
};

export default ActivePlan;
