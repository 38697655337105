import { Dispatch } from "react";
import IIndicator from "../../Interfaces/IIndicator";
import { AppActions } from "./rootActions";
import axios from "../../Axios";
import IIndicatorType from "../../Interfaces/IIndicatorType";
import { baseUrlPMBackend } from "../../Configurations/consts";
import customToast from "../../components/Shared/Toast/CustomToast";

export const SET_INDICATORS = "SET_INDICATORS";
export const ADD_INDICATOR = "ADD_INDICATOR";
export const EDIT_INDICATOR = "EDIT_INDICATOR";
export const DELETE_INDICATOR = "DELETE_INDICATOR";
export const FETCH_INDICATORS_REQUEST = "FETCH_INDICATORS_REQUEST";
export const FETCH_INDICATORS_SUCCESS = "FETCH_INDICATORS_SUCCESS";
export const FETCH_INDICATORS_FAILURE = "FETCH_INDICATORS_FAILURE";
export const FETCH_INDICATORS_TYPES_REQUEST = "FETCH_INDICATORS_TYPES_REQUEST";
export const FETCH_INDICATORS_TYPES_SUCCESS = "FETCH_INDICATORS_TYPES_SUCCESS";
export const FETCH_INDICATORS_TYPES_FAILURE = "FETCH_INDICATORS_TYPES_FAILURE";
export const UPDATE_INDICATOR = "UPDATE_INDICATOR";
export const SAVE_INDICATORS_REQUEST = "SAVE_INDICATORS_REQUEST";
export const SAVE_INDICATORS_SUCCESS = "SAVE_INDICATORS_SUCCESS";
export const SAVE_INDICATORS_FAILURE = "SAVE_INDICATORS_FAILURE";
export const RESET_INDICATORS_UPDATE_COUNTER =
  "RESET_INDICATORS_UPDATE_COUNTER";
export const INCREMENT_INDICATORS_UPDATE_COUNTER =
  "INCREMENT_INDICATORS_UPDATE_COUNTER";
export const INCREMENT_INDICATORS_RENDER_COUNTER =
  "INCREMENT_INDICATORS_RENDER_COUNTER";
export const DECREMENT_INDICATORS_RENDER_COUNTER =
  "DECREMENT_INDICATORS_RENDER_COUNTER";
export const RESET_INDICATORS_RENDER_COUNTER =
  "RESET_INDICATORS_RENDER_COUNTER";

export interface SetIndicatorsAction {
  type: typeof SET_INDICATORS;
  indicators: IIndicator[];
}

export interface AddIndicatorAction {
  type: typeof ADD_INDICATOR;
  indicator: IIndicator;
}

export interface EditIndicatorAction {
  type: typeof EDIT_INDICATOR;
  indicator: IIndicator;
}

export interface DeleteIndicatorAction {
  type: typeof DELETE_INDICATOR;
  id: number;
}

export interface fetchIndicatorsRequest {
  type: typeof FETCH_INDICATORS_REQUEST;
}

export interface fetchIndicatorsSuccess {
  type: typeof FETCH_INDICATORS_SUCCESS;
  indicators: IIndicator[];
}

export interface fetchIndicatorsFailure {
  type: typeof FETCH_INDICATORS_FAILURE;
  error: Error | "";
}

export interface fetchIndicatorsTypesRequest {
  type: typeof FETCH_INDICATORS_TYPES_REQUEST;
}

export interface fetchIndicatorsTypesSuccess {
  type: typeof FETCH_INDICATORS_TYPES_SUCCESS;
  indicatorsTypes: IIndicatorType[];
}

export interface fetchIndicatorsTypesFailure {
  type: typeof FETCH_INDICATORS_TYPES_FAILURE;
  error: Error | "";
}

export interface UpdateIndicator {
  type: typeof UPDATE_INDICATOR;
  indicator: IIndicator;
}

export interface SaveIndicatorsRequest {
  type: typeof SAVE_INDICATORS_REQUEST;
}

export interface SaveIndicatorsSuccess {
  type: typeof SAVE_INDICATORS_SUCCESS;
}

export interface SaveIndicatorsFailure {
  type: typeof SAVE_INDICATORS_FAILURE;
  error: Error | "";
}

export interface IncrementUpdateCounter {
  type: typeof INCREMENT_INDICATORS_UPDATE_COUNTER;
}

export interface ResetUpdateCounter {
  type: typeof RESET_INDICATORS_UPDATE_COUNTER;
}

export interface DecrementRenderCounter {
  type: typeof DECREMENT_INDICATORS_RENDER_COUNTER;
}

export interface IncrementRenderCounter {
  type: typeof INCREMENT_INDICATORS_RENDER_COUNTER;
}

export interface ResetRenderCounter {
  type: typeof RESET_INDICATORS_RENDER_COUNTER;
}

export const fetchIndicatorsByPlanId = (planId: number, t: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_INDICATORS_REQUEST,
    });

    axios
      .get(`${baseUrlPMBackend}indicators/getIndicatorsByPlanId`, {
        params: { planId: planId },
      })
      .then((res) => {
        let indicators = res.data.rows.map((indicator: IIndicator) => {
          return { ...indicator, errors: ["", ""] };
        });
        dispatch({ type: FETCH_INDICATORS_SUCCESS, indicators: indicators });
      })
      .catch((error) => {
        dispatch({ type: FETCH_INDICATORS_FAILURE, error: error });
        customToast.error(t("indicatorsLoadError"));
      });
  };
};

export const fetchIndicatorsTypes = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_INDICATORS_TYPES_REQUEST,
    });

    dispatch({ type: RESET_INDICATORS_UPDATE_COUNTER });

    axios
      .get(`${baseUrlPMBackend}indicators/getIndicatorsTypes`)
      .then((res) => {
        let indicatorsTypes: IIndicatorType[] = res.data.rows;
        dispatch({
          type: FETCH_INDICATORS_TYPES_SUCCESS,
          indicatorsTypes: indicatorsTypes,
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_INDICATORS_TYPES_FAILURE, error: error });
      });
  };
};

export const saveIndicators = (
  indicators: IIndicator[],
  planId: number,
  t: any
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: SAVE_INDICATORS_REQUEST,
    });

    axios
      .post(`${baseUrlPMBackend}indicators/saveIndicators`, {
        params: {
          indicators: indicators,
          planId: planId,
        },
      })
      .then((res) => {
        dispatch({ type: SAVE_INDICATORS_SUCCESS });
        let insertedIndicators: IIndicator[] = res.data;
        insertedIndicators.map((indicator) =>
          dispatch({ type: UPDATE_INDICATOR, indicator: indicator })
        );
      })
      .catch((error) => {
        dispatch({ type: SAVE_INDICATORS_FAILURE, error: error });
        customToast.error(t("indicatorsSaveErrors"));
      });
  };
};

export const incrementCounter = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INCREMENT_INDICATORS_UPDATE_COUNTER });
  };
};

export const incrementRenderCounter = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INCREMENT_INDICATORS_RENDER_COUNTER });
  };
};

export const decrementRenderCounter = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: DECREMENT_INDICATORS_RENDER_COUNTER });
  };
};

// Indicator types
export type IndicatorActionTypes =
  | SetIndicatorsAction
  | AddIndicatorAction
  | EditIndicatorAction
  | DeleteIndicatorAction
  | fetchIndicatorsFailure
  | fetchIndicatorsRequest
  | fetchIndicatorsSuccess
  | fetchIndicatorsTypesRequest
  | fetchIndicatorsTypesFailure
  | fetchIndicatorsTypesSuccess
  | UpdateIndicator
  | SaveIndicatorsRequest
  | SaveIndicatorsSuccess
  | SaveIndicatorsFailure
  | IncrementUpdateCounter
  | ResetUpdateCounter
  | IncrementRenderCounter
  | DecrementRenderCounter
  | ResetRenderCounter;
