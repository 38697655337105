import React, { memo, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import "./VerticalStackedBar.css";
import "chartjs-plugin-datalabels";

interface IProps {
  barData: { value: number; color: string; key: number }[];
  hundredPercentsValue: number;
  key: number | string;
}
interface IBarDataset {
  data: number[]; //array of 1 bar
  label: string; //label for the bars' part
  backgroundColor: string; //bar's background color bars' part
  hoverBackgroundColor: string; //bar's hoverbackground color bars' part
  key: number;
}

const VerticalStackedBar: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { barData, hundredPercentsValue } = props;

  const backgroundColor = "#2A2C30";
  const TRANSPARENCY = "CC";
  const options = {
    layout: {},
    plugins: {
      anchor: "center",
      clamp: true,
      align: "center",
      legend: {
        onClick: null,
      },
      labels: {
        render: () => "",
      },
      datalabels: {
        anchor: "center",
        align: function (context: any) {
          var value = context.dataset.data[context.dataIndex];
          return value.x < 1000 ? "end" : "center";
        },
        color: "#151516",

        textAlign: "center",
        font: {
          size: 14,
          family: "Bold",
        },

        offset: 2,
        padding: 0,

        formatter: (value: number, context: any) => {
          return context.dataset.backgroundColor === backgroundColor ||
            context.dataset.data[context.dataIndex] == 0
            ? ""
            : Number(context.dataset.label) < 10 //one digit number
            ? ` ${context.dataset.label}`
            : context.dataset.label;
        },
      },
    },
    tooltips: {
      enabled: false,
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            display: false,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            display: false,
            min: 0,
            max: hundredPercentsValue,
          },
        },
      ],
    },
    responsive: true,
    maintainAspectRatio: false,
    legend: { display: false },
  };
  /**
   *
   * @param arrayOfBarData
   * @returns the required dataset for the chart
   */
  const getBarDataSet = (
    arrayOfBarData: {
      value: number;
      color: string;
      key: number;
    }[]
  ): IBarDataset[] => {
    const minBarPercentage = 6; // the minimun value precentage for the bar
    let addedAmountsCounter = 0;
    arrayOfBarData.push({
      value:
        hundredPercentsValue === 0
          ? 100
          : hundredPercentsValue -
            arrayOfBarData
              .map((d) => d.value)
              .reduce((accumulator, current) => accumulator + current, 0),
      color: backgroundColor,
      key: arrayOfBarData.length,
    });
    //sort bar's dataset to be with ascending bar parts values
    arrayOfBarData.sort((previousValue, currentValue) => {
      return previousValue.value - currentValue.value;
    });

    //Count the amount of all bar parts values
    let totalAmountsInBars = arrayOfBarData.reduce(
      (previousValue, currentValue) => {
        return previousValue + currentValue.value;
      },
      0
    );

    //gets the percentages values for the parts of the bar
    const getValuesForBarData = (barAmountValue: number) => {
      let percentageValueToReturn = barAmountValue; // init as barAmountValue - to return "barAmountValueincase" there is no "addedAmountsCounter"

      if (barAmountValue !== 0) {
        if ((barAmountValue / hundredPercentsValue) * 100 < minBarPercentage) {
          addedAmountsCounter +=
            (minBarPercentage * hundredPercentsValue) / 100 - barAmountValue; //counting the added percentages
          percentageValueToReturn = Math.round(
            (minBarPercentage * hundredPercentsValue) / 100
          );
          totalAmountsInBars -= barAmountValue;
        } else if (
          addedAmountsCounter !== 0 &&
          (barAmountValue / hundredPercentsValue) * 100 > minBarPercentage
        ) {
          percentageValueToReturn =
            barAmountValue -
            (barAmountValue / totalAmountsInBars) * addedAmountsCounter;
          percentageValueToReturn = Math.max(
            percentageValueToReturn,
            (minBarPercentage * hundredPercentsValue) / 100
          );
        }
      }
      return {
        data: [percentageValueToReturn],
        label: String(barAmountValue),
      };
    };

    let dataset: IBarDataset[] = arrayOfBarData.map((barPart) => {
      return {
        backgroundColor: barPart.color,
        hoverBackgroundColor: barPart.color + TRANSPARENCY,
        ...getValuesForBarData(barPart.value),
        key: barPart.key,
      };
    });

    return [
      //sort by the initialkey
      ...dataset.sort((previousValue, currentValue) => {
        return previousValue.key - currentValue.key;
      }),
    ];
  };
  const datasetKeyProvider = (e: any) => {
    return `${props.key} ${e.key}`;
  };
  return (
    <div className="barStacked">
      <Bar
        datasetKeyProvider={datasetKeyProvider}
        height={230}
        width={70}
        options={options}
        data={{
          datasets: getBarDataSet(barData),
        }}
      ></Bar>
    </div>
  );
};

export default VerticalStackedBar;
