import { IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import Axios from "../../../Axios";
import { useEffect, useState, useContext, FC } from "react";

import { useTranslation } from "react-i18next";
import useForcesChecked from "../../../components/CustomHooks/useForcesChecked";
import DateDrawer from "../../../components/Mobile/DateDrawer/DateDrawer";
import MobileHeader from "../../../components/Mobile/MobileHeader/MobileHeader";
import customToast from "../../../components/Shared/Toast/CustomToast";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import { IHistoryTabs, ITabOptions } from "../../../Interfaces/IDataHistory";
import { IDateRange } from "../../../Interfaces/IDatePicker";
import IForceTreeNode from "../../../Interfaces/IForceTreeNode";
import { mobileRoutes, userRoles } from "../../../services/routeRoles";
import ForcesContext from "../../../context/ForcesContext/forcesContext";

import "./DataHistoryMobile.css";
import HistoryListMobile from "./HistoryListMobile";
import OrbatTreeModal from "../../../components/Mobile/OrbatTreeModal/OrbatTreeModal";
import SegmentTitle from "./SegmentTitle";
import SegmentsMenu from "./SegmentsMenu";
import { UserContext } from "../../../context/UserContext/userContext";
import usePlans from "../../../components/CustomHooks/usePlans";
import EDateDrawer from "../../../Enums/EDateDrawer";
import PMLabel from "../../../components/themeComponents/PMLabel";

interface IProps {}

const DataHistoryMobile: FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();

  const [segmentsOptions, setSegmentsOptions] = useState<ITabOptions[]>(
    [] as ITabOptions[]
  );
  const [selectedSegment, setSelectedSegment] = useState<
    ITabOptions | undefined
  >();

  const [selectedSoldier, setSelectedSoldier] = useState<IForceTreeNode[]>(
    [] as IForceTreeNode[]
  );

  const [rangeDate, setRangeDate] = useState<IDateRange | undefined>();

  //state to control what to display in the modal
  const [drawerState, setDrawerState] = useState<
    EDateDrawer.selectEnd | EDateDrawer.selectStart
  >(EDateDrawer.selectStart);

  // useContext hooks
  const { user } = useContext(UserContext);
  const { activePlan } = usePlans();
  const { forces, isForcesTreeOpen, setIsForcesTreeOpen } =
    useContext(ForcesContext);
  const { forcesToRemove, newForces } = useForcesChecked(selectedSoldier);

  useEffect(() => {
    setSelectedSoldier(forces);
  }, [forces]);

  useEffect(() => {
    if (
      user.relatedForce?.is_soldier &&
      user.role === userRoles.Viewer &&
      Number(user.relatedForce.id) === Number(user.forceToDisplayInOrbat?.id)
    ) {
      setRangeDate(undefined);
    }
  }, [user]);

  useEffect(() => {
    let mounted = true;
    const getTrainingTypesHeaders = async () => {
      try {
        let res = await Axios.get(
          `${baseUrlPMBackend}dataHistory/getTrainingTypesHeaders`
        );

        let tabsData: ITabOptions[] = [];
        res.data.data.map((row: IHistoryTabs) => {
          tabsData.push({
            headersArray: row.headers,
            tabId: row.trainingTypeId,
            title: row.trainingTypeName,
            url: `${mobileRoutes.dataHistory}/${row.trainingTypeName}`,
          });
        });
        mounted && setSelectedSegment(tabsData[0]);
        mounted && setSegmentsOptions(tabsData);
      } catch (err) {
        customToast.error(t("loadPlansError"));
      }
    };
    getTrainingTypesHeaders();

    return () => {
      mounted = false;
    };
  }, []);
  const [cameToEnd, setCameToEnd] = useState<boolean>(false);

  const handleScroll = (e: any) => {
    console.log("hjjhj");

    if (
      e.target.scrollTop > 0 && //make sure scroll happened
      Math.floor(e.target.scrollHeight - e.target.scrollTop) -
        Math.floor(e.target.clientHeight) <
        2
    ) {
      setCameToEnd((prev) => (prev === false ? true : prev));
    }
  };

  return (
    <IonPage>
      <IonContent scrollY={false}>
        <MobileHeader
          iconEndSrc={EIconsSrc.MENU_ICON}
          title={t("historyPage")}
        />
        <IonGrid className="mobile_dh_grid">
          <IonRow>
            <PMLabel
              fontColor="xLight"
              fontFamily="Light"
              fontSize="medium"
              textAlign="start"
            >
              {activePlan
                ? `${t("activePlan")} ${activePlan.name}`
                : t("noActivePlanSelected")}
            </PMLabel>
          </IonRow>
          <IonRow>
            <SegmentsMenu
              segmentsOptions={segmentsOptions}
              selectedSegment={selectedSegment}
              setSelectedSegment={setSelectedSegment}
            />
          </IonRow>
          <IonRow className="title-name">
            <SegmentTitle selectedSegment={selectedSegment} />
          </IonRow>
          <IonRow className="mobile_dh_list_row" onScroll={handleScroll}>
            {selectedSegment && (
              <HistoryListMobile
                trainingTypeId={selectedSegment.tabId}
                rangeDate={rangeDate}
                selectedSoldier={selectedSoldier}
                setSelectedSoldier={setSelectedSoldier}
                forcesToRemove={forcesToRemove}
                newForces={newForces!}
                activePlanId={activePlan?.id}
                cameToEnd={cameToEnd}
                setCameToEnd={setCameToEnd}
              />
            )}
          </IonRow>
        </IonGrid>
        <DateDrawer
          idToControl="data-history-drawer"
          rangeDate={rangeDate}
          setRangeDate={setRangeDate}
          drawerState={drawerState}
          setDrawerState={setDrawerState}
        />
      </IonContent>
      <OrbatTreeModal
        limit={3}
        isOpen={isForcesTreeOpen}
        close={() => setIsForcesTreeOpen(false)}
        mode="secondary"
      />
    </IonPage>
  );
};

export default DataHistoryMobile;
