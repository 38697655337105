import React, { useState, useEffect, Dispatch, useContext } from "react";
import TreeNode from "./TreeNode/TreeNode";
import axios from "../../../../Axios";
import Tooltip from "react-tooltip-lite";
import "./OrbatTree.css";

// context
import { UserContext } from "../../../../context/UserContext/userContext";

// redux
import { useDispatch, useSelector } from "react-redux";
import { orbatTreeState } from "../../../../redux/store/OrbatTreeStore";
import {
  getOrbatTree,
  deleteForce,
  resetFlags,
  removeUnsaveForce,
} from "../../../../redux/actions/orbatTreeActions";
import { IOrbatTreeReducer } from "../../../../redux/reducers/orbatTree";

// services
import { useTranslation } from "react-i18next";
import { getForceFullName, treeFlatting } from "../../../../services/helpers";

// ionic imports
import { IonCol, IonGrid, IonRow } from "@ionic/react";

// interfaces
import IForcePointer from "./../../../../Interfaces/IForcePointer";
import IForceTreeNode from "./../../../../Interfaces/IForceTreeNode";
import { baseUrlPMBackend } from "../../../../Configurations/consts";
import Dropdown from "../../SearchDropdown/SearchDropdown";
import ImportFile from "./ImportFile/ImportFile";
import customToast from "../../Toast/CustomToast";
import IForceType from "../../../../Interfaces/IForceType";
import PMTitle from "../../../themeComponents/PMTitle";
import PMIcon from "../../../themeComponents/PMIcon";
import PMLabel from "../../../themeComponents/PMLabel";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import Alert from "../../Alert/Alert";
import { userRoles } from "../../../../services/routeRoles";
import Spinner from "../../Spinner/Spinner";

interface IProps {
  checked: (force: IForceTreeNode[], isSubordinates?: boolean) => void;
  readonly: boolean | true;
  limit?: number;
  forceToChange?: IForceTreeNode;
  forceToAdd?: IForceTreeNode;
  forceToRemove?: IForceTreeNode;
  isMobile?: boolean | false;
  checkedForces?: IForceTreeNode[];
  isAdmin?: boolean | true;
  isForDropDown?: boolean;
  isReport?: boolean;
  setDeletingLoading?: (isLoading: boolean) => void;
  disableEditing?: boolean;
  initIsDuplicated?: () => void;
  displayPlattonAndAbove?: boolean | undefined;
  isRootDisable?: boolean;
  setEnableReorder?: React.Dispatch<React.SetStateAction<boolean>>;
  enableReorder?: boolean;
  draggedElement?: HTMLDivElement | undefined;
  setDraggedElement?: (element: HTMLDivElement | undefined) => void;
}
interface IOptionsState {
  options: "reorder" | "add" | "import" | undefined;
}
const OrbatTree: React.FC<IProps> = (props: IProps) => {
  const [forces, setForces] = useState<IForceTreeNode>({} as IForceTreeNode);
  const [thereIsForces, setThereIsForces] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [checked, setChecked] = useState<IForceTreeNode[]>([]);
  const [checkedForce, setCheckedForce] = useState<IForceTreeNode>(
    {} as IForceTreeNode
  );
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [flatTree, setFlatTree] = useState<IForcePointer[]>([]);
  const [path, setPath] = useState<(number | null)[]>([]);
  const [optionState, setOptionState] = useState<IOptionsState["options"]>();
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch<any>>();

  // context
  const { user } = useContext(UserContext);

  //redux state
  const orbatTree: IForceTreeNode = useSelector<
    orbatTreeState,
    IOrbatTreeReducer
  >((state) => state.orbatTree).orbatTree;
  const deleteOccured: boolean = useSelector<orbatTreeState, IOrbatTreeReducer>(
    (state) => state.orbatTree
  ).deleteOccured;

  useEffect(() => {
    !orbatTree && checkRelatedForceAndGetTree(false);
    props.checkedForces && setChecked(props.checkedForces);
    if (user.role !== userRoles.Admin && !user.forceToDisplayInOrbat) {
      setIsLoading(false);
    }
    return () => {
      dispatch(removeUnsaveForce());
    };
  }, []);

  useEffect(() => {
    if (forces.id !== undefined) setIsLoading(false);
  }, [forces]);

  useEffect(() => {
    props.checkedForces?.forEach((f: IForceTreeNode) => findPath(f.id));
    props.checkedForces && setChecked(props.checkedForces);
  }, [props.checkedForces]);

  useEffect(() => {
    props.forceToChange && changeNode(forces, props.forceToChange);
  }, [props.forceToChange]);

  useEffect(() => {
    if (props.forceToAdd) {
      addChildToTree(forces, props.forceToAdd);
      props.checked([props.forceToAdd]);
    }
  }, [props.forceToAdd]);

  useEffect(() => {
    props.forceToRemove && removeItemFromTree(forces, props.forceToRemove);
  }, [props.forceToRemove]);

  useEffect(() => {
    props.checkedForces?.forEach((f: IForceTreeNode) => findPath(f.id));
  }, [flatTree]);

  useEffect(() => {
    if (deleteOccured) {
      setCheckedForce({} as IForceTreeNode);
      props.checked([]);
      setForces({ ...orbatTree });
      customToast.success(t("deleteForceMsg"));
      dispatch(resetFlags());
    }
  }, [deleteOccured]);

  useEffect(() => {
    if (!!orbatTree) {
      setThereIsForces(true);
      setForces({ ...orbatTree });
      setFlatTree([]);
      treeFlatting(orbatTree, setFlatTree);
    } else {
      setThereIsForces(false);
    }
  }, [orbatTree]);

  // Adding new leaf to the checked node - without saving it in the database
  const addForce: (tree: IForceTreeNode) => void = (
    tree: IForceTreeNode
  ): void => {
    let currNodes: IForceTreeNode[] | null = tree.nodes;

    if (tree.id === checkedForce.id) {
      let newForce: IForceTreeNode = {
        id: Math.floor(Math.random() * -1000),
        is_deleted: false,
        level: tree.level + 1,
        name: t("newForce"),
        nodes: null,
        parent_id: checkedForce.id,
        soldier_id: 0,
        tag_id: null,
        weapon_id: "0",
        weapon_type: "None",
        weapon_sight: "None",
        weapon_sight_id: 0,
        force_type: "מתאמן",
        is_soldier: true,
        personal_id: null,
        magazine_id: null,
        laser_id: null,
        head_sensor_id: null,
      };
      let tempTree: IForceTreeNode = currNodes
        ? { ...tree, nodes: [...currNodes, newForce] }
        : { ...tree, nodes: [newForce] };

      Object.assign(tree, tempTree);
      setChecked([newForce]);
      props.checked([newForce]);
      setPath((prev: (number | null)[]) => [...prev, newForce.id]);
    } else {
      currNodes &&
        currNodes.forEach((node: IForceTreeNode) => {
          addForce(node);
        });
    }
  };

  // Force has been selected in the search field
  const getSelectedForce = (
    e: {
      label: string | null;
      value: IForcePointer | null | number | string | IForceType;
    } | null
  ): void => {
    if (props.limit && checked.length >= props.limit) {
      setOpenAlert(true);
      return;
    }

    if (e && e.value) {
      if (typeof e.value === "object") {
        findForceById(forces, e.value.id);
      }
    } else {
      setPath([]);
      onCheckForce({} as IForceTreeNode);
    }
  };

  // Find the selected force and gatting his path
  const findForceById = (tree: IForceTreeNode, id: number): void => {
    if (tree.id === id) {
      onCheckForce(tree);
      findPath(tree.id);
    } else {
      tree.nodes &&
        tree.nodes.forEach((node: IForceTreeNode) => {
          findForceById(node, id);
        });
    }
  };

  // Calls when force has been checked
  const onCheckForce: (newForce: IForceTreeNode) => void = (
    newForce: IForceTreeNode,
    isSubordinates?: boolean
  ): void => {
    props.initIsDuplicated && props.initIsDuplicated();
    setCheckedForce(newForce);
    if (checked.some((f: IForceTreeNode) => f.id === newForce.id)) {
      //unchecked force - force already exist in the checked assay
      if (props.readonly || props.enableReorder) {
        //the reorder functionality is like the read only tree - multiple force can be unchecked
        let temp: IForceTreeNode[] | undefined = checked.filter(
          (node: IForceTreeNode) => node.id !== newForce.id
        );
        setChecked(temp);
        props.checked(temp, isSubordinates);
      } else {
        setChecked([]);
        props.checked([]);
        setCheckedForce({} as IForceTreeNode);
      }
    } else {
      //check force
      //the reorder functionality is like the read only tree - multiple force can be checked
      let temp: IForceTreeNode[] =
        (props.readonly || props.enableReorder) && props.limit !== 1
          ? [...checked, newForce]
          : [newForce];
      setChecked(temp);
      props.checked(temp, isSubordinates);
    }
  };

  // remove gevan item from tree
  const removeItemFromTree = (
    tree: IForceTreeNode,
    itemToRemove: IForceTreeNode
  ) => {
    if (tree.id == itemToRemove.parent_id) {
      if (tree.nodes) {
        tree.nodes = tree.nodes.filter(
          (node: IForceTreeNode) => node.id !== itemToRemove.id
        );
      }
      setChecked([tree]);
      props.checked([tree]);
      removeFromPath(itemToRemove.id);
    } else {
      tree.nodes &&
        tree.nodes.forEach((node: IForceTreeNode) => {
          removeItemFromTree(node, itemToRemove);
        });
    }
  };

  // changing details of node
  const changeNode = (
    tree: IForceTreeNode,
    newValues: IForceTreeNode
  ): void => {
    if (+newValues.id === +tree.id) {
      let newTree: IForceTreeNode = {
        id: tree.id,
        is_deleted: tree.is_deleted,
        level: tree.level,
        name: newValues.name,
        nodes: tree.nodes,
        parent_id: tree.parent_id,
        soldier_id: newValues.soldier_id,
        tag_id: newValues.tag_id,
        weapon_id: newValues.weapon_id,
        weapon_type: newValues.weapon_type,
        weapon_sight: newValues.weapon_sight,
        weapon_sight_id: newValues.weapon_sight_id,
        force_type: newValues.force_type,
        is_soldier: newValues.is_soldier,
        personal_id: newValues.personal_id,
        magazine_id: newValues.magazine_id,
        laser_id: newValues.laser_id,
        head_sensor_id: newValues.head_sensor_id,
      };
      Object.assign(tree, newTree);
      setCheckedForce(newTree);
    } else {
      tree.nodes &&
        tree.nodes.forEach((node: IForceTreeNode) => {
          changeNode(node, newValues);
        });
    }
  };

  // adding new node to tree
  const addChildToTree = (
    tree: IForceTreeNode,
    force: IForceTreeNode
  ): void => {
    if (tree.id < 0) {
      Object.assign(tree, { ...force, is_deleted: false });
      props.checked([tree]);
      setCheckedForce(tree);
    } else {
      tree.nodes &&
        tree.nodes.forEach((node: IForceTreeNode) => {
          addChildToTree(node, force);
        });
    }
  };

  // Calls when delete button clicked
  const onDeleteForce: (force: IForceTreeNode) => void = (
    force: IForceTreeNode
  ): void => {
    props.setDeletingLoading && props.setDeletingLoading(true);
    dispatch(
      deleteForce(
        force,
        customToast,
        t("forceInSite"),
        t("deleteError"),
        props.setDeletingLoading
      )
    );
  };

  // Getting the path (ID's) - from root node to the givan node
  const findPath = (id: number | null) => {
    flatTree.forEach((el: IForcePointer) => {
      if (el.id === id) {
        setPath((prev: (number | null)[]) => [...prev, el.id]);

        findPath(el.parent);
      }
    });
  };

  const removeFromPath = (id: number | null) => {
    let a = path.filter((p: number | null) => p && id && p < id);
    setPath(a);
  };

  const loadTreeTrigger = () => {
    checkRelatedForceAndGetTree(false);
  };

  const switchOprionState = (state: IOptionsState["options"]) => {
    setOptionState((prev) => (prev === state ? undefined : state));
  };

  const toggleReorderBtn = () => {
    switchOprionState("reorder");
    setChecked((prev) => (prev.length ? [prev[0]] : prev)); //get the first selected force
    props.setEnableReorder && props.setEnableReorder((prev: boolean) => !prev);
  };

  const checkRelatedForceAndGetTree = (isAfterChangingParent: boolean) => {
    if (user.role === userRoles.Admin || user.forceToDisplayInOrbat) {
      if (!isAfterChangingParent) {
        setIsLoading(true);
      }
      dispatch(getOrbatTree(user.forceToDisplayInOrbat?.id, t));
    } else {
      if (!isAfterChangingParent) {
        setIsLoading(false);
      }
      setThereIsForces(false);
    }
  };

  const changeForceParent = async (
    forcesToReorder: IForceTreeNode[],
    newParentId: number
  ) => {
    try {
      //for each one of the dragged forces send there details to the backend
      let result = await Promise.all(
        forcesToReorder.map((forceToReorder) => {
          return axios.post(`${baseUrlPMBackend}forces/updateParentId`, {
            params: {
              newParentId: newParentId,
              forceId: forceToReorder.id,
              oldParentId: forceToReorder.parent_id,
            },
          });
        })
      );
      if (result?.find((res) => res?.status !== 200)) {
        customToast.error(t("updateForcesError"));
      } else {
        setChecked([]);
        customToast.success(t("forceUpdateSuccessMsg"));
        checkRelatedForceAndGetTree(true);
      }
    } catch (e) {
      console.error(e);
      customToast.error(t("updateForcesError"));
    }
  };

  const closeAlert = (): void => {
    setOpenAlert(false);
  };

  return (
    <IonGrid
      className="tree-col-grid"
      onDrop={() => {
        props.draggedElement?.remove();
      }}
    >
      <IonRow className="header-row">
        {!props.isMobile && (
          <PMTitle fontColor="light" fontFamily="Regular" fontSize="large">
            {t("forcesTree")}
          </PMTitle>
        )}
        {!props.readonly &&
          !props.disableEditing &&
          (props.isAdmin ? (
            <>
              <Tooltip className="btn-tooltip" content={t("ReorderBtnTooltip")}>
                <PMIcon
                  isButton={false}
                  iconSrc={EIconsSrc.SWITCH_FORCE}
                  onClick={toggleReorderBtn}
                  color={
                    optionState === "reorder" && props.enableReorder
                      ? "xLight"
                      : "xDark"
                  }
                  size="large"
                />
              </Tooltip>
              <Tooltip className="btn-tooltip" content={t("ImportBtnTooltip")}>
                <div className={`file-import-button `}>
                  <ImportFile
                    isSuccess={loadTreeTrigger}
                    checkedForce={checkedForce}
                    onClick={() => {
                      setOptionState("import");
                      props.setEnableReorder && props.setEnableReorder(false);
                    }}
                    iconColor={optionState === "import" ? "xLight" : "xDark"}
                  />
                </div>
              </Tooltip>
              <Tooltip className="btn-tooltip" content={t("addBtnTooltip")}>
                <PMIcon
                  iconSrc={EIconsSrc.ADD_FORCE}
                  disabled={checkedForce.is_soldier || !checkedForce.id}
                  onClick={() => {
                    setOptionState("add");
                    addForce(forces);
                    props.setEnableReorder && props.setEnableReorder(false);
                  }}
                  color={optionState === "add" ? "xLight" : "xDark"}
                  size="large"
                />
              </Tooltip>
            </>
          ) : null)}
      </IonRow>
      <IonRow>
        <Dropdown
          isForcesTreeSearch={true}
          showIcon
          isForDropDown={props.isForDropDown}
          defaultValue={{
            label: "",
            value: {
              id: checkedForce.id,
              name: checkedForce.name,
              soldier_id: checkedForce.soldier_id,
              tag_id: checkedForce.tag_id,
              weapon_id: checkedForce.weapon_id,
              weapon_type: checkedForce.weapon_type,
              parent: checkedForce.parent_id,
              weapon_sight: checkedForce.weapon_sight,
              weapon_sight_id: checkedForce.weapon_sight_id,
              force_type: checkedForce.force_type,
              magazine_id: checkedForce.magazine_id,
              laser_id: checkedForce.laser_id,
              head_sensor_id: checkedForce.head_sensor_id,
              personal_id: checkedForce.personal_id,
            },
          }}
          options={
            flatTree.length
              ? flatTree.map((e: IForcePointer) => {
                  return {
                    label: getForceFullName(e.name, e.force_type, t),
                    value: e,
                  };
                })
              : []
          }
          onSelect={getSelectedForce}
        />
      </IonRow>
      <IonRow className="tree-row">
        <IonCol
          className={`tree-scroll ${props.isForDropDown ? "dropDownTree" : ""}`}
        >
          {isLoading ? (
            <Spinner className="spinner" />
          ) : thereIsForces && forces.id ? (
            <TreeNode
              node={forces}
              onChoose={onCheckForce}
              checkedForces={checked}
              onDelete={onDeleteForce}
              readonly={props.readonly}
              path={path}
              limit={props.limit}
              removeFromPath={removeFromPath}
              enableReorder={props.enableReorder === true}
              onReorder={changeForceParent}
              isAdmin={props.isAdmin}
              isReport={props.isReport}
              setOpenAlert={setOpenAlert}
              disableEditing={props.disableEditing}
              displayPlatoonAndAbove={props.displayPlattonAndAbove === true}
              isRootDisable={props.isRootDisable}
              setDraggedElement={props.setDraggedElement}
              draggedElement={props.draggedElement}
            />
          ) : (
            <PMLabel fontSize="medium" fontFamily="Bold" fontColor="light">
              {user.forceToDisplayInOrbat
                ? t("forcesNotExist")
                : t("forcesNotAssigned")}
            </PMLabel>
          )}
          <Alert
            isOpen={openAlert}
            setIsOpen={setOpenAlert}
            header={`${t("maxChoosingHeader1")} ${props.limit} ${t(
              "maxChoosingHeader2"
            )}`}
            subHeader={t("cancelChoosing")}
            actionOnSave={closeAlert}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default OrbatTree;
