import { IonButton, IonIcon } from "@ionic/react";
import React, { FC } from "react";
import PMLabel from "./PMLabel";
import { IPMButton } from "../../Interfaces/IPMTheme";
import "./PMButton.css";
import "./PMIcon.css";

const PMButton: FC<IPMButton> = (props: IPMButton) => {
  const {
    onClickHandler,
    isDisabled,
    size,
    label,
    isHidden,
    icon,
    fill,
    color,
    routerLink,
    routerDirection,
    cssClass,
    children,
  } = props;

  return (
    <>
      <IonButton
        className={`pmButton ${size}B ${
          fill !== "outline" ? "fullButtons" : ""
        } ${cssClass}`}
        color={color}
        fill={fill}
        onClick={onClickHandler}
        hidden={isHidden}
        disabled={isDisabled}
        routerLink={routerLink}
        routerDirection={routerDirection}
      >
        {icon ? (
          <IonIcon
            className={`pmIcon ${size}I ${icon.color} ${
              icon.disabledColor || icon.disabled ? "disabledColorI" : color
            } ${icon.disabled ? "disabledI" : ""}`}
            src={icon.iconSrc}
          ></IonIcon>
        ) : null}
        {label ? (
          <PMLabel
            fontSize={label.fontSize}
            fontColor={label.fontColor}
            fontFamily={label.fontFamily}
            textAlign={label.textAlign}
            margin={icon ? true : false}
          >
            {children}
          </PMLabel>
        ) : null}
      </IonButton>
    </>
  );
};
export default PMButton;
