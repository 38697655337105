import React, { useContext, useState } from "react";
import "./OrbatTreeModal.css";

// services
import { useTranslation } from "react-i18next";
import { getForceFullName } from "../../../services/helpers";

// ionic
import { IonGrid, IonModal, IonRow } from "@ionic/react";

// components
import OrbatTree from "../../Shared/Orbat/OrbatTree/OrbatTree";
import ForcesContext from "../../../context/ForcesContext/forcesContext";
import MobileHeader from "../MobileHeader/MobileHeader";

// interfaces
import IForceTreeNode from "./../../../Interfaces/IForceTreeNode";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import PMLabel from "../../themeComponents/PMLabel";
import PMButton from "../../themeComponents/PMButton";

interface IProps {
  isOpen: boolean;
  close: () => void;
  mode: "main" | "secondary";
  limit: number;
}

const OrbatTreeModal: React.FC<IProps> = (props) => {
  const { t, i18n } = useTranslation();
  const forces = useContext(ForcesContext);
  const [checkedForces, setCheckedForces] = useState<IForceTreeNode[]>([]);
  const onSubmitChoosing = () => {
    forces.setForces(checkedForces);
    props.close();
  };

  const onChoose = (choosenForces: IForceTreeNode[]) => {
    setCheckedForces(
      choosenForces.map((force: IForceTreeNode) => {
        let currName: string = force.name;

        currName = getForceFullName(force.name, force.force_type, t);

        return {
          ...force,
          name: currName,
        };
      })
    );
  };

  return (
    <IonModal
      isOpen={props.isOpen}
      className={
        props.mode === "main" ? "orbat-modal" : "orbat-modal secondary"
      }
    >
      <IonGrid className="modal-grid">
        <IonRow className="modal-header-row">
          <MobileHeader
            title={t("forcesTree")}
            isUserDisplay={false}
            iconStartSrc={
              props.mode === "main"
                ? undefined
                : i18n.language === "he"
                ? EIconsSrc.BACK_ICON_HE
                : EIconsSrc.BACK_ICON_EN
            }
            iconEndSrc={EIconsSrc.MENU_ICON}
            iconStartHandler={props.close}
          />
        </IonRow>
        <IonRow className="sub-header">
          <PMLabel textAlign="start" fontFamily="Regular" fontColor="light">
            {t("selectForces")}
          </PMLabel>
          <PMButton
            size="small"
            onClickHandler={onSubmitChoosing}
            isDisabled={
              JSON.stringify(checkedForces) === JSON.stringify(forces.forces)
            }
            color="tertiary"
            label={{
              fontColor: "light",
              fontFamily: "Regular",
              fontSize: "medium",
            }}
          >
            {t("select")}
          </PMButton>
        </IonRow>
        <IonRow className="modal-tree-row">
          <OrbatTree
            readonly
            checked={onChoose}
            isMobile={true}
            checkedForces={forces.forces}
            limit={props.limit}
          />
        </IonRow>
      </IonGrid>
    </IonModal>
  );
};

export default OrbatTreeModal;
