import {
  IonRadioGroup,
  IonContent,
  IonIcon,
  IonInput,
  IonPage,
  IonRadio,
  IonRow,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import useGetPerformanceData from "../../../components/CustomHooks/UseGetPerformanceData";
import { IDataHistory } from "../../../Interfaces/IDataHistory";
import { IDateRange } from "../../../Interfaces/IDatePicker";
import DatePicker from "../../../components/Desktop/DataPosting/DatePicker";
// css
import FadeIn from "react-fade-in";
import DrillsDataTable from "../../../components/Desktop/DataPosting/DrillsDataTable";
import { useTranslation } from "react-i18next";
import "./DataHistory.css";
import { close } from "ionicons/icons";
import { baseUrlPMBackend, environment } from "../../../Configurations/consts";
import Axios from "../../../Axios";
import customToast from "../../../components/Shared/Toast/CustomToast";
import AdminPasswordModal from "../../../components/Desktop/AdminPassword/AdminPasswordModal";
import { getForceFullName } from "../../../services/helpers";
import PMButton from "../../../components/themeComponents/PMButton";
import TrainingPlanTool from "../../../components/Desktop/TrainingPlanTool/TrainingPlanTool";
import { UserContext } from "../../../context/UserContext/userContext";
import PMLabel from "../../../components/themeComponents/PMLabel";
import PMIcon from "../../../components/themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import i18n from "../../../services/i18next";
import { checkSuperUserPassword } from "../../../services/passwordsHandler";
import IData from "../../../Interfaces/IDataHistoryData";
import EEnvironment from "../../../Enums/EEnvironment";
import SimpleSelect from "../../../components/Desktop/DataPosting/SimpleSelect";
import Spinner from "../../../components/Shared/Spinner/Spinner";
import Dropdown from "../../../components/Shared/Dropdown/Dropdown";
import {
  getWeapons,
  XDefaultColumn,
} from "../../../components/Desktop/DataPosting/dataPostingHelpers";
import { Option } from "react-dropdown";
import DataHistoryTextEditor from "../../../components/Desktop/DataPosting/DataHistoryTextEditor";
import SaveCancelButtons from "../../../components/Shared/Buttons/SaveCancelButtons";
import DropdownEditor from "../../../components/Desktop/DataPosting/DropdownEditor";
import EDatatypesTypes from "../../../Interfaces/EDatatypesTypes";
import OrbatInput from "../../../components/Shared/Orbat/Input/OrbatInput";

interface dataType {
  name: string;
  type: string;
}
const DataHistory: React.FC<IDataHistory> = (
  props: IDataHistory
): JSX.Element => {
  const {
    headers,
    trainingTypeId,
    rangeDate,
    setRangeDate,
    selectedSoldier,
    setSelectedSoldier,
    forcesToRemove,
    newForces,
    selectedPlan,
    plansOptions,
    onPlanSelectedHandler,
  } = props;
  const { t } = useTranslation();
  const { isAdmin } = useContext(UserContext);
  const [columns, setColumns] = useState<any[]>([]);
  const [cameToEnd, setCameToEnd] = useState<boolean>(false);
  const [isDeleteRows, setDeleteRows] = useState<boolean>(false);
  const [isFilterOn, setFilterOn] = useState<boolean>(true);
  const [invalid, setInvalid] = useState<boolean>(false);
  const [isTreeOpened, setIsTreeOpened] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [isTrainingTool, setIsTrainingTool] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<{ [key: string]: string }>({});

  const [filteredSoldierUniqueIds, setFilteredSoldierUniqueIds] =
    useState<number[]>();
  const [isEditRowClicked, setEditRowClicked] = useState<boolean>(false);
  const [isEditRowInProcess, setEditRowInProcess] = useState<boolean>(false);
  const [dataTypes, setDataTypes] = useState<dataType[]>([]);
  const [weaponTypes, setWeaponTypes] = useState<string[]>([]);
  const filterRef = useRef<HTMLDivElement>(null);
  const [tempEditedRow, setTempEditedRow] = useState<IData>({} as IData);
  const handleViewClick = (event: MouseEvent) => {
    if (!filterRef.current?.contains(event.target as HTMLElement)) {
      setIsTreeOpened(false);
    }
  };

  const setRangeDateAsAllDay = (dateRange: IDateRange) => {
    setRangeDate({
      startDate: new Date(dateRange.startDate.setHours(0, 0, 0, 0)),
      endDate: new Date(dateRange.endDate.setHours(23, 59, 59)),
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleViewClick);

    return () => {
      document.removeEventListener("mousedown", handleViewClick);
    };
  }, []);

  useEffect(() => {
    let mounted: boolean = true;
    setFilteredSoldierUniqueIds((prev) => {
      let newIds = selectedSoldier.map((force) => force.id);
      if (
        !prev ||
        JSON.stringify(prev?.sort()) !== JSON.stringify(newIds.sort())
      )
        return newIds;
      return prev;
    });
    return () => {
      mounted = false;
    };
  }, [selectedSoldier]);

  useEffect(() => {
    //get the editable data type according to the data types of the current training types
    let editableDataTypesName = dataTypes.map((dataType) => dataType.name);
    setColumns((prev) => {
      return headers.map((header, index) => {
        return {
          ...XDefaultColumn,
          key: header.id,
          name: header.name,
          headerCellClass: "",
          cellClass: "disableEdit",
          editor: editableDataTypesName.includes(String(header.id))
            ? header.id === "WeaponType"
              ? DropdownEditor //DropdownEditor(weapons types) enable when its "WeaponType" data type
              : DataHistoryTextEditor // text editor enable when its data types
            : undefined,
        };
      });
    });
  }, [trainingTypeId, headers, isEditRowInProcess]);

  useEffect(() => {
    //get the datatypes that for the current training type id
    const getTrainingTypes = async (isMounted: boolean) => {
      try {
        let { data } = await Axios.get(
          `${baseUrlPMBackend}/stations/getDataTypes`,
          {
            params: { trainingTypeId: trainingTypeId },
          }
        );
        if (isMounted) {
          setDataTypes(data);
        }
      } catch (error) {}
    };
    let isMounted = true;
    Number(trainingTypeId) && getTrainingTypes(isMounted);

    return () => {
      isMounted = false;
    };
  }, [trainingTypeId]);

  useEffect(() => {
    //if the WeaponType datatypes exist in the data types array get weapons types
    let mounted = true;

    if (dataTypes.find((dataType) => dataType.name === "WeaponType"))
      getWeapons(mounted, setWeaponTypes);

    return () => {
      mounted = false;
    };
  }, [dataTypes]);
  const FilterOn = (props: any) => (
    <div className={"filtersHeader " + (isFilterOn ? "fadeIn" : "fadeOut")}>
      <PMLabel fontSize="small" cssClass="filterLabel">
        {t(props.header.name)}
      </PMLabel>
      {props.header.name === "trainingPlan" ? (
        <SimpleSelect
          onSelect={(e) => {
            setFilters((prev: any) => {
              return {
                ...prev,
                [props.header.key]: e.target.value,
              };
            });
          }}
          value={filters[props.header.key]}
          options={[
            { key: 0, value: "" },
            ...plansOptions.map((option: Option) => ({
              key: String(option.value),
              value: String(option.label),
            })),
          ]}
          title={t("chooseTrainingPlan")}
        ></SimpleSelect>
      ) : (
        <IonInput
          slot="start"
          clearInput={true}
          dir={i18n.language == "en" ? "rtl" : "ltr"}
          className={"filterInputDH"}
          onIonChange={(e: any) => {
            setFilters((prev: any) => {
              return {
                ...prev,
                [props.header.key]: e.detail.value,
              };
            });
          }}
        >
          <PMIcon
            iconSrc={EIconsSrc.FILTER}
            size={"small"}
            cssClass="dataHistoryFilter"
          ></PMIcon>
        </IonInput>
      )}
    </div>
  );

  const FilterOff = (props: any) => (
    <div className={"filtersHeader " + (!isFilterOn ? "fadeIn" : "fadeOut")}>
      <PMLabel fontSize="small" cssClass="filterLabel">
        {t(props.header.name)}
      </PMLabel>
    </div>
  );

  useEffect(() => {
    setColumns((prev) => {
      return prev.map((header) => {
        return {
          ...header,
          headerRenderer: () => {
            return isFilterOn ? (
              <FadeIn className="fadeClass">
                <FilterOn header={header}></FilterOn>
              </FadeIn>
            ) : (
              <FadeIn className="fadeClass">
                <FilterOff header={header}></FilterOff>
              </FadeIn>
            );
          },
        };
      });
    });
  }, [isFilterOn]);

  useEffect(() => {
    setColumns((prev) => {
      return prev.map((header) => {
        return {
          ...header,
          headerRenderer: () => {
            return isFilterOn ? (
              <FilterOn header={header}></FilterOn>
            ) : (
              <FilterOff header={header}></FilterOff>
            );
          },
        };
      });
    });
  }, [plansOptions, isEditRowInProcess]);

  useEffect(() => {
    setInvalid(false);
  }, [isDeleteRows, isEditRowClicked]);

  const { loading, data, setData, setLoading } = useGetPerformanceData(
    trainingTypeId,
    cameToEnd,
    rangeDate,
    filteredSoldierUniqueIds,
    setCameToEnd,

    selectedPlan?.id
  );

  const deleteRows = async () => {
    setIsLoading(true);
    if (selectedRows && selectedRows.length) {
      await Axios.delete(`${baseUrlPMBackend}dataHistory/deleteRow`, {
        params: {
          drillsIds: selectedRows,
          isProduction: environment === EEnvironment.production,
        },
      })
        .then(() => {
          setIsLoading(false);
          setData((prev: IData[]) => {
            return prev
              .filter((row: IData) => !selectedRows.includes(row["drillId"]))
              .map((row: IData, index: number) => {
                return { ...row, rowIndex: index + 1 };
              });
          });
          customToast.success(t("rowDeletedSuccessfully"));
          setSelectedRows([]);
        })
        .catch((res) => {
          setIsLoading(false);

          res.response?.status === 401
            ? customToast.error(t("unauthorizedToDeleteRow"))
            : customToast.error(t("errorWhileDeleteRow"));
        });
    }
  };

  /*
   * check for the edited row if value is correct according to the edited datatype
   */
  const checkRowValidation = (row: any) => {
    let isValid = true;
    Object.keys(row).forEach((rowKey) => {
      let dataType = dataTypes.find((dataType) => dataType.name === rowKey);
      if (dataType !== undefined) {
        if (
          //number
          (EDatatypesTypes.number.includes(dataType.type) &&
            isNaN(Number(row[rowKey]))) ||
          //boolean
          (EDatatypesTypes.boolean.includes(dataType.type) &&
            row[rowKey] !== "true" &&
            row[rowKey] !== "false")
        )
          isValid = false;
        return;
      }
    });
    return isValid;
  };

  const editRow = async () => {
    if (selectedRows && selectedRows.length === 1) {
      //search the edited row to save
      let rowToSave = {
        ...data.find((row) => row.drillId === selectedRows[0]),
      };
      let isValid = rowToSave && checkRowValidation(rowToSave);
      if (isValid && rowToSave !== undefined) {
        //for each value of the row check if its a data type and was edited
        Object.keys(rowToSave).map((key) => {
          let isDataType =
            dataTypes.find((dataType) => dataType.name == key) !== undefined;
          if (!isDataType || rowToSave![key] === tempEditedRow[key]) {
            delete rowToSave![key];
          }
        });

        setLoading(true);
        await Axios.post(`${baseUrlPMBackend}dataHistory/editRow`, {
          drillData: rowToSave,
          drillId: tempEditedRow.drillId,
        })
          .then(() => {
            customToast.success(t("rowEditedSuccessfully"));
            setLoading(false);
            setEditRowInProcess(false);
          })
          .catch((res) => {
            customToast.error(t("errorWhileEditRow"));
            setLoading(false);
            cancelEditingRow();
          });
      } else {
        customToast.error(t("errorInOnOfTheFields"));
      }
    }
  };

  /**
   * restore the edited row to the old row if edit was cancel
   */
  const cancelEditingRow = () => {
    setEditRowInProcess(false);
    setData((prev) => {
      return prev.map((row) => {
        if (row.drillId === selectedRows[0]) {
          return tempEditedRow;
        }
        return row;
      });
    });
  };

  /**
   * set the selected row as editable and store the row in the temp object
   */
  const enableRowToEdit = () => {
    if (selectedRows && selectedRows.length === 1) {
      setData((prev) => {
        return prev.map((row) => {
          if (row.drillId === selectedRows[0]) {
            setTempEditedRow(row);
            //if the row has weaponType datatype add the weapon type to the row param - if not weaponTypes will be empty
            return { ...row, editable: true, weapons: weaponTypes };
          }
          return row;
        });
      });
    }
  };

  useEffect(() => {
    //when edit row process is finished set the editable as false and remove the weapon types
    if (!isEditRowInProcess)
      setData((prev) =>
        prev.map((row) => ({ ...row, editable: false, weapons: null }))
      );
  }, [isEditRowInProcess]);

  const onSendPasswordHandler = async (password: string) => {
    const isPasswordValid = await checkSuperUserPassword(password, setInvalid);
    if (isPasswordValid) {
      //is password enter for deleting rows
      if (isDeleteRows) {
        deleteRows();
        setDeleteRows(false);
      }
      //if password enter for editing row
      if (isEditRowClicked) {
        enableRowToEdit();
        setEditRowClicked(false);
        setEditRowInProcess(true); //start the edit process
      }
    }
  };

  return (
    <IonPage>
      <IonContent className="DHContent" scrollY={false}>
        <TrainingPlanTool
          isOpen={isTrainingTool}
          onDismiss={() => setIsTrainingTool(false)}
          plansOptions={plansOptions}
        ></TrainingPlanTool>
        <IonRow className="filtersRow">
          <div className="rowDH">
            <IonRadioGroup
              value={props.inputType}
              onIonChange={(e: any) => {
                if (e.detail.value === "date" || e.detail.value === "plan")
                  props.setInputType(e.detail.value);
              }}
              className="radioDHGroup"
            >
              <div
                className="radioDHItem"
                onClick={() => {
                  props.setInputType("date");
                }}
              >
                <div className="datePickerDH">
                  <DatePicker
                    mode="range"
                    rangeDate={rangeDate}
                    setRangeDate={setRangeDateAsAllDay}
                    disabled={props.inputType !== "date"}
                  ></DatePicker>
                </div>
                <IonRadio
                  className="radioDataHistory"
                  slot="start"
                  value={"date"}
                />
              </div>
              <div
                className="radioDHItem"
                onClick={() => {
                  props.setInputType("plan");
                }}
              >
                <div className="dropdownWrapDH">
                  <Dropdown
                    options={plansOptions}
                    onChange={onPlanSelectedHandler}
                    value={
                      selectedPlan && selectedPlan?.id !== undefined
                        ? `${selectedPlan.name} ${
                            selectedPlan.isActive ? `(${t("active")})` : ""
                          }`
                        : t("selectPlan")
                    }
                    placeholder={t("measurement")}
                    controlClassName="Dropdown DWidth"
                    arrowClassName="DropdownArrow"
                    placeholderClassName="DropdownPlaceholderCompetency"
                    menuClassName="DropdownMenuCompetency DropdownMenu"
                    disabled={props.inputType !== "plan"}
                  ></Dropdown>
                </div>

                <IonRadio
                  className="radioDataHistory"
                  slot="start"
                  value={"plan"}
                />
              </div>
            </IonRadioGroup>
            <OrbatInput
              wrapperCss="datePickerDH"
              filterRef={filterRef}
              inputValue={`${t("selectForces")}`}
              placeholder={`${t("selectForces")}`}
              onClickInput={() => {
                setIsTreeOpened((prev) => !prev);
              }}
              inputWidth="long"
              isForDropDown
              isTreeOpened={isTreeOpened}
              collapseCssClass="treeCollapse scrollM"
              selectedForces={selectedSoldier}
              setSelectedForces={setSelectedSoldier}
            />
          </div>
          <div className="selectedSoldiers">
            {selectedSoldier.map((soldier) => (
              <span className="soldierBadge" key={soldier.id + soldier.name}>
                {soldier.name.includes(soldier.force_type)
                  ? soldier.name
                  : getForceFullName(soldier.name, soldier.force_type, t)}
                <IonIcon
                  mode="ios"
                  className="soldierRemoveIcon"
                  size="small"
                  icon={close}
                  onClick={() => {
                    setSelectedSoldier((prev) => {
                      return prev.filter((force) => force.id !== soldier.id);
                    });
                  }}
                />
              </span>
            ))}
          </div>
          <div className="trainingPlanTool">
            <PMButton
              label={{
                fontColor: "xLight",
                fontSize: "medium",
                fontFamily: "Light",
              }}
              size="fit"
              color="text-primary"
              fill="outline"
              cssClass="dhbuttons"
              isHidden={!isAdmin || environment === EEnvironment.production}
              onClickHandler={() => setIsTrainingTool(true)}
            >
              {t("trainingPlanTool")}
            </PMButton>
            {environment !== EEnvironment.production ? (
              <PMButton
                label={{
                  fontColor: "xLight",
                  fontSize: "medium",
                  fontFamily: "Light",
                }}
                cssClass="dhbuttons"
                color="text-primary"
                fill="outline"
                isDisabled={
                  !selectedRows.length ||
                  (!isAdmin && environment === EEnvironment.production)
                }
                onClickHandler={() => {
                  setDeleteRows((prev) => !prev);
                }}
              >
                {t("deleteDataHistoryRow")}
              </PMButton>
            ) : null}
            {environment !== EEnvironment.production &&
            Number(trainingTypeId) ? (
              <PMButton
                label={{
                  fontColor: "xLight",
                  fontSize: "medium",
                  fontFamily: "Light",
                }}
                cssClass="dhbuttons"
                color="text-primary"
                fill="outline"
                isDisabled={
                  !selectedRows.length ||
                  selectedRows.length > 1 ||
                  (!isAdmin && environment === EEnvironment.production)
                }
                onClickHandler={() => {
                  setEditRowClicked(true);
                }}
              >
                {t("editRow")}
              </PMButton>
            ) : null}
          </div>
        </IonRow>
        <IonRow className="gridRow scrollM">
          <DrillsDataTable
            setEditRow={setEditRowClicked}
            isEditRow={isEditRowInProcess}
            isLoading={isLoading}
            columns={columns}
            rows={data}
            setRows={setData}
            setLoadMore={setCameToEnd}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            isWithFilter={isFilterOn}
            filters={filters}
            isToDisplayDetails={!Number(trainingTypeId)}
          />
        </IonRow>
        <IonRow className="saveCancelButtonsDH">
          {isEditRowInProcess ? (
            <SaveCancelButtons
              onSaveClickHandler={editRow}
              onCancelClickHandler={cancelEditingRow}
              disabled={!isEditRowInProcess}
            ></SaveCancelButtons>
          ) : null}
        </IonRow>
        <div className="spinnerContainer">
          {loading ? (
            <IonRow>
              <Spinner className="spinner-container" />
            </IonRow>
          ) : null}
        </div>
        <AdminPasswordModal
          isOpen={isDeleteRows || isEditRowClicked}
          onSendPasswordHandler={(password: string) =>
            onSendPasswordHandler(password)
          }
          onCancelHandler={() => {
            isDeleteRows && setDeleteRows(false);
            isEditRowClicked && setEditRowClicked(false);
          }}
          invalid={invalid}
          text={t("deleteSuperUser")}
        ></AdminPasswordModal>
      </IonContent>
    </IonPage>
  );
};

export default DataHistory;
