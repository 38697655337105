import { IonSearchbar } from "@ionic/react";
import { useTranslation } from "react-i18next";
import "./SearchBar.css";

interface ISearchBarProps {
  value: string;
  placeholder?: string;
  onChange: (e: string) => void;
  onClear?: () => void;
}
const SearchBar = (props: ISearchBarProps) => {
  const { value, placeholder, onChange, onClear } = props;
  const { t } = useTranslation();
  return (
    <IonSearchbar
      animated
      color="dark"
      value={value}
      onIonChange={(e) => onChange(e.detail.value!)}
      placeholder={placeholder ? placeholder : t("search")}
      className="pmSearchBar"
      onIonClear={() => onClear && onClear()}
    ></IonSearchbar>
  );
};

export default SearchBar;
