import { IonPage } from "@ionic/react";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../../components/Desktop/TrainingPlan/TrainingPlanInput";
import "./Login.css";
import { eyeOutline } from "ionicons/icons";
import PMButton from "../../../components/themeComponents/PMButton";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import { UserContext } from "../../../context/UserContext/userContext";
import customToast from "../../../components/Shared/Toast/CustomToast";
import { userRoles } from "../../../services/routeRoles";
import IUser from "../../../Interfaces/IUser";

const Login = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordInputType] = useState<"password" | "text">("password");
  // const [,] = useState<string>(eyeOutline);
  const { t } = useTranslation();
  const { login } = useContext(UserContext);

  // Handles icon click
  // const onIconClickHandler = () => {
  //   setIcon((prev) => (prev === eyeOutline ? eyeOffOutline : eyeOutline));
  //   setPasswordInputType((prev) => (prev === "password" ? "text" : "password"));
  // };

  const tryLogin = async () => {
    if (username && password) {
      await Axios.post(`${baseUrlPMBackend}users/login`, {
        username: username,
        password: password,
      })
        .then((res) => {
          loginAfterAuthentication(res.data);
          customToast.success(t("loginSucceed"));
        })
        .catch(() => customToast.error(t("loginError")));
    } else {
      customToast.error(t("missingInformation"));
    }
  };

  const loginAfterAuthentication = (user: IUser) => {
    if (user && user.role !== userRoles.Unauthorized) {
      login({
        id: user.id,
        displayName: user.displayName,
        role: user.role,
        emailAddress: user.emailAddress,
        relatedForce: user.relatedForce,
        forceToDisplayInOrbat: user.forceToDisplayInOrbat,
      });

      // Sets local storage
      if (!localStorage.getItem("user"))
        localStorage.setItem(
          "user",
          JSON.stringify({
            id: user.id,
            displayName: user.displayName,
            role: user.role,
            emailAddress: user.emailAddress,
            relatedForce: user.relatedForce,
            forceToDisplayInOrbat: user.forceToDisplayInOrbat,
          })
        );
    } else {
      customToast.error(t("wrongCredentials"));
    }
  };

  return (
    <IonPage>
      <form onSubmit={tryLogin} className="loginDiv">
        <div className="inputsDiv">
          <Input
            inputValue={username}
            inputType="text"
            onChangeHandler={(inputName: string, inputValue: string) =>
              setUsername(inputValue)
            }
            inputName="username"
            length="long"
            placeholder={t("userName")}
          ></Input>

          <Input
            inputValue={password}
            inputType={passwordInputType}
            onChangeHandler={(inputName: string, inputValue: string) =>
              setPassword(inputValue)
            }
            inputName="password"
            length="long"
            placeholder={t("password")}
            // isPassword
            // icon={icon}
            // iconInInput
            // onIconClick={onIconClickHandler}
          ></Input>
        </div>
        <div className="buttonDiv">
          <PMButton
            color="tertiary"
            label={{ fontColor: "light", fontFamily: "Light" }}
            onClickHandler={tryLogin}
            size="large"
            type="submit"
          >
            {t("login")}
          </PMButton>
        </div>
      </form>
    </IonPage>
  );
};

export default Login;
