import { IonRow, IonCol, IonItem } from "@ionic/react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import MultiSelectDropdown from "../MultySelectDropdown/MultiSelectDropdown";
import {
  IChartsSelection,
  IIndicatorsSelection,
  ITooltipsOptions,
} from "../../../Interfaces/IExportReport";
import { Option } from "react-dropdown";

import "./ExportModalCollapse.css";
import { useTranslation } from "react-i18next";
import Checkbox from "../../Shared/Checkbox/Checkbox";
import PMLabel from "../../themeComponents/PMLabel";
import { Collapse } from "react-collapse";
import PMIcon from "../../themeComponents/PMIcon";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import Dropdown from "../../Shared/Dropdown/Dropdown";
import { IForceAllData } from "../../../Interfaces/IForceAllData";
import IPlan from "../../../Interfaces/IPlan";

interface IProps {
  isSelectionOpened: boolean;
  chartsType: IChartsSelection[];
  indicatorsSelection: IIndicatorsSelection[];
  hashtags: ITooltipsOptions[];
  tooltipsTitles: ITooltipsOptions[];
  setChartsData: Dispatch<SetStateAction<IChartsSelection[]>>;
  setHashtags: Dispatch<SetStateAction<ITooltipsOptions[]>>;
  setIndicatorsSelection: Dispatch<SetStateAction<IIndicatorsSelection[]>>;
  setTooltipsTitles: Dispatch<SetStateAction<ITooltipsOptions[]>>;
  plansOptions: Option[];
  onPlanSelectedHandler: (planOption: Option) => void;
  selectedPlan: IPlan | undefined;
}

const ExportModalCollapse: React.FC<IProps> = (props: IProps) => {
  const {
    chartsType,
    indicatorsSelection,
    hashtags,
    tooltipsTitles,
    setChartsData,
    setHashtags,
    setIndicatorsSelection,
    setTooltipsTitles,
    selectedPlan,
    plansOptions,
    onPlanSelectedHandler,
  } = props;
  const { t } = useTranslation();
  const [isGraphOpen, setIsGraphOpen] = useState<boolean>(false);
  const [isIndicatorsOpen, setIsIndicatorsOpen] = useState<boolean>(false);
  const [isTooltipsOpen, setIsTooltipsOpen] = useState<boolean>(false);
  const [isHashtagsOpen, setIsHashtagsOpen] = useState<boolean>(false);

  const setChartsChangeHandler = (chartId: number, checked: boolean) => {
    setChartsData((prev) => {
      return prev.map((chart) => {
        if (chart.chartId === chartId) return { ...chart, isSelected: checked };
        return chart;
      });
    });
  };

  const setHashtagsChangeHandler = (
    hashtag?: {
      id: string;
      labelName: string;
    },
    checked?: boolean,
    isSelect?: boolean,
    trainingTypeId?: number
  ) => {
    let trainingTypeId2 = hashtag ? hashtag.id.split("_")[0] : trainingTypeId;
    setHashtags((prev) => {
      return prev.map((trainingType) => {
        if (Number(trainingType.trainingTypeId) === Number(trainingTypeId2))
          return {
            ...trainingType,
            selectionsOptions: trainingType.selectionsOptions.map(
              (hashtagOption) => {
                if (
                  hashtagOption.option.label === hashtag?.labelName ||
                  isSelect !== undefined
                )
                  return {
                    ...hashtagOption,
                    isSelected:
                      isSelect !== undefined
                        ? isSelect
                        : checked
                        ? checked
                        : false,
                  };
                else return hashtagOption;
              }
            ),
          };
        return trainingType;
      });
    });
  };
  const setIndicatorsChangeHandler = (indicatorId: number) => {
    setIndicatorsSelection((prev) => {
      return prev.map((indicator) => {
        if (indicator.id === indicatorId)
          return { ...indicator, isSelected: !indicator.isSelected };
        return indicator;
      });
    });
  };

  const setTrainingTypesChangeHandler = (
    indicatorId: number,
    trainingTypeId: number,
    checked: boolean
  ) => {
    setIndicatorsSelection((prev) => {
      return prev.map((indicator) => {
        if (indicator.id === indicatorId)
          return {
            ...indicator,
            trainingTypeSelections: indicator.trainingTypeSelections.map(
              (trainingType) => {
                if (trainingType.id === trainingTypeId)
                  return {
                    ...trainingType,
                    isSelected: checked,
                  };
                else return trainingType;
              }
            ),
          };
        return indicator;
      });
    });
    setHashtags((prev) => {
      return prev.map((trainingType) => {
        if (Number(trainingType.trainingTypeId) === Number(trainingTypeId))
          return {
            ...trainingType,
            isSelected: checked,
          };
        return trainingType;
      });
    });
    setTooltipsTitles((prev) => {
      return prev.map((trainingType) => {
        if (Number(trainingType.trainingTypeId) === Number(trainingTypeId))
          return {
            ...trainingType,
            isSelected: checked,
          };
        return trainingType;
      });
    });
  };

  const setCheckedTooltipsHandler = (
    data?: {
      id: string;
      labelName: string;
    },
    checked?: boolean,
    isSelect?: boolean,
    trainingTypeId?: number
  ) => {
    let trainingTypeName = data?.id.split("_")[0];
    setTooltipsTitles((prev) => {
      return prev.map((trainingType) => {
        if (
          trainingType.trainingTypeName === trainingTypeName ||
          Number(trainingType.trainingTypeId) === Number(trainingTypeId)
        )
          return {
            ...trainingType,
            selectionsOptions: trainingType.selectionsOptions.map((tooltip) => {
              if (
                tooltip.option.label === data?.labelName ||
                isSelect !== undefined
              )
                return {
                  ...tooltip,
                  isSelected:
                    isSelect !== undefined
                      ? isSelect
                      : checked
                      ? checked
                      : false,
                };
              else return tooltip;
            }),
          };
        return trainingType;
      });
    });
  };
  return (
    <IonRow>
      <IonCol className="borderCol">
        <Dropdown
          options={plansOptions}
          onChange={onPlanSelectedHandler}
          value={`${
            selectedPlan && selectedPlan?.id !== undefined
              ? `${selectedPlan.name} ${
                  selectedPlan.isActive ? `(${t("active")})` : ""
                }`
              : t("selectPlan")
          }`}
          placeholder={t("measurement")}
          controlClassName="Dropdown DWidth"
          arrowClassName="DropdownArrow"
          placeholderClassName="DropdownPlaceholderCompetency"
          menuClassName="DropdownMenuCompetency DropdownMenu"
          disabled={false}
        ></Dropdown>
      </IonCol>
      <IonCol className="borderCol">
        <IonItem
          className={`iconNameDivEM ${isGraphOpen ? "borderBottom" : ""}`}
          lines="none"
          onClick={() => {
            setIsGraphOpen((prev) => !prev);
          }}
        >
          <PMLabel fontSize="medium" fontColor="light" fontFamily="Light">
            {t("selectGraphs")}
          </PMLabel>
          <PMIcon
            color="light"
            iconSrc={
              isGraphOpen ? EIconsSrc.UP_ARROW_FULL : EIconsSrc.DOWN_ARROW_FULL
            }
            size="xLarge"
          ></PMIcon>
        </IonItem>
        <Collapse isOpened={isGraphOpen}>
          <div className="collapseRow" hidden={!isGraphOpen}>
            <div className="optionsCol">
              {chartsType.map((chart) => (
                <div className="selectRow" key={chart.chartId}>
                  <Checkbox
                    onChange={(event) =>
                      setChartsChangeHandler(
                        chart.chartId,
                        event.detail.checked
                      )
                    }
                    isChecked={chart.isSelected}
                    name={chart.name}
                    value={chart.name}
                    color="light"
                  ></Checkbox>
                  <PMLabel
                    fontColor="light"
                    fontFamily="Light"
                    fontSize="medium"
                  >
                    {t(chart.name)}
                  </PMLabel>
                </div>
              ))}
            </div>
          </div>
        </Collapse>
      </IonCol>
      <IonCol className="borderCol">
        <IonItem
          className={`iconNameDivEM ${isIndicatorsOpen ? "borderBottom" : ""}`}
          lines="none"
          onClick={() => {
            setIsIndicatorsOpen((prev) => !prev);
          }}
        >
          <PMLabel fontSize="medium" fontColor="light" fontFamily="Light">
            {t("selectIndicatorsAndTrainingTypesDetails")}
          </PMLabel>
          <PMIcon
            color="light"
            iconSrc={
              isIndicatorsOpen
                ? EIconsSrc.UP_ARROW_FULL
                : EIconsSrc.DOWN_ARROW_FULL
            }
            size="xLarge"
          ></PMIcon>
        </IonItem>
        <Collapse isOpened={isIndicatorsOpen}>
          <div className="collapseRow" hidden={!isIndicatorsOpen}>
            <div className="optionsCol">
              {indicatorsSelection?.map((indicator) => (
                <div key={indicator.id}>
                  <div className="selectRow">
                    <Checkbox
                      onChange={() => setIndicatorsChangeHandler(indicator.id)}
                      name={indicator.label}
                      value={indicator.label}
                      isChecked={indicator.isSelected}
                      color="light"
                    ></Checkbox>
                    <PMLabel
                      fontColor="light"
                      fontFamily="Light"
                      fontSize="medium"
                    >
                      {t(indicator.label)}
                    </PMLabel>
                  </div>
                  <div>
                    {indicator.trainingTypeSelections.map((trainingType) => (
                      <div
                        className="selectRow tooltipRow"
                        key={`${trainingType.id}${indicator.id}`}
                      >
                        <Checkbox
                          onChange={(event) =>
                            setTrainingTypesChangeHandler(
                              indicator.id,
                              trainingType.id,
                              event.detail.checked
                            )
                          }
                          name={trainingType.label}
                          value={trainingType.label}
                          isChecked={trainingType.isSelected}
                          color="light"
                        ></Checkbox>
                        <PMLabel
                          fontColor="light"
                          fontFamily="Light"
                          fontSize="medium"
                        >
                          {t(trainingType.label)}
                        </PMLabel>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Collapse>
      </IonCol>
      <IonCol className="borderCol">
        <IonItem
          className={`iconNameDivEM ${isTooltipsOpen ? "borderBottom" : ""}`}
          lines="none"
          onClick={() => {
            setIsTooltipsOpen((prev) => !prev);
          }}
        >
          <PMLabel fontSize="medium" fontColor="light" fontFamily="Light">
            {t("selectTooltips")}
          </PMLabel>
          <PMIcon
            color="light"
            iconSrc={
              isTooltipsOpen
                ? EIconsSrc.UP_ARROW_FULL
                : EIconsSrc.DOWN_ARROW_FULL
            }
            size="xLarge"
          ></PMIcon>
        </IonItem>
        <Collapse isOpened={isTooltipsOpen}>
          <div className="collapseRow" hidden={!isTooltipsOpen}>
            <div className="optionsCol flexCol">
              {tooltipsTitles?.map((trainingType) =>
                trainingType.isSelected ? (
                  <div
                    className="dropdownSelections"
                    key={trainingType.trainingTypeName}
                  >
                    <MultiSelectDropdown
                      isForModal={true}
                      key={trainingType.trainingTypeName}
                      setCheckedHandler={(data, checked, isSelect) =>
                        setCheckedTooltipsHandler(
                          data,
                          checked,
                          isSelect,
                          trainingType.trainingTypeId
                        )
                      }
                      title={t(trainingType.trainingTypeName)}
                      selectionPotions={trainingType.selectionsOptions}
                    ></MultiSelectDropdown>
                  </div>
                ) : null
              )}
            </div>
          </div>
        </Collapse>
      </IonCol>
      <IonCol className="borderCol">
        <IonItem
          className={`iconNameDivEM ${isHashtagsOpen ? "borderBottom" : ""}`}
          lines="none"
          onClick={() => {
            setIsHashtagsOpen((prev) => !prev);
          }}
        >
          <PMLabel fontSize="medium" fontColor="light" fontFamily="Light">
            {t("selectHashtags")}
          </PMLabel>
          <PMIcon
            color="light"
            iconSrc={
              isHashtagsOpen
                ? EIconsSrc.UP_ARROW_FULL
                : EIconsSrc.DOWN_ARROW_FULL
            }
            size="xLarge"
          ></PMIcon>
        </IonItem>
        <Collapse isOpened={isHashtagsOpen}>
          <div className="collapseRow" hidden={!isHashtagsOpen}>
            <div className="optionsCol flexCol">
              {hashtags?.map((trainingType) =>
                trainingType.isSelected ? (
                  <div
                    className="dropdownSelections"
                    key={trainingType.trainingTypeId}
                  >
                    <MultiSelectDropdown
                      isForModal={true}
                      key={trainingType.trainingTypeId}
                      setCheckedHandler={(data, checked, isSelect) =>
                        setHashtagsChangeHandler(
                          data,
                          checked,
                          isSelect,
                          trainingType.trainingTypeId
                        )
                      }
                      title={t(trainingType.trainingTypeName)}
                      selectionPotions={trainingType.selectionsOptions}
                    ></MultiSelectDropdown>
                  </div>
                ) : null
              )}
            </div>
          </div>
        </Collapse>
      </IonCol>
    </IonRow>
  );
};

export default ExportModalCollapse;
