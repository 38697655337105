import React, { FC, Fragment } from "react";
import { IonButton, IonText } from "@ionic/react";
import IStationDetails from "../../../Interfaces/IStationDetails";
import PMLabel from "../../themeComponents/PMLabel";
import { useTranslation } from "react-i18next";
import { URLs } from "../../../Configurations/urls";
import "./StationButtonComp.css";

interface IButtonCompData {
  station: IStationDetails;
  planId: number | undefined;
}
const StationButtonComp: FC<IButtonCompData> = (props: IButtonCompData) => {
  const { planId, station } = props;
  const { t } = useTranslation();

  return (
    <Fragment>
      <IonButton
        className="stationButtonComp"
        strong={false}
        routerLink={
          station.stationName === "מטווח חי"
            ? URLs.stations.shootingRange.plans.getURL()
            : station.isCheckIn
            ? `/station/${planId}/${station.stationName}/${station.stationId}/${
                station.trainingTypeId
              }/${station.isManual ? "manual" : "notManual"}`
            : `searchSoldier/${planId}/${station.stationName}/${station.stationId}/${station.trainingTypeId}`
        }
        routerDirection="none"
        color="dark"
      >
        <PMLabel
          fontColor="light"
          fontFamily="Regular"
          fontSize="medium"
          textAlign="center"
          cssClass="stationButtonLabel"
        >
          {t(station.stationName.trim())}
        </PMLabel>
      </IonButton>
    </Fragment>
  );
};
export default StationButtonComp;
