import { useQuery } from "react-query";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import { ITooltipsOptions } from "../../../Interfaces/IExportReport";
import ISelectionOption from "../../../Interfaces/ISelectionOption";

const asyncFetchTooltipsTitle = async () => {
  let res = await Axios.get(
    `${baseUrlPMBackend}performanceDetails/getTooltipsTitles`
  );
  let selectionsOptions: ITooltipsOptions[] = [];
  Object.keys(res.data).map((trainingType) => {
    let selectionOptions: ISelectionOption[] = [];
    res.data[trainingType].map((tooltipTitle: string, index: number) => {
      if (tooltipTitle !== "")
        selectionOptions.push({
          isSelected: false,
          option: {
            id: `${trainingType}_${tooltipTitle}`,
            label: tooltipTitle,
          },
        });
    });
    selectionsOptions.push({
      selectionsOptions: selectionOptions,
      trainingTypeName: trainingType,
      isSelected: false,
      trainingTypeId: Number(0),
    });
  });
  return selectionsOptions;
};

export const useOriginalTooltipsTitles = () => {
  let query = useQuery<ITooltipsOptions[]>({
    queryKey: ["tooltipsTitles"],
    queryFn: asyncFetchTooltipsTitle,
  });
  return query;
};
