import {
  FETCH_INDICATORS_REQUEST,
  FETCH_INDICATORS_TYPES_FAILURE,
  FETCH_INDICATORS_TYPES_REQUEST,
  FETCH_INDICATORS_TYPES_SUCCESS,
  SAVE_INDICATORS_REQUEST,
  UPDATE_INDICATOR,
  SAVE_INDICATORS_SUCCESS,
  SAVE_INDICATORS_FAILURE,
} from "./../actions/indicatorsActions";
import IIndicator from "../../Interfaces/IIndicator";
import {
  ADD_INDICATOR,
  DELETE_INDICATOR,
  EDIT_INDICATOR,
  FETCH_INDICATORS_FAILURE,
  FETCH_INDICATORS_SUCCESS,
  IndicatorActionTypes,
  SET_INDICATORS,
} from "../actions/indicatorsActions";
import IIndicatorType from "../../Interfaces/IIndicatorType";

export interface IIndicatorReducer {
  indicators: IIndicator[];
  loading: boolean;
  error: Error | "";
  indicatorsTypes: IIndicatorType[];
  indicatorRenderId: number;
}

const indicatorReducerDefaultState: IIndicatorReducer = {
  indicators: [],
  loading: false,
  error: "",
  indicatorsTypes: [],
  indicatorRenderId: 0,
};

const indicatorsReducer = (
  state = indicatorReducerDefaultState,
  action: IndicatorActionTypes
) => {
  switch (action.type) {
    // Sets indicators
    case SET_INDICATORS:
      return { ...state, indicators: action.indicators };

    // Adds an indicator
    case ADD_INDICATOR:
      return { ...state, indicators: [...state.indicators, action.indicator] };

    // Edit specific indicator
    case EDIT_INDICATOR:
      return {
        ...state,
        indicators: [
          ...state.indicators.map((indicator) => {
            // If the indicator is new (before saving), it wont have a valueId and we'll check by render id
            if (
              indicator.indicatorRenderId &&
              action.indicator.indicatorRenderId
            ) {
              if (
                indicator.indicatorRenderId ===
                action.indicator.indicatorRenderId
              ) {
                return {
                  ...action.indicator,
                  errors: action.indicator.errors,
                };
              } else {
                return indicator;
              }
              // If the indicator has gone through saving, it'll compare by the valueId
            } else {
              if (indicator.valueId === action.indicator.valueId) {
                return {
                  ...indicator,
                  ...action.indicator,
                  errors: action.indicator.errors,
                };
              } else {
                return indicator;
              }
            }
          }),
        ],
      };

    case DELETE_INDICATOR:
      return {
        ...state,
        indicators: [
          ...state.indicators.filter(({ id }) => id !== action.id),
        ].map((indicator, index) => {
          if (indicator.indicatorRenderId) {
            return { ...indicator, indicatorRenderId: index + 1 };
          } else {
            return indicator;
          }
        }),
      };

    case SET_INDICATORS:
      return action.indicators;

    case FETCH_INDICATORS_REQUEST:
      return { ...state, loading: true };

    case FETCH_INDICATORS_SUCCESS:
      return {
        ...state,
        loading: false,
        indicators: action.indicators,
        error: "",
      };

    case FETCH_INDICATORS_FAILURE:
      return { ...state, loading: false, indicators: [], error: action.error };

    case FETCH_INDICATORS_TYPES_REQUEST:
      return { ...state, loading: true };

    case FETCH_INDICATORS_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        indicatorsTypes: action.indicatorsTypes,
        error: "",
      };

    case FETCH_INDICATORS_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        indicatorsTypes: [],
        error: action.error,
      };

    case UPDATE_INDICATOR: {
      return {
        ...state,
        indicators: [
          ...state.indicators.map((indicator) => {
            if (indicator.id === action.indicator.id) {
              return { ...indicator, ...action.indicator };
            } else {
              return indicator;
            }
          }),
        ],
      };
    }

    case SAVE_INDICATORS_REQUEST: {
      return { ...state };
    }

    case SAVE_INDICATORS_SUCCESS: {
      return { ...state, loading: false };
    }

    case SAVE_INDICATORS_FAILURE: {
      return { ...state, loading: false, error: action.error };
    }

    default:
      return state;
  }
};

export default indicatorsReducer;
