import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DropdownEditor from "./DropdownEditor";
import DrillsDataTable from "./DrillsDataTable";
import "./ResponsiveShooting.css";
import DesktopManualInputTemplate from "./DesktopManualInputTemplate";
import IHeat from "../../../Interfaces/IHeat";
import HeatsModal from "./HeatsModal/HeatsModal";
import { heatList } from "../../Mobile/ManualStation/ShootingRangeDataPosting/heatList";
import ISoldier from "../../../Interfaces/ISoldier";
import {
  handleSave,
  IbasicRow,
  orderMRKAndIESTLanes,
  resetRowsData,
  selectHeat,
  sendDrillToBBackend,
  XDefaultColumn,
} from "./dataPostingHelpers";
import {
  ComparisonFormatter,
  PositiveNumberFormatter,
  PositiveNumberNtoMFormatter,
} from "./InputFormatters";
import UseDataPosting from "../../CustomHooks/UseDataPosting";
import SoldiersListEditor from "./SoldiersListEditor";
import IDataPosting from "../../../Interfaces/IDataPosting";

const messageType: number = 6;
const trainingTypeId: number = 9;
const rowsNumber: number = 14;

//  message interface
interface responsiveShootingMessage {
  MessageType: number;
  TimeStamp: Date;
  SoldierID: number | "";
  LaneId: number | "";
  TrainingTypeID: number;
  WeaponType: string;
  NumOfTargets: number | "";
  NumOfShots: number | "";
  RedHits: number | "";
  BlueHits: number | "";
  Duration: number;
  TargetsDestroyed: number | "";
  CleanTargets: string;
  Hashtags: string[];
  DrillStartTime: Date;
  DrillEndTime: Date;
  DamagePercentage: number | "";
  GreenHits: number | "";
  PlanId: number | undefined;
}
interface IResponsiveShootingRow extends IbasicRow {
  heats: IHeat[];
  laneId: number;
  soldierName: string;
  soldierId: number | "";
  numberOfShots: number | "";
  numberOfTargets: number | "";
  redHits: number | "";
  blueHits: number | "";
  duration: number | "";
  cleanTargets: string;
  targetsDestroyed: number | "";
  weaponType: string;
  soldiersInLanes: ISoldier[];
  hashtags: string | "";
  weapons: string[];
  damagePercentage: number | "";
  greenHits: number | "";
}

const ResponsiveShooting: React.FC<IDataPosting> = (
  props: IDataPosting
): JSX.Element => {
  const { t } = useTranslation();

  const XDefaultRow: IResponsiveShootingRow = {
    heats: heatList,
    soldierId: "",
    soldierName: "",
    weaponType: "",
    numberOfShots: "",
    cleanTargets: "",
    duration: "",
    laneId: 0,
    numberOfTargets: "",
    targetsDestroyed: "",
    weapons: [],
    soldiersInLanes: [],
    hashtags: "",
    blueHits: "",
    redHits: "",
    damagePercentage: "",
    greenHits: "",
  };

  const {
    isHeatsModalOpen,
    isSuccess,
    setIsSuccess,
    rows,
    setRows,
    setIsHeatsModalOpen,
    refreshRowsWrap,
    resetRowsDataWrap,
    soldiersInLanes,
  } = UseDataPosting(XDefaultRow, rowsNumber, trainingTypeId);
  useEffect(() => {
    orderMRKAndIESTLanes(soldiersInLanes, setRows);
  }, [soldiersInLanes]);

  // Columns for each input type
  const columns = [
    {
      ...XDefaultColumn,
      key: "laneId",
      name: t("lane"),
      editor: undefined,
      cellClass: "celClassExtra",
    },

    {
      ...XDefaultColumn,
      key: "soldierName",
      name: t("soldierName"),
      editor: SoldiersListEditor,
      cellClass: "celClassExtra",
    },
    {
      ...XDefaultColumn,
      key: "soldierId",
      name: t("militaryId"),
      formatter: PositiveNumberFormatter,
    },
    {
      ...XDefaultColumn,
      key: "heat",
      name: t("heat"),
      editor: DropdownEditor,
      cellClass: "celClassExtra",
    },
    {
      ...XDefaultColumn,
      key: "weaponType",
      name: t("weaponType"),
      editor: DropdownEditor,
      cellClass: "celClassExtra",
    },
    {
      ...XDefaultColumn,
      key: "numberOfShots",
      name: t("numOfShots"),
      formatter: PositiveNumberFormatter,
    },
    {
      ...XDefaultColumn,
      key: "redHits",
      name: t("RedHits"),
      formatter: (event: any) => (
        <ComparisonFormatter comparisonKey="numberOfShots" event={event} />
      ),
    },

    {
      ...XDefaultColumn,
      key: "numberOfTargets",
      name: t("numOfTargets"),
      formatter: (event: any) => (
        <PositiveNumberNtoMFormatter
          minToMax={{ max: 10, min: 1 }}
          event={event}
        />
      ),
    },
    {
      ...XDefaultColumn,
      key: "duration",
      name: t("Duration"),
      formatter: PositiveNumberFormatter,
    },
    {
      ...XDefaultColumn,
      key: "damagePercentage",
      name: t("DamagePercentage"),
      formatter: (event: any) => (
        <PositiveNumberNtoMFormatter
          minToMax={{ max: 100, min: 1 }}
          event={event}
        />
      ),
    },
    {
      ...XDefaultColumn,
      key: "cleanTargets",
      name: t("CleanTargets"),
      formatter: PositiveNumberFormatter,
    },
    {
      ...XDefaultColumn,
      key: "targetsDestroyed",
      name: t("TargetsDestroyed"),
      formatter: PositiveNumberFormatter,
    },

    {
      ...XDefaultColumn,
      key: "hashtags",
      name: t("hashtags"),
      cellClass: "celClassExtra",
    },
  ];

  // Manages data sending
  const sendData = async (row: IResponsiveShootingRow) => {
    // To avoid sending empty rows, check if all elements exist
    if (
      row.soldierId !== "" ||
      row.cleanTargets !== "" ||
      row.numberOfShots !== "" ||
      row.duration !== "" ||
      row.numberOfTargets !== ""
    ) {
      // Sets the loading
      setIsSuccess(-1);
      let rowToSend: responsiveShootingMessage;

      let weaponType = row.weaponType === "" ? "None" : row.weaponType;

      rowToSend = {
        MessageType: messageType,
        TimeStamp: new Date(),
        TrainingTypeID: trainingTypeId,
        SoldierID: row.soldierId,
        WeaponType: weaponType,
        NumOfShots: row.numberOfShots,
        BlueHits: row.blueHits,
        CleanTargets: row.cleanTargets,
        Duration: row.duration === "" ? 0 : row.duration,
        NumOfTargets: row.numberOfTargets,
        LaneId: row.laneId,
        RedHits: row.redHits,
        TargetsDestroyed: row.targetsDestroyed,
        Hashtags: row.hashtags.split(" "),
        DrillStartTime: new Date(),
        DrillEndTime: new Date(),
        DamagePercentage: row.damagePercentage,
        GreenHits: row.greenHits,
        PlanId: props.selectedPlan?.id,
      };

      // Then sends it
      sendDrillToBBackend(
        rowToSend,
        () => setIsSuccess(200),
        () => setIsSuccess(500)
      );
    }
  };

  return (
    <DesktopManualInputTemplate
      selectedPlan={props.selectedPlan}
      plansOptions={props.plansOptions}
      onPlanSelectedHandler={props.onPlanSelectedHandler}
      pageName={"responsiveShooting"}
      onClickThirdButton={() => {
        handleSave(rows, sendData, t);
      }}
      onClickSecondButton={() => {
        refreshRowsWrap(XDefaultRow);
        orderMRKAndIESTLanes(soldiersInLanes, setRows);
      }}
      onClickFirstButton={() => {
        resetRowsDataWrap(XDefaultRow, true);
      }}
    >
      <HeatsModal
        isOpen={isHeatsModalOpen}
        onDismiss={() => setIsHeatsModalOpen(false)}
        onHeatSelect={(heat: IHeat) => {
          selectHeat(heat, setRows, setIsHeatsModalOpen);
        }}
      ></HeatsModal>
      <DrillsDataTable
        columns={columns}
        isSuccess={isSuccess}
        resetRows={() => {
          resetRowsData(setRows, rowsNumber, XDefaultRow);
        }}
        rows={rows}
        setRows={setRows}
        isDataPosting
      />
    </DesktopManualInputTemplate>
  );
};

export default ResponsiveShooting;
