import {
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import EEventType from "../../../../Interfaces/EEventType";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import i18n from "../../../../services/i18next";
import {
  getWeapons,
  sendDrillToBBackend,
} from "../../../Desktop/DataPosting/dataPostingHelpers";
import PMButton from "../../../themeComponents/PMButton";
import PMLabel from "../../../themeComponents/PMLabel";
import MobileHeader from "../../MobileHeader/MobileHeader";
import MobileTitleRow from "../../MobileHeader/MobileTitleRow";
import { heatList } from "./heatList";
import "./ShootingRangeDataPosting.css";

interface IWeaponType {
  name: string;
  id: number;
}

const ShootingRangeDataPosting: FC = () => {
  const [numOfShots, setNumOfShots] = useState<string>();
  const [numOfHits, setNumOfHits] = useState<string>();
  const [grouping, setGrouping] = useState<string>();
  const [score, setScore] = useState<string>();
  const [hashtags, setHashtags] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [weaponTypes, setWeaponTypes] = useState<string[]>([]);
  const [weaponType, setWeaponType] = useState<string>();
  const { t } = useTranslation();
  const history = useHistory();
  const startDateTime: Date = new Date();

  const params = useParams<{
    heatId: string;
    stationName: string;
    trainingTypeId: string;
    stationId: string;
    laneId: string;
    traineeName: string;
    traineeId: string;
    planId: string;
  }>();

  const traineeId = parseInt(params.traineeId);
  const trainingTypeId = parseInt(params.trainingTypeId);
  const stationId = parseInt(params.stationId);
  const stationName = params.stationName;
  const traineeName = params.traineeName;
  const heatId = params.heatId;
  const planId = params.planId;

  useEffect(() => {
    const heat = heatList.find((heat) => heat.id === Number(heatId));

    setNumOfShots(heat?.bullets);
    setHashtags(heat?.hashtags || "");
  }, [heatId]);

  const returnToSoldiersList = () => {
    history.goBack();
  };

  /**
   * return an array with the hour, minutes and seconds as a string
   * @param overalltime
   */
  const getDurationParams = (overalltime: string) => {
    const overallTime: string[] = overalltime.split(":");
    const minutes = overallTime[0];
    const seconds = overallTime[1];
    return [minutes, seconds];
  };

  /**
   * returns the duration in seconds
   * @param overalltime
   */
  const getDurationInSeconds = (overalltime: string) => {
    let durationParams = getDurationParams(overalltime);
    return parseInt(durationParams[0]) * 60 + parseInt(durationParams[1]);
  };

  /**
   * post the data to b-backend
   */
  const sendData = async () => {
    if (traineeId === undefined) {
      // if (isDesktop) customToast.error(t("noSelectedSoldier"));
      return;
    }
    setIsButtonDisabled(true);
    const timeStamp: Date = new Date();
    let currentWeaponType = weaponType === "No Weapon" ? "None" : weaponType;
    const shootingRangeDrill = {
      MessageType: EEventType.SHOOTING_RANGE_MESSAGE_TYPE,
      TimeStamp: timeStamp,
      SoldierID: traineeId,
      TrainingTypeID: trainingTypeId,
      WeaponType: currentWeaponType,
      NumOfShots: numOfShots,
      RedHits: numOfHits,
      Grouping: grouping,
      Score: score,
      DrillStartTime: startDateTime,
      Hashtags: hashtags.split(" "),
      PlanId: planId,
    };
    sendDrillToBBackend(
      shootingRangeDrill,
      returnToSoldiersList,
      () => {
        setIsButtonDisabled(false);
      },
      t
    );
  };

  // Weapon Types
  useEffect(() => {
    let mounted = true;
    getWeapons(mounted, setWeaponTypes);
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <IonPage>
      <MobileHeader
        iconEndSrc={EIconsSrc.MENU_ICON}
        iconStartSrc={
          i18n.language === "he"
            ? EIconsSrc.BACK_ICON_HE
            : EIconsSrc.BACK_ICON_EN
        }
        title={t(stationName)}
        iconStartHandler={returnToSoldiersList}
      />
      <IonContent className="cnt">
        <IonGrid className="shootingRangeGrid">
          <MobileTitleRow
            title={
              traineeName +
              " - " +
              heatList.find((heat) => heat.id === Number(heatId))?.name
            }
          />
          <IonRow className="shootingRangeInputsRow">
            <IonRow className="shootingRangeInputRow">
              <IonItem
                mode="md"
                class="manualInputContainerM itemM"
                lines="full"
              >
                <PMLabel
                  fontSize="medium"
                  fontFamily="Light"
                  fontColor="light"
                  position="floating"
                >
                  {t("weaponType")}
                </PMLabel>
                <IonSelect
                  interface="action-sheet"
                  interfaceOptions={{ cssClass: "weaponsActionSheet" }}
                  value={weaponType}
                  onIonChange={(e) => setWeaponType(e.detail.value)}
                >
                  {weaponTypes.map((weaponType: string, index: number) => (
                    <IonSelectOption
                      key={index}
                      className="weaponOpt"
                      value={weaponType}
                    >
                      {weaponType}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonRow>

            <IonRow className="shootingRangeInputRow">
              <IonItem
                mode="md"
                class="manualInputContainerM itemM"
                lines="full"
              >
                <PMLabel
                  fontSize="medium"
                  fontFamily="Light"
                  fontColor="light"
                  position="floating"
                >
                  {t("numOfShots")}
                </PMLabel>
                <IonInput
                  onIonChange={(e) => {
                    setNumOfShots(e.detail.value!);
                  }}
                  value={numOfShots}
                  type="number"
                ></IonInput>
              </IonItem>
            </IonRow>
            <IonRow className="shootingRangeInputRow">
              <IonItem
                mode="md"
                class="manualInputContainerM itemM"
                lines="full"
              >
                <PMLabel
                  fontSize="medium"
                  fontFamily="Light"
                  fontColor="light"
                  position="floating"
                >
                  {t("numOfHits")}
                </PMLabel>
                <IonInput
                  onIonChange={(e) => {
                    setNumOfHits(e.detail.value!);
                  }}
                  value={numOfHits}
                  type="number"
                ></IonInput>
              </IonItem>
            </IonRow>
            <IonRow className="shootingRangeInputRow">
              <IonItem
                mode="md"
                class="manualInputContainerM itemM"
                lines="full"
              >
                <PMLabel
                  fontSize="medium"
                  fontFamily="Light"
                  fontColor="light"
                  position="floating"
                >
                  {t("Grouping")}
                </PMLabel>
                <IonInput
                  onIonChange={(e) => {
                    setGrouping(e.detail.value!);
                  }}
                  value={grouping}
                  type="number"
                ></IonInput>
              </IonItem>
            </IonRow>
            <IonRow className="shootingRangeInputRow">
              <IonItem
                mode="md"
                class="manualInputContainerM itemM"
                lines="full"
              >
                <PMLabel
                  fontSize="medium"
                  fontFamily="Light"
                  fontColor="light"
                  position="floating"
                >
                  {t("score")}
                </PMLabel>
                <IonInput
                  onIonChange={(e) => {
                    setScore(e.detail.value!);
                  }}
                  value={score}
                  type="number"
                ></IonInput>
              </IonItem>
            </IonRow>
            <IonRow className="shootingRangeInputRow">
              <IonItem
                mode="md"
                class="manualInputContainerM itemM"
                lines="full"
              >
                <PMLabel
                  fontSize="medium"
                  fontFamily="Light"
                  fontColor="light"
                  position="floating"
                >
                  {t("hashtags")}
                </PMLabel>
                <IonInput
                  onIonChange={(e) => {
                    setHashtags(e.detail.value!);
                  }}
                  value={hashtags}
                  type="text"
                ></IonInput>
              </IonItem>
            </IonRow>
          </IonRow>
          <IonRow className="sendRow">
            <PMButton
              isDisabled={isButtonDisabled}
              onClickHandler={sendData}
              // isDisabled={!isComplete}
              color="tertiary"
              size="small"
              label={{
                fontColor: "light",
                fontSize: "medium",
                fontFamily: "Regular",
              }}
            >
              {t("send")}
            </PMButton>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ShootingRangeDataPosting;
