import React, { useEffect, useState } from "react";
import { Polar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import IHashtag from "../../../../Interfaces/IHashtag";
import IPolarAreaData from "../../../../Interfaces/IPolarAreaData";
import IToolTipChartItem from "../../../../Interfaces/IToolTipChartItem";
import i18n from "../../../../services/i18next";

interface polarArea {
  chartData: IPolarAreaData;
}

interface IRound {
  hashtag: string;
  rounds: number;
}

const PolarArea = (props: polarArea) => {
  const { t } = useTranslation();
  const [rounds, setRounds] = useState<IRound[]>([]);
  const data = {
    datasets: [
      {
        datalabels: {
          font: { size: 20 },
          textAlign: "center",
          color: "rgb(78, 79, 79)",
        },
        pointHoverBackgroundColor: "transparent",
        pointHoverBorderColor: "transparent",
        data: props.chartData.datasets
          ?.filter((hashtag) => hashtag.isActive)
          .map((hashtag) => Math.round(hashtag.grade)),
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(231, 233, 237, 0.8)",
          "rgba(54, 255, 220, 0.8)",
          "rgba(54, 162, 0, 0.8)",
          "rgba(254, 162, 235, 0.8)",
          "rgba(250, 162, 100, 0.8)",
          "rgba(54, 254, 100, 0.8)",
          "rgba(250, 100, 100, 0.8)",
          "rgba(54, 162, 100, 0.8)",
          "rgba(250, 250, 100, 0.8)",
          "rgba(250, 50, 100, 0.8)",
          "rgba(255, 255, 255, 0.8)",
          "rgba(254, 50, 235, 0.8)",
          "rgba(100, 0, 235, 0.8)",
          "rgba(54, 50, 235, 0.8)",
          "rgba(54, 255, 220, 0.8)",
          "rgba(54, 162, 0, 0.8)",
          "rgba(254, 162, 235, 0.8)",
          "rgba(250, 162, 100, 0.8)",
          "rgba(54, 254, 100, 0.8)",
          "rgba(250, 100, 100, 0.8)",
          "rgba(54, 162, 100, 0.8)",
          "rgba(255, 99, 132, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(231, 233, 237, 0.8)",
          "rgba(250, 250, 100, 0.8)",
          "rgba(250, 50, 100, 0.8)",
          "rgba(255, 255, 255, 0.8)",
          "rgba(254, 50, 235, 0.8)",
        ],
        borderWidth: 6,
        borderColor: "transparent",
        label: "ממוצע: ", // for legend
      },
    ],
    labels: props.chartData.labels
      ?.filter((hashtag) => hashtag.isActive)
      .map((hashtag) => hashtag.hashtag),
  };

  const options = {
    plugins: {
      labels: {
        render: () => "",
      },
    },
    legend: {
      position: "bottom",
      responsive: true,
      reverse: false,
      rtl: i18n.language === "he",
      display: true,
      labels: {
        boxWidth: 7,
        fontSize: 20,
        fontColor: "rgba(255, 255, 255, 0.89)",
        padding: 10,
        usePointStyle: true,
      },
      onClick: (e: any) => e.stopPropagation(),
    },
    gridLines: {
      display: true,
    },
    scale: {
      gridLines: {
        color: "rgba(255,255,255,0.5)",
      },
      angleLines: {
        color: "rgba(255,255,255,0.5)",
      },
      pointLabels: {
        fontSize: 40,
        fontColor: "rgba(255, 255, 255, 0.89)",
        padding: 100,
      },
      animation: {
        animateScale: true,
        animateRoute: true,
      },

      ticks: {
        showLabelBackdrop: false,
        fontColor: "#FFFF",
        backdropColor: "#4B4D52",
        fontSize: 15,
        beginAtZero: true,
        maxTicksLimit: 11,
        stepSize: 10,
        backdropPaddingY: 3,
        backdropPaddingX: 4,
        min: 0,
        max: 100,
      },
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: IToolTipChartItem, data: IPolarAreaData) => {
          let label: string =
            `${t("rounds")}: ` + rounds[tooltipItem.index].rounds.toString();
          return label;
        },
        title: (tooltipItem: IToolTipChartItem[], data: IPolarAreaData) => {
          let label: string = rounds[tooltipItem[0].index].hashtag.toString();
          return label;
        },
      },
      backgroundColor: "#36383E",
      titleFontColor: "rgba(255,255,255,0.89)",
      bodyFontColor: "rgba(255,255,255,0.89)",
      footerFontColor: "rgba(255,255,255,0.89)",
      footerFontSize: 12,
      footerAlign: t("startTooltipAlign"),
      titleAlign: t("startTooltipAlign"),
      titleFontSize: 18,
      displayColors: false,
      titleFontFamily: "Light",
      footerFontFamily: "Light",
      mode: "nearest",
      bodyAlign: t("startTooltipAlign"),
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  useEffect(() => {
    setRounds(
      props.chartData.datasets
        ?.filter((hashtag: IHashtag) => hashtag.isActive)
        .map((hashtag) => {
          return { hashtag: hashtag.name, rounds: hashtag.rounds };
        })
    );
  }, [props.chartData.datasets]);

  return (
    <Polar
      data={data}
      options={options}
      datasetKeyProvider={() => Math.random()}
    ></Polar>
  );
};

export default PolarArea;
