import { IonItem, IonRow } from "@ionic/react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useHistory } from "react-router";
import ITraineeDetails from "../../../../Interfaces/ITraineeDetails";
import Checkbox from "../../../Shared/Checkbox/Checkbox";
import PMLabel from "../../../themeComponents/PMLabel";
import "./MobileListItem.css";

interface IMobileListItem {
  setCheckedTrainee: (trainee: ITraineeDetails) => void;
  setUnCheckedTrainee: (trainee: ITraineeDetails) => void;
  isButton: boolean;
  isDisabled: boolean;
  trainee: ITraineeDetails;
  isEdit: boolean;
  stationId: number;
  planId?: number;
  trainingTypeId: number;
  stationName: string;
  isDesktop: boolean;
  setSelectedTraineeForDesktop?: Dispatch<
    SetStateAction<ITraineeDetails | undefined>
  >;
  selectedTrainee?: ITraineeDetails | undefined;
}
const MobileListItem: React.FC<IMobileListItem> = (props: IMobileListItem) => {
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (checked) props.setCheckedTrainee(props.trainee);
    else props.setUnCheckedTrainee(props.trainee);
  }, [checked]);
  const clickedHandler = () => {
    if (props.isButton)
      history.push(
        `/${props.stationName}/${props.planId}${props.stationId}/${props.trainingTypeId}/${props.trainee.traineeName}`
      );
  };
  const setCheckedForDesktop = () => {
    if (props.setSelectedTraineeForDesktop)
      props.setSelectedTraineeForDesktop(props.trainee);
  };

  return (
    <IonRow key={props.trainee.uniqId}>
      <IonItem
        lines="full"
        mode="md"
        className={
          "traineeItemLI " +
          (checked ? "selected " : "") +
          (props.isDesktop &&
          Number(props.selectedTrainee?.uniqId) === Number(props.trainee.uniqId)
            ? "selectedDesktop"
            : "")
        }
        button={props.isButton || props.isDesktop}
        disabled={props.isDisabled && !props.isEdit && !props.isDesktop}
        onClick={props.isDesktop ? setCheckedForDesktop : clickedHandler}
      >
        <PMLabel fontSize="medium" fontFamily="Light" fontColor="xLight">
          {props.trainee.traineeName}
        </PMLabel>
        <Checkbox
          hidden={!props.isEdit || props.isDesktop}
          isChecked={checked}
          onChange={(e) => setChecked(e.detail.checked)}
        />
      </IonItem>
    </IonRow>
  );
};
export default MobileListItem;
