import { useHistory } from "react-router";

const useNavigation = () => {
  const history = useHistory();

  const returnToPreviousPage = (): void => {
    history.goBack();
  };

  const navigate = <T,>(url: string, itemToPass?: T): void => {
    history.push(url, itemToPass);
  };

  return { returnToPreviousPage, navigate };
};

export default useNavigation;
