import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Axios from "../../Axios";
import { baseUrlPMBackend } from "../../Configurations/consts";
import {
  ITrainingPlanResult,
  IIndicatorResult,
} from "../../Interfaces/dataCalculator";
import IIndicator from "../../Interfaces/ITrendIndicator";
import IFinalGradeDisplay from "../../Interfaces/IFinalGradeDisplay";
import IForceFromOrbat from "../../Interfaces/IForceFromOrbat";
import IForceTreeNode from "../../Interfaces/IForceTreeNode";
import IPlan from "../../Interfaces/IPlan";
import IPlanGrade from "../../Interfaces/IPlanGrade";
import ITrendsIndicatorData from "../../Interfaces/ITrendsIndicatorData";
import ITrendsDataForForce from "../../Interfaces/ITrendsDataForForce";
import customToast from "../Shared/Toast/CustomToast";
interface IGradesResults {
  planId: number;
  forcesResults: ITrainingPlanResult[];
}
const getAllGradesData = async (
  activePlan: IPlan | undefined,
  plans: (IPlan | undefined)[],
  indicatorsIds: number[],
  forcesIds: number[]
) => {
  if (!forcesIds.length) return;
  return await Axios.get(
    `${baseUrlPMBackend}performanceGrades/getTrendsResults`,
    {
      params: {
        forcesIds: forcesIds,
        indicatorsIds: indicatorsIds,
        planId: activePlan?.id,

        plansIds: plans.map((plan) => plan?.id),
      },
    }
  );
};
const isEqual = (
  arr1: IForceFromOrbat[] | undefined,
  arr2: IForceFromOrbat[] | undefined,
  trendsBarChartsData: ITrendsIndicatorData[]
) => {
  return arr2 && arr2.length === 1
    ? arr1 &&
      trendsBarChartsData.length &&
      trendsBarChartsData.find((data) =>
        data.data.find((force) => force.id == arr1[0].id)
      )
      ? true
      : false
    : JSON.stringify(arr1?.map((force) => Number(force.id)).sort()) ==
        JSON.stringify(arr2?.map((force) => Number(force.id)).sort()) &&
        trendsBarChartsData.length;
};

const useGetPerformanceTrends = (
  activePlan: IPlan | undefined,
  plans: (IPlan | undefined)[],
  newForces: IForceFromOrbat[],
  forcesToRemove: IForceFromOrbat[],
  checkedForces: IForceFromOrbat[],
  indicators: IIndicator[],
  setLoading: (state: boolean) => void,
  personalZone?: boolean,
  isMobile?: boolean,
  setActivePlanFinalGrade?: Dispatch<SetStateAction<IFinalGradeDisplay[]>>
) => {
  const { t } = useTranslation();

  const [trendsBarChartsDataMapObj, setTrendsBarChartsDataMapObj] =
    useState<Map<number, Map<number, IPlanGrade[]>>>();
  const [trendsBarChartsData, setTrendsBarChartsData] = useState<
    ITrendsIndicatorData[]
  >([] as ITrendsIndicatorData[]);
  const [isToRefresh, setIsToRefresh] = useState<boolean>(false);

  const setData = (
    isToConcat: boolean,
    res: { data: IGradesResults[] },
    indicatorsIds: number[],
    newForces: IForceFromOrbat[],
    setActivePlanFinalGrade?: Dispatch<SetStateAction<IFinalGradeDisplay[]>>
  ) => {
    let indicatorsToData: Map<number, Map<number, IPlanGrade[]>> = new Map();
    if (trendsBarChartsDataMapObj && isToConcat)
      setTrendsBarChartsDataMapObj(
        orderResponseAsNeeded(
          trendsBarChartsDataMapObj,
          res.data,
          indicatorsIds
        )
      );
    else
      setTrendsBarChartsDataMapObj(
        orderResponseAsNeeded(indicatorsToData, res.data, indicatorsIds)
      );
    let activePlanResults = res.data.find(
      (plan) => Number(plan.planId) === Number(activePlan?.id)
    );
    if (activePlanResults)
      setActivePlanFinalGrade &&
        setActivePlanFinalGrade((prev) => {
          let finalGrades: IFinalGradeDisplay[] = [];
          activePlanResults!.forcesResults.map((forceData) => {
            let myForce = [...checkedForces, ...newForces].find(
              (force) => Number(force.id) === Number(forceData.forceId)
            );
            if (
              !isToConcat ||
              !prev.find(
                (data) =>
                  Number(data.soldierFinalGrade.id) === Number(myForce?.id)
              )
            )
              finalGrades.push({
                soldierFinalGrade: {
                  id: Number(forceData.forceId),
                  grade: Number(forceData.grade),
                  name: myForce!.name,
                  legendColor: myForce!.color!,
                  isAttended: forceData.grade !== null,
                },
                threshold: forceData.threshold,
              });
          });
          return isToConcat ? [...prev, ...finalGrades] : finalGrades;
        });
  };
  const orderResponseAsNeeded = (
    indicatorsToData: Map<number, Map<number, IPlanGrade[]>>,
    res: IGradesResults[],
    indicatorsIds: number[]
  ) => {
    if (!indicatorsToData.size) {
      indicatorsToData.set(-1, new Map());
      indicatorsIds.map((indicatorId) =>
        indicatorsToData.set(indicatorId, new Map())
      );
    }

    res.map((planData: IGradesResults) => {
      planData.forcesResults.map((forceData) => {
        //insert the lethality grade
        indicators.map((indicator) => {
          let forceGradesData = indicatorsToData
            .get(indicator.id)!
            .get(forceData.forceId);
          if (forceGradesData) {
            indicatorsToData.set(
              indicator.id,
              indicatorsToData.get(indicator.id)!.set(forceData.forceId, [
                ...forceGradesData,
                {
                  grade: indicator.id === -1 ? Number(forceData.grade) : 0,
                  id: planData.planId,
                  isAttended:
                    indicator.id === -1 && forceData.grade !== null
                      ? true
                      : false,
                  finalGrade: forceData.grade,
                },
              ])
            );
          } else {
            indicatorsToData.set(
              indicator.id,
              indicatorsToData.get(indicator.id)!.set(forceData.forceId, [
                {
                  grade: indicator.id === -1 ? Number(forceData.grade) : 0,
                  id: planData.planId,
                  isAttended: forceData.grade !== null,
                  finalGrade: forceData.grade,
                },
              ])
            );
          }
        });

        //insert all the indicators
        forceData.indicatorResults.map((indicator: IIndicatorResult) => {
          if (indicator.grade) {
            let forceGradesData = indicatorsToData
              .get(Number(indicator.id))!
              .get(forceData.forceId);
            forceGradesData = forceGradesData?.filter(
              (plan) => plan.id != planData.planId
            );
            if (forceGradesData) {
              indicatorsToData.set(
                Number(indicator.id),
                indicatorsToData
                  .get(Number(indicator.id))!
                  .set(forceData.forceId, [
                    ...forceGradesData,
                    {
                      grade: Number(indicator.grade),
                      id: planData.planId,
                      isAttended: indicator.grade ? true : false,
                      finalGrade: forceData.grade,
                    },
                  ])
              );
            }
          }
        });
      });
    });
    setTrendsBarChartsData(
      getTrendDataAsArray(
        indicatorsToData,
        res.map((plan) => plan.planId)
      )
    );
    return indicatorsToData;
  };
  const getTrendDataAsArray = (
    indicatorToData: Map<number, Map<number, IPlanGrade[]>>,
    plansIdsArray: number[]
  ) => {
    let orbatForces = Array.from(new Set([...checkedForces, ...newForces]));
    let trendsForcesData: ITrendsIndicatorData[] = [];
    indicatorToData.forEach((indicator, indicatorId) => {
      if (indicator.size < orbatForces.length) {
        orbatForces.map((force) => {
          if (
            indicatorToData.get(Number(indicatorId))!.get(force.id) ===
            undefined
          ) {
            let plansArray: IPlanGrade[] = plansIdsArray.map((planId) => {
              return {
                finalGrade: 0,
                grade: 0,
                id: planId,
                isAttended: false,
                name: "",
              };
            });
            indicatorToData.set(
              Number(indicatorId),
              indicatorToData
                .get(Number(indicatorId))!
                .set(force.id, plansArray)
            );
          }
        });
      }

      let forcesData: ITrendsDataForForce[] = [];
      indicator.forEach((plansGrades, forceId) => {
        let myForce = [...checkedForces, ...newForces].find(
          (force) => Number(force.id) === Number(forceId)
        );
        forcesData.push({
          gradesArray: plansGrades.sort((a, b) => a.id)!,
          id: Number(forceId),
          name: myForce ? myForce.name : "",
        });
      });
      trendsForcesData.push({
        indicatorId: Number(indicatorId),
        data: forcesData,
      });
    });
    return trendsForcesData;
  };
  useEffect(() => {
    if (personalZone) return;
    if (
      !newForces?.length ||
      (isEqual(newForces, checkedForces, trendsBarChartsData) && !isMobile)
    )
      return;

    let indicatorsIds = indicators.map((indicator) => indicator.id);
    setLoading(true);
    getAllGradesData(
      activePlan,
      plans,
      indicatorsIds,
      newForces.map((force) => force.id)
    )
      .then((res: any) => {
        setLoading(false);

        if (!res) return;
        setData(true, res, indicatorsIds, newForces, setActivePlanFinalGrade);
      })
      .catch((e) => {
        setLoading(false);
        customToast.error(t("errorWhileLoadingData"));
      });
  }, [newForces]);
  useEffect(() => {
    let mounted: boolean = true;
    if (!personalZone) return;
    if (!newForces?.length || !indicators.length) return;
    let indicatorsIds = indicators.map((indicator) => indicator.id);
    mounted && setLoading(true);
    getAllGradesData(
      activePlan,
      plans,
      indicatorsIds,
      newForces.map((force) => force.id)
    )
      .then((res: any) => {
        mounted && setLoading(false);

        if (!res) return;
        mounted &&
          setData(true, res, indicatorsIds, newForces, setActivePlanFinalGrade);
      })
      .catch((e) => {});

    return () => {
      mounted = false;
    };
  }, [newForces, indicators]);

  useEffect(() => {
    if (!forcesToRemove.length) return;
    setTrendsBarChartsData((prev) => {
      return prev.map((indicator: ITrendsIndicatorData) => {
        return {
          ...indicator,
          data: indicator.data.filter(
            (forceIndicator) =>
              forcesToRemove.find(
                (force) => Number(force.id) === Number(forceIndicator.id)
              ) === undefined
          ),
        };
      });
    });
    setTrendsBarChartsDataMapObj((prev) => {
      prev?.forEach((indicator) => {
        forcesToRemove.map((force) => {
          indicator.delete(Number(force.id));
        });
      });
      return prev;
    });
  }, [forcesToRemove]);

  useEffect(() => {
    if (!isToRefresh) return;

    setIsToRefresh(false);
    if (!checkedForces?.length || !activePlan || !indicators.length) return;
    let indicatorsIds = indicators.map((indicator) => indicator.id);
    setLoading(true);
    getAllGradesData(
      activePlan,
      plans,
      indicatorsIds,
      checkedForces.map((force) => force.id)
    )
      .then((res: any) => {
        setLoading(false);

        if (!res) return;
        setData(
          false,
          res,
          indicatorsIds,
          checkedForces,
          setActivePlanFinalGrade
        );
      })
      .catch((e) => {});
  }, [isToRefresh]);
  return {
    trendsBarChartsData,
    setIsToRefresh,
  };
};
export default useGetPerformanceTrends;
