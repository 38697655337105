import React, { useContext, useEffect, useMemo, useState } from "react";
import { IonContent } from "@ionic/react";
import "./exportModal.css";
import IForceTreeNode from "../../../Interfaces/IForceTreeNode";
import { useTranslation } from "react-i18next";
import OrbatTree from "../../Shared/Orbat/OrbatTree/OrbatTree";
import RadarGraph from "../../Shared/Charts/Radar/RadarGraph";
import ILabel from "../../../Interfaces/ILabel";
import IPlan from "../../../Interfaces/IPlan";
import IThreshold from "../../../Interfaces/IThreshold";
import domtoimage from "dom-to-image-improved";
import Axios from "../../../Axios";
import { baseUrlPMBackend } from "../../../Configurations/consts";
import { exportToPdfAndCsv } from "./exportToPdfAndCsv";
import PMLoading from "../../Shared/Alert/PMLoading";
import BarChart from "../../Shared/Charts/Bar/BarChart";
import { toast } from "react-toastify";
import { IForceAllData } from "../../../Interfaces/IForceAllData";
import { IExportDataForForce } from "../../../Interfaces/IExportDataForForce";
import FinalGradeDisplay from "../../Shared/FinalGradeDisplay/FinalGradeDisplay";
import { getForceFullName } from "../../../services/helpers";
import ExportModalCollapse from "./ExportModalCollapse";
import {
  EChartId,
  IChartsSelection,
  IIndicatorsSelection,
  ISelection,
  ITooltipsOptions,
} from "../../../Interfaces/IExportReport";
import ISelectionOption from "../../../Interfaces/ISelectionOption";
import useForcesChecked from "../../CustomHooks/useForcesChecked";
import EIconsSrc from "../../../Interfaces/EIconsSrc";
import PMButton from "../../themeComponents/PMButton";
import { IForce } from "../../../Interfaces/results/force.interface";
import { UserContext } from "../../../context/UserContext/userContext";
import { userRoles } from "../../../services/routeRoles";
import customToast from "../../Shared/Toast/CustomToast";
import {
  IIndicatorResult,
  ITrainingPlanResult,
  ITrainingTypeResult,
} from "../../../Interfaces/dataCalculator";
import { useOriginalTooltipsTitles } from "../../CustomHooks/HooksWithRQ/useOriginalTooltipsTitles";
import usePlans from "../../CustomHooks/usePlans";
interface IProps {
  indicatorsLabels: ILabel[];
  indicatorsThreshold: IThreshold[];
  trainingTypesLabels: ILabel[];
  trainingTypesThreshold: IThreshold[];
  setPlanId: React.Dispatch<React.SetStateAction<number | undefined>>;
}
const ModalExcel: React.FC<IProps> = (props: IProps): JSX.Element => {
  const {
    indicatorsLabels,
    indicatorsThreshold,
    trainingTypesLabels,
    trainingTypesThreshold,
    setPlanId,
  } = props;
  const { t } = useTranslation();

  const [orbatForces, setOrbatForces] = useState<IForceTreeNode[]>(
    [] as IForceTreeNode[]
  );
  const { checkedForces, setCheckedForces, relevantPlansIds } =
    useForcesChecked(orbatForces);

  const { plansOptions, selectedPlan, onPlanSelectedHandler } =
    usePlans(relevantPlansIds);
  const [forceFlatTreeToRemove, setFlatTreeToRemove] = useState<{
    forceToRemove: IForceTreeNode | undefined;
    isSubordinates: boolean | undefined;
  }>({ forceToRemove: undefined, isSubordinates: undefined });
  const { user } = useContext(UserContext);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [indicatorsSelection, setIndicatorsSelection] = useState<
    IIndicatorsSelection[]
  >([] as IIndicatorsSelection[]);
  const [tooltipsTitlesSelection, setTooltipsTitles] = useState<
    ITooltipsOptions[]
  >([] as ITooltipsOptions[]);

  const originalTooltipsTitlesSelectionQuery = useOriginalTooltipsTitles();
  const originalTooltipsTitlesSelection = useMemo(
    () => originalTooltipsTitlesSelectionQuery?.data ?? [],
    [originalTooltipsTitlesSelectionQuery?.data]
  );

  const [hashtagsSelections, setHashtags] = useState<ITooltipsOptions[]>(
    [] as ITooltipsOptions[]
  );
  const [originalHashtags, setOriginalHashtags] = useState<ITooltipsOptions[]>(
    [] as ITooltipsOptions[]
  );
  const [chartsType, setChartsData] = useState<IChartsSelection[]>([
    {
      isSelected: false,
      name: t("details"),
      chartId: EChartId.PERFORMANCE_DETAILS,
    },
    { isSelected: false, name: t("qualifications"), chartId: EChartId.RADAR },
    {
      isSelected: false,
      name: t("combatCapacity"),
      chartId: EChartId.LETHALITY,
    },
  ]);

  const color = "#2334B5";
  const [graphsData, setGraphsData] = useState<IForceAllData[]>(
    [] as IForceAllData[]
  );

  const [flatTree, setFlatTree] = useState<IForceTreeNode[]>([]);
  const fetchExportData = async (): Promise<{ data: IForce } | undefined> => {
    if (!(user.role === userRoles.Admin) && !user.forceToDisplayInOrbat) {
      customToast.error(t("user not authorized or not attached to force"));
      return undefined;
    }
    return await Axios.get(
      `${baseUrlPMBackend}performanceGrades/getReportGenerator`,
      {
        params: {
          planId: selectedPlan?.id,
          forceId: user.forceToDisplayInOrbat?.id,
        },
      }
    );
  };
  const [dataResult, setDataResult] = useState<IForce>();

  const getNewSelectedForcesM = (
    checkedForces: IForceTreeNode[] | undefined,
    selectedForces: IForceTreeNode[] | undefined
  ) => {
    let newSelectedForces: IForceTreeNode[] | undefined = checkedForces?.filter(
      (force: IForceTreeNode) => {
        if (
          !selectedForces?.find(
            (selectedForce: IForceTreeNode) =>
              Number(selectedForce.id) === Number(force.id)
          )
        ) {
          return force;
        }
      }
    );

    return newSelectedForces;
  };
  const getForceToRemoveM = (
    selectedForces: IForceTreeNode[] | undefined,
    checkedForces: IForceTreeNode[] | undefined
  ) => {
    let newForceToRemove: IForceTreeNode | undefined = selectedForces?.find(
      (selectedForce: IForceTreeNode) => {
        if (
          !checkedForces?.find(
            (force: IForceTreeNode) =>
              Number(selectedForce.id) === Number(force.id)
          )
        ) {
          return selectedForce;
        }
      }
    );
    return newForceToRemove;
  };

  const onChoose = (
    chosenForces: IForceTreeNode[],
    isSubordinates?: boolean
  ) => {
    if (chosenForces.length === 0) {
      setGraphsData([]);
      setFlatTree([]);
      setOrbatForces([]);
      setLoading(false);
      setDownloadLoading(false);
      return;
    }
    if (chosenForces && chosenForces.length) {
      setFlatTreeToRemove({
        forceToRemove: getForceToRemoveM(flatTree, chosenForces),
        isSubordinates: isSubordinates,
      });
      let newForces: IForceTreeNode[] | undefined = getNewSelectedForcesM(
        chosenForces,
        flatTree
      );
      if (isSubordinates) {
        newForces?.map((chosenForce: IForceTreeNode) => {
          treeFlatting(chosenForce);
        });
      } else {
        if (newForces && newForces[0] !== undefined) {
          setFlatTree((prev: IForceTreeNode[]) => [
            ...prev,
            {
              ...newForces![0],
              name: getForceFullName(
                newForces![0].name,
                newForces![0].force_type,
                t
              ),
            },
          ]);
        }
      }
    }
  };

  const exportToCsv = async (
    imagesRadar: string[],
    imagesBar: string[],
    imagesBadge: string[]
  ) => {
    let forcesData: IForceAllData[] = graphsData!.map(
      (force: IForceAllData, index) => {
        if (index !== undefined && index >= 0) {
          return {
            ...force,
            forceGradesData: {
              ...force.forceGradesData,
              forceResults: filterForceResults(
                force.forceGradesData.forceResults
              ),
              pic1: imagesRadar[index],
              pic2: imagesBar[index],
              pic3: imagesBadge[index],
            },
          };
        }
        return force;
      }
    );

    let isFinished: boolean = await exportToPdfAndCsv(
      forcesData,
      selectedPlan,
      t,
      true
    );
    isFinished
      ? toast.success(t("DownloadFileSuccessfully"))
      : toast.error(t("DownloadFileUnsuccessfully"));
    setDownloadLoading(false);
  };
  const filterForceResults = (
    forceResult: ITrainingPlanResult
  ): ITrainingPlanResult => {
    console.log(
      hashtagsSelections,
      tooltipsTitlesSelection,
      indicatorsSelection
    );

    return {
      ...forceResult,
      indicatorResults: forceResult.indicatorResults
        .map((indicator) => {
          return {
            ...indicator,
            trainingTypeResults: indicator.trainingTypeResults
              .map((trainingType) => {
                return {
                  ...trainingType,
                  hashtagsResults: trainingType.hashtagsResults.filter(
                    (hashtag) =>
                      hashtagsSelections
                        .find(
                          (h) =>
                            Number(h.trainingTypeId) == Number(trainingType.id)
                        )
                        ?.selectionsOptions.find(
                          (h) => h.option.label === hashtag.name
                        )?.isSelected
                  ),
                  tooltipResults: {
                    ...trainingType.tooltipResults,
                    tooltipElementsResults:
                      trainingType.tooltipResults.tooltipElementsResults.filter(
                        (tooltip) =>
                          tooltipsTitlesSelection
                            .find(
                              (t) =>
                                Number(t.trainingTypeId) ===
                                Number(trainingType.id)
                            )
                            ?.selectionsOptions.find(
                              (t) => tooltip.name === t.option.label
                            )?.isSelected
                      ),
                  },
                };
              })
              .filter(
                (t) =>
                  indicatorsSelection
                    .find((i) => Number(i.id) === Number(indicator.id))
                    ?.trainingTypeSelections.find(
                      (j) => Number(j.id) === Number(t.id)
                    )?.isSelected ||
                  t.hashtagsResults.length ||
                  t.tooltipResults.tooltipElementsResults.length
              ),
          };
        })
        .filter(
          (t) =>
            indicatorsSelection.find((i) => Number(i.id) === Number(t.id))
              ?.isSelected || t.trainingTypeResults.length
        ),
    };
  };

  const getImageAndSendToCsv = async () => {
    if (orbatForces?.length === 0) return;
    setDownloadLoading(true);
    let imagesCompRadar: HTMLCollectionOf<Element>;
    let imagesArrayRadar = [];
    let imagesCompBar: HTMLCollectionOf<Element>;
    let imagesArrayBar = [];
    let imagesCompBadge: HTMLCollectionOf<Element>;
    let imagesArrayBadge = [];
    if (
      chartsType.find((chart) => chart.chartId === EChartId.RADAR)?.isSelected
    ) {
      imagesCompRadar = document.getElementsByClassName("csvRadarImage");
      imagesArrayRadar = Array.prototype.slice.call(imagesCompRadar);
    }
    if (
      chartsType.find((chart) => chart.chartId === EChartId.PERFORMANCE_DETAILS)
        ?.isSelected
    ) {
      imagesCompBar = document.getElementsByClassName("csvBarImage");
      imagesArrayBar = Array.prototype.slice.call(imagesCompBar);
    }
    if (
      chartsType.find((chart) => chart.chartId === EChartId.LETHALITY)
        ?.isSelected
    ) {
      imagesCompBadge = document.getElementsByClassName("csvBadgeImage");
      imagesArrayBadge = Array.prototype.slice.call(imagesCompBadge);
    }
    let allImagesRadar: string[] = [];
    let allImagesBar: string[] = [];
    let allImagesBadge: string[] = [];

    await Promise.all([
      await Promise.all(
        imagesArrayRadar?.map(async (element: Element) => {
          allImagesRadar.push(await domtoimage.toJpeg(element));
        })
      ),
      await Promise.all(
        imagesArrayBar?.map(async (element: Element) => {
          allImagesBar.push(await domtoimage.toJpeg(element));
        })
      ),
      await Promise.all(
        imagesArrayBadge?.map(async (element: Element) => {
          allImagesBadge.push(await domtoimage.toJpeg(element));
        })
      ),
    ]);
    exportToCsv(allImagesRadar, allImagesBar, allImagesBadge);
  };

  // Converts the hierarchical tree to a flat array
  const treeFlatting = (tree: IForceTreeNode): void => {
    setFlatTree((prev: IForceTreeNode[]) => [
      ...prev,
      { ...tree, name: getForceFullName(tree.name, tree.force_type, t) },
    ]);

    tree.nodes &&
      tree.nodes.forEach((node: IForceTreeNode) => {
        treeFlatting(node);
      });
  };

  const getSubordinates = (
    tree: IForceTreeNode | undefined,
    treeArray: IForceTreeNode[]
  ) => {
    if (tree && tree.nodes !== null) {
      treeArray = treeArray.concat(tree.nodes!);
      tree.nodes.forEach((node: IForceTreeNode) => {
        treeArray = treeArray.concat(getSubordinates(node, treeArray));
      });
      return treeArray;
    }
    return [];
  };

  useEffect(() => {
    let newArray = flatTree.reduce(
      (accumalator: IForceTreeNode[], current: IForceTreeNode) => {
        if (!accumalator.some((item) => item.id === current.id)) {
          accumalator.push(current);
        }
        return accumalator;
      },
      []
    );

    setOrbatForces((prev: IForceTreeNode[] | undefined) => {
      setFlatTreeToRemove({
        forceToRemove: undefined,
        isSubordinates: undefined,
      });
      return newArray;
    });
  }, [flatTree]);
  useEffect(() => {
    if (forceFlatTreeToRemove.forceToRemove === undefined) return;
    let forceWithSubs: IForceTreeNode[] | undefined = [];
    if (forceFlatTreeToRemove.isSubordinates) {
      forceWithSubs = getSubordinates(forceFlatTreeToRemove.forceToRemove, []);
      if (forceWithSubs)
        forceWithSubs = [...forceWithSubs, forceFlatTreeToRemove.forceToRemove];
    } else {
      if (forceFlatTreeToRemove.forceToRemove)
        forceWithSubs = [forceFlatTreeToRemove.forceToRemove];
      else forceWithSubs = undefined;
    }
    if (forceWithSubs) {
      let removeUnchecked: IForceTreeNode[] = flatTree.filter(
        (forceElement: IForceTreeNode) =>
          !forceWithSubs?.find((f) => f.id === forceElement.id)
      );
      setFlatTree(removeUnchecked);
    }
  }, [forceFlatTreeToRemove]);
  useEffect(() => {
    if (!dataResult && orbatForces?.length) {
      setLoading(true);
      return;
    } else setLoading(false);
    setGraphsData(() => {
      let forcesData: IForce[] = [];
      if (dataResult) findAllForcesResults(forcesData, dataResult);
      let forcesGradesData: IForceAllData[] = forcesData.map((force) => {
        let forceDetails = orbatForces?.find(
          (forceFromOrbat) => Number(force.id) === Number(forceFromOrbat.id)
        );
        return {
          forceDetails: forceDetails!,
          forceGradesData: {
            forceResults: force.results,
            affiliation: force.hierarchicalName,
            isAttended: force.results.grade ? true : false,
            isSoldier: force.isSoldier,
          },
        };
      });
      return forcesGradesData;
    });

    setCheckedForces(() =>
      orbatForces?.map((force: IForceTreeNode) => ({
        id: force.id,
        name: force.name,
        isSoldier: force.nodes === null,
        forceType: force.force_type,
      }))
    );
  }, [orbatForces, dataResult]);
  const findAllForcesResults = (forcesData: IForce[], dataResult: IForce) => {
    if (
      orbatForces.find(
        (force: IForceTreeNode) => force.id == Number(dataResult.id)
      )
    ) {
      forcesData.push(dataResult);
    }
    if (dataResult.children?.length)
      dataResult.children.map((child) => {
        findAllForcesResults(forcesData, child);
      });
  };

  useEffect(() => {
    let mounted: boolean = true;
    const getHashtags = async (selectedPlan: IPlan) => {
      if (!selectedPlan.startDate || !selectedPlan.endDate) return;
      Axios.get(`${baseUrlPMBackend}exportReport/getHashtags`, {
        params: { selectedPlan: selectedPlan },
      }).then((res: any) => {
        let selectionsOptions: ITooltipsOptions[] = [];
        Object.keys(res.data).map((trainingType) => {
          let selectionOptions: ISelectionOption[] = [];
          res.data[trainingType].map((hashtag: string) => {
            if (hashtag !== "")
              selectionOptions.push({
                isSelected: false,
                option: {
                  id: `${trainingType}_${hashtag}`,
                  label: hashtag,
                },
              });
          });
          selectionsOptions.push({
            selectionsOptions: selectionOptions,
            trainingTypeName: trainingType,
            isSelected: false,
            trainingTypeId: Number(trainingType),
          });
        });
        mounted && setOriginalHashtags(selectionsOptions);
      });
    };

    const refetchData = async () => {
      if (selectedPlan) {
        setPlanId(selectedPlan.id);
        if (orbatForces?.length) setLoading(true);
        let allGradesResults = await fetchExportData();
        setLoading(false);
        mounted && allGradesResults && setDataResult(allGradesResults.data);
      }
    };
    refetchData();
    return () => {
      mounted = false;
    };
  }, [selectedPlan]);

  useEffect(() => {
    if (
      trainingTypesLabels &&
      trainingTypesLabels.length &&
      originalTooltipsTitlesSelection &&
      originalTooltipsTitlesSelection.length
    ) {
      setTooltipsTitles((prev) =>
        originalTooltipsTitlesSelection.map((trainingTypeTooltips) => {
          let tt = trainingTypesLabels.find(
            (trainingType) =>
              trainingType.label === trainingTypeTooltips.trainingTypeName
          );
          return {
            ...trainingTypeTooltips,
            trainingTypeId: tt ? Number(tt.id) : 0,
          };
        })
      );
    }
  }, [trainingTypesLabels, originalTooltipsTitlesSelection]);
  useEffect(() => {
    if (
      trainingTypesLabels &&
      trainingTypesLabels.length &&
      originalHashtags &&
      originalHashtags.length
    ) {
      setHashtags((prev) =>
        originalHashtags.map((trainingTypeTooltips) => {
          let tt = trainingTypesLabels.find(
            (trainingType) =>
              Number(trainingType.id) ===
              Number(trainingTypeTooltips.trainingTypeId)
          );
          return {
            ...trainingTypeTooltips,
            trainingTypeName: tt
              ? tt.label
              : trainingTypeTooltips.trainingTypeName,
          };
        })
      );
    }
  }, [trainingTypesLabels, originalHashtags]);
  useEffect(() => {
    let indicatorsSelections: IIndicatorsSelection[] = [];
    indicatorsLabels?.map((labelRadar) => {
      let trainingTypeSelections: ISelection[] = [];
      trainingTypesLabels
        ?.filter(
          (labelBar) => Number(labelBar.indicatorId) === Number(labelRadar.id)
        )
        ?.map((bar) => {
          trainingTypeSelections.push({
            id: Number(bar.id),
            isSelected: false,
            label: bar.label,
          });
        });
      indicatorsSelections.push({
        id: Number(labelRadar.id),
        isSelected: false,
        label: labelRadar.label,
        trainingTypeSelections: trainingTypeSelections,
      });
    });
    setIndicatorsSelection(indicatorsSelections);
  }, [indicatorsLabels, trainingTypesLabels]);
  return (
    <IonContent scrollY={false}>
      <PMLoading
        isOpen={isLoading}
        message={t("loadingData")}
        spinner={"bubbles"}
      />
      <PMLoading
        isOpen={downloadLoading}
        message={t("downloadFile")}
        spinner={"bubbles"}
      />

      <div className="modalGrid">
        <div className=" rowAllM">
          <div className="treeCol">
            <OrbatTree
              readonly
              checked={onChoose}
              isMobile={true}
              checkedForces={orbatForces}
              isReport={true}
            />
          </div>
          <div className="modalComp ">
            <div>
              <ExportModalCollapse
                chartsType={chartsType}
                hashtags={hashtagsSelections}
                indicatorsSelection={indicatorsSelection}
                isSelectionOpened={true}
                tooltipsTitles={tooltipsTitlesSelection}
                setChartsData={setChartsData}
                setHashtags={setHashtags}
                setIndicatorsSelection={setIndicatorsSelection}
                setTooltipsTitles={setTooltipsTitles}
                plansOptions={plansOptions}
                onPlanSelectedHandler={onPlanSelectedHandler}
                selectedPlan={selectedPlan}
              ></ExportModalCollapse>
            </div>
            <div className="exportButtonEM">
              <PMButton
                size="medium"
                color="tertiary"
                isDisabled={!checkedForces.length}
                icon={{
                  iconSrc: EIconsSrc.EXPORT_ICON,
                  color: "xLight",
                  size: "large",
                }}
                onClickHandler={getImageAndSendToCsv}
                label={{
                  fontSize: "large",
                  fontColor: "xLight",
                  fontFamily: "Regular",
                }}
              >
                {t("export")}
              </PMButton>
            </div>
            <div className="modalCol">
              <div className="graphsRowEM">
                {graphsData?.map((data: IForceAllData) => (
                  <div className="modalCol" key={data.forceDetails.id}>
                    <div className="finalGradeModal">
                      <FinalGradeDisplay
                        key={data.forceDetails.id}
                        soldierFinalGrade={{
                          id: data.forceDetails.id,
                          name: `${data.forceDetails.name}`,
                          grade: Number(
                            data.forceGradesData.forceResults.grade
                          ),
                          legendColor: color,
                          isAttended: data.forceGradesData.isAttended === true,
                        }}
                        threshold={
                          selectedPlan ? Number(selectedPlan.lethality) : 0
                        }
                        className="csvBadgeImage"
                      />
                    </div>
                    <div className="graphRow rowModal">
                      <div className="graphWrap radarModal">
                        <RadarGraph
                          key={data.forceDetails.id}
                          className="csvRadarImage imageModal"
                          isModal={true}
                          color={color}
                          radarData={[
                            {
                              forceColor: "#ffffff",
                              id: Number(data.forceDetails.id),
                              indicators:
                                data.forceGradesData.forceResults
                                  .indicatorResults,
                              name: data.forceDetails.name,
                            },
                          ]}
                          selectedForces={checkedForces}
                          thresholds={indicatorsThreshold}
                          labels={indicatorsLabels}
                          newForces={[
                            checkedForces?.find(
                              (force) =>
                                Number(force.id) ===
                                Number(data.forceDetails.id)
                            ),
                          ]}
                          forcesToRemove={[]}
                          plan={selectedPlan}
                        />
                      </div>
                      <div className="graphWrap barModal">
                        <BarChart
                          key={data.forceDetails.id}
                          className="csvBarImage imageModal"
                          isModal={true}
                          color={color}
                          barChartData={[
                            {
                              finalGrade:
                                data.forceGradesData.forceResults.grade,
                              gradesArray:
                                data.forceGradesData.forceResults.indicatorResults
                                  .map(
                                    (indicator: IIndicatorResult) =>
                                      indicator.trainingTypeResults
                                  )
                                  .flat(1)
                                  .map((trainingType: ITrainingTypeResult) => {
                                    return {
                                      grade: trainingType.grade,
                                      id: trainingType.id,
                                      isAttended: trainingType.participants > 0,
                                      tooltipResults:
                                        trainingType.tooltipResults,
                                      standardDeviation:
                                        trainingType.tooltipResults
                                          .standardDeviation,
                                      name: trainingType.name,
                                    };
                                  }),

                              id: Number(data.forceDetails.id),
                              isAttended: true,
                              name: data.forceDetails.name,
                            },
                          ]}
                          thresholds={trainingTypesThreshold}
                          labels={trainingTypesLabels}
                          selectedForces={checkedForces}
                          newForces={[
                            checkedForces?.find(
                              (force) =>
                                Number(force.id) ===
                                Number(data.forceDetails.id)
                            ),
                          ]}
                          forcesToRemove={[]}
                          barsType={"trainingTypes"}
                          plan={selectedPlan}
                          isDisableZoom
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </IonContent>
  );
};
export default ModalExcel;
