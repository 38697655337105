import React, { FC, useContext, useEffect, useState } from "react";
import {
  IonCol,
  IonButton,
  IonContent,
  IonGrid,
  IonLabel,
  IonPage,
  IonRow,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import MobileHeader from "../../../components/Mobile/MobileHeader/MobileHeader";
import Dropdown from "../../../components/Shared/Dropdown/Dropdown";
import "./Competency.css";
import FinalGrade from "../../../components/Mobile/PersonalZone/Competency/FinalGrade/FinalGrade";
import IndicatorsRadar from "../../../components/Mobile/PersonalZone/Competency/IndicatorsRadar/IndicatorsRadar";
import PersonalPerformanceDetails from "../../../components/Mobile/PersonalZone/Competency/PersonalPerformanceDetails/PersonalPerformanceDetails";
import PersonalTrends from "../../../components/Mobile/PersonalZone/Competency/PersonalTrends/PersonalTrends";
import Rankings from "../../../components/Mobile/PersonalZone/Competency/Rankings/Rankings";
import useGetPerformanceGrades, {
  FilteredIndicatorsGrades,
} from "../../../components/CustomHooks/useGetPerformanceGrades";
import IForceTreeNode from "../../../Interfaces/IForceTreeNode";
import useInitGraphsData from "../../../components/CustomHooks/useInitGraphsData";
import IThreshold from "../../../Interfaces/IThreshold";
import ILabel from "../../../Interfaces/ILabel";
import useGetPerformanceTrends from "../../../components/CustomHooks/UseGetPerformaneTrends";
import IForceFromOrbat from "../../../Interfaces/IForceFromOrbat";
import useForcesChecked from "../../../components/CustomHooks/useForcesChecked";
import ForcesContext from "../../../context/ForcesContext/forcesContext";
import { UserContext } from "../../../context/UserContext/userContext";
import OrbatTreeModal from "../../../components/Mobile/OrbatTreeModal/OrbatTreeModal";
import PMButton from "../../../components/themeComponents/PMButton";
import Spinner from "../../../components/Shared/Spinner/Spinner";
import NotExistLabel from "../../../components/Shared/NotExistLabel/NotExistLabel";
import TooltipData from "../../../components/Mobile/PersonalZone/Competency/TooltipData/TooltipData";
import usePlans from "../../../components/CustomHooks/usePlans";
import PMLabel from "../../../components/themeComponents/PMLabel";
import { ECompetencyMode } from "../../../Enums/ECompetencyPageMode";
interface ICompProps {
  mode: ECompetencyMode;
}

const Competency: FC<ICompProps> = (props: ICompProps) => {
  const { mode } = props;
  const { t } = useTranslation();

  const {
    forces: orbatForces,
    isForcesTreeOpen,
    setIsForcesTreeOpen,
  } = useContext(ForcesContext);
  const { user } = useContext(UserContext);
  const [isGradesLoading, setIsGradesLoading] = useState<boolean>(true);
  const [isTrendsLoading, setIsTrendsLoading] = useState<boolean>(true);
  const [force, setForce] = useState<IForceFromOrbat[]>([]);
  const [isTooltipOpened, setIsTooltipOpened] = useState<boolean>(false);

  const [detailsThresholds, setDetailsThresholds] = useState<IThreshold[]>(
    [] as IThreshold[]
  );
  const [detailsLabels, setDetailsLabels] = useState<ILabel[]>([] as ILabel[]);
  const [translatedDetailsLabels, setTranslatedDetailsLabels] = useState<
    ILabel[]
  >([] as ILabel[]);

  const [localOrbatForces, setLocalOrbatForces] = useState<
    IForceTreeNode[] | []
  >([]);

  useEffect(() => {
    let mounted: boolean = true;
    const getForce = async () => {
      if (user.relatedForce) {
        mounted && setLocalOrbatForces([user.relatedForce]);
      }
    };
    if (mode === ECompetencyMode.personal) {
      if (!localOrbatForces.length) getForce();
    }
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted: boolean = true;
    if (mode === ECompetencyMode.competency) {
      if (mounted) {
        setLocalOrbatForces(orbatForces);
      }
    }

    return () => {
      mounted = false;
    };
  }, [orbatForces]);
  const { checkedForces, forcesToRemove, newForces, relevantPlansIds } =
    useForcesChecked(localOrbatForces);
  const { onPlanSelectedHandler, plansOptions, selectedPlan, globalPlans } =
    usePlans(relevantPlansIds);

  const {
    labelsCompetency,
    thresholdsCompetency,
    thresholdsTrends,
    trendsIndicators,
    labelsTrends,
  } = useInitGraphsData(
    undefined,
    t,
    selectedPlan,
    setDetailsLabels,
    setDetailsThresholds
  );

  const { activePlanFinalGrades, indicatorsGrades, trainingTypesGrades } =
    useGetPerformanceGrades(
      selectedPlan,
      checkedForces,
      newForces,
      forcesToRemove,
      setIsGradesLoading,
      undefined,
      undefined
    );

  const { trendsBarChartsData } = useGetPerformanceTrends(
    selectedPlan,
    globalPlans,
    newForces,
    forcesToRemove,
    checkedForces,
    trendsIndicators,
    setIsTrendsLoading,
    true
  );

  useEffect(() => {
    setTranslatedDetailsLabels(
      detailsLabels.map((l) => ({ ...l, label: t(l.label.trim()) }))
    );
  }, [detailsLabels]);

  return (
    <IonPage>
      <MobileHeader
        isHideForcesTree={props.mode === ECompetencyMode.personal}
      />
      <IonContent
        className="competency"
        scrollY={selectedPlan?.id !== undefined}
      >
        {selectedPlan?.id ? (
          <IonGrid>
            <OrbatTreeModal
              limit={3}
              isOpen={isForcesTreeOpen}
              close={() => setIsForcesTreeOpen(false)}
              mode="secondary"
            />

            <IonRow className="selectForceRow">
              <PMLabel fontSize="medium" fontColor="light" fontFamily="Light">
                {mode === "competency" ? `${t("selectForceToDisplay")}` : ""}
              </PMLabel>
            </IonRow>

            <IonRow className="dropdownRow">
              {mode === "personal" ? null : (
                <IonCol size="3" className="traineeTreeButtonCol">
                  <PMButton
                    label={{
                      fontColor: "light",
                      fontSize: "small",
                      fontFamily: "Light",
                    }}
                    size="fit"
                    color="light"
                    fill="solid"
                    onClickHandler={() => setIsForcesTreeOpen(true)}
                    cssClass="traineeTreeButton"
                  >
                    {t("forcesTree")}
                  </PMButton>
                </IonCol>
              )}
              <IonCol size={`${mode === "personal" ? "12" : "9"}`}>
                <Dropdown
                  options={plansOptions}
                  onChange={onPlanSelectedHandler}
                  value={`${
                    selectedPlan && selectedPlan?.id !== undefined
                      ? `${selectedPlan.name} ${
                          selectedPlan.isActive ? `(${t("active")})` : ""
                        }`
                      : t("selectPlan")
                  }`}
                  placeholder={t("measurement")}
                  controlClassName="Dropdown mobileDropdown"
                  arrowClassName="DropdownArrow"
                  placeholderClassName="DropdownPlaceholderCompetency"
                  menuClassName="DropdownMenuCompetency DropdownMenu"
                  disabled={false}
                  isLoading={
                    relevantPlansIds.length === 0 && checkedForces.length > 0
                  }
                ></Dropdown>
              </IonCol>
            </IonRow>
            {!localOrbatForces.length ? (
              <NotExistLabel
                className="no-selected-forces"
                text="noSelectedForces"
              />
            ) : selectedPlan ? (
              <div>
                <IonRow className="finalGradeRow itemRow">
                  <div className="itemHeaderDiv">
                    <IonLabel className="itemHeaderLabel">
                      {t("lethality")}
                    </IonLabel>
                  </div>
                  {isGradesLoading || !activePlanFinalGrades ? (
                    <Spinner className="competency-spinner" />
                  ) : (
                    activePlanFinalGrades.map((finalGrade, index) => (
                      <FinalGrade
                        key={index}
                        lethality={finalGrade.soldierFinalGrade.grade}
                        isLoading={isGradesLoading}
                        forceName={finalGrade.soldierFinalGrade.name}
                        forceColor={finalGrade.soldierFinalGrade.legendColor}
                      ></FinalGrade>
                    ))
                  )}
                </IonRow>
                <IonRow className="radarRow itemRow">
                  <div className="itemHeaderDiv">
                    <IonLabel className="itemHeaderLabel">
                      {t("indicators")}
                    </IonLabel>
                  </div>

                  <IndicatorsRadar
                    plan={selectedPlan}
                    labels={labelsCompetency}
                    thresholds={thresholdsCompetency}
                    radarData={FilteredIndicatorsGrades(
                      indicatorsGrades,
                      labelsCompetency
                    )}
                    planGrades={activePlanFinalGrades.map((grade) => ({
                      id: grade.soldierFinalGrade.id,
                      grade: grade.soldierFinalGrade.grade,
                    }))}
                    isLoading={isGradesLoading}
                    force={force}
                    threshold={activePlanFinalGrades[0]?.threshold}
                    checkedForces={checkedForces}
                    forcesToRemove={forcesToRemove}
                  ></IndicatorsRadar>
                </IonRow>
                <IonRow className="performanceDetailsRow itemRow">
                  <div className="itemHeaderDiv">
                    <IonLabel className="itemHeaderLabel">
                      {t("details")}
                    </IonLabel>
                    {isGradesLoading ? (
                      <div></div>
                    ) : (
                      <>
                        <IonButton
                          id="open-modal"
                          size="small"
                          onClick={() => {
                            setIsTooltipOpened(true);
                          }}
                        >
                          {t("moreDetails")}
                        </IonButton>

                        <TooltipData
                          tooltipData={trainingTypesGrades}
                          isOpen={isTooltipOpened}
                          setIsOpen={setIsTooltipOpened}
                        />
                      </>
                    )}
                  </div>
                  <PersonalPerformanceDetails
                    plan={selectedPlan}
                    isLoading={isGradesLoading}
                    barChartData={trainingTypesGrades}
                    thresholds={detailsThresholds}
                    labels={translatedDetailsLabels}
                    force={force}
                    checkedForces={checkedForces}
                    forcesToRemove={forcesToRemove}
                  ></PersonalPerformanceDetails>
                </IonRow>
                {checkedForces.filter((force) => force.isSoldier).length ? (
                  <IonRow className="rankingsRow itemRow">
                    <div className="itemHeaderDiv">
                      <IonLabel className="itemHeaderLabel">
                        {t("rank")}
                      </IonLabel>
                    </div>
                    <Rankings
                      plan={selectedPlan}
                      checkedForces={checkedForces}
                    ></Rankings>
                  </IonRow>
                ) : (
                  <></>
                )}
                <IonRow className="trendsRow itemRow">
                  <div className="itemHeaderDiv">
                    <IonLabel className="itemHeaderLabel">
                      {t("trends")}
                    </IonLabel>
                  </div>
                  <PersonalTrends
                    isLoading={isTrendsLoading}
                    trendsChartsData={trendsBarChartsData}
                    indicators={trendsIndicators}
                    force={force}
                    labels={labelsTrends} //if we want relevant plans just switch to relevantLabelsTrends
                    thresholds={thresholdsTrends}
                    plan={selectedPlan}
                    checkedForces={checkedForces}
                    forcesToRemove={forcesToRemove}
                    mode={mode}
                  ></PersonalTrends>
                </IonRow>
              </div>
            ) : (
              <NotExistLabel text="noRelevantPlans" className="no-relevant" />
            )}
          </IonGrid>
        ) : (
          <Spinner />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Competency;
