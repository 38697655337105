import { Dispatch, SetStateAction } from "react";
import IElement from "../../Interfaces/IElement";
import { AppActions } from "./rootActions";
import axios from "../../Axios";
import { baseUrlPMBackend } from "../../Configurations/consts";
import customToast from "../../components/Shared/Toast/CustomToast";

export const SET_ELEMENTS = "SET_ELEMENTS";
export const ADD_ELEMENT = "ADD_ELEMENT";
export const EDIT_ELEMENT = "EDIT_ELEMENT";
export const DELETE_ELEMENT = "DELETE_ELEMENT";
export const FETCH_ELEMENTS_REQUEST = "FETCH_ELEMENTS_REQUEST";
export const FETCH_ELEMENTS_SUCCESS = "FETCH_ELEMENTS_SUCCESS";
export const FETCH_ELEMENTS_FAILURE = "FETCH_ELEMENTS_FAILURE";
export const UPDATE_ELEMENT = "UPDATE_ELEMENT";
export const SAVE_ELEMENTS_REQUEST = "SAVE_ELEMENTS_REQUEST";
export const SAVE_ELEMENTS_SUCCESS = "SAVE_ELEMENTS_SUCCESS";
export const SAVE_ELEMENTS_FAILURE = "SAVE_ELEMENTS_FAILURE";
export const INCREMENT_ELEMENTS_ERROR_COUNTER =
  "INCREMENT_ELEMENTS_ERROR_COUNTER";
export const DECREMENT_ELEMENTS_ERROR_COUNTER =
  "DECREMENT_ELEMENTS_ERROR_COUNTER";
export const RESET_ELEMENTS_ERROR_COUNTER = "RESET_ELEMENTS_ERROR_COUNTER";

export interface SetElementsAction {
  type: typeof SET_ELEMENTS;
  elements: IElement[];
}

export interface AddElementAction {
  type: typeof ADD_ELEMENT;
  element: IElement;
}

export interface EditElementAction {
  type: typeof EDIT_ELEMENT;
  element: IElement;
}

export interface DeleteElementAction {
  type: typeof DELETE_ELEMENT;
  id: number;
}

export interface fetchElementsRequest {
  type: typeof FETCH_ELEMENTS_REQUEST;
}

export interface fetchElementsSuccess {
  type: typeof FETCH_ELEMENTS_SUCCESS;
  elements: IElement[];
}

export interface fetchElementsFailure {
  type: typeof FETCH_ELEMENTS_FAILURE;
  error: Error | "";
}

export interface UpdateElement {
  type: typeof UPDATE_ELEMENT;
  element: IElement;
}

export interface SaveElementsRequest {
  type: typeof SAVE_ELEMENTS_REQUEST;
}

export interface SaveElementsSuccess {
  type: typeof SAVE_ELEMENTS_SUCCESS;
}

export interface SaveElementsFailure {
  type: typeof SAVE_ELEMENTS_FAILURE;
  error: Error | "";
}

export interface IncrementErrorCounter {
  type: typeof INCREMENT_ELEMENTS_ERROR_COUNTER;
}
export interface DecrementErrorCounter {
  type: typeof DECREMENT_ELEMENTS_ERROR_COUNTER;
}
export interface ResetErrorCounter {
  type: typeof RESET_ELEMENTS_ERROR_COUNTER;
}

export const fetchElementsByPlanId = (
  planId: number,
  setCurrentElements: Dispatch<SetStateAction<IElement[]>>
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: FETCH_ELEMENTS_REQUEST,
    });

    axios
      .get(`${baseUrlPMBackend}elements/getElementsByPlanId`, {
        params: { planId: planId },
      })
      .then((res) => {
        let elements: IElement[] = res.data.rows.map((element: IElement) => {
          return { ...element, errors: ["", "", "", ""] };
        });
        setCurrentElements(elements);
        dispatch({ type: FETCH_ELEMENTS_SUCCESS, elements: elements });
      })
      .catch((error) => {
        dispatch({ type: FETCH_ELEMENTS_FAILURE, error: error });
      });
  };
};

export const saveElements = (elements: IElement[], planId: number, t: any) => {
  let errorCount: number = elements.filter((element: IElement) =>
    element.errors?.find((error: string) => error !== "")
  ).length;

  if (errorCount)
    return async (dispatch: Dispatch<AppActions>) => {
      dispatch({
        type: SAVE_ELEMENTS_FAILURE,
        error: new Error("saveElementsError"),
      });
      customToast.error(t("saveElementsError"));
    };

  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({
      type: SAVE_ELEMENTS_REQUEST,
    });

    axios
      .post(`${baseUrlPMBackend}elements/saveElements`, {
        params: {
          elements: elements,
          planId: planId,
        },
      })
      .then((res) => {
        dispatch({ type: SAVE_ELEMENTS_SUCCESS });
        let insertedElements: IElement[] = res.data;
        insertedElements.map((element) =>
          dispatch({ type: UPDATE_ELEMENT, element: element })
        );
      })
      .catch((error) => {
        dispatch({ type: SAVE_ELEMENTS_FAILURE, error: error });
      });
  };
};

export const incrementErrorCounter = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: INCREMENT_ELEMENTS_ERROR_COUNTER });
  };
};

export const decrementRenderCounter = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch({ type: DECREMENT_ELEMENTS_ERROR_COUNTER });
  };
};

// Element types
export type ElementActionTypes =
  | SetElementsAction
  | AddElementAction
  | EditElementAction
  | DeleteElementAction
  | fetchElementsFailure
  | fetchElementsRequest
  | fetchElementsSuccess
  | UpdateElement
  | SaveElementsRequest
  | SaveElementsSuccess
  | SaveElementsFailure
  | IncrementErrorCounter
  | DecrementErrorCounter
  | ResetErrorCounter;
