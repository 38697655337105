import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import "./UIFElement.css";
import { UIFGrades } from "../../../../Interfaces/IUrbanInstructorFeedback";
import { IonCol, IonInput, IonItem, IonList, IonRow } from "@ionic/react";
import PMLabel from "../../../themeComponents/PMLabel";
import PMTitle from "../../../themeComponents/PMTitle";
import UFIElementHook from "../../../CustomHooks/UFIElementHook";

export interface IProps {
  title: string;
  elements: string[];
  dataType: string;
  setGrade: Dispatch<SetStateAction<UIFGrades[]>>;
  resetAll: boolean;
  setResetAll: (state: boolean) => void;
  index: number;
}
const UIFElement: React.FC<IProps> = (props: IProps): JSX.Element => {
  const { title, elements, dataType, setGrade, resetAll, setResetAll, index } =
    props;
  const { t } = useTranslation();

  const { elementsGrades, setElementGrade, finalGrade } = UFIElementHook(
    elements,
    dataType,
    setGrade,
    resetAll,
    setResetAll
  );
  return (
    <React.Fragment>
      <IonRow
        className={"wrapUIFRow" + (index === 0 ? " firstRowElement" : "")}
      >
        <IonCol size="2" className="borderEnd">
          <div className="UIFWrapTitle">
            <PMTitle
              textAlign="start"
              fontColor="xLight"
              fontFamily="Regular"
              fontSize="large"
            >
              {title}
            </PMTitle>
          </div>
        </IonCol>
        <IonCol size="7" className="columnUIF borderEnd">
          <IonList className="UIFData">
            {elements.map((element) => (
              <IonRow key={element} className="elementRowUIF textRow">
                <IonItem className="elementWrap">
                  <PMTitle
                    textAlign="start"
                    fontColor="xLight"
                    fontFamily="Light"
                    fontSize="medium"
                  >
                    {element}
                  </PMTitle>
                </IonItem>
              </IonRow>
            ))}
          </IonList>
        </IonCol>
        <IonCol size="1.5" className="columnUIF borderEnd">
          <IonList className="UIFData">
            {elementsGrades.map((element) => (
              <IonRow key={element.name} className="elementRowUIF">
                <IonItem className="UIFGrades">
                  <IonInput
                    onIonChange={(event) =>
                      setElementGrade(element.name, event.detail.value)
                    }
                    onIonFocus={(event: any) => {
                      event.target.placeholder = "";
                    }}
                    onIonBlur={(event: any) => {
                      event.target.placeholder = t("grade");
                    }}
                    placeholder={t("grade")}
                    value={element.grade}
                    className="UIFGrades"
                    type="number"
                    max="10"
                    min="1"
                  ></IonInput>
                </IonItem>
              </IonRow>
            ))}
          </IonList>
        </IonCol>
        <IonCol className="gradeColumn">
          <div className="UIFWrapFinalGrade">
            <PMLabel fontSize="xxLarge" fontColor="xLight" fontFamily="Regular">
              {finalGrade}
            </PMLabel>
          </div>
        </IonCol>
      </IonRow>
    </React.Fragment>
  );
};

export default UIFElement;
