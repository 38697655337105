import React from "react";
import { IonFooter, IonToolbar } from "@ionic/react";
import OnlineBadge from "../OnlineBadge/OnlineBadge";
import ActivePlan from "../ActivePlan/ActivePlan";
import "./Footer.css";

const Footer: React.FC = (): JSX.Element => {
  return (
    <div className="footer-wrapper">
      <IonFooter>
        <IonToolbar className="footer-height">
          <div className="footer-content">
            <OnlineBadge></OnlineBadge>
            <ActivePlan />
          </div>
        </IonToolbar>
      </IonFooter>
    </div>
  );
};

export default Footer;
