import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import ITrendIndicator from "../../Interfaces/ITrendIndicator";
import ILabel from "../../Interfaces/ILabel";
import IThreshold from "../../Interfaces/IThreshold";
import Axios from "../../Axios";
import { baseUrlPMBackend } from "../../Configurations/consts";
import IPlan from "../../Interfaces/IPlan";
import { Option } from "react-dropdown";
import { TFunction } from "i18next";
import { fetchGraphData } from "../../apis/PlansAPI";

const useInitGraphsData = (
  graphsData: AxiosResponse<any> | undefined,
  t: TFunction,
  plan?: IPlan,
  setLabelsDetailsActivePlan?: Dispatch<SetStateAction<ILabel[]>>,
  setThresholdsDetailsActivePlan?: Dispatch<SetStateAction<IThreshold[]>>,
  detailsPlan?: IPlan,
  fromTab?: boolean
) => {
  const [activePlan, setActivePlan] = useState<IPlan>({} as IPlan);
  const [activePlanID, setActivePlanID] = useState<number>();

  //indicators
  const [thresholdsCompetency, setThresholdsCompetency] = useState<
    IThreshold[]
  >([] as IThreshold[]);
  const [labelsCompetency, setLabelsCompetency] = useState<ILabel[]>(
    [] as ILabel[]
  );
  //trends
  const [thresholdsTrends, setThresholdsTrends] = useState<IThreshold[]>(
    [] as IThreshold[]
  );
  const [trendsIndicators, setTrendsIndicators] = useState<ITrendIndicator[]>(
    [] as ITrendIndicator[]
  );
  const [labelsTrends, setLabelsTrends] = useState<ILabel[]>([] as ILabel[]);

  const setData = (
    resultData: any | undefined,
    isFirst: Boolean,
    isDetailsPlanChanged?: boolean
  ) => {
    if (!resultData || resultData === null) return;
    setActivePlan((prev) => {
      let plan = isFirst
        ? resultData.planData.find((p: IPlan) => p.isActive)
        : resultData.planData[0];
      if (plan && plan.id === prev?.id && !isDetailsPlanChanged) return prev;
      {
        setThresholdsCompetency(() =>
          resultData.indicatorsData.map(
            (item: {
              indicatorData: {
                id: number;
                label: string;
                threshold: number;
                weight: number;
              };
            }) => ({
              id: item.indicatorData.id,
              threshold: item.indicatorData.threshold,
            })
          )
        );

        setLabelsCompetency(() =>
          resultData.indicatorsData
            .map(
              (item: {
                indicatorData: {
                  id: number;
                  label: string;
                  threshold: number;
                  weight: number;
                };
              }) => ({
                id: item.indicatorData.id,
                label: item.indicatorData.label,
              })
            )
            .sort((a: ILabel, b: ILabel) => Number(a.id) - Number(b.id))
        );
        if (setThresholdsDetailsActivePlan)
          setThresholdsDetailsActivePlan(() =>
            resultData.trainingTypesData.map(
              (item: {
                trainingTypeData: {
                  id: number;
                  label: string;
                  threshold: number;
                  weight: number;
                };
              }) => ({
                id: item.trainingTypeData.id,
                threshold: item.trainingTypeData.threshold,
              })
            )
          );

        if (setLabelsDetailsActivePlan)
          setLabelsDetailsActivePlan(() =>
            resultData.trainingTypesData
              .map(
                (item: {
                  trainingTypeData: {
                    id: number;
                    label: string;
                    threshold: number;
                    weight: number;
                    indicatorId: number;
                  };
                }) => ({
                  id: item.trainingTypeData.id,
                  label: item.trainingTypeData.label,
                  indicatorId: item.trainingTypeData.indicatorId,
                })
              )
              .sort((a: ILabel, b: ILabel) => Number(a.id) - Number(b.id))
          );
        setActivePlanID((prev) => {
          if (plan && prev !== plan.id) return plan.id;
          return prev;
        });
        return plan;
      }
    });

    setTrendsIndicators((prev) => {
      if (prev.length) return prev;
      return [
        { id: -1, name: t("lethality") },
        ...resultData.trendsIndicators.map(
          (t: { id: string; name: string }) => ({ ...t, id: Number(t.id) })
        ),
      ];
    });

    setLabelsTrends((prev) => {
      if (prev.length) return prev;
      return resultData.trendsData
        .filter((plan: any) => plan.trendData.isGlobal)
        .sort((previousValue: any, currentValue: any) => {
          return (
            new Date(previousValue.trendData!.startDate!).getTime() -
            new Date(currentValue.trendData!.startDate!).getTime()
          );
        })
        .map(
          (item: {
            trendData: {
              id: number;
              label: string;
              threshold: number;
              weight: number;
              startDate: string;
              endDate: string;
            };
          }) => {
            let startDay = new Date(item.trendData.startDate).getDate();
            let endDay = new Date(item.trendData.endDate).getDate();
            let startMonth = new Date(item.trendData.startDate).getMonth() + 1;
            let endMonth = new Date(item.trendData.endDate).getMonth() + 1;
            let dateText: string;
            if (startMonth === endMonth) {
              dateText =
                endDay + "." + endMonth + " - " + startDay + "." + startMonth;
            } else {
              dateText =
                endDay + "." + endMonth + " - " + startDay + "." + startMonth;
            }
            return {
              id: item.trendData.id,
              label: item.trendData.label + " " + dateText,
              plan: item.trendData,
            };
          }
        );
    });
    setThresholdsTrends((prev) => {
      if (prev.length) return prev;

      return resultData.trendsData
        .filter((plan: any) => plan.trendData.isGlobal)
        .map(
          (item: {
            trendData: {
              id: number;
              label: string;
              threshold: number;
              weight: number;
              startDate: string;
              endDate: string;
            };
          }) => ({
            id: Number(item.trendData.id),
            threshold: item.trendData.threshold,
          })
        );
    });
  };

  useEffect(() => {
    let mounted: boolean = true;
    if (!detailsPlan && activePlanID !== undefined && fromTab) {
      fetchGraphData(activePlanID).then(
        (resultData) => mounted && setData(resultData.data, false, true)
      );
    }
    return () => {
      mounted = false;
    };
  }, [detailsPlan]);

  useEffect(() => {
    let mounted: boolean = true;
    if (activePlanID !== undefined && activePlanID !== activePlan?.id) {
      fetchGraphData(activePlanID).then(
        (resultData) => mounted && setData(resultData.data, false, true)
      );
    }
    return () => {
      mounted = false;
    };
  }, [activePlanID]);

  useEffect(() => {
    let mounted: boolean = true;
    if (plan) {
      fetchGraphData(plan?.id).then(
        (resultData) =>
          mounted && setData(resultData.data, true, plan.id !== activePlanID)
      );
    }
    return () => {
      mounted = false;
    };
  }, [plan]);

  useEffect(() => {
    let mounted: boolean = true;
    if (graphsData) {
      mounted && setData(graphsData.data, true, false);
    }
    return () => {
      mounted = false;
    };
  }, [graphsData]);

  return {
    activePlan,
    thresholdsCompetency,
    labelsCompetency,
    thresholdsTrends,
    trendsIndicators,
    labelsTrends,
    setLabelsTrends,
    setActivePlan,
    setActivePlanID,
  };
};

export default useInitGraphsData;
