import { useEffect, useState } from "react";
import Axios from "../../Axios";
import { baseUrlPMBackend } from "../../Configurations/consts";
import IForceFromOrbat from "../../Interfaces/IForceFromOrbat";
import IFinalGradeDisplay from "../../Interfaces/IFinalGradeDisplay";
import IStationsChart from "../../Interfaces/IStationsChart";
import IRadarInfo from "../../Interfaces/IRadarInfo";
import IPlan from "../../Interfaces/IPlan";
import { useTranslation } from "react-i18next";
import customToast from "../Shared/Toast/CustomToast";
import ILabel from "../../Interfaces/ILabel";
import { ITrainingPlanResult } from "../../Interfaces/dataCalculator";

const getTrainingPlanGradesForForces = async (
  plan: IPlan,
  forcesIds: number[]
) => {
  if (!plan.id || !forcesIds.length) return;
  return await Axios.get(
    `${baseUrlPMBackend}performanceGrades/getCompetencyResults`,
    {
      params: {
        planId: plan.id,
        forcesIds: forcesIds,
      },
    }
  );
};

const useGetPerformanceGrades = (
  selectedPlan: IPlan | undefined,
  checkedForces: IForceFromOrbat[],
  newForces: IForceFromOrbat[],
  forcesToRemove: IForceFromOrbat[],
  setCompetencyLoading: (state: boolean) => void,
  setDetailsLoading?: (state: boolean) => void,
  detailsPlan?: IPlan
) => {
  const { t } = useTranslation();

  //all
  const [activePlanFinalGrades, setCompetencyFinalGrades] = useState<
    IFinalGradeDisplay[]
  >([] as IFinalGradeDisplay[]);
  //details
  const [trainingTypesGrades, setTrainingTypesGrades] = useState<
    IStationsChart[]
  >([] as IStationsChart[]);

  const [planDetailsFinalGrades, setStationsDetailsFinalGrades] = useState<
    IFinalGradeDisplay[]
  >([] as IFinalGradeDisplay[]);

  const [indicatorsGrades, setIndicatorsGrades] = useState<IRadarInfo[]>(
    [] as IRadarInfo[]
  );
  const [isToRefreshCompetency, setIsToRefreshCompetency] =
    useState<boolean>(false);

  const [isToRefreshDetails, setIsToRefreshDetails] = useState<boolean>(false);

  const [forcesResults, setForcesResults] = useState<ITrainingPlanResult[]>();

  /**
   * stop the loading for details and competency
   * (setDetailsLoading might be undefined)
   */
  const setLoading = (state: boolean) => {
    if (setDetailsLoading) setDetailsLoading(state);
    setCompetencyLoading(state);
  };

  /**
   * Set ITrainingPlanResult results For competency and station details tabs
   * @param isToConcat - whether need to push to the arrays(new checked forces) or reset it
   * @param trainingPlanResults - the plan results for the selected forces
   * @param onlyForDetails - is only stations details changed
   * @param onlyForCompetency - - is only competency changed
   */
  const setTrainingPlanResults = (
    isToConcat: boolean,
    trainingPlanResults: { data: ITrainingPlanResult[] },
    onlyForDetails?: boolean,
    onlyForCompetency?: boolean
  ) => {
    if (!trainingPlanResults) return;

    if (!onlyForDetails && !onlyForCompetency)
      setForcesResults((prev) => {
        if (isToConcat && prev) {
          return prev?.concat(trainingPlanResults.data);
        }
        return trainingPlanResults.data;
      });

    if (!onlyForDetails)
      setCompetencyFinalGrades((prev) => {
        let finalGrades: IFinalGradeDisplay[] = trainingPlanResults.data.map(
          (forceData: ITrainingPlanResult) => {
            let myForce = [...checkedForces, ...newForces].find(
              (force) => Number(force.id) === Number(forceData.forceId)
            );

            return {
              soldierFinalGrade: {
                id: Number(forceData.forceId),
                grade: Number(forceData.grade),
                name: myForce!.name,
                legendColor: myForce!.color!,
                isAttended: forceData.grade !== null,
              },
              threshold: Number(forceData.threshold),
            };
          }
        );
        return isToConcat ? [...prev, ...finalGrades] : finalGrades;
      });

    if (!onlyForCompetency)
      setStationsDetailsFinalGrades((prev) => {
        let finalGrades: IFinalGradeDisplay[] = trainingPlanResults.data.map(
          (forceData: ITrainingPlanResult) => {
            let myForce = [...checkedForces, ...newForces].find(
              (force) => Number(force.id) === Number(forceData.forceId)
            );

            return {
              soldierFinalGrade: {
                id: Number(forceData.forceId),
                grade: Number(forceData.grade),
                name: myForce!.name,
                legendColor: myForce!.color!,
                isAttended: forceData.grade !== null,
              },
              threshold: Number(forceData.threshold),
            };
          }
        );
        return isToConcat ? [...prev, ...finalGrades] : finalGrades;
      });

    if (!onlyForCompetency)
      setTrainingTypesGrades((prev) => {
        let trainingTypesGrades: IStationsChart[] =
          trainingPlanResults.data.map((forceData: ITrainingPlanResult) => {
            let myForce = [...checkedForces, ...newForces].find(
              (force) => Number(force.id) === Number(forceData.forceId)
            );

            return {
              id: Number(forceData.forceId),
              name: myForce!.name,
              forceColor: myForce!.color!,
              gradesArray: forceData.indicatorResults
                .map((indicatorResult) => indicatorResult.trainingTypeResults)
                .flat(1)
                .map((trainingType) => {
                  return {
                    id: trainingType.id,
                    grade: trainingType.grade,
                    isAttended: trainingType.participants > 0,
                    tooltipResults: trainingType.tooltipResults,
                    standardDeviation:
                      trainingType.tooltipResults.standardDeviation,
                    name: trainingType.name,
                  };
                }),
              isAttended: forceData.grade !== null,
            };
          });
        return isToConcat
          ? prev.concat(trainingTypesGrades)
          : trainingTypesGrades;
      });
    if (!onlyForDetails)
      setIndicatorsGrades((prev) => {
        let indicatorsGrades: IRadarInfo[] = trainingPlanResults.data.map(
          (forceData: ITrainingPlanResult) => {
            let myForce = [...checkedForces, ...newForces].find(
              (force) => Number(force.id) === Number(forceData.forceId)
            );
            return {
              id: Number(forceData.forceId),
              name: myForce!.name,
              forceColor: myForce!.color!,
              indicators: forceData.indicatorResults,
            };
          }
        );
        return isToConcat ? [...prev, ...indicatorsGrades] : indicatorsGrades;
      });
  };

  useEffect(() => {
    if (!newForces?.length || !selectedPlan) return;

    setLoading(true);

    getTrainingPlanGradesForForces(
      selectedPlan,
      newForces?.map((force) => force.id)
    )
      .then((res: any) => {
        setLoading(false);
        setTrainingPlanResults(true, res);
      })
      .catch((e) => {
        setLoading(false);
        customToast.error(t("errorWhileLoadingData"));
      });
  }, [newForces]);

  useEffect(() => {
    if (!forcesToRemove.length) return;

    setCompetencyFinalGrades((prev: IFinalGradeDisplay[]) => {
      return prev.filter(
        (planGrade) =>
          forcesToRemove.find(
            (force) =>
              Number(force.id) === Number(planGrade.soldierFinalGrade.id)
          ) === undefined
      );
    });

    setStationsDetailsFinalGrades((prev) => {
      return prev.filter(
        (planGrade) =>
          forcesToRemove.find(
            (force) =>
              Number(force.id) === Number(planGrade.soldierFinalGrade.id)
          ) == undefined
      );
    });

    setTrainingTypesGrades((prev) => {
      return prev.filter(
        (trainingTypesGrade) =>
          forcesToRemove.find(
            (force) => Number(force.id) === Number(trainingTypesGrade.id)
          ) === undefined
      );
    });

    setIndicatorsGrades((prev) => {
      return prev.filter(
        (indicatorGrade) =>
          forcesToRemove.find(
            (force) => Number(force.id) === Number(indicatorGrade.id)
          ) === undefined
      );
    });
  }, [forcesToRemove]);

  //when selectedPlan changed both competency and station details need to be refresh
  useEffect(() => {
    let mounted: boolean = true;
    if (selectedPlan?.id === undefined || !checkedForces.length) return;
    setLoading(true);

    getTrainingPlanGradesForForces(
      selectedPlan,
      checkedForces.map((force) => force.id)
    )
      .then((res: any) => {
        mounted && setLoading(false);
        mounted && setTrainingPlanResults(false, res);
      })
      .catch((e) => {
        setLoading(false);
        customToast.error(t("errorWhileLoadingData"));
      });
    return () => {
      mounted = false;
    };
  }, [selectedPlan]);

  useEffect(() => {
    setIsToRefreshDetails(true);
  }, [detailsPlan]);

  useEffect(() => {
    setIsToRefreshCompetency(false);
    if (!isToRefreshCompetency || !selectedPlan || !checkedForces.length)
      return;
    setCompetencyLoading(true);

    getTrainingPlanGradesForForces(
      selectedPlan,
      checkedForces.map((force) => force.id)
    )
      .then((res: any) => {
        setCompetencyLoading(false);
        setTrainingPlanResults(false, res, false, true);
      })
      .catch((e) => {
        setCompetencyLoading(false);
        customToast.error(t("errorWhileLoadingData"));
      });
  }, [isToRefreshCompetency]);

  useEffect(() => {
    setIsToRefreshDetails(false);
    if (
      !isToRefreshDetails ||
      (detailsPlan?.id === undefined && selectedPlan?.id === undefined) ||
      !checkedForces.length
    )
      return;

    if (setDetailsLoading) setDetailsLoading(true);

    getTrainingPlanGradesForForces(
      detailsPlan && detailsPlan.id ? detailsPlan : selectedPlan!,
      checkedForces.map((force) => force.id)
    )
      .then((res: any) => {
        if (setDetailsLoading) setDetailsLoading(false);
        setTrainingPlanResults(false, res, true, false);
      })
      .catch((e) => {
        if (setDetailsLoading) setDetailsLoading(false);
        customToast.error(t("errorWhileLoadingData"));
      });
  }, [isToRefreshDetails]);

  return {
    forcesResults,
    indicatorsGrades,
    activePlanFinalGrades,
    trainingTypesGrades,
    planDetailsFinalGrades,
    setIsToRefreshCompetency,
    setIsToRefreshDetails,
    setCompetencyFinalGrades,
  };
};

export default useGetPerformanceGrades;

export const FilteredIndicatorsGrades = (
  indicatorsGrades: IRadarInfo[],
  labels: ILabel[]
): IRadarInfo[] => {
  return indicatorsGrades.map<IRadarInfo>((force) => {
    return {
      forceColor: force.forceColor,
      id: force.id,
      name: force.name,
      indicators: force.indicators?.filter((i) =>
        labels.find((l) => Number(l.id) === Number(i.id))
      ),
    };
  });
};
