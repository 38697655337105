import { IonCol, IonRow, IonLabel } from "@ionic/react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import IElement from "../../../Interfaces/IElement";
import IUnsavedPlan from "../../../Interfaces/IUnsavedPlan";
import { EDIT_ELEMENT } from "../../../redux/actions/elementsActions";
import { IElementsReducer } from "../../../redux/reducers/elements";
import { IPlansReducer } from "../../../redux/reducers/plans";
import { AppState } from "../../../redux/store/plansStore";
import { compareToInitialArrayOfObjects } from "../../../services/helpers";
import Element from "./Element";
import "./Elements.css";

interface IElementsProps {
  stationId: number;
  setIsUnsavedPlan: Dispatch<SetStateAction<IUnsavedPlan | undefined>>;
  currentElements: IElement[];
}

const Elements: React.FC<IElementsProps> = (props: IElementsProps) => {
  const { stationId, setIsUnsavedPlan, currentElements } = props;
  const [initialElements, setInitialElements] = useState<IElement[]>([]);
  const [localElements, setLocalElements] = useState<IElement[]>([]);
  const [mounted, setMounted] = useState<boolean>(true);
  const dispatch = useDispatch<Dispatch<any>>();

  const elements = useSelector<AppState, IElementsReducer>(
    (state) => state.elements
  );
  const editMode = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).editMode;
  const savePlan = useSelector<AppState, IPlansReducer>(
    (state) => state.plans
  ).savePlan;

  const { t } = useTranslation();

  // Handles component mounting
  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  // Sets local elements
  useEffect(() => {
    if (mounted && !elements.loading) setLocalElements(elements.elements);
  }, []);

  useEffect(() => {
    setInitialElements(currentElements);
  }, [currentElements]);

  // Cancel changes
  useEffect(() => {
    if (
      !editMode &&
      savePlan <= 0 &&
      !elements.loading &&
      initialElements?.length !== 0
    ) {
      setLocalElements(initialElements);
    }
  }, [editMode]);

  // Updates element every onBlur
  const updateElement = (element: IElement) => {
    setLocalElements((prev: IElement[]) =>
      prev.map((prevElement: IElement) =>
        prevElement.id === element.id ? element : prevElement
      )
    );

    dispatch({ type: EDIT_ELEMENT, element: element });
  };

  useEffect(() => {
    setIsUnsavedPlan((prev: IUnsavedPlan | undefined) => ({
      ...prev!,
      indicators: {
        ...prev?.indicators!,
        stations: {
          ...prev?.indicators.stations!,
          elements: {
            ...prev?.indicators.stations.elements!,
            isElementsUnsaved: compareToInitialArrayOfObjects(
              initialElements,
              localElements
            ),
          },
        },
      },
    }));
  }, [localElements]);

  useEffect(() => {
    if (mounted && !elements.loading) setLocalElements(elements.elements);
  }, [elements]);

  return (
    <IonCol size="2">
      <IonRow className="elementsHeadersRow">
        <IonCol size="2.8" className="elNameCol">
          <IonLabel className="elementsHeader ">{t("gradeElement")}</IonLabel>
        </IonCol>
        <IonCol size="2.8">
          <IonLabel className="elementsHeader">{t("elementWeight")}</IonLabel>
        </IonCol>
        <IonCol size="1">
          <div className="elThresholdsDiv">
            <IonLabel className="elementsHeader">
              {t("lowerThreshold")}
            </IonLabel>
          </div>
        </IonCol>
        <IonCol size="1">
          <div className="elThresholdsDiv">
            <IonLabel className="elementsHeader">
              {t("upperThreshold")}
            </IonLabel>
          </div>
        </IonCol>{" "}
        <IonCol size="1">
          <div className="elThresholdsDiv">
            <IonLabel className="elementsHeader ">
              {t("requiredThreshold")}
            </IonLabel>
          </div>
        </IonCol>
        <IonCol size="1" className="checkboxHeader">
          <div className="isForDashboardCheckbox">
            <IonLabel className="elementsHeader isForDashboardLabel ">
              {t("isForDashboard")}
            </IonLabel>
          </div>
        </IonCol>
      </IonRow>
      <IonRow className="elementsRow elementRow ">
        {localElements &&
          localElements
            .filter((element) => element.stationId === stationId)
            .map((element, index) => (
              <Element
                key={index}
                element={element}
                updateElement={updateElement}
              ></Element>
            ))}
      </IonRow>
    </IonCol>
  );
};

export default Elements;
