import React, { useContext, useEffect, useState } from "react";
import Axios from "../../Axios";
import { baseUrlPMBackend } from "../../Configurations/consts";
import { IDateRange } from "../../Interfaces/IDatePicker";

import { UserContext } from "../../context/UserContext/userContext";
import IData from "../../Interfaces/IDataHistoryData";
import { userRoles } from "../../services/routeRoles";
import axios, { CancelTokenSource } from "axios";
import IForceFromOrbat from "../../Interfaces/IForceFromOrbat";
import IForceTreeNode from "../../Interfaces/IForceTreeNode";
import { useTranslation } from "react-i18next";

const useGetPerformanceData = (
  trainingTypeId: number,
  cameToEnd: boolean,
  rangeDate: IDateRange | undefined,
  selectedSoldierIds: number[] | undefined,
  setCameToEnd: React.Dispatch<React.SetStateAction<boolean>>,
  selectedPlanId: undefined | number
) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const isTheUserIsATrainee =
    user.relatedForce?.is_soldier === true && //if the current force is a trainee/soldier
    user.role === userRoles.Viewer && //if the role permission is viewer
    user.relatedForce.id == user.forceToDisplayInOrbat?.id; //if there is only one related force (might be string == number)
  const initLimit = 100;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<IData[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [cancelTokenSource, setCancelTokenSource] = useState<
    CancelTokenSource | undefined
  >(undefined);

  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(initLimit);

  const loadData = (selectedForcesIds: number[], isToConcat: boolean) => {
    if (
      !selectedForcesIds ||
      (user.role !== userRoles.Admin && !user.forceToDisplayInOrbat)
    )
      return;
    if (trainingTypeId) {
      if (cancelTokenSource) {
        cancelTokenSource?.cancel("Request canceled due to a new request.");
      }
      const source = axios.CancelToken.source();
      setCancelTokenSource(source);

      setLoading(true);
      setError(false);

      Axios.get(`${baseUrlPMBackend}dataHistory/getPrefDataForTrainingType`, {
        params: {
          trainingTypeId: trainingTypeId,
          selectedSoldierIds: selectedForcesIds.length
            ? selectedForcesIds
            : user.forceToDisplayInOrbat
            ? [user.forceToDisplayInOrbat.id]
            : undefined,
          limit: isToConcat ? limit : initLimit,
          offset: isToConcat ? offset : 0,
          startDate: isTheUserIsATrainee ? undefined : rangeDate?.startDate,
          endDate: isTheUserIsATrainee ? undefined : rangeDate?.endDate,
          selectedPlanId: selectedPlanId,
        },
        cancelToken: source.token,
      })
        .then((res: any) => {
          setLoading(false);
          setCameToEnd(false);
          let dataToReturn = res.data.performanceData.data.map((row: any) => {
            let date = new Date(row.date);
            Object.keys(row).forEach((key) => {
              if (isNaN(row[key])) row[key] = t(row[key]);
            });
            return {
              ...row,
              hour: date.toLocaleTimeString("en-US", {
                hourCycle: "h23",
              }),
              date: `${String(date.getDate()).padStart(2, "0")}/${String(
                date.getMonth() + 1
              ).padStart(2, "0")}/${date.getFullYear()}`,
            };
          });

          setOffset((prev) => {
            return prev + dataToReturn.length;
          });
          setData((prev: IData[]) => {
            return !isToConcat
              ? dataToReturn.map((row: IData, index: number) => {
                  return { ...row, rowIndex: index + 1 };
                })
              : selectedSoldierIds?.length === 0
              ? [...prev, ...dataToReturn].map((row: IData, index: number) => {
                  return { ...row, rowIndex: index + 1 };
                })
              : [...prev, ...dataToReturn]
                  .sort(
                    (a, b) =>
                      new Date(`${b.date} ${b.hour}`).getTime() -
                      new Date(`${a.date} ${a.hour}`).getTime()
                  )
                  .map((row: IData, index: number) => {
                    return { ...row, rowIndex: index + 1 };
                  });
          });
          setLimit((prev) => Math.min(prev * 2, 1000));
          if (dataToReturn.length === 0) setLimit(initLimit);

          setHasMore(dataToReturn.length > 0);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
          } else {
            console.error(error);
            setError(true);
          }
        });
    }
  };

  useEffect(() => {
    if (cameToEnd && selectedSoldierIds && hasMore) {
      loadData(selectedSoldierIds, true);
    }
  }, [cameToEnd]);

  useEffect(() => {
    if (selectedSoldierIds) {
      setLimit(initLimit);
      setOffset(0);
      setData([]);
      loadData(selectedSoldierIds, false);
    }
  }, [rangeDate, trainingTypeId, selectedPlanId, selectedSoldierIds]);

  return { loading, error, data, hasMore, setData, setLoading };
};
export default useGetPerformanceData;
