import React, { useEffect, useState, FC } from "react";
import "./IndicatorBarChart.css";

// components import
import BarChart from "./../../Shared/Charts/Bar/BarChart";

// interfaces
import ILabel from "./../../../Interfaces/ILabel";
import IForceFromOrbat from "../../../Interfaces/IForceFromOrbat";
import IThreshold from "../../../Interfaces/IThreshold";
import ITrendsDataForForce from "../../../Interfaces/ITrendsDataForForce";
import ITrendsIndicatorData from "../../../Interfaces/ITrendsIndicatorData";
import IDatasetsChart from "../../../Interfaces/IDatasetsChart";
import IPlan from "../../../Interfaces/IPlan";
import LineOrBarChart from "../../Shared/Charts/Line/LineOrBarChart";

interface IProps {
  thresholds: IThreshold[];
  labels: ILabel[] | undefined;
  selectedForces: IForceFromOrbat[] | undefined;
  newForces: IForceFromOrbat[] | undefined;
  forcesToRemove: IForceFromOrbat[] | undefined;
  barChartsData: ITrendsIndicatorData[] | undefined;
  indicatorId: number;
  setDataForMobileLabels?: (data: IDatasetsChart[]) => void;
  isDesktop?: boolean;
  plan: IPlan | undefined;
  trends?: boolean;
  lineOrBarTypeToggle: "line" | "scatter";
}

const IndicatorBarChart: FC<IProps> = (props: IProps) => {
  const {
    labels,
    thresholds,
    selectedForces,
    newForces,
    forcesToRemove,
    barChartsData,
    indicatorId,
    setDataForMobileLabels,
    isDesktop,
    plan,
    trends,
    lineOrBarTypeToggle,
  } = props;

  const [barChartData, setBarChartData] = useState<ITrendsDataForForce[]>();

  useEffect(() => {
    if (barChartsData && barChartsData.length) {
      let temp = barChartsData?.find(
        (forceItems: ITrendsIndicatorData) =>
          forceItems && Number(forceItems?.indicatorId) === Number(indicatorId)
      );

      findBarsDataToDisplay(temp ? temp.data : []);
    }
  }, [barChartsData]);

  const findBarsDataToDisplay = (
    barsData: ITrendsDataForForce[] | undefined
  ) => {
    setBarChartData([...barsData!]);
  };

  return (
    <>
      <LineOrBarChart
        barChartData={barChartData}
        thresholds={thresholds}
        labels={labels}
        selectedForces={selectedForces}
        newForces={newForces}
        forcesToRemove={forcesToRemove}
        barsType={"plans"}
        setDataForMobileLabels={setDataForMobileLabels}
        isDesktop={isDesktop}
        plan={plan}
        trends={trends}
        lineOrScatterTypeToggle={lineOrBarTypeToggle}
      ></LineOrBarChart>
    </>
  );
};

export default IndicatorBarChart;
