import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
  memo,
} from "react";
import RadarGraph from "../../../../../components/Shared/Charts/Radar/RadarGraph";
import "./PerformanceQualifications.css";
import IPlan from "../../../../../Interfaces/IPlan";
import IForceFromOrbat from "../../../../../Interfaces/IForceFromOrbat";
import IForceTreeNode from "../../../../../Interfaces/IForceTreeNode";
import IFinalGradeDisplay from "../../../../../Interfaces/IFinalGradeDisplay";
import ILabel from "../../../../../Interfaces/ILabel";
import { useTranslation } from "react-i18next";
import IThreshold from "../../../../../Interfaces/IThreshold";
import domtoimage from "dom-to-image-improved";
import EExportType from "../../../../../Interfaces/EExportType";
import IRadarInfo from "../../../../../Interfaces/IRadarInfo";
import Dropdown from "../../../../../components/Shared/Dropdown/Dropdown";
import { Option } from "react-dropdown";
import { FilteredIndicatorsGrades } from "../../../../../components/CustomHooks/useGetPerformanceGrades";
import PerformanceTemplate from "../../performanceTemplate";

interface IProps {
  orbatForces: IForceTreeNode[];
  checkedForces: IForceFromOrbat[];
  setChecked: (force: IForceTreeNode[]) => void;
  exportToCsv: (imageUrl: string, exportType: number) => void;
  isAdmin?: boolean | true;
  exportHandler: () => void;
  downloadLoading: boolean;
  thresholds: IThreshold[];
  labels: ILabel[];
  plan: IPlan;
  indicatorsGrades: IRadarInfo[];
  finalGrades: IFinalGradeDisplay[];
  newForces: IForceFromOrbat[];
  forcesToRemove: IForceFromOrbat[];
  loading: boolean;
  setIsToRefresh: (state: boolean) => void;
  allPlansOptions: Option[];
  onPlanSelectedHandler: (option: Option) => void;
  isDrawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PerformanceQualifications: FC<IProps> = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    orbatForces,
    checkedForces,
    setChecked,
    downloadLoading,
    plan,
    thresholds,
    labels,
    indicatorsGrades,
    finalGrades,
    newForces,
    forcesToRemove,
    setIsToRefresh,
    loading,
    allPlansOptions,
    onPlanSelectedHandler,
    isDrawerOpen,
    setDrawerOpen,
  } = props;

  const [myNewForces, setMyNewForces] = useState<IForceFromOrbat[]>();
  useEffect(() => {
    setMyNewForces((prev) => {
      if (!prev) return checkedForces;
      return newForces;
    });
  }, [newForces]);

  const getImageAndSendToCsv = async () => {
    let imageUrl = await domtoimage.toJpeg(
      document.getElementById("csvRadarImage")
    );
    props.exportToCsv(imageUrl, EExportType.competency);
  };

  const chart = (
    <div className="radarQ toImage">
      <div className="grapsWrap">
        <RadarGraph
          id="csvRadarImage"
          radarData={FilteredIndicatorsGrades(indicatorsGrades, labels)}
          selectedForces={checkedForces}
          thresholds={thresholds}
          labels={labels}
          newForces={myNewForces}
          forcesToRemove={forcesToRemove}
          plan={plan}
        />
      </div>
    </div>
  );
  return (
    <PerformanceTemplate
      key={"competency"}
      loadingText={
        loading ? t("loadingData") : downloadLoading ? t("downloadFile") : ""
      }
      chart={chart}
      finalGrades={finalGrades}
      secondButtonText={t("refresh")}
      firstButtonText={t("export")}
      onClickSecondButton={() => setIsToRefresh(true)}
      onClickFirstButton={() => getImageAndSendToCsv()}
      isDrawerOpen={isDrawerOpen}
      loading={loading || downloadLoading}
      orbatForces={orbatForces}
      plansOptions={allPlansOptions}
      selectedPlan={plan}
      onPlanSelectedHandler={onPlanSelectedHandler}
      setChecked={setChecked}
      setDrawerOpen={setDrawerOpen}
      title={t("qualifications")}
    ></PerformanceTemplate>
  );
};

export default memo(PerformanceQualifications);
