import React, { FC, useEffect, useState } from "react";
import {
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import EEventType from "../../../../Interfaces/EEventType";
import EIconsSrc from "../../../../Interfaces/EIconsSrc";
import i18n from "../../../../services/i18next";
import { getWeapons } from "../../../Desktop/DataPosting/dataPostingHelpers";
import customToast from "../../../Shared/Toast/CustomToast";
import PMButton from "../../../themeComponents/PMButton";
import PMLabel from "../../../themeComponents/PMLabel";
import MobileHeader from "../../MobileHeader/MobileHeader";
import MobileTitleRow from "../../MobileHeader/MobileTitleRow";
import { sendDrillToBBackend } from "../../../Desktop/DataPosting/dataPostingHelpers";

import "./MagnetDataPosting.css";

interface IWeaponType {
  name: string;
  id: number;
}
const MagnetDataPosting: FC = () => {
  const [numOfTargets, setNumOfTargets] = useState<string>("");
  const [numOfShots, setNumOfShots] = useState<string>("");
  const [numOfHits, setNumOfHits] = useState<string>("");
  const [numOfDestroyed, setNumOfDestroyed] = useState<string>("");
  const [hashtags, setHashtags] = useState<string>("");
  const [overalltime] = useState<string>("");
  const [weaponTypes, setWeaponTypes] = useState<string[]>([]);
  const [weaponType, setWeaponType] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const { t } = useTranslation();
  const startDateTime: Date = new Date();
  const history = useHistory();
  const params = useParams<{
    stationName: string;
    trainingTypeId: string;
    stationId: string;
    laneId: string;
    traineeName: string;
    traineeId: string;
    planId: string;
  }>();
  const traineeId = parseInt(params.traineeId);
  const traineeName = params.traineeName;
  const trainingTypeId = parseInt(params.trainingTypeId);
  const stationId = parseInt(params.stationId);
  const laneId = params.laneId;
  const stationName = params.stationName;
  const planId = params.planId;

  const returnToSoldiersList = () => {
    history.goBack();
  };

  /**
   * return an array with the hour, minutes and seconds as a string
   * @param overalltime
   */
  const getDurationParams = (overalltime: string) => {
    const overallTime: string[] = overalltime.split(":");
    const minutes = overallTime[0];
    const seconds = overallTime[1];
    return [minutes, seconds];
  };

  /**
   * returns the duration in seconds
   * @param overalltime
   */
  const getDurationInSeconds = (overalltime: string) => {
    let durationParams = getDurationParams(overalltime);
    return parseInt(durationParams[0]) * 60 + parseInt(durationParams[1]);
  };

  /**
   * post the data to b-backend
   */
  const sendData = () => {
    if (traineeId === undefined) {
      // if (isDesktop) customToast.error(t("noSelectedSoldier"));
      customToast.error(t("traineeNotFound"));
      return;
    }
    if (numOfDestroyed === "" || numOfTargets === "") {
      customToast.error(t("fillMagnetError"));
    } else {
      setIsButtonDisabled(true);
      const timeStamp: Date = new Date();
      const duration = getDurationInSeconds(overalltime);
      let currentWeaponType = weaponType === "No Weapon" ? "None" : weaponType;
      const magnetDrill = {
        MessageType: EEventType.MAGNET_MESSAGE_TYPE,
        TimeStamp: timeStamp,
        LaneID: laneId,
        TrainingTypeID: trainingTypeId,
        SoldierID: traineeId,
        DrillStartTime: startDateTime,
        DrillEndTime: timeStamp,
        Duration: duration,
        WeaponType: currentWeaponType,
        NumOfTargets: numOfTargets,
        NumOfShots: numOfShots,
        RedHits: numOfHits,
        TargetsDestroyed: numOfDestroyed,
        Hashtags: hashtags.split(" "),
        PlanId: planId,
      };
      const displayToast = true;
      sendDrillToBBackend(
        magnetDrill,
        returnToSoldiersList,
        () => setIsButtonDisabled(false),
        t,
        displayToast
      );
    }
  };

  useEffect(() => {
    let mounted = true;
    getWeapons(mounted, setWeaponTypes);
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <IonPage>
      <MobileHeader
        iconEndSrc={EIconsSrc.MENU_ICON}
        iconStartSrc={
          i18n.language === "he"
            ? EIconsSrc.BACK_ICON_HE
            : EIconsSrc.BACK_ICON_EN
        }
        title={t(stationName)}
        iconStartHandler={returnToSoldiersList}
      />
      <IonContent className="cnt">
        <IonGrid className="magnetGrid">
          <MobileTitleRow title={traineeName} />
          <IonRow className="magnetDataRow">
            <IonRow className="magnetInputRow">
              <IonItem
                mode="md"
                class="manualInputContainerM itemM"
                lines="full"
              >
                <PMLabel
                  fontSize="medium"
                  fontFamily="Light"
                  fontColor="light"
                  position="floating"
                >
                  {t("weaponType")}
                </PMLabel>
                <IonSelect
                  interface="action-sheet"
                  value={weaponType}
                  onIonChange={(e) => setWeaponType(e.detail.value)}
                >
                  {weaponTypes.map((weaponType: string, index: number) => (
                    <IonSelectOption
                      key={index}
                      className="weaponOpt"
                      value={weaponType}
                    >
                      {weaponType}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
            </IonRow>
            <IonRow className="magnetInputRow">
              <IonItem
                mode="md"
                class="manualInputContainerM itemM"
                lines="full"
              >
                <PMLabel
                  fontSize="medium"
                  fontFamily="Light"
                  fontColor="light"
                  position="floating"
                >
                  {t("numOfTargets")}
                </PMLabel>
                <IonInput
                  onIonChange={(e) => {
                    setNumOfTargets(e.detail.value!);
                  }}
                  value={numOfTargets}
                  type="number"
                ></IonInput>
              </IonItem>
            </IonRow>
            <IonRow className="magnetInputRow">
              <IonItem
                mode="md"
                class="manualInputContainerM itemM"
                lines="full"
              >
                <PMLabel
                  fontSize="medium"
                  fontFamily="Light"
                  fontColor="light"
                  position="floating"
                >
                  {t("numOfShots")}
                </PMLabel>
                <IonInput
                  onIonChange={(e) => {
                    setNumOfShots(e.detail.value!);
                  }}
                  value={numOfShots}
                  type="number"
                ></IonInput>
              </IonItem>
            </IonRow>
            <IonRow className="magnetInputRow">
              <IonItem
                mode="md"
                class="manualInputContainerM itemM"
                lines="full"
              >
                <PMLabel
                  fontSize="medium"
                  fontFamily="Light"
                  fontColor="light"
                  position="floating"
                >
                  {t("numOfHits")}
                </PMLabel>
                <IonInput
                  onIonChange={(e) => {
                    setNumOfHits(e.detail.value!);
                  }}
                  value={numOfHits}
                  type="number"
                ></IonInput>
              </IonItem>
            </IonRow>
            <IonRow className="magnetInputRow">
              <IonItem
                mode="md"
                class="manualInputContainerM itemM"
                lines="full"
              >
                <PMLabel
                  fontSize="medium"
                  fontFamily="Light"
                  fontColor="light"
                  position="floating"
                >
                  {t("numOfDestroyed")}
                </PMLabel>
                <IonInput
                  onIonChange={(e) => {
                    setNumOfDestroyed(e.detail.value!);
                  }}
                  value={numOfDestroyed}
                  type="number"
                ></IonInput>
              </IonItem>
            </IonRow>
            <IonRow className="magnetInputRow">
              <IonItem
                mode="md"
                class="manualInputContainerM itemM"
                lines="full"
              >
                <PMLabel
                  fontSize="medium"
                  fontFamily="Light"
                  fontColor="light"
                  position="floating"
                >
                  {t("hashtagsExcName")}
                </PMLabel>
                <IonInput
                  onIonChange={(e) => {
                    setHashtags(e.detail.value!);
                  }}
                  value={hashtags}
                  type="text"
                ></IonInput>
              </IonItem>
            </IonRow>
          </IonRow>
          <IonRow className="sendRow">
            <PMButton
              onClickHandler={sendData}
              isDisabled={isButtonDisabled}
              color="tertiary"
              size="small"
              label={{
                fontColor: "light",
                fontSize: "medium",
                fontFamily: "Regular",
              }}
            >
              {t("send")}
            </PMButton>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default MagnetDataPosting;
