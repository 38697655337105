import { useEffect, useRef, useState } from "react";
import Axios from "../../Axios";
import { baseUrlPMBackend } from "../../Configurations/consts";
import IForceFromOrbat from "../../Interfaces/IForceFromOrbat";
import IPlan from "../../Interfaces/IPlan";
import { AxiosResponse } from "axios";
import customToast from "../Shared/Toast/CustomToast";
import { IForce } from "../../Interfaces/results/force.interface";
import Colors from "../../Interfaces/Colors";
import { DashboardConstants } from "../../Configurations/DashboardConstants";
import { TFunction } from "i18next";
import Dictionary from "../../pages/IDictionary";
import {
  IElementResult,
  IIndicatorResult,
  ITrainingTypeResult,
} from "../../Interfaces/dataCalculator";

const useGetCommanderDashboardGrades = (
  selectedPlan: IPlan | undefined,
  force: IForceFromOrbat[],
  refresh: boolean,
  t: TFunction,
  setLoading: (state: boolean) => void
) => {
  const [trainingTypeData, setTrainingTypeData] = useState<Dictionary<any>>();
  const [indicatorData, setIndicatorData] = useState<Dictionary<any>>();
  const [displayedForceData, setDisplayedForceData] = useState<IForce>();
  const [forceData, setForceData] = useState<IForce>();
  const [selectedForcesID, setSelectedForcesID] = useState<number[]>([]);
  const [amountOfSoldiers, setAmountOfSoldiers] = useState<number>();
  const [onlySoldiers, setOnlySoldiers] = useState<IForce[]>();
  const componentMounted = useRef(true); //  component is mounted

  const setData = (
    res: AxiosResponse<IForce> | undefined,
    selectedForcesID?: number[]
  ) => {
    let selectedForceRes: IForce = res!.data;
    if (selectedForcesID && selectedForcesID.length) {
      //if selected force exist run all calculation on the selected force (- the orbat force child )
      let selectedForceData: IForce[] | undefined = res?.data.children.filter(
        (c) => selectedForcesID.includes(Number(c.id))
      );

      if (selectedForceData) {
        if (selectedForceData.length == 1)
          selectedForceRes = selectedForceData[0];
        else
          selectedForceRes = {
            ...selectedForceRes,
            children: selectedForceData,
          }; //need to get the avarage all over again - not possible for now
      }
    }

    const indicators: Dictionary<any> = [];
    const allChildren = res!.data.children.map((child) => {
      if (
        !selectedForcesID ||
        !selectedForcesID.length ||
        selectedForcesID.includes(Number(child.id))
      )
        //if selected force exist filter the soldiers to be only the selected force children
        return getAllChildren(child);
    });
    const onlySoldiers: IForce[] = [];
    allChildren.forEach((child) =>
      child?.forEach((grand) => onlySoldiers.push(grand))
    );

    const indicatorsForTrainingType: Dictionary<any> = {};
    selectedForceRes?.results.indicatorResults.forEach(
      (ir: IIndicatorResult) => {
        const trainingTypes: Dictionary<any> = {};
        const elementsForIndicators = new Map<string, any>();
        ir.trainingTypeResults.forEach((ttr: ITrainingTypeResult) => {
          const elements: Dictionary<any> = {};
          ttr.elementResults.forEach((er) => {
            let soldiersAboveRequired = 0;
            const green: any[] = [];
            const yellow: any[] = [];
            const red: any[] = [];
            const ForcesElementData = {
              green: green,
              yellow: yellow,
              red: red,
            };
            allChildren.forEach((soldiers, index) => {
              const forceElementData = {
                green: {
                  value: 0,
                  color: Colors[index],
                },
                yellow: {
                  value: 0,
                  color: Colors[index],
                },
                red: {
                  value: 0,
                  color: Colors[index],
                },
              };
              soldiers?.forEach((soldier, index) => {
                const cr = getChildResult(soldier, ir, ttr, er);
                if (cr !== null) {
                  const color = getElementColor(cr);
                  if (color === DashboardConstants.GREEN_GRADE_COLOR) {
                    forceElementData[DashboardConstants.GREEN_GRADE_COLOR][
                      "value"
                    ] += 1;
                  } else if (color === DashboardConstants.YELLOW_GRADE_COLOR) {
                    forceElementData[DashboardConstants.YELLOW_GRADE_COLOR][
                      "value"
                    ] += 1;
                  } else if (color === DashboardConstants.RED_GRADE_COLOR) {
                    forceElementData[DashboardConstants.RED_GRADE_COLOR][
                      "value"
                    ] += 1;
                  }
                }
              });
              ForcesElementData[DashboardConstants.GREEN_GRADE_COLOR].push({
                ...forceElementData.green,
                key: index,
              });
              soldiersAboveRequired += forceElementData.green.value;
              ForcesElementData[DashboardConstants.YELLOW_GRADE_COLOR].push({
                ...forceElementData.yellow,
                key: index,
              });
              ForcesElementData[DashboardConstants.RED_GRADE_COLOR].push({
                ...forceElementData.red,
                key: index,
              });
            });
            if (er.isForDashboard) {
              elements[er.id] = {
                id: er.id,
                name: er.name,
                threshold: er.requiredThreshold,
                lowerThreshold: er.lowerThreshold,
                upperThreshold: er.upperThreshold,
                requiredThreshold: er.requiredThreshold,
                thresholdType: er.thresholdType,
                negative: er.negative,
                forcesElementData: ForcesElementData,
              };
              if (!elementsForIndicators.has(er.name)) {
                elementsForIndicators.set(er.name, {
                  name: er.name,
                  participants: ttr.participants,
                  soldiersAboveRequired: soldiersAboveRequired,
                });
              } else {
                const element = elementsForIndicators.get(er.name);
                elementsForIndicators.set(er.name, {
                  name: er.name,
                  participants: ttr.participants + element.participants,
                  soldiersAboveRequired:
                    soldiersAboveRequired + element.soldiersAboveRequired,
                });
              }
            }
          });

          if (Object.keys(elements).length)
            trainingTypes[ttr.id] = {
              id: ttr.id,
              name: ttr.name,
              threshold: ttr.threshold,
              elements: elements,
              participants: ttr.participants,
              soldierMissed: ttr.soldierMissed,
            };
          setAmountOfSoldiers(ttr.participants + ttr.soldierMissed);
        });
        indicatorsForTrainingType[ir.id] = trainingTypes;
        indicators[ir.id] = Array.from(elementsForIndicators.values()).map(
          (element) => {
            const [value, color] = getElementColorForIndicator(
              element.soldiersAboveRequired,
              element.participants
            );
            return {
              label: element.name,
              value: value,
              color: color,
            };
          }
        );
      }
    );

    setOnlySoldiers(onlySoldiers);
    setIndicatorData(indicators);
    setTrainingTypeData(indicatorsForTrainingType);
    setLoading(false);
  };

  useEffect(() => {
    /** when refresh activated or force changed*/
    if (selectedPlan && selectedPlan.id && force && force.length) {
      getAllGradesData(
        selectedPlan,
        force.map((f) => f.id),
        refresh
      )
        .then((res) => {
          if (componentMounted.current) {
            setData(res);
            setDisplayedForceData(res?.data);
          }
        })
        .catch((error) => {
          if (componentMounted.current) {
            customToast.error(t("errorWhileLoadingData"));
            setLoading(false);
          }
        });
    }
  }, [force, refresh]);

  //filter by selected force
  useEffect(() => {
    if (
      displayedForceData &&
      Number(displayedForceData.id) === Number(force[0].id)
    ) {
      setData(
        { data: displayedForceData } as AxiosResponse<IForce> | undefined,
        selectedForcesID
      );
    }
  }, [selectedForcesID]);

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  // recursive function to find the force data
  const getDataFromExistingResults = (
    forceData: IForce,
    forceId: number
  ): IForce | null => {
    if (Number(forceData?.id) === Number(forceId)) return forceData;
    let children = forceData.children;
    let forceDataToReturn: IForce | null = null;
    if (children.length > 0)
      for (let i = 0; forceDataToReturn == null && i < children.length; i++) {
        forceDataToReturn = getDataFromExistingResults(children[i], forceId);
      }
    return forceDataToReturn;
  };

  //get data from the parent object or the backend
  const getAllGradesData = async (
    plan: IPlan,
    forcesIds: number[],
    isRefresh?: boolean
  ) => {
    let resultFromExistingResults: any;
    if (forceData && !isRefresh)
      resultFromExistingResults = getDataFromExistingResults(
        forceData,
        forcesIds[0]
      );
    if (resultFromExistingResults) {
      return { data: resultFromExistingResults } as
        | AxiosResponse<IForce>
        | undefined;
    }

    setDisplayedForceData(undefined); // if need new data from backend reset view

    if (!plan.id || !forcesIds.length) return;

    let res = await Axios.get(
      `${baseUrlPMBackend}performanceGrades/getDashboardResults`,
      {
        params: {
          planId: plan.id,
          forceId: forcesIds[0],
        },
      }
    );
    if (res.status === 200 && componentMounted.current) setForceData(res.data);
    return res;
  };
  return {
    trainingTypeData,
    setTrainingTypeData,
    indicatorData,
    setIndicatorData,
    displayedForceData,
    setDisplayedForceData,
    setForceData,
    forceData,
    amountOfSoldiers,
    setAmountOfSoldiers,
    onlySoldiers,
    selectedForcesID,
    setSelectedForcesID,
  };
};

export const getElementColor = (element: IElementResult): string => {
  if (element.value === null) {
    return DashboardConstants.MISSING_GRADE_COLOR;
  }
  // TODO :: Consts should move to config / All of this functions should be in PM-Backend
  if (
    (!element.negative && element.value >= element.requiredThreshold) ||
    (element.negative && element.value <= element.requiredThreshold)
  ) {
    return DashboardConstants.GREEN_GRADE_COLOR;
  } else if (
    (!element.negative &&
      element.value < element.requiredThreshold &&
      element.value >= Math.round(Number(element.upperThreshold) / 2)) ||
    (element.negative &&
      element.value > element.requiredThreshold &&
      element.value <= Math.round(Number(element.lowerThreshold) / 2))
  ) {
    return DashboardConstants.YELLOW_GRADE_COLOR;
  } else {
    return DashboardConstants.RED_GRADE_COLOR;
  }
};

const getElementColorForIndicator = (
  passingSoldiers: number,
  participants: number
): [number, string] => {
  const percentage = (passingSoldiers / participants) * 100;
  if (isNaN(percentage)) return [0, DashboardConstants.MISSING_GRADE_COLOR];
  else if (percentage >= DashboardConstants.INDICATOR_PASSING_GRADE) {
    return [percentage, DashboardConstants.GREEN_GRADE_COLOR];
  } else if (
    percentage < DashboardConstants.INDICATOR_PASSING_GRADE &&
    percentage >= DashboardConstants.INDICATOR_WARNING_GRADE
  ) {
    return [percentage, DashboardConstants.YELLOW_GRADE_COLOR];
  } else {
    return [percentage, DashboardConstants.RED_GRADE_COLOR];
  }
};

const getAllChildren = (force: IForce): IForce[] => {
  let children: IForce[] = [];

  if (force.children.length > 0) {
    force.children.forEach(
      (child) => (children = children.concat(getAllChildren(child)))
    );
  } else if (force.isSoldier) {
    children.push(force);
  }
  return children;
};

const getChildResult = (
  child: IForce,
  ir: IIndicatorResult,
  ttr: ITrainingTypeResult,
  er: IElementResult
): IElementResult | null => {
  const childIR = child.results.indicatorResults.find(
    (cir: IIndicatorResult) => cir.id === ir.id
  );
  if (childIR) {
    const childTTR = childIR.trainingTypeResults.find(
      (cttr: ITrainingTypeResult) => cttr.id === ttr.id
    );
    if (childTTR) {
      const childER = childTTR.elementResults.find(
        (cer: IElementResult) => cer.id === er.id
      );
      if (childER) {
        return childER;
      }
    }
  }
  return null;
};

export default useGetCommanderDashboardGrades;
