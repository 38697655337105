import React, { useState } from "react";
import IIndicator from "../../Interfaces/IIndicator";

import IIndicatorsContext from "../../Interfaces/TrainingPlanContext/IIndicatorsContext";

export const IndicatorsContext = React.createContext<IIndicatorsContext | null>(
  null
);

const IndicatorsProvider: React.FC = ({ children }) => {
  const [indicators, setIndicators] = useState<IIndicator[]>([]);

  const updateIndicators = (indicators: IIndicator[]) => {
    setIndicators(indicators);
  };

  const addIndicator = (indicator: IIndicator) => {
    setIndicators([...indicators, indicator]);
  };

  const editIndicator = (indicator: IIndicator) => {
    setIndicators((prev: IIndicator[]) =>
      prev.map((prevIndicator: IIndicator) =>
        prevIndicator.id === indicator.id
          ? { ...prevIndicator, ...indicator }
          : prevIndicator
      )
    );
  };

  return (
    <IndicatorsContext.Provider
      value={{ indicators, updateIndicators, addIndicator, editIndicator }}
    >
      {children}
    </IndicatorsContext.Provider>
  );
};

export default IndicatorsProvider;
