import React, { Dispatch, FC, RefObject, SetStateAction } from "react";
import { IonRow } from "@ionic/react";
import PMInput from "../../TrainingPlan/PMInput";
import { Collapse } from "react-collapse";
import OrbatTree from "../../../Shared/Orbat/OrbatTree/OrbatTree";
import Dropdown from "../../../Shared/Dropdown/Dropdown";
import { useTranslation } from "react-i18next";
import IForceTreeNode from "../../../../Interfaces/IForceTreeNode";
import IShootingDay from "../../../../Interfaces/IShootingDay";
import IShootingHeat from "../../../../Interfaces/IShootingHeat";
import { Option } from "react-dropdown";
import IDropdownOption from "../../../../Interfaces/IDropdownOption";
import "./ShootingRangeInputs.css";
import OrbatInput from "../../../Shared/Orbat/Input/OrbatInput";

type Props = {
  isTreeOpened: boolean;
  setIsTreeOpened: Dispatch<SetStateAction<boolean>>;
  filterRef: RefObject<HTMLDivElement>;
  forcePath: string | undefined;
  selectedForces: IForceTreeNode[];
  setSelectedForces: Dispatch<SetStateAction<IForceTreeNode[]>>;
  selectedShootingDay: IShootingDay | undefined;
  selectedHeat: IShootingHeat | undefined;
  onChangeShootingDay: (currentItem: Option) => void;
  onChangeHeat: (currentValue: number) => void;
  shootingDayOptions: IDropdownOption[];
  heatOptions: IDropdownOption[];
};

const ShootingRangeInputs: FC<Props> = (props: Props): JSX.Element => {
  const {
    isTreeOpened,
    setIsTreeOpened,
    filterRef,
    forcePath,
    selectedForces,
    setSelectedForces,
    selectedShootingDay,
    selectedHeat,
    onChangeShootingDay,
    onChangeHeat,
    shootingDayOptions,
    heatOptions,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="inputs-row">
      <OrbatInput
        wrapperCss="selected-soldier"
        filterRef={filterRef}
        inputValue={`${forcePath ? forcePath : t("selectForces")}`}
        placeholder={`${t("selectForces")}`}
        onClickInput={() => {
          setIsTreeOpened((prev) => !prev);
        }}
        inputWidth="long"
        isForDropDown
        isTreeOpened={isTreeOpened}
        collapseCssClass="treeCollapse scrollM"
        selectedForces={selectedForces}
        setSelectedForces={setSelectedForces}
        limit={1}
      />
      <div className="selected-shooting-day-input">
        <Dropdown
          options={shootingDayOptions ? shootingDayOptions : []}
          onChange={(item) => onChangeShootingDay(item)}
          value={
            selectedShootingDay
              ? selectedShootingDay.name
              : t("selectShootingDay")
          }
          placeholder={t("selectShootingDay")}
          controlClassName="Dropdown DWidth"
          arrowClassName="DropdownArrow"
          placeholderClassName="DropdownPlaceholderCompetency"
          menuClassName="DropdownMenuCompetency DropdownMenu"
          disabled={false}
        />
      </div>
      <div className="selected-heat-input">
        <Dropdown
          options={heatOptions ? heatOptions : []}
          onChange={(item) => onChangeHeat(+item.value)}
          value={selectedHeat ? selectedHeat.name : t("selectHeat")}
          placeholder={t("selectHeat")}
          controlClassName="Dropdown DWidth"
          arrowClassName="DropdownArrow"
          placeholderClassName="DropdownPlaceholderCompetency"
          menuClassName="DropdownMenuCompetency DropdownMenu"
          disabled={false}
        />
      </div>
    </div>
  );
};

export default ShootingRangeInputs;
