import { TFunction } from "i18next";
import { useState, useEffect } from "react";
import customToast from "../Shared/Toast/CustomToast";
import {
  UIFGrades,
  UIFElementsData,
} from "../../Interfaces/IUrbanInstructorFeedback";
import EEventType from "../../Interfaces/EEventType";
import { sendDrillToBBackend } from "../Desktop/DataPosting/dataPostingHelpers";
const UFIComponentHook = (
  t: TFunction,
  traineeId: number | undefined,
  planId?: number | string | undefined,
  isDesktop?: boolean,
  afterPostHandler?: () => void
) => {
  const trainingTypeUIFId = 16;

  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [isTraineeChanged, setIsTraineeChanged] = useState<boolean>(false);
  const [UIFGrades, setUIFGrades] = useState<UIFGrades[]>([] as UIFGrades[]);
  const data: UIFElementsData[] = [
    {
      title: t("Practice"),
      dataType: "Practice",

      elements: [
        t("Practice1"),
        t("Practice2"),
        t("Practice3"),
        t("Practice4"),
        t("Practice5"),
        t("Practice6"),
        t("Practice7"),
      ],
    },
    {
      title: t("Covering"),
      dataType: "Covering",

      elements: [
        t("Covering1"),
        t("Covering2"),
        t("Covering3"),
        t("Covering4"),
        t("Covering5"),
      ],
    },
    {
      title: t("Decisions"),
      dataType: "Decisions",

      elements: [
        t("Decisions1"),
        t("Decisions2"),
        t("Decisions3"),
        t("Decisions4"),
        t("Decisions5"),
        t("Decisions6"),
      ],
    },
    {
      title: t("Sequence"),
      dataType: "Sequence",

      elements: [
        t("Sequence1"),
        t("Sequence2"),
        t("Sequence3"),
        t("Sequence4"),
        t("Sequence5"),
      ],
    },
    {
      title: t("CommandAndControl"),
      dataType: "CommandAndControl",

      elements: [
        t("CommandAndControl1"),
        t("CommandAndControl2"),
        t("CommandAndControl3"),
        t("CommandAndControl4"),
        t("CommandAndControl5"),
        t("CommandAndControl6"),
        t("CommandAndControl7"),
      ],
    },
  ];
  const initUFIGrades = () => {
    let newArray: UIFGrades[] = [];
    data.map((dataTypeData) => {
      newArray.push({
        dataType: dataTypeData.dataType,
        isComplete: false,
        value: undefined,
      });
    });
    setUIFGrades(newArray);
  };
  /**
   * check if the user insert the all required input
   */
  const isCompleteInput = () => {
    let isComplete = true;
    UIFGrades.map((grade) => {
      if (!grade.isComplete) isComplete = false;
    });
    setIsComplete(isComplete);
  };
  useEffect(() => {
    isCompleteInput();
  }, [UIFGrades]);
  useEffect(() => {
    initUFIGrades();
  }, []);
  /**
   * post the data to b-backend
   */
  const postData = async () => {
    if (
      (traineeId !== undefined && isNaN(traineeId)) ||
      traineeId === undefined
    ) {
      if (isDesktop) customToast.error(t("noSelectedSoldier"));
      return;
    }
    const timeStamp: Date = new Date();
    const UFIDrill = {
      MessageType: EEventType.URBAN_INSTRUCTOR_FEEDBACK,
      TimeStamp: timeStamp,
      TrainingTypeID: trainingTypeUIFId,
      DrillStartTime: timeStamp,
      SoldierID: traineeId,
      Practice: UIFGrades.find((grade) => grade.dataType === "Practice")?.value,
      Covering: UIFGrades.find((grade) => grade.dataType === "Covering")?.value,
      Decisions: UIFGrades.find((grade) => grade.dataType === "Decisions")
        ?.value,
      Sequence: UIFGrades.find((grade) => grade.dataType === "Sequence")?.value,
      CommandAndControl: UIFGrades.find(
        (grade) => grade.dataType === "CommandAndControl"
      )?.value,
      PlanId: planId,
    };
    const displayToast = true;
    sendDrillToBBackend(UFIDrill, afterPostHandler, undefined, t, displayToast);
  };

  useEffect(() => {
    initUFIGrades();
    setIsTraineeChanged(true);
  }, [traineeId]);
  return {
    data,
    isComplete,
    isTraineeChanged,
    setUIFGrades,
    postData,
    setIsTraineeChanged,
  };
};
export default UFIComponentHook;
